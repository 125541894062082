import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  Card,
  TextField,
  CardContent,
  Typography,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

import styles from "./styles";

const EditGeneralInfo = memo((props) => {
  const {
    configUser,
    handleCloseModal,
    handleOnchangeConfigUser,
    image_design,
    isOpenModal,
    listServices,
    setImage_design,
    showImage,
    updateConfigUser,
  } = props;

  return (
    <Modal
      open={isOpenModal}
      onClose={handleCloseModal}
      style={styles.modalContainer}
    >
      <Card style={styles.cardContainer}>
        <CardContent>
          <Typography
            variant='h2'
            gutterBottom
            style={styles.titleWhite}
            data-testid='Title-proveedor'
          >
            Datos generales
          </Typography>

          <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
            style={styles.divisorTop}
          >
            <Grid item style={styles.containerField}>
              <FormHelperText>
                Estado de solicitud
              </FormHelperText>
              <Select
                data-testid='select-status'
                value={configUser.status}
                name='status'
                onChange={handleOnchangeConfigUser}
                style={{
                  width: "100%",
                }}
              >
                {listServices.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item style={styles.containerField}>
              <TextField
                data-testid='text-webHook'
                label='URL para notificaciones'
                type='text'
                id='webHook'
                name='webHook'
                value={configUser.webHook}
                onChange={(event) => handleOnchangeConfigUser(event)}
                style={{ width: "100%", paddingTop: 5 }}
              />
            </Grid>

            <Grid item style={styles.containerField}>
              <FormHelperText style={styles.text_aux}>
                Validez de los enlaces
              </FormHelperText>
              <Select
                data-testid='select-timePaymentLink'
                value={configUser.timePaymentLink}
                name='timePaymentLink'
                onChange={handleOnchangeConfigUser}
                style={{
                  width: "100%",
                }}
              >
                {configUser.timePaymentList.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item style={styles.containerField}>
              <FormHelperText style={styles.text_aux}>
                Moneda recepción dinero
              </FormHelperText>
              <Select
                data-testid='select-currencyPayment'
                value={`${configUser.currencyPayment}`}
                name='currencyPayment'
                onChange={props.handleOnchangeConfigUser}
                style={{
                  width: "100%",
                }}
              >
                {configUser.CurrencyPaymentList.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item>
              <Grid container style={styles.gridButton}>
                <Button
                  variant='contained'
                  component='label'
                  style={styles.styleButtonImage}
                >
                  <Grid container style={styles.girdTextImage}>
                    <Grid item style={styles.gridImage}>
                      <InputLabel style={{ fontSize: 30 }}>🖼</InputLabel>
                    </Grid>
                    <Grid item>Subir imagen *</Grid>
                  </Grid>
                  <input
                    type='file'
                    name='files'
                    accept='.jpg, .jpeg, .png'
                    hidden
                    onChange={(e) => setImage_design(e.target.files)}
                  />
                </Button>
                {image_design.length > 0 && (
                  <Button onClick={showImage}>Ver Imagen</Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>

        <Grid
          container
          direction='row'
          justify='space-evenly'
          alignItems='center'
          style={styles.containerButtons}
        >
          <Button
            onClick={handleCloseModal}
            data-testid='button-Cerrar'
            style={styles.buttonClose}
          >
            Cerrar
          </Button>

          <Button
            onClick={updateConfigUser}
            style={{ backgroundColor: "#33F5A5" }}
            data-testid='button-Actualizar'
            color='primary'
          >
            Actualizar
          </Button>
        </Grid>
      </Card>
    </Modal>
  );
});

EditGeneralInfo.propTypes = {
  configUser: PropTypes.shape({
    status: PropTypes.string,
    webHook: PropTypes.string,
    timePaymentLink: PropTypes.string,
    currencyPayment: PropTypes.string,
    timePaymentList: PropTypes.array,
    CurrencyPaymentList: PropTypes.array,
  }),
  handleCloseModal: PropTypes.func,
  handleOnchangeConfigUser: PropTypes.func,
  handleUpdate: PropTypes.func,
  image_design: PropTypes.array,
  isOpenModal: PropTypes.bool,
  listServices: PropTypes.array,
  setImage_design: PropTypes.func,
  showImage: PropTypes.func,
  updateConfigUser: PropTypes.func,
};

EditGeneralInfo.defaultProps = {
  configUser: {
    status: "",
    webHook: "",
    timePaymentLink: "",
    currencyPayment: "",
    timePaymentList: [],
    CurrencyPaymentList: [],
  },
  handleCloseModal: () => {},
  handleOnchangeConfigUser: () => {},
  handleUpdate: () => {},
  image_design: [],
  isOpenModal: false,
  listServices: [],
  setImage_design: () => {},
  showImage: () => {},
  updateConfigUser: () => {},
};

export default EditGeneralInfo;
