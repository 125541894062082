import { apiCall } from "../../Middlewares";
import { ENDPOINTS } from "../../Config/env";

const fechSegmentsAdmin = async (isAll = false) => {
  let { url } = ENDPOINTS.ADMIN.SEGMENTS.LIST;

  if (isAll) {
    url = `${url}?page=1&count=10&q[s]=id+DESC`;
  }

  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.SEGMENTS.LIST,
    url,
  });
};

export default {
  fechSegmentsAdmin,
};
