import {GET_LIST} from 'react-admin';

import { API_URL } from '../../../../Config/env';

const requestHandler = (type, params) => {
    switch (type) {
        case GET_LIST: {
            const {
                filter: {
                    id,
                    target_type,
                    provider,
                    target_id,
                    is_error,
                },
                pagination: {
                    page,
                    perPage,
                },
                sort: {
                    field,
                    order,
                },
            } = params;

            let url = `${API_URL}/admin/requests?page=${page}&count=${perPage}&q[s]=${field}+${order}&`;
            url += id ? `q[id_eq]=${id}&`: '';
            url += target_type ? `q[target_type_eq]=${target_type}&`: '';
            url += provider ? `q[provider_eq]=${provider}&`: '';
            url += target_id ? `q[target_id_eq]=${target_id}&`: '';
            url += is_error ? `q[is_successful_eq]=${false}&`: '';

            return {
                url,
                method: 'get',
            };
        }
        default: {
            return {

            }
        }
    }
}

export default requestHandler;