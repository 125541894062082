import useCreateGeneralLimits from './useCreateGeneralLimits';
import useFetchGeneralLimits from './useFetchGeneralLimits';
import useAccessRole from './useAccessRole';

export const useGeneralLimits = (props = {}) => {
    const { isAccess } = useAccessRole();
    const hook = useFetchGeneralLimits(
        useCreateGeneralLimits(props)
    );

    return {
        ...hook,
        isAddLimit: (hook.countries || []).length > 0,
        isCreate: isAccess('create_general_limits'),
    }
};

export default useGeneralLimits;