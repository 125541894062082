import { useState, useMemo, useEffect, useCallback } from 'react';
import GeneralLimitsProvider from '../Providers/GeneralLimits';
import useAlerts from './useAlerts';
import useAccessRole from './useAccessRole';

export const useFetchGeneralLimits = (props = {}) => {
    const { newLimits = [], onCancelEdit = () => { } } = props;
    const [limits, setLimits] = useState([]);
    const [countriesData, setCountriesData] = useState([]);
    const [countryId, setCountryId] = useState(null)
    const [registerCountries, setRegisterCountries] = useState([]);
    const [userType, setUserType] = useState(null);
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [count, setCount] = useState(10)
    const { showModal, showLoading, close, errorMessage } = useAlerts();
    const { isAccess } = useAccessRole();

    const loadGeneralLimits = useCallback(async () => {
        if (!!isAccess('show_general_limits')) {
            try {
                const response = await GeneralLimitsProvider.getCountriesGeneralLimit();
                setCountriesData(response.data.data);
                setRegisterCountries([{ id: null, name: null }, ...response.data.register_countries]);
            } catch (error) {
                errorMessage(error.data.error.message);
            }

            try {
                const filters = { q: [] }

                if (userType) {
                    filters['q']['user_type_eq'] = userType === 'personal' ? 0 : 1;
                }

                if (countryId) {
                    filters['q']['country_id_eq'] = countryId;
                }

                const response = await GeneralLimitsProvider.getGeneralLimits({ page: page + 1, total, count, filters });
                const {
                    limits: limitsResponse,
                    total: totalResponse,
                } = response.data;

                setLimits(limitsResponse.data.map((item) => ({
                    ...item,
                    ...item.attributes,
                    limit_amount: item.attributes.amount,
                })));

                setTotal(parseInt(totalResponse));
            } catch (error) {
                errorMessage(error.data.error.message);
            }
        }
    }, [page, total, count, userType, countryId]);

    const onDeleteGeneralLimit = async (id) => {
        if (!!isAccess('delete_general_limits')) {
            try {
                const { isConfirmed = false } = await showModal({
                    title: '¿Estás seguro que deseas eliminar el límite?',
                    text: "Esta operación es irreversible",
                    icon: "warning",
                    confirmButtonColor: '#ff2e00',
                    cancelButtonColor: 'transparent',
                    confirmButtonText: 'Eliminar',
                });

                if (isConfirmed) {
                    showLoading();

                    await GeneralLimitsProvider.deleteGeneralLimit(id);
                    await loadGeneralLimits();

                    showModal({
                        title: '¡Límite eliminado!',
                        text: "",
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonColor: '#00ff9b',
                        confirmButtonText: 'Entendido',
                    });
                }
            } catch (error) {
                errorMessage(error.data.error.message);
            }
        }
    };

    useEffect(() => {
        let isFetch = true;

        (async () => {
            if (isFetch) {
                showLoading();
                await loadGeneralLimits();
                close();
            }
        })();

        return () => {
            isFetch = false;
        };
    }, [page, count, userType, countryId]);

    const onSave = async (limit, amount) => {
        if (!!isAccess('update_general_limits')) {
            showLoading();

            try {
                await GeneralLimitsProvider.updateGeneralLimit(limit.id, { amount });
                setLimits([...limits.map((item) => {
                    if (item.id === limit.id) {
                        item.limit_amount = amount;
                    }

                    return { ...item };
                })]);

                showModal({
                    title: '¡Cambios guardados!',
                    text: "",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: '#00ff9b',
                    confirmButtonText: 'Entendido',
                });

                onCancelEdit();
            } catch (error) {
                errorMessage(error.data.error.message);
            }
        }
    };

    const countries = useMemo(() => {
        const countryLimitsMap = new Map();

        newLimits.forEach((item) => {
            const countryId = item.country;
            countryLimitsMap.set(countryId, (countryLimitsMap.get(countryId) || 0) + 1);
        });

        limits.forEach((item) => {
            const countryId = item.country_id;
            countryLimitsMap.set(countryId, (countryLimitsMap.get(countryId) || 0) + 1);
        });

        return countriesData.map((country) => ({
            ...country,
            disabled: (countryLimitsMap.get(country.id) || 0) >= 2,
        }));
    }, [limits, newLimits]);

    const changeRowsPerPage = (event) => {
        setCount(event.target.value)
    }

    const changePage = (event, newPage) => {
        setPage(newPage)
    }

    const changeUserType = (event) => {
        setUserType(event.target.value)
    }

    const changeCountryId = (event) => {
        setCountryId(event.target.value)
    }

    return {
        ...props,
        userType,
        changeUserType,
        limits,
        isLimits: limits.length > 0,
        countries,
        page,
        total,
        count,
        onDeleteGeneralLimit,
        loadGeneralLimits,
        onSave,
        changePage,
        changeRowsPerPage,
        changeCountryId,
        registerCountries,
        countryId,
    }
};

export default useFetchGeneralLimits;