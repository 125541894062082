import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SaveButton,
  Toolbar,
} from "react-admin";

const CurrencyEdit = (props) => {
  const CurrencyEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  return (
    <Edit {...props} title={`Editar moneda ${props.id}`}>
      <SimpleForm toolbar={<CurrencyEditToolbar />}>
        <TextInput 
          disabled 
          label="Id" 
          source="id" 
        />
        <TextInput
          disabled
          source="attributes.singular_name"
          label="Nombre singular"
        />
        <TextInput
          disabled
          source="attributes.plural_name"
          label="Nombre plural"
        />
        <TextInput
          disabled
          source="attributes.iso_code"
          label="Código"
        />
        <TextInput
          source="attributes.quantity_decimals"
          label="Cantidad de decimales"
        />
        <BooleanInput
          source={"attributes.is_crypto"}
          label={"Crypto"}
        />
      </SimpleForm>
    </Edit>
  );
};

export default CurrencyEdit;
