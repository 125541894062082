import { downloadCSV }              from 'react-admin';
import { unparse as convertToCSV }  from 'papaparse/papaparse.min';

const capitalizeName = (name = '') => {
    name = name ? name.toLowerCase() : '';
    const tokens = name.split(' ');
    let capitaliceName = '';
    tokens.map((token) => {
        capitaliceName += token.charAt(0).toUpperCase() + token.slice(1) + ' '
        return null;
    });
    return capitaliceName;
};

const Exporter = (transactions) => {
    const usersForExport = transactions.map((transaction) => {
        const {id, attributes} = transaction;
        const {sender_id, sender_user: {data}, recipient_user, account_bank} = attributes;

        const sender_full_name = sender_id ? `${data.attributes.first_name} ${data.attributes.last_name}` : '';
        const sender_document_number = sender_id ? data.attributes.document_number : '';
        const sender_document_type = sender_id ? data.attributes.document_type : '';
        const recipient_full_name = recipient_user ? recipient_user.data ? `${recipient_user.data.attributes.first_name} ${recipient_user.data.attributes.last_name}` : '' : '';
        const recipient_document_number = recipient_user ? recipient_user.data ? recipient_user.data.attributes.document_number : '' : '';
        const recipient_document_type = recipient_user ? recipient_user.data ? recipient_user.data.attributes.document_type : '' : '';

        return {
            id,
            ...attributes,
            sender_first_name: sender_id ? capitalizeName(data.attributes.first_name) : '',
            sender_last_name: sender_id ? capitalizeName(data.attributes.last_name) : '',
            sender_full_name: sender_full_name,
            sender_document_type: sender_document_type,
            sender_document_number: sender_document_number,
            recipient_first_name: account_bank ? account_bank.first_name : recipient_user && recipient_user.data ? capitalizeName(recipient_user.data.attributes.first_name) : '',
            recipient_last_name: account_bank ? account_bank.last_name : recipient_user && recipient_user.data ? capitalizeName(recipient_user.data.attributes.last_name) : '',
            recipient_full_name: account_bank ? `${account_bank.first_name} ${account_bank.last_name}` : recipient_full_name,
            recipient_document_type: account_bank ? account_bank.document_type : recipient_document_type,
            recipient_document_number: account_bank ? account_bank.document_number : recipient_document_number,
            sender_email: sender_id ? data.attributes.email : '',
            recipient_email: account_bank ? account_bank.email : recipient_user ? recipient_user.data ? recipient_user.data.attributes.email : '' : '',
            bank_account: account_bank ? account_bank.account_bank.account_bank : '',
            bank: account_bank ? account_bank.account_bank.bank : '',
        };
    });

    const csv = convertToCSV({
        data: usersForExport,
        fields: [
            'id',
            'sender_first_name',
            'sender_last_name',
            'sender_full_name',
            'sender_document_type',
            'sender_document_number',
            'recipient_first_name',
            'recipient_last_name',
            'recipient_full_name',
            'recipient_document_type',
            'recipient_document_number',
            'description',
            'sender_email',
            'recipient_email',
            'amount',
            'total',
            'created_at',
            'currency',
            'default_currency',
            'bank',
            'bank_account',
        ]
    });

    downloadCSV(csv, 'Transacciones');
};

export default Exporter;
