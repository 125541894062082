import { useState, useEffect } from "react";
import SegmentProvider from "../Providers/Segments";

const useSegment = () => {
  const [segments, setSegments] = useState([]);

  useEffect(() => {
    let isFetch = true;

    (async () => {
      try {
        if (isFetch) {
          const response = await SegmentProvider.fechSegmentsAdmin(true);
          const SegmentList = [];

          response.data.segments.data.map((segment) => {
            SegmentList.push({
              ...segment,
              ...segment.attributes,
              value: segment.id,
              label: segment.attributes.name,
            });

            return null;
          });

          setSegments(SegmentList);
          isFetch = false;
        }
      } catch {}
    })();

    return () => {
      isFetch = false;
    };
  }, []);

  return {
    segments,
  };
};

export default useSegment;
