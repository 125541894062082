export const ALL_COUNTRIES = {
  id: "ALL",
  name: "Todos los países",
  checked: false,
};

export const STATUS_GENERAL_SNACKBARS = {
  identifier: "",
  description: "",
  textButton: "",
  segment: '',
  scene: '',
  status: false,
};

export const SEGMENTS = {
  marketing: 'Marketing',
  support: 'Soporte',
  operations: 'Operaciones',
};

export const Routes_APP = {
  Authentication: "Authentication",
  SignInScene: "SignInScene",
  LogInScene: "LogInScene",
  Logged: "Logged",
  Dashboard: "Dashboard",
  Exchange: "Exchange",
  Recharge: "Recharge",
  Receive: "Receive",
  Sent: "Sent",
  AccountBank: "AccountBank",
  Integration: "Integration",
  Security: "Security",
  Notifications: "Notifications",
  PushNotifications: "PushNotifications",
  PersonalInformation: "PersonalInformation",
  CryptoSettings: "CryptoSettings",
  AccountActivator: "AccountActivator",
  Pin: "Pin",
  PaymentLink: "PaymentLink",
  VerificationStatus: "VerificationStatus",
  Browser: "Browser",
  PaymentGateway: "PaymentGateway",
  PaymentVoucher: "PaymentVoucher",
  EmailConfirmation: "EmailConfirmation",
  RecoverPinAccessScene: "RecoverPinAccessScene",
  MenuScene: "MenuScene",
  RecoverPasswordAccessScene: "RecoverPasswordAccessScene",
  PinLogIn: "PinLogIn login",
  BusinessCredentials: "BusinessCredentials",
  LoadingScene: "LoadingScene",
  Loading: "Loading",
  AccountActivatorManual: "AccountActivatorManual",
  Favorites: "Favorites",
  CreateOrEditFavorite: "CreateOrEditFavorite",
  CardRequest: "CardRequest",
  VitaCard: "VitaCard",
  SetPin: "SetPin",
  UpdatePin: "UpdatePin",
  Coupons: "Coupons",
  ValidateSignScene: "ValidateSignScene",
  ActivateCrypto: "ActivateCrypto",
  PaymentServices: "PaymentServices",
  ChangeAvatar: "ChangeAvatar",
  ActivateBalance: 'ActivateBalance',
  PaymmentByLot: 'PaymmentByLot',
  Limits: 'Limits',
  Balances: 'Balances',
  Checkout: 'Checkout',
  BusinessPaymentLink: 'BusinessPaymentLink',
};

export const styleAlert = {
  color: 'white',
  subtitle: '',
  background: '#303030',
  confirmButtonColor: '#21BB8D',
  confirmButtonText: 'Entendido',
  height: 500,
  width: 464,
  showCloseButton: true,
  paddingBotton: 50,
  padding: 20,
  html: ' ‎',
};


// Banners

export const STATUS_ROUTES = [
  {
    id: "INTERNAL",
    name: "Ruta interna",
    checked: false,
  },
  {
    id: "EXTERNAL",
    name: "Ruta externa",
    checked: false,
  },
];

export const STATUS_GENERAL = {
  identifier: "",
  textBanner: "",
  fontColor1: "",
  fontColor2: "",
  btColorBg: "",
  btColorText: "",
  btText: "",
};

export const orderObject = (obj) => {
  const ordered = {};
  Object.keys(obj).sort().forEach((key) => {
    ordered[key] = obj[key];
  });
  return ordered;
};

export const SEGMENTS_USERS = [
  {
    key: 'business',
    label: 'B2B'
  },
  {
    key: 'natural',
    label: 'B2C'
  },
  {
    key: 'both',
    label: 'Ambos'
  }
];