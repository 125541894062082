import React from 'react';
import { ShowController } from 'react-admin';

import { Show } from './components';

const BeneficiaryShow = (props) => {
  return (
    <>
      <ShowController
        {...props}
        title={`Beneficiario ${props.id}`}
      >
        {controllerProps => Show({...controllerProps})}
      </ShowController>
    </>
  );
}

export default BeneficiaryShow;
