import { apiCall }   from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const getTransferRules = async () => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.TRANSFER_RULES.GET_RULES
    });
};

export default {
  getTransferRules
};
