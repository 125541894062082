import { apiCall }    from '../../Middlewares';
import { ENDPOINTS }  from '../../Config/env';

const fetchPricesBtc = async () =>
    await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.PRICES.BTC,
    });

const deletePriceConfiguration = async (id) => {
    const url = ENDPOINTS.ADMIN.PRICES.DELETE_PRICE_CONFIGURATION.url.replace(':id', id)
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.PRICES.DELETE_PRICE_CONFIGURATION,
        url,
    });
};

const getStatusOfPriceProviders = async () => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.PRICES.GET_STATUS_OF_PRICE_PROVIDERS,
    });
};

export default {
    fetchPricesBtc,
    deletePriceConfiguration,
    getStatusOfPriceProviders
}
