import React                   from 'react';
import moment                  from 'moment';
import ReactCountryFlag        from 'react-country-flag';
import * as IconCryptoCurrency from 'react-cryptocoins';
import { Container, Row, Col } from 'react-grid-system';

import { Flag }           from '../../../../../Components';
import { toMoney }        from '../../../../../Helpers/MoneyHelper';
import { flagsCountries } from '../../../../../Config/constants';

import styles from './styles';

const Configuration = (props) => {
    const {
        record: {
            btc_buy_fee,
            btc_buy_fee_usd,
            btc_sell_fee,
            btc_sell_fee_usd,
            updated_at,
            usd_buy_fee_ars,
            usd_buy_fee_bob,
            usd_buy_fee_clp,
            usd_buy_fee_cop,
            usd_buy_fee_ecs,
            usd_buy_fee_mxn,
            usd_buy_fee_pab,
            usd_buy_fee_pen,
            usd_buy_fee_pyg,
            usd_buy_fee_uyu,
            usd_buy_fee_ves,
            usd_buy_fee_usd,
            usd_buy_fee_gbp,
            usd_buy_fee_brl,
            usd_buy_fee_cny,
            usd_buy_fee_dop,
            usd_buy_fee_crc,
            usd_sell_fee_ars,
            usd_sell_fee_bob,
            usd_sell_fee_clp,
            usd_sell_fee_cop,
            usd_sell_fee_ecs,
            usd_sell_fee_mxn,
            usd_sell_fee_pab,
            usd_sell_fee_pen,
            usd_sell_fee_pyg,
            usd_sell_fee_uyu,
            usd_sell_fee_ves,
            usd_sell_fee_usd,
            usd_sell_fee_gbp,
            usd_sell_fee_brl,
            usd_sell_fee_cny,
            usd_sell_fee_dop,
            usd_sell_fee_crc,
        }
    } = props;

    moment.locale('es');
    return (
        <Container style={{ width: 500 }}>
            <Row>
                <Col sm={12} style={styles.box}>
                    <div style={styles.box}>
                        Última fecha de actualización
                    </div>
                    <div style={styles.box}>
                        {moment.utc(updated_at).format('LLLL')}
                    </div>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.fee}>
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            COMPRA
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            VENTA
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <IconCryptoCurrency.Btc color="yellow" size={30}/>
                            <ReactCountryFlag countryCode={flagsCountries["Chile"]} style={{fontSize: '2em'}} svg/>
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(btc_buy_fee, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(btc_sell_fee, 'USD')}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <IconCryptoCurrency.Btc color="yellow" size={30} />
                            <ReactCountryFlag countryCode={flagsCountries["Estados Unidos"]} style={{fontSize: '2em'}} svg/>
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(btc_buy_fee_usd, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(btc_sell_fee_usd, 'USD')}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <Flag record={{attributes: {name: 'Argentina'}}} />
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_buy_fee_ars, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_sell_fee_ars, 'USD')}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <Flag record={{attributes: {name: 'Bolivia'}}} />
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_buy_fee_bob, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_sell_fee_bob, 'USD')}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <Flag record={{attributes: {name: 'Brazil'}}} />
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_buy_fee_brl, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_sell_fee_brl, 'USD')}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <Flag record={{attributes: {name: 'Chile'}}} />
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_buy_fee_clp, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_sell_fee_clp, 'USD')}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <Flag record={{attributes: {name: 'China'}}} />
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_buy_fee_cny, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_sell_fee_cny, 'USD')}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <Flag record={{attributes: {name: 'Colombia'}}} />
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_buy_fee_cop, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_sell_fee_cop, 'USD')}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <Flag record={{attributes: {name: 'Costa Rica'}}} />
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_buy_fee_crc, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_sell_fee_crc, 'USD')}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <Flag record={{attributes: {name: 'Ecuador'}}} />
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_buy_fee_ecs, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_sell_fee_ecs, 'USD')}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <Flag record={{attributes: {name: 'México'}}} />
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_buy_fee_mxn, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_sell_fee_mxn, 'USD')}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <Flag record={{attributes: {name: 'Panamá'}}} />
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_buy_fee_pab, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_sell_fee_pab, 'USD')}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <Flag record={{attributes: {name: 'Paraguay'}}} />
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_buy_fee_pyg, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_sell_fee_pyg, 'USD')}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <Flag record={{attributes: {name: 'Perú'}}} />
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_buy_fee_pen, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_sell_fee_pen, 'USD')}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <Flag record={{attributes: {name: 'Uruguay'}}} />
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_buy_fee_uyu, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_sell_fee_uyu, 'USD')}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <Flag record={{attributes: {name: 'Venezuela'}}} />
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_buy_fee_ves, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_sell_fee_ves, 'USD')}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <Flag record={{attributes: {name: 'Estados Unidos'}}} />
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_buy_fee_usd, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_sell_fee_usd, 'USD')}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <Flag record={{attributes: {name: 'República Dominicana'}}} />
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_buy_fee_dop, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_sell_fee_dop, 'USD')}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.icon}>
                            <Flag record={{attributes: {name: 'Reino Unido'}}} />
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_buy_fee_gbp, 'USD')}
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {toMoney(usd_sell_fee_gbp, 'USD')}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Configuration;
