import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import Rest from "../../../Providers/Rest";
import HeaderMenu from "../../CouponSegmentList/CouponList/Components/HeaderMenu";
import styles from "./styles";
import useAlerts from "../../../hooks/useAlerts"

const SegmentCreate = (props) => {
  const { history } = props;
  const [name, setName] = useState("");
  const [rawFile, setRawFile] = useState([]);
  const [render, setRender] = useState({
    status: false,
    dataNotSave: [],
    id: null
  });
  const [finished, setFinished] = useState(true);
  const { errorMessage } = useAlerts()
  const [isInformationCompleted, setIsInformationCompleted] = useState(false);

  useEffect(() => {
    if (name !== "" && rawFile.length !== 0) {
      setIsInformationCompleted(false);
    }

    if (name === "" || rawFile.length === 0) {
      setIsInformationCompleted(true);
    }
  }, [rawFile, name]);

  const send = async () => {
    let data = {
      data: {
        document_file: { rawFile: rawFile[0] },
        name:name.trim(),
      },
    };

    try {
      const response = await Rest("CREATE", "segments", data);

      if (response.data) {
        const {
          data: {
            attributes: { user_not_found }, id
          },
        } = response;
        setFinished(false);

        return setRender({
          status: true,
          dataNotSave: user_not_found,
          id
        });
      }
    } catch (e) {
      if (e.data.error.message.document_file){
        errorMessage(e.data.error.message.document_file[0])
        document.getElementById("fileLoading").value = "";
        setRawFile([]);
        return;
      }
      errorMessage(e.data.error.message.name[0])
      document.getElementById("fileLoading").value = "";
      setRawFile([]);
      return;
    }
  };

  const changeName = (event) => {
    setName(event.target.value);
  };

  const showUserSaved = () => {
    return history.push(`/segmentListUsers?filter=%7B%22id%22%3A%22${render.id}%22%7D&order=DESC&page=1&perPage=10&sort=id`)
  }

  const RenderData = (props) => {
    return (
      <CardContent>
        <Table>
          <TableHead>
            <Grid item xs={12} style={styles.subTitle}>
              {props.title}
            </Grid>
            <TableRow>
              <TableCell align={"right"} style={{ width: 300 }}>
                Usuario #
              </TableCell>
              <TableCell align={"right"}>Email</TableCell>
            </TableRow>
          </TableHead>

          {Object.entries(props.data).length > 0 ? (
            <TableBody>
              {props.data.map((user, index) => {
                return (
                  <TableRow>
                    <TableCell align={"right"}>{index + 1}</TableCell>

                    {props.title === "Usuarios no guardados" ? (
                      <TableCell align={"right"}>{user}</TableCell>
                    ) : (
                      <TableCell align={"right"}>{user.email}</TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <Grid item style={styles.notFound}>
              No existen coincidencias
            </Grid>
          )}
        </Table>
      </CardContent>
    );
  };

  return (
    <>
      <HeaderMenu history={history} />
      <Card>
        <CardContent>
          <Grid container className={styles.container}>
            <Grid item xs={12} style={styles.header}>
              Crear Segmento
            </Grid>

            <Grid item xs={12} style={styles.boxCenter}>
              <TextField
                label='Nombre del segmento'
                value={name}
                onChange={changeName}
              />
            </Grid>

            {finished && (
              <Grid item xs={12} style={styles.boxCenter}>
                <Button
                  variant='contained'
                  component='label'
                  style={styles.inputFile}
                >
                  Elige el archivo .csv
                  <input
                    type='file'
                    id='fileLoading'
                    name='files'
                    accept='.csv'
                    hidden
                    onChange={(e) => setRawFile(e.target.files)}
                  />
                </Button>
              </Grid>
            )}

            <Grid item xs={12} style={styles.boxCenter}>
              {rawFile.length > 0 && (
                <Grid item xs={12} style={styles.header}>
                  <Grid>{rawFile.length > 0 && "Archivo Cargado"}</Grid>

                  <Grid> {rawFile.length > 0 && rawFile[0].name}</Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} style={styles.header}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => history.push("/segments")}
              >
                <div style={{ color: "darkslategray" }}>Cerrar </div>
              </Button>

              {finished && (
                <>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={send}
                    style={{ margin: 10 }}
                    disabled={isInformationCompleted}
                  >
                    <div style={{ color: "#000000" }}>Continuar</div>
                  </Button>
                </>

              )}
            </Grid>
          </Grid>
        </CardContent>

        {render.status && (
          <Grid style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={showUserSaved}
              variant='contained'
              color='primary'
            >
              <div style={{ color: "darkslategray" }}>Usuarios guardados </div>

            </Button>
          </Grid>
        )}

        {render.status && (
          <RenderData title={"Usuarios no guardados"} data={render.dataNotSave} />
        )}
      </Card>
    </>
  );
};

SegmentCreate.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default SegmentCreate;
