import { CREATE, GET_LIST, GET_ONE, DELETE, UPDATE } from "react-admin";

const responseHandler = (response, type, resource) => {
  switch (type) {
    case GET_LIST: {
      if (resource === 'segmentListUsers') {
        return {
          data: response.data.user_segments.data,
          total: response.data["total"],
        };
      }
      if (resource === 'couponsList'){
        return {
          data: response.data.user_segments.data,
          total: response.data["total"],
        };
      }
      return {
        data: response.data.segments.data,
        total: response.data["total"],
      };
    }
    case CREATE: {
      return {
        data: response.data.data,
      };
    }
    case UPDATE: {
      return {
        data: response.data.data,
      };
    }
    case GET_ONE: {
      return {
        data: response.data.segments.data[0],
      };
    }
    case DELETE: {
      if (response.data.error) {
        return {
          data: response.data.error,
        };
      }
      return {
        data: null,
      };
    }
    default: {
      return {};
    }
  }
};

export default responseHandler;
