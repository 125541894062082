const createStyles = () => {
  return {
    input_fields: {
      margin: '0 10px',
      minWidth: '450px'
    },
    checks: {
      margin: '0 20px',
    }
  }
}

export default createStyles;