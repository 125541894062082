import React from 'react';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';
import UserLimitRequest from '../UserLimitRequest';

export const CountryLimit = (props) => (
    <>
        <div style={styles.countryLimitContainer}>
            <div style={styles.containerTitle}>
                Límite activo del usuario asignado para su país {props.userLimit[3]}
            </div>
            <div style={styles.amount}>
                <ReactCountryFlag 
                    countryCode={props.userLimit[2] === 'COCOP' ? 'CO' : props.userLimit[2]} 
                    style={styles.flag} svg
                /> 
                <div style={styles.amountLabel}>{props.userLimit[0]} USD</div>
            </div>
        </div>
    </>
);

CountryLimit.propTypes = {
    userLimit: PropTypes.array,
};

CountryLimit.defaultProps = {
    userLimit: 0,
};

export const CountryLimitNotFound = (props) => (
    <>
        {props.userLimit && <div style={styles.countryLimitContainer}>
            <div style={styles.amount}>
                Sin Límite
            </div>
            <div style={styles.text}>
                El usuario no tiene asignado límites y su país {props.userLimit[3]} tampoco tiene asignado un limite
            </div>
        </div>}
    </>
);

CountryLimitNotFound.propTypes = {
    userLimit: PropTypes.array,
};

CountryLimitNotFound.defaultProps = {
    userLimit: 0,
};

export const ActiveUserTrxLimit = (props) => (
    <>
        {props.activeRequest ? (
            <div style={styles.container}>
                <div style={styles.containerTitle}>
                    <div>Límite actual</div>
                </div>
                <UserLimitRequest
                    {...props.activeRequest} 
                    isDataTable={false}
                    updateRequests={props.updateRequestsParent}
                />
            </div>
        ) : props.userLimit && props.userLimit[0] ? (
            <CountryLimit userLimit={props.userLimit} />
        ) : (
            <CountryLimitNotFound userLimit={props.userLimit}  />
        )}
    </>
);

ActiveUserTrxLimit.propTypes = {
    activeRequest: PropTypes.shape({
        id: PropTypes.number,
        email: PropTypes.string,
        request_date: PropTypes.string,
        current_limit: PropTypes.number,
        requested_limit: PropTypes.number,
        status: PropTypes.string,
    }),
    updateRequestsParent: PropTypes.func,
    userLimit: PropTypes.array,
};

ActiveUserTrxLimit.defaultProps = {
    activeRequest: null,
    updateRequestsParent: () => {},
    userLimit: null,
};

const styles = {
    containerTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        color: '#FFFFFF',
        fontWeight: 'bold',
        width: 1630,
        paddingTop: 30,
    },
    container: {
        paddingBottom: 20,
    },
    countryLimitContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingTop: 10,
    },
    amount: {
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: '1.5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    flag: {
        fontSize: '1.5em',
    },
    amountLabel: {
        paddingLeft: 10,
    },
    text: {
        color: '#FFFFFF',
    },
};

export default ActiveUserTrxLimit;