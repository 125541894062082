import React, { useEffect, useState } from 'react';
import { SelectInput } from 'react-admin';
import DescriptionIcon from '@material-ui/icons/Description';
import { Dialog, DialogContent, TextField, Button, Switch, FormControlLabel} from '@material-ui/core';
import Swal from 'sweetalert2';
import { UserProvider } from '../../../../../../Providers';
import { INTERNAL_DOCUMENT_TYPES_WRITING } from '../utils';
import { reduxForm } from 'redux-form';

let FileDialog = (props) => {
  const { open, handleClose: handleDialogClose, handleDownload, handleRefresh, action, item, record } = props;

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [filePreview, setFilePreview] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [internalDocumentType, setInternalDocumentType] = useState(null);
  
  useEffect(() => {
    setName(item && item.attributes ? item.attributes.name : '');
    setDescription(item && item.attributes ? item.attributes.description : '');
    setInternalDocumentType(item?.attributes?.internal_document_type || null);
    setIsDefault(item?.attributes?.is_default || false);
  }, [item]);

  useEffect(() => {
    if(action==='edit'){
      setIsValid(
        name.trim() !== '' && description.trim() !== ''
      );
    }else if(action==='create'){
      setIsValid(
        name.trim() !== '' && description.trim() !== '' && file
      );
    }
  }, [name, file, description, action, internalDocumentType]);

  useEffect(()=>{
    if(file){
      setFileName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    event.target.value = '';
  };

  const handleClearFile = () => {
    setFile(null);
    setFileName('');
    setFilePreview('');
  };

  const handleInternalDocumentTypeChange = (event, key, payload) => {
    setInternalDocumentType(key || null);
  }

  const handleIsDefaultChange = (event) => {
    setIsDefault(event.target.checked);
  }

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.set('name', name);
      formData.set('description', description);
      formData.set('internal_document_type', internalDocumentType || '');
      formData.set('is_default', (isDefault && internalDocumentType != null));

      if(action==='create'){
        // File is only available for creation
        formData.set('file', file);
        await UserProvider.createAttachmentFile(record.id, formData);
        Swal.fire({
          title: '¡Documento agregado!',
          text: '',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Entendido'
        });
      }else if(action==='edit'){
        await UserProvider.updateAttachmentFile(record.id, item.id, formData);
        Swal.fire({
          title: '¡Cambios guardados!',
          text: '',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Entendido'
        });
      }
      handleClose();
    } catch (error) {
      Swal.fire({
        title: 'Algo ha salido mal, no pudimos completar el proceso',
        text: error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: 'Reintentar'
      })
    }
  };

  const handleClose = () => {
    handleRefresh();
    handleClearFile();
    handleDialogClose();
  }

  const renderFileSection = () => {
    switch (action) {
      case 'create':
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '25px'}}>
            <input
              accept='.jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx'
              style={{ display: 'none' }}
              id='file'
              type='file'
              required
              onChange={handleFileChange}
            />
            <label htmlFor='file'>
              { 
                !filePreview &&
                <Button variant='contained' component='span' color='secondary'>
                Seleccionar archivo
                </Button>
              }
            </label>
            
            {file && (
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', justifyContent: 'center', alignItems: 'center'}}>
                {fileName} <br />
                {
                  (filePreview && file && file.type && file.type.split('/')[0]==='image') ?
                  (<img src={filePreview} alt='Selected File Preview' style={{ width: 'auto', height: '200px', marginTop: '5px' }} />) :
                  (<DescriptionIcon style={{ fontSize: 200, padding: '0', marginTop: '5px' }}/>)
                }
                <Button onClick={handleClearFile}>Limpiar</Button>           
              </div>
            )}
          </div>
        )
      case 'show':
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '25px'}}>
            {item && (
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', justifyContent: 'center', alignItems: 'center'}}>
                {item.attributes.file_name} <br />
                {
                  (item.attributes.file_content_type.split('/')[0]==='image') ?
                  (<img src={item.attributes.file_url} alt='Selected File Preview' style={{ width: 'auto', height: '200px', marginTop: '5px' }} />) :
                  (<DescriptionIcon style={{ fontSize: 200, padding: '0', marginTop: '5px' }}/>)
                }
                <Button onClick={() => handleDownload(item.attributes.file_url)}>Descargar</Button>           
              </div>
            )}
          </div>
        )
      case 'edit':
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '25px'}}>
            {item && (
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', justifyContent: 'center', alignItems: 'center'}}>
                {item.attributes.file_name} <br />
                {
                  (item.attributes.file_content_type.split('/')[0]==='image') ?
                  (<img src={item.attributes.file_url} alt='Selected File Preview' style={{ width: 'auto', height: '200px', marginTop: '5px' }} />) :
                  (<DescriptionIcon style={{ fontSize: 200, padding: '0', marginTop: '5px' }}/>)
                }
                <Button onClick={() => handleDownload(item.attributes.file_url)}>Descargar</Button>           
              </div>
            )}
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogContent>
        <form>
          <TextField
            disabled={action==='show'}
            autoFocus
            margin='dense'
            id='name'
            label='Nombre'
            type='text'
            fullWidth
            value={name}
            required
            onChange={handleNameChange}
          />

          {renderFileSection()}

          <TextField
            disabled={action==='show'}
            margin='dense'
            id='description'
            label='Descripción'
            type='text'
            fullWidth
            multiline
            rows={1}
            value={description}
            required
            onChange={handleDescriptionChange}
          />

          <SelectInput
            disabled={action==='show'}
            margin='dense'
            id='internal_document_type'
            label='Tipo de documento'
            choices = {INTERNAL_DOCUMENT_TYPES_WRITING[record?.attributes?.category]}
            onChange={handleInternalDocumentTypeChange}
            defaultValue={internalDocumentType}
            fullWidth
            resettable
          />

          {
            internalDocumentType != null &&
            <FormControlLabel
              control={
                <Switch disabled={action==='show'} checked={isDefault} onChange={handleIsDefaultChange}/>
              }
              label={'Documento por defecto'}
            />
          }
        </form>
      </DialogContent>
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '16px' }}>
        <Button onClick={handleClose} color='secondary'>
          {action==='show' ? 'Cerrar' : 'Cancelar'}
        </Button>
        {
          (action!=='show') &&
          <Button disabled={!isValid} onClick={handleSubmit} color='primary' variant='contained' style={{ marginLeft: '16px' }}>
            Guardar
          </Button>
        }
      </div>
    </Dialog>
  );
};

FileDialog = reduxForm({
  form: 'fileDialogForm'
})(FileDialog)

export default FileDialog;
