import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Paper from '@material-ui/core/Paper';
import styles from './styles';
import { LinearProgress, CircularProgress } from '@material-ui/core';
import { Typography, Button } from '@material-ui/core';
import Swal from 'sweetalert2';
import { UserProvider } from '../../../../../Providers';
import { useState } from 'react';
import moment from 'moment';
import useReset from '../../../../../hooks/useReset';

function NeitcomStatusLogTable(props) {
  const defaultNeitcomItemsToShow = [10, Math.min()]; // [0] for short, [1] for extended

  const {id, verification, provider, title} = props;
  const [neitcomItems, setNeitcomItems] = useState(verification?.['data'] || {});
  const [neitcomResults, setNeitcomResults] = useState('attributes' in neitcomItems ? neitcomItems['attributes']['results'] : []);
  const [neitcomTableMode, setNeitcomTableMode] = useState(0); // 0 for short, 1 for extended
  const [neitcomIsLoading, setNeitcomIsLoading] = useState(false);
  const { resetList } = useReset();

  const shortOrExtendNeitcomTable = () => {
    setNeitcomTableMode(1-neitcomTableMode);
  }

  const handleIgnore = async (item_id) => {
    let copy_of_results = [...neitcomResults];
    for(let i = 0; i < copy_of_results.length; i++){
      if(item_id === copy_of_results[i].id){
        // Visual change is first to give a better experience.
        let original_is_ignore = neitcomResults[i].is_ignored;
        copy_of_results[i].is_ignored = !original_is_ignore;
        try {
          await UserProvider.updateCriminalVerificationItemIgnoreStatus(id, item_id, {is_ignored: copy_of_results[i].is_ignored});
        } catch (error) {
          // Something went wrong, visual changes are reversed.
          copy_of_results[i].is_ignored = original_is_ignore;
        }
        break;
      }
    }
    setNeitcomResults(copy_of_results);
  }

  const NeitcomRecordsTable = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Severidad</TableCell>
            <TableCell>A. Ingreso</TableCell>
            <TableCell>Fuente</TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell>CI</TableCell>
            <TableCell>Sanción</TableCell>
            <TableCell>Crimen</TableCell>
            <TableCell>Ignorado</TableCell>
          </TableRow>
        </TableHead>
        { !neitcomIsLoading && <TableBody>
          {neitcomResults.length > 0 ? neitcomResults.slice(0, defaultNeitcomItemsToShow[neitcomTableMode]).map(row => {
          return (
            <TableRow key={ row.id } selected={ row.is_ignored }>
              <TableCell>{ row.percentage }%</TableCell>
              <TableCell>{ row.entry_date?.split('-')?.[0] }</TableCell>
              <TableCell component="th" scope="row">
                { row.source }
              </TableCell>
              <TableCell>{ row.full_name }</TableCell>
              <TableCell>{ row.ci_number }</TableCell>
              <TableCell>{ row.sanction }</TableCell>
              <TableCell>{ row.crime }</TableCell>
              <TableCell> <Button onClick={()=>handleIgnore(row.id)}>{ row.is_ignored ? 'Retomar' : 'Ignorar'}</Button> </TableCell>
            </TableRow>
          );
        }) : 
        <TableRow>
          <TableCell>No hay nada para mostrar</TableCell>
        </TableRow>}
        </TableBody> }
      </Table>
    )
  }

  const TruoraRecordsTable = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Severidad</TableCell>
            <TableCell>Fuente</TableCell>
            <TableCell>Resultado</TableCell>
            <TableCell>Ignorado</TableCell>
          </TableRow>
        </TableHead>
        { !neitcomIsLoading && <TableBody>
          {neitcomResults.length > 0 ? neitcomResults.slice(0, defaultNeitcomItemsToShow[neitcomTableMode]).map(row => {
          return (
            <TableRow key={ row.id } selected={ row.is_ignored }>
              <TableCell>{ row.percentage }%</TableCell>
              <TableCell component="th" scope="row">
                { row.source }
              </TableCell>
              <TableCell>{ row.sanction }</TableCell>
              <TableCell> <Button onClick={()=>handleIgnore(row.id)}>{ row.is_ignored ? 'Retomar' : 'Ignorar'}</Button> </TableCell>
            </TableRow>
          );
        }) : 
        <TableRow>
          <TableCell>No hay nada para mostrar</TableCell>
        </TableRow>}
        </TableBody> }
      </Table>
    )
  }

  const requestCriminalVerification = (provider) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: `¿Quieres solicitar una consulta del usuario con ${provider.toUpperCase()}?`,
      icon: 'warning',
      showCancelButton: true,
      background: 'rgb(19,19,19)',
      confirmButtonColor: '#9BA40E',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, Solicitar!',
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          setNeitcomIsLoading(true);
          let response = await UserProvider.requestCriminalVerification(id, provider);
          setNeitcomItems(response['data']['data']);
          setNeitcomResults(response['data']['data']['attributes']['results']);
          if(response?.data?.data?.attributes?.status === 'pending'){
            setTimeout(resetList, 15000);
          }else{
            setNeitcomIsLoading(false);
          }
        }
      })
      .catch((error) => {
        setNeitcomIsLoading(false);
        Swal.fire({
          title: `No fue posible consultar con ${provider.toUpperCase()}`,
          text: error.data?.message || error.message || 'Intente luego',
          icon: 'error',
          background: 'rgb(19, 19, 19)',
        });
      });
  };
  return (
    <>
      <Typography variant='h4' gutterBottom style={styles.title}>
        {title}
        {
          verification?.data?.attributes?.provider_report_url && (
            <a href={verification?.data?.attributes?.provider_report_url || '#'} rel="noopener noreferrer" target="_blank">
              <OpenInNewIcon
                style={{ color: 'white', fontSize: 13, verticalAlign: 'top' }}
              />
            </a>
          )
        }
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant='h4' gutterBottom style={styles.title}>
          <strong>Estado:</strong>
          {neitcomItems?.['attributes']?.['status'] === 'successful' && ' Exitosa'}
          {neitcomItems?.['attributes']?.['status'] === 'failed' && ' Fallida'}
          {neitcomItems?.['attributes']?.['status'] === 'pending' && ' Pendiente'}
          {neitcomItems?.['attributes']?.['status'] == null && ' /'}
        </Typography>
        <Typography variant='h4' gutterBottom style={styles.title}>
          <strong>Fecha:</strong>
          {(neitcomItems?.['attributes'] ? ` ${moment.utc(neitcomItems['attributes']['created_at']).format('DD/MM/YYYY HH:mm:ss')} UTC` : ' /')}
        </Typography>
        <Typography variant='h4' gutterBottom style={styles.title}>
          <strong>Origen:</strong>
          {neitcomItems?.['attributes']?.['generation_way'] === 'requested_by_operator' && ' Solicitado por un operador'}
          {neitcomItems?.['attributes']?.['generation_way'] === 'periodic_verification' && ' Verificación recurrente'}
          {neitcomItems?.['attributes']?.['generation_way'] === 'verification_flow' && ' Por solicitud de verificación'}
          {neitcomItems?.['attributes']?.['generation_way'] == null && ' /'}
        </Typography>
        <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#242424", width: '10rem' }}
            onClick={() => requestCriminalVerification(provider)}
            disabled={neitcomIsLoading}
          >

            { !neitcomIsLoading && 'Consultar'}
            { neitcomIsLoading && <CircularProgress size={20} color={'secondary'}/> }
        </Button>
      </div>
      <Paper style={styles.container}>
        { provider === 'neitcom' && <NeitcomRecordsTable />}
        { provider === 'truora' && <TruoraRecordsTable />}
        { neitcomIsLoading && <LinearProgress size={100} color={'secondary'}/> }
      </Paper>
      {neitcomResults.length>defaultNeitcomItemsToShow[0] && <div style={styles.container}>
        <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#242424" }}
            onClick={shortOrExtendNeitcomTable}
          >
            Mostrar 
             { neitcomTableMode===0 && ' más' }
             { neitcomTableMode===1 && ' menos' }
        </Button>
      </div>}
    </>
  );
}

export default withStyles(styles)(NeitcomStatusLogTable);
