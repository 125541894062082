const responseHandler = (response, type/* , resource */) => {
    switch (type) {
        default: {
            return {
                data: response.data.beneficiaries.data,
                total: response.data['total'],
            };
        }
    }
};

export default responseHandler;
