import {useState, useCallback, useMemo} from 'react';

import transactionsProvider from '../Providers/Transactions';
import {TO_NUMBER} from '../Helpers/MoneyHelper';

const useAddBalanceForAdmin = (props) => {
    const {history} = props;
    const [currency, setCurrency] = useState('clp');
    const [amount, setAmount] = useState(0);

    const isValidForm = useMemo(() => {
        return (amount > 0 && !!currency);
    }, [amount, currency]);

    const changeCurrency = (event) => {
        setCurrency(event.target.value);
    };

    const changeAmount = useCallback((event) => {
        setAmount(TO_NUMBER(event.target.value, currency.toUpperCase()));
    }, [currency]);

    const onSubmit = useCallback(async () => {
        if (isValidForm) {
            try {
                await transactionsProvider.addBalanceForAdmin({
                    currency,
                    total: amount,
                });

                history.push('/');
            } catch {}
        }
    }, [amount, currency, history]);

    return {
        currency,
        amount,
        isValidForm,
        changeCurrency,
        changeAmount,
        onSubmit,
    };
};

export default useAddBalanceForAdmin;
