import React from 'react';
import styles from '../styles';
import useAccessRole from '../../../../hooks/useAccessRole';
import { SimpleForm, Edit, SaveButton, DeleteButton, Toolbar, TextInput } from 'react-admin';
import { Grid } from '@material-ui/core';

import {
  validateRequired,
} from '../../../../Helpers/ValidateHelper';

const MarketingEdit = (props) => {

  const {isAccess} = useAccessRole();

  const RenderFields = () => {

    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        xs={12}
        sm={10}
        item
        style={styles.container}
      >
        <Grid
          xs={12}
          sm={3}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.title_es'}
            label={'Titulo Español'}
            fullWidth
            validate={validateRequired}
          />
        </Grid>
        <Grid
          xs={12}
          sm={3}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.title_en'}
            label={'Titulo Ingles'}
            fullWidth
            validate={validateRequired}
          />
        </Grid>
        <Grid
          xs={12}
          sm={2}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.place'}
            label={'Lugar'}
            fullWidth
            validate={validateRequired}
          />
        </Grid>
        <Grid
          xs={12}
          sm={2}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.max_messages'}
            label={'Cantidad'}
            fullWidth
            validate={validateRequired}
            type="number"
          />
        </Grid>
      </Grid>
    );
  };

  const MarketingEditToolbar = props => { 

    return (
      <Toolbar {...props} >
        <SaveButton />
        { isAccess('delete_marketing_messages') ? <DeleteButton style={{'margin-left': 'auto'}} /> : null }
      </Toolbar>
    )
  };
  
  return (
    <Edit 
      title={`Editar Titulo de mensaje #${props.id}`} 
      {...props}
    >
      <SimpleForm toolbar={<MarketingEditToolbar />}>
        <RenderFields />
      </SimpleForm>
    </Edit>
  );
};

export default MarketingEdit;