import React from 'react';
import {
    Filter,
    DateInput,
} from 'react-admin';
import { Grid } from '@material-ui/core';

const ReferrerFilter = (props) => {

    return (
        <Filter {...props}>
            <Grid 
                label="Fecha"
                source={'register_dates'}
            >
                <DateInput 
                    label={'Desde'}
                    source="register_dates.register_date_start"
                />
                <DateInput 
                    label={'Hasta'}
                    source="register_dates.register_date_end"
                />
            </Grid>
        </Filter>
    );
};

export default ReferrerFilter;
