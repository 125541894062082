import React              from 'react';
import ReactDOM           from 'react-dom';
import App                from './App';
import axios              from 'axios';
import * as serviceWorker from './serviceWorker';
import { API_URL, HAS_INDEX }        from './Config/env';
import './index.css';
import 'react-notifications/lib/notifications.css';

axios.defaults.baseURL = API_URL;

if (!HAS_INDEX) {
  const metaTag = document.createElement('meta');
  metaTag.setAttribute('name', 'robots');
  metaTag.setAttribute('content', 'noindex');
  document.head.appendChild(metaTag);
}

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
