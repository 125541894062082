import React from 'react';
import PropTypes from 'prop-types';
import { RemoveRedEye } from '@material-ui/icons';

import Navigation from '../../../../Navigation';

const ShowTransaction = (props) => {
    const { record = {} } = props;
    const { id } = record;

    const onNavigate = () => {
        window.open(`/transacciones/${id}/show`, '_blank');
    };

    return (
        <RemoveRedEye 
            onClick={onNavigate} 
            style={styles.icon}
        />
    );
};

ShowTransaction.propTypes = {
    record: PropTypes.shape({
        id: PropTypes.number,
    }),
};

ShowTransaction.defaultProps = {
    record: {
        id: 0,
    },
};

const styles = {
    icon: {
        cursor: 'pointer',
    },
};

export default ShowTransaction;