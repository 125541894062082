import colors from "../../../../../Assets/Colors";

const styles = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingBlock: 20,
    alignItems: "center",
  },
  title: {
    color: "white",
    marginTop: 30,
  },
  input: {
    width: 250,
  },
  grid: {
    padding: 30,
  },
  button: {
    width: 90,
    height: 40,
    border: 'none',
    borderRadius: 5,
    fontWeight: 600,
    marginTop: 30,
  },
};

export default styles;
