import { AUTH_LOGIN } from 'react-admin';

import { apiCall }   from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const Auth = async (type, params) => {
    if(type === AUTH_LOGIN) {
        const { username, password } = params;

        try {
            const response = await apiCall({
                ...ENDPOINTS.ADMIN.AUTH.SIGN_IN,
                data: {
                    email: username,
                    password
                }
            });

            const user = response.data.data;
            if(user.attributes.admin) {
                localStorage.setItem('headers', JSON.stringify(response.headers));
                localStorage.setItem('role_access', JSON.stringify(user.attributes.role));
            } else {
                throw new Error('No estás autorizado');
            }

        } catch (e) {
            throw new Error('No estás autorizado');
        }
    }

    return Promise.resolve();
};

export default Auth;
