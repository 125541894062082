const REJECT_VERIFICATION = 'rejected';
const ACCEPTED_VERIFICATION = 'accepted';
const INITIAL_VERIFICATION = 'initial';

const types = {
    REJECT_VERIFICATION,
    ACCEPTED_VERIFICATION,
    INITIAL_VERIFICATION,
};

export default types;
