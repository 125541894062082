import React, { memo } from 'react';
import DOMPurify from 'dompurify';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';
import { commentTags } from '../../../../../Config/constants';

const Comment = (props) => {
  const {
    attributes: {
      author: {
        full_name,
      },
      content,
      tags,
      created_at,
    },
  } = props;

  return (
    <div className='item-comment_container'>
      <div className='item-comment_author'>
        <p>
          {full_name}, {`${moment.utc(created_at).format('LLL')} UTC`}
        </p>
      </div>
      <div>
        {
          tags?.map((element, index) => {
            return <Chip
              key={index}
              style={{ fontSize: '0.7rem', height: '15px' }}
              variant='outlined' color='primary'
              label={commentTags[element]} />
          })
        }
      </div>
      <div className='item-comment_body'>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
      </div>
    </div>
  );
};

export default memo(Comment, (prev, next) => {
  return (
    prev.id === next.id &&
    JSON.stringify(prev.attributes) === JSON.stringify(next.attributes)
  );
});