import React, { useState } from "react";
import PropTypes, { array } from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { UserProvider } from "../../../../../../Providers";

import styles from "../styles";
import { useAlerts, useReset } from "../../../../../../hooks";
import { MAP_NAMES_TO_PREFERENTIAL_TYPES, MAP_PREFERENTIAL_TYPES_TO_NAMES, PREFERENTIAL_TYPES } from "../../utils";

const AddRoute = ({
  pricesConfiguration,
  origins,
  destinations,
  initialValue,
  setIsAddRoute,
  props
}) => {
  const [originCurrency, setOriginCurrency] = useState(origins[0]);
  const [destination, setDestination] = useState(destinations[0].country ? destinations[0].country : destinations[0].currency);
  const [preferentialPrice, setPreferentialPrice] = useState(0);
  const [value, setValue] = useState(initialValue.value);
  const [preferentialType, setPreferentialType] = useState(initialValue.preferentialType);
  const { errorMessage } = useAlerts();
  const { resetList } = useReset();

  const selectHandler = (e) => {
    const { value, name } = e.target;

    if (name === "preferential_type") {
      setPreferentialType(value);
      getValue(originCurrency, destination, MAP_NAMES_TO_PREFERENTIAL_TYPES[value]);
    } else {
      (name === "origin" ? setOriginCurrency : setDestination)(value);
      name === "origin" ? getValue(value, destination, preferentialType) : getValue(originCurrency, value, preferentialType);
    }
  };

  const createRoute = async () => {
    const infoDestination = destinations.find(item => item.currency === destination || item.country === destination);
    const configuration = pricesConfiguration.find(route => {
      return route.code === `${originCurrency.toUpperCase()}${infoDestination.currency.toUpperCase()}` && (infoDestination.id && route.country_id ? route.country_id === infoDestination.id : true);
    });

    let data = {
      price_configuration_id: configuration.id,
      id: props.record.id,
      origin_currency: originCurrency,
      destination_currency: infoDestination.currency,
      country_name: infoDestination.country,
      preferential_price: preferentialPrice,
      preferential_type: MAP_NAMES_TO_PREFERENTIAL_TYPES[preferentialType],
    };

    try {
      await UserProvider.createSendUserRoute(data);
      resetList();
    } catch (error) {
      errorMessage(error.data.message.includes("duplicate key value") ? "Registro existente!" : error.data.message);
    }
  };

  const getDestinationPair = () => {
    const foundDestination = destinations.find(item => (item.country ? item.country : item.currency) === destination)
    return foundDestination.currency
  }

  const getValue = (origin_currency, destination, preferentialType) => {
    const originRoute = origin_currency.toUpperCase();
    const foundDestination = destinations.find(item => (item.country ? item.country : item.currency) === destination);

    const configuration = pricesConfiguration.find(route => {
      return route.code === `${originRoute}${foundDestination.currency.toUpperCase()}` && (route.country_id && foundDestination.id ? route.country_id === foundDestination.id : true);
    });
    setValue(configuration ? (preferentialType === "exchange" || preferentialType === "price" ? configuration.sell_fee : configuration[preferentialType]) : null);
  }

  return (
    <Paper style={styles.descriptionInput}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell numeric></TableCell>
            <TableCell numeric></TableCell>
            <TableCell numeric></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <Select
              name="origin"
              onChange={selectHandler}
              value={originCurrency}
              style={styles.descriptionInputRight}
            >
              {origins.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
            <Select
              name="destination"
              onChange={selectHandler}
              value={destination}
              style={styles.descriptionInputRight}
            >
              {destinations.map((item) => {
                return <MenuItem value={item.country ? item.country : item.currency}>{item.country ? item.country : item.currency}</MenuItem>;
              })}
            </Select>
            <div style={styles.labelContainer}>
              <div style={styles.label}>Tipo Preferencial</div>
              <Select
                name="preferential_type"
                onChange={selectHandler}
                value={preferentialType}
                style={styles.descriptionInputRight}
              >
                {PREFERENTIAL_TYPES.map((item) => {
                  return <MenuItem value={MAP_PREFERENTIAL_TYPES_TO_NAMES[item.name]}>{MAP_PREFERENTIAL_TYPES_TO_NAMES[item.name]}</MenuItem>;
                })}
              </Select>

            </div>
            <TextField
              label="Código"
              value={`${originCurrency}${getDestinationPair()}`.toUpperCase()}
              style={styles.descriptionInputRight}
              disabled
            />
            <TextField
              label={MAP_PREFERENTIAL_TYPES_TO_NAMES[preferentialType]}
              value={value}
              style={styles.descriptionInputRight}
              disabled
            />
            <TextField
              label="Monto Preferencial"
              value={preferentialPrice}
              onChange={(e) => setPreferentialPrice(e.target.value)}
              style={styles.descriptionInputRight}
            />
            <Button
              style={{
                backgroundColor: parseFloat(preferentialPrice) >= 0 ? "#00BB8D" : "#B0B0B0",
                color: "white",
                border: "true",
                borderRadius: "4px",
                padding: "7px",
                cursor: parseFloat(preferentialPrice) >= 0 ? "pointer" : "default",
                marginRight: 10,
              }}
              onClick={parseFloat(preferentialPrice) >= 0 ? createRoute : null}
            >
              GUARDAR CAMBIOS
            </Button>
            <Button
              style={{
                backgroundColor: "#E9320A",
                color: "white",
                border: "true",
                borderRadius: "4px",
                padding: "7px",
                cursor: "pointer",
              }}
              onClick={() => setIsAddRoute(false)}
            >
              CANCELAR
            </Button>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

AddRoute.propTypes = {
  record: PropTypes.object,
  initialValue: PropTypes.object,
};

AddRoute.defaultProps = {
  initialValue: {},
}

export default AddRoute;
