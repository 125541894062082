import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import { apiCall } from "../../Middlewares";
import { API_URL } from "../../Config/env";

import styles from "./styles";

const BtcInformationUser = (props) => {
  const { user } = props;
  const [dataBtc, setDataBtc] = useState([]);
  const [btcBalance, setBtcBalance] = useState(0);

  useEffect(() => {
    let isFetch = true;

    (async () => {
      try {
        if (isFetch) {
          if (user) {
            const config = {
              url: `${API_URL}/admin/users/${user}/btc_addresses_information`,
              method: "get",
            };

            const res = await apiCall({
              authorization: true,
              ...config,
            });

            setBtcBalance(res.data.suma);
            setDataBtc(res.data.data.data);
          }
        }
      } catch (e) {}
    })();

    return () => {
      isFetch = false;
    };
  }, [user]);

  const renderizador = () => {
    return (
      <div style={styles.containerTab}>
        <Paper style={styles.paper}>
          <Table style={styles.table}>
            <caption style={styles.title}>Billeteras Bitcoin (Legacy)</caption>
            <TableHead>
              <TableRow>
                <TableCell align={"right"} style={styles.textTitle}>
                  Id
                </TableCell>
                <TableCell align={"right"} style={styles.textTitle}>
                  Direcciones bitcoin
                </TableCell>
                <TableCell align={"right"} style={styles.textTitle}>
                  Balance
                </TableCell>
                <TableCell align={"right"} style={styles.textTitle}>
                  Estado
                </TableCell>
              </TableRow>
            </TableHead>
            {dataBtc.map((UserInformation) => {
              const {
                attributes: { balance, multisig_address, status, id },
              } = UserInformation;
              return (
                <TableBody key={id}>
                  <TableRow key={balance}>
                    <TableCell align={"right"} style={styles.textBody}>
                      {id}
                    </TableCell>
                    <TableCell align={"right"} style={styles.textBody}>
                      {multisig_address}
                    </TableCell>
                    <TableCell align={"right"} style={styles.textBody}>
                      {balance}
                    </TableCell>
                    <TableCell align={"right"} style={styles.textBody}>
                      {status}
                    </TableCell>
                  </TableRow>
                </TableBody>
              );
            })}
            <TableBody>
              <TableCell align={"right"}></TableCell>
              <TableCell align={"right"} style={styles.textTitle}>
                Balance total
              </TableCell>
              <TableCell align={"right"} style={styles.textTitle}>
                {btcBalance}
              </TableCell>
              <TableCell align={"right"}></TableCell>
            </TableBody>
          </Table>
        </Paper>
        <br />
        <br />
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <div style={{ color: "#FFFFFF" }}>
        {dataBtc.length === 0
          ? "No hay direcciones bitcoin asociadas"
          : renderizador()}
      </div>
    </div>
  );
};

export default BtcInformationUser;
