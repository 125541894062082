import { Person }  from '@material-ui/icons';

import CardAccountList  from './CardAccountList';
import CardAccountShow  from './CardAccountShow';
import CardAccountEdit  from './CardAccountEdit';

export default {
    name: 'card_accounts',
    icon: Person,
    list: CardAccountList,
    show: CardAccountShow,
    edit: CardAccountEdit
}