import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const automaticCircleWithdrawal = async (data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.CIRCLE.AUTOMATIC_CIRCLE_WITHDRAWAL,
        data: { ...data }
    });
};

const completeCircleWithdrawal = async (data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.CIRCLE.COMPLETE_CIRCLE_WITHDRAWAL,
        data: { ...data }
    });
};

const rejectCircleWithdrawal = async (data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.CIRCLE.REJECT_CIRCLE_WITHDRWAL,
        data: { ...data }
    });
};

const circleBalance = async () => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.CIRCLE.BALANCE
    })
};

const masterAdminBalances = async () => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.CIRCLE.MASTER_ADMIN_BALANCES
    })
};

const userCircleBalances = async (id) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.CIRCLE.USER_CIRCLE_BALANCES(id)
    })
};

const getCircleNetworks = async () => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.CIRCLE.GET_CIRCLE_NETWORKS
    })
};

const circleWithdrawal = async (data) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.CIRCLE.CIRCLE_WITHDRAWAL,
        data: { ...data }
    });
};

export default {
    automaticCircleWithdrawal,
    completeCircleWithdrawal,
    rejectCircleWithdrawal,
    circleBalance,
    masterAdminBalances,
    userCircleBalances,
    getCircleNetworks,
    circleWithdrawal,
}
