import React, { useState, useEffect } from "react";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Switch from '@material-ui/core/Switch';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { CountriesProvider } from '../../../../../Providers';
import style from "./styles";
import useAlerts from "../../../../../hooks/useAlerts";
import useReset from "../../../../../hooks/useReset";
import useAccessRole from '../../../../../hooks/useAccessRole';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
});

const CurrenciesTable = (props) => {
  const [currentCurrencies, setCurrentCurrencies] = useState(null);
  const [hasDefault, setHasDefault] = useState(false);
  const [infoCurrenciesDefault, setInfoCurrenciesDefault] = useState(null);
  const [addCurrency, setAddCurrency] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const [selectOptions, setSelectOptions] = useState(null);
  const { successMessage, showLoading, errorMessage, close, showModal } = useAlerts();
  const { resetList } = useReset();
  const {isAccess} = useAccessRole();

  useEffect(() => {
    (async () => {
      let activeCountriesCurrencies = await CountriesProvider.getActiveCountriesCurrencies(props.record.id);
      let disabledCountriesCurrencies = await CountriesProvider.disabledCountriesCurrencies(props.record.id);
      setSelectOptions(disabledCountriesCurrencies.data.currencies);

      let newInfoCurrenciesDefault = {};
      let currencies = activeCountriesCurrencies.data.currencies

      setCurrentCurrencies(currencies);
      
      currencies.forEach(item => {
        newInfoCurrenciesDefault[`${item.currency.id}`] = item.is_default
      })

      setInfoCurrenciesDefault(newInfoCurrenciesDefault);
      setHasDefault(Object.values(newInfoCurrenciesDefault).includes(true))
    })();
  }, []);

  const handleSwitch = async (currency, is_default) => {
    const { isConfirmed = false } = await showModal({
      title: `¿Estás seguro de ${is_default ? "deshabilitar" : "habilitar"} la moneda ${currency.iso_code} como moneda por defecto?`,
      text: `Esta operación ${is_default ? "deshabilitará" : "habilitará"} la moneda por defecto para el país`,
      icon: "warning",
      confirmButtonColor: '#009bff',
      cancelButtonColor: 'transparent',
      confirmButtonText: is_default ? "deshabilitar" : "habilitar",
      cancelButtonText: "Cancelar"
    });

    if(isConfirmed){
      try {
        showLoading();
        await CountriesProvider.manageDefaultCurrency(props.record.id, currency.iso_code, !is_default);
        close();
        setInfoCurrenciesDefault((prev) => ({ ...prev, [currency.id]: !is_default }))
        setHasDefault(!hasDefault);
        successMessage("Operación realizada satisfactoriamente!");
      } catch (error) {
        errorMessage(error.data.error);
      }
    }
  }

  const changeDefaultCurrency = (currency) => {
    if(isAccess("add_default_currency_to_country")){
      return !infoCurrenciesDefault[currency.id] && hasDefault;
    }else{
      return true;
    }
  }

  const saveButton = async (e) => {
    e.preventDefault();
    const { isConfirmed = false } = await showModal({
      title: `¿Estás seguro de habilitar la moneda ${selectValue}?`,
      text: "Esta operación creará la moneda para el país",
      icon: "warning",
      confirmButtonColor: '#009bff',
      cancelButtonColor: 'transparent',
      confirmButtonText: 'Habilitar',
      cancelButtonText: "Cancelar"
    });

    if(isConfirmed){
      try {
        showLoading();
        await CountriesProvider.enableCurrency(props.record.id, selectValue);
        close();
        successMessage("Moneda agregada satisfactoriamente!");
        resetList();
      } catch (error) {
        errorMessage(error.data.error);
      }
    }

    setAddCurrency(false);
    setSelectValue("");
  }

  const AdministratorButtons = () => {
    return (
      <div style={style.buttonSeccion}>
        <button
          style={style.buttonStyle}
          onClick={() => setAddCurrency(true)}
        >
          Agregar moneda
        </button>
        {addCurrency && selectOptions && (
          <Select
          value={selectValue}
          onChange={(e) => setSelectValue(e.target.value)}
          style={{ width: 100 }}
        >
          {selectOptions.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
        )}
        {selectValue && (
          <button
            style={style.buttonStyle}
            onClick={(e) => saveButton(e)}
          >
            Guardar
          </button>
        )}
        {
          addCurrency && (
            <button
              style={{...style.buttonStyle, background: "#B90101"}}
              onClick={() => {
                setAddCurrency(false);
                setSelectValue("");
              }}
            >
              Cancelar
            </button>
          )
        }
      </div>
    )
  }

  return <>
      {isAccess("add_country_currency") && <AdministratorButtons />}
      <Paper className={styles.root}>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell align="right">Nombre Singular</TableCell>
              <TableCell align="right">Nombre Plural</TableCell>
              <TableCell align="right">Código</TableCell>
              <TableCell align="right">Moneda Defecto</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentCurrencies && infoCurrenciesDefault && currentCurrencies.map(({currency}) => (
              <TableRow key={currency.id}>
                <TableCell align="right">{currency.singular_name}</TableCell>
                <TableCell align="right">{currency.plural_name}</TableCell>
                <TableCell align="right">{currency.iso_code}</TableCell>
                <TableCell align="right">
                  <Switch
                    checked={infoCurrenciesDefault[currency.id]}
                    disabled={changeDefaultCurrency(currency)}
                    onClick={() => handleSwitch(currency, infoCurrenciesDefault[currency.id])}
                  />
              </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
  </>
};

export default CurrenciesTable;