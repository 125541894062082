import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Card,
  Typography,
  CardContent,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import ModalComponent from "../../../Components/ModalComponent";
import useSessionSnackbar from "./hooks/useSessionSnackbar";
import {
  RenderCountries,
  TitleSesion,
} from "../../../Components/Comunications";
import {
  Routes_APP as SCENE,
  SEGMENTS,
  orderObject,
} from "../../../Components/Comunications/utils";

import { styles } from "./styles";

const Snackbar = (props) => {
  const { open, handleCloseModal } = props;
  const {
    handleOnChangeStatusGeneral,
    handleCheckboxChangeCountries,
    handleOnChangeAllCountry,
    createSnackbar,
    updateSnackbar,
    statusGeneral,
    allCountries,
    optionsCountries,
    isEdit,
    counterCaracter,
    validateFields,
  } = useSessionSnackbar({ ...props });

  return (
    <ModalComponent open={open} onClose={handleCloseModal}>
      <Card style={styles.container}>
        <Button style={styles.buttonClose} onClick={handleCloseModal}>
          x
        </Button>
        <Typography
          variant='headline'
          align='center'
          style={{
            fontWeight: "bold",
            paddingTop: 30,
          }}
        >
          Nuevo Snackbar
        </Typography>

        <CardContent>
          <TitleSesion title={"General"} width={"92%"} />
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <TextField
                label={"Nombre identificatorio"}
                name='identifier'
                value={statusGeneral.identifier}
                onChange={handleOnChangeStatusGeneral}
                disabled={isEdit}
                style={{ width: "95%" }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <InputLabel id='select-label'>Segmentación</InputLabel>
                <Select
                  name='segment'
                  value={statusGeneral.segment}
                  onChange={handleOnChangeStatusGeneral}
                  style={{ width: 400 }}
                >
                  {Object.keys(SEGMENTS).map((route) => (
                    <MenuItem value={route} key={route}>
                      {SEGMENTS[route]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} style={{ position: "relative" }}>
              <TextField
                label={"Texto del snackbar"}
                name='description'
                value={statusGeneral.description}
                onChange={handleOnChangeStatusGeneral}
                inputProps={{
                  maxLength: 48,
                }}
                style={{ width: "83%" }}
              />
              <span style={styles.spanStyles}>
                {counterCaracter.description}/48
              </span>
              <div style={styles.underLineSpan} />
            </Grid>
            <Grid item xs={6} style={{ position: "relative" }}>
              <TextField
                label={"Texto botón"}
                name='textButton'
                value={statusGeneral.textButton}
                onChange={handleOnChangeStatusGeneral}
                inputProps={{
                  maxLength: 26,
                }}
                fullWidth
              />
              <span style={styles.spanStylesRigth}>
                {counterCaracter.textButton}/26
              </span>
            </Grid>
          </Grid>
        </CardContent>

        <CardContent>
          <TitleSesion title={"Ruta"} width={"95%"} />
          <Grid item xs={12}>
            <FormControl>
              <InputLabel id='select-label'>Escena</InputLabel>
              <Select
                name='scene'
                value={statusGeneral.scene}
                onChange={handleOnChangeStatusGeneral}
                style={{ width: 800 }}
              >
                {Object.keys(orderObject(SCENE)).map((route) => (
                  <MenuItem value={route} key={route}>
                    {SCENE[route]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </CardContent>

        <CardContent>
          <TitleSesion title={"Visibilidad"} width={"90%"} />
          <RenderCountries
            handleCheckboxChange={handleCheckboxChangeCountries}
            handleOnChangeAllCountry={handleOnChangeAllCountry}
            allCountries={allCountries}
            optionsCountries={optionsCountries}
            isEdit={isEdit}
          />
        </CardContent>

        <CardContent style={styles.containerButtons}>
          <Button
            variant='contained'
            onClick={handleCloseModal}
            style={{ backgroundColor: "#E9320A", marginRight: 60 }}
          >
            Cancelar
          </Button>
          <Button
            variant='contained'
            style={{ backgroundColor: validateFields ? "#005FEE" : "gray" }}
            onClick={isEdit ? updateSnackbar : createSnackbar}
            disabled={!validateFields}
          >
            {isEdit ? "GUARDAR CAMBIOS" : "CREAR SNACKBAR"}
          </Button>
        </CardContent>
      </Card>
    </ModalComponent>
  );
};

Snackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

Snackbar.defaultProps = {
  open: false,
  handleCloseModal: () => {},
};

export default Snackbar;
