import useAlerts from './useAlerts';
import useAccessRole from './useAccessRole';
import fortressProvider from '../Providers/fortress';
import Navigation from '../Navigation';
import { generateTableFromJson } from '../Resources/Fortress/utils';

const useFortress = (props = {}) => {
	const { record = {} } = props;
	const { accountNumber = '', id, ownerIdentityId } = record;
	const { isAccess } = useAccessRole();
	const { hiddenLoading, showLoading, showHTML, errorMessage } = useAlerts();
	  
	const onAnchoring = async () => {
		showLoading();

		try {
			const response = await fortressProvider.getFiatDepositInstructions({ 
				custodial_account_id: record.id, 
				currency: 'usd'
			});

			showHTML({ html: generateTableFromJson(response.data) });
		} catch(error) {
			errorMessage(error.data.error.message);
		}
		
		hiddenLoading();
	};

	const onBalances = async () => {
		showLoading();

		try {
			const response = await fortressProvider.getBalancesCustodialAccount({ 
				custodial_account_id: record.id
			});

			showHTML({ html: generateTableFromJson(response.data) });
		} catch(error) {
			errorMessage(error.data.error.message);
		}
		
		hiddenLoading();
	};

	const onCreateWithdrawal = async () => {
		if (accountNumber && id && ownerIdentityId) {
			Navigation.nav().history.push('/fortress/create', { 
				accountNumber,
				id: `${id}****${ownerIdentityId}`,
			});
		}
	};

	return {
		isAnchoring: isAccess('get_fiat_deposit_instructions_fortress'),
		isBalances: isAccess('get_balances_custodial_account_fortress'),
		isCreatePayment: isAccess('create_payment_fortress'),
		onCreateWithdrawal,
		onAnchoring,
		onBalances,
	}
};

export default useFortress;