import { useState } from 'react';
import useAccessRole from './useAccessRole';

const useEditGeneralLimit = (props = {}) => {
    const { 
        limit_amount = 0, 
        onSave = () => {},
    } = props;
    const [ amount, setAmount ] = useState(limit_amount);
    const { isAccess } = useAccessRole();

    const changeAmount = (event) => {
        setAmount(event.target.value);
    };

    const onSaveHandler = () => {
        onSave(amount);
    };

    return {
        ...props,
        amount,
        changeAmount,
        onSave: onSaveHandler,
        isDelete: isAccess('delete_general_limits'),
        isUpdate: isAccess('update_general_limits'),
    }
};

export default useEditGeneralLimit;