const styles = {
    container: {
        width: '100%'
    },
    table: {
        backgroundColor: '#323232'
    },
    paper: {
        width: '100%',
        marginTop: 3,
        overflowX: 'auto',
    },
    containerTab: {
        paddingBottom: 20
    }
};

export default styles;
