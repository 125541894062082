import React           from 'react';
import PropTypes       from 'prop-types';

import styles          from './styles';
import { toMoney }   from '../../Helpers/MoneyHelper';


const BalanceField = ({source, record = {}, isLabel = false, isShowCoin = true}) => {
    const {attributes: {balances}} = record;
    const renderLabel = () => {
        if(isLabel)
            return(
                <div style={styles.colorGreen}>
                    Balance {source.toUpperCase()}
                    <br/>
                </div>
            );
    };

    return(
        <div style={styles.PB10}>
            {renderLabel()}
            <div style={styles.colorWhite}>
                <span>{
                    toMoney(
                        balances[source],
                        source
                    )
                }</span>
                {isShowCoin ? <span>&nbsp;{source.toUpperCase()}</span> : null}
            </div>
        </div>
    );
};

BalanceField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    isLabel: PropTypes.bool
};

export default BalanceField;
