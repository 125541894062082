import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  TablePagination,
} from "@material-ui/core";

import { COLUNMS_LIST_LINKS } from "../../../utils";

import styles from "./styles";

const ListLinks = (props) => {
  const {
    listLinksUser,
    hadleCopyLink,
    handleChangePage,
    handleChangeRowsPerPage,
    pageList,
  } = props;

  return (
    <div style={styles.container}>
      <Typography variant='body1' gutterBottom style={styles.titleProvider}>
        Enlaces generados
      </Typography>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              {COLUNMS_LIST_LINKS.map((colum, index) => {
                return <TableCell key={index}>{colum}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {listLinksUser.length > 0 &&
              listLinksUser.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>
                      {" "}
                      {moment(item.createDate).format("LLLL")}{" "}
                    </TableCell>
                    <TableCell>
                      {" "}
                      {moment(item.expirationDate).format("LLLL")}{" "}
                    </TableCell>
                    <TableCell> {` ${item.amount.toLocaleString() } ${item.currency}`} </TableCell>
                    <TableCell> {item.status} </TableCell>
                    <TableCell> {item.link} </TableCell>

                    <TableCell>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => hadleCopyLink(item.link)}
                        data-testid='copy-button'
                      >
                        Copiar enlace
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          component='div'
          count={pageList.total}
          rowsPerPage={pageList.count}
          page={pageList.page}
          backIconButtonProps={{
            "aria-label": "Atras",
          }}
          nextIconButtonProps={{
            "aria-label": "Siguiente",
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};
ListLinks.propTypes = {
  listLinksUser: PropTypes.array,
  hadleCopyLink: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  pageList: PropTypes.shape({
    total: PropTypes.number,
    count: PropTypes.number,
    page: PropTypes.number,
  }),
};

ListLinks.defaultProps = {
  listLinksUser: [],
  hadleCopyLink: () => {},
  handleChangePage: () => {},
  handleChangeRowsPerPage: () => {},
  pageList: {
    total: 0,
    count: 0,
    page: 0,
  },
};

export default ListLinks;
