import React from 'react';
import { Filter, TextInput } from 'react-admin';

const AdminFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput
        label={'Buscar'}
        source={'search_key'}
        helperText={"Busca un usuario por id, nombre, apellido, email o DNI"}
        alwaysOn
      />
    </Filter>
  );
};

export default AdminFilter;
