import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useCountries from "../../hooks/useCountries";

const CountrySelect = (props) => {
  return (
    <Select
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      style={{ width: 200 }}
    >
      {props.isTranferCountry.map((itemField) => (
        <MenuItem key={`field${itemField.value}`} value={itemField.value}>
          {itemField.label}
        </MenuItem>
      ))}
    </Select>
  );
};

const CountrySelectHoc = (props) => {
  const hook = useCountries();
  const { paises } = hook;

  const isTransferCountry = paises.filter(
    (country) => country.is_transfer === true
  );

  if (paises.length > 0)
    return (
      <CountrySelect
        {...hook}
        isTranferCountry={isTransferCountry}
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        label={props.label}
      />
    );
  return null;
};

export default CountrySelectHoc;
