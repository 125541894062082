export const stylesCuponInitial = {
  cardContainer: { alignItems: "center", marginLeft: 30 },
  boxCenter: {
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: "center",
  },
  gridItem: {
    marginLeft: 25,
    marginRight: 5,
  },
  gridItem2: {
    marginLeft: 12,
  },
  inputCountry: {
    fontSize: 12,
    alignSelf: "flex-start",
    display: "flex",
    marginLeft: 20,
    marginTop: 9,
  },
  inputCoin: {
    fontSize: 12,
    alignSelf: "flex-start",
    display: "flex",
    marginLeft: 20,
    marginTop: 4,
  },
  inputTypeTransaction: {
    fontSize: 12,
    alignSelf: "flex-start",
    display: "flex",
    marginLeft: 20,
    marginTop: 3,
  },
  textStyle: {
    color: "#000000",
    fontSize: 12,
  },
  noStylesButton: {
    backgroundColor: "transparent",
    borderColor: "transparent",
    display: "grid",
  },
  inputBonus: {
    fontSize: 12,
    alignSelf: "flex-start",
    display: "flex",
    marginTop: 4,
  },
};

export const stylesScreenSegment = {
  gridContainer: {
    marginTop: 30,
    paddingLeft: "25%",
    paddingRight: "25%",
    display: "grid",
    alignItems: "center",
    backgroundColor: "red",
  },
  color: {
    color: "#000000",
  },
  containerTitle: { display: "grid", justifyContent: "center" },
  inputTitle: { textAlign: "left", marginBottom: 20, marginTop: 10 },
  gridContainerBack: {
    marginTop: 30,
    marginBottom: 20,
  },
  gridButton: {
    display: "flex",
    alignContent: "end",
    justifyContent: "end",
  },
};

export const stylesCuponDetails = {
  gridContainer1: {
    /*display: "grid",*/
    justifyContent: "center",
    alignContent: "center",
    marginRight: "20%",
    marginLeft: "20%",
  },
  gridContainerButton: {
    marginTop: 60,
    marginBottom: 15,
  },
  buttonDraft: {
    color: "#000000",
    fontSize: 12,
    display: "flex",
    padding: 20,
  },
  color: {
    color: "#000000",
  },
};

export const stylesCuponComplete = {
  cardContainer: {
    paddingLeft: "25%",
    paddingRight: "25%",
    width: "50%",
    marginBottom: 50,
    alignItems: "center",
  },
  gridItemEmoji: {
    marginTop: 40,
    marginBottom: 20,
  },
  textSize: {
    fontSize: 16,
  },
  gridContainerButton: {
    margin: 40,
    justifyContent: "center",
  },
  marginButton: {
    margin: 10,
  },
};