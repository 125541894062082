import { useEffect, useState } from "react";
import Swal from "sweetalert2";

import { RechargeProviders } from "../Providers";
import useAlerts from "./useAlerts";
import useAccessRole from "./useAccessRole";

const useProvidersRecharges = (idCountry) => {
  const { isAccess } = useAccessRole();

  const [hidden, setHidden] = useState(false);
  const [isRechargeStatus, setIsRechargeStatus] = useState(false);
  const [tabState, setTabState] = useState(0);
  const { successMessage, errorMessage } = useAlerts();
  const [providersList, setprovidersList] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [image_design, setImage_design] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isGlobal, setisGlobal] = useState(false);
  const [itemUpdate, setItemUpdate] = useState({
    id: 0,
    name: "",
    public_name: "",
    is_form: false,
    commission_local: 0,
    commission_type_local: "",
    commission_global: 0,
    commission_type_global: "",
    arrive_message: "",
    image: "",
    global_commission_status: "local",
    is_active: true,
    order: 1,
    is_auto_recharge: false,
  });

  const required = (message = "Required") => (value) => value ? undefined : message;
  const changeTab = (event, value) => setTabState(value);
  const editHide = (value) => setHidden(value);

  const validateString = (value) => {
    if (value === null || value === "")
      return "El campo Más información no puede estar vacio";
  };

  const validateModalRoute = [required(), validateString];

  useEffect(() => {
    if (idCountry && isAccess("get_recharge_providers") && isRechargeStatus) {
      getProvidersRecharge(idCountry);
    }
  }, [idCountry, isRechargeStatus]);

  useEffect(() => {
    if (image_design.length) {
      setIsUpdate(true);
    }
  }, [image_design]);

  useEffect(() => {
    if (itemUpdate.global_commission_status === "local") {
      setisGlobal(false);
    } else {
      setisGlobal(true);
    }
  }, [itemUpdate.global_commission_status]);

  const handleOnchange = (e, name = null) => {
    if (!isUpdate) {
      setIsUpdate(true);
    }
    if (!name && e.target.name) {
      setItemUpdate({
        ...itemUpdate,
        [e.target.name]: e.target.value,
      });
    } else {
      if (name) {
        const payload = {}
        payload[name] = e.target.checked

        setItemUpdate({
          ...itemUpdate,
          ...payload,
        });
      }
    }
  };

  const handleOpenModal = (item) => {
    setItemUpdate({
      id: item.id,
      name: item.name,
      public_name: item.public_name,
      is_form: item.is_form,
      commission_local: item.commission_country.commission,
      commission_type_local: item.commission_country.commission_type,
      commission_global: item.commission_global.commission,
      commission_type_global: item.commission_global.commission_type,
      arrive_message: item.arrive_message,
      global_commission_status: item.status_global_commission
        ? "global"
        : "local",
      is_active: item.is_active,
      order: item.order,
      is_auto_recharge: item.is_auto_recharge,
    });
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setImage_design([]);
    setIsUpdate(false);
    setItemUpdate({
      id: 0,
      name: "",
      public_name: "",
      is_form: false,
      commission_local: 0,
      commission_type_local: "",
      commission_global: 0,
      commission_type_global: "",
      arrive_message: "",
      image: "",
      global_commission_status: "local",
      order: 1,
      is_auto_recharge: false,
    });
    setIsOpenModal(false);
  };

  const showImage = () => {
    Swal.fire({
      position: "center",
      heightAuto: true,
      imageUrl: URL.createObjectURL(image_design[0]),
      text: "Imagen Cargada Correctamente.",
      width: 350,
      imageWidth: 200,
      imageHeight: 300,
      imageAlt: "Error",
    });
  };

  const getProvidersRecharge = async (id) => {
    try {
      const response = await RechargeProviders.fetchProvidersRecharge(id);
      if (response.data.recharge_providers.data.length) {
        setprovidersList(
          response.data.recharge_providers.data.map((item) => item.attributes)
        );
      }
    } catch (error) { }
  };

  const handleUpdateProvider = async () => {
    if (isAccess("update_recharge_providers")) {
      try {
        const formData = new FormData();

        formData.append("name", itemUpdate.name);
        formData.append("public_name", itemUpdate.public_name);
        formData.append("order", itemUpdate.order);
        formData.append("is_auto_recharge", itemUpdate.is_auto_recharge);
        formData.append("is_form", itemUpdate.is_form);
        formData.append("arrive_message", itemUpdate.arrive_message);
        formData.append(
          "is_global_commission",
          itemUpdate.global_commission_status === "local" ? false : true
        );
        formData.append("is_active", itemUpdate.is_active);

        if (itemUpdate.global_commission_status === "local") {
          formData.append("commission", itemUpdate.commission_local);
          formData.append("commission_type", itemUpdate.commission_type_local);
        } else {
          formData.append("commission", itemUpdate.commission_global);
          formData.append("commission_type", itemUpdate.commission_type_global);
        }

        if (image_design.length) {
          formData.append("recharge_provider_image", image_design[0]);
        }

        const response = await RechargeProviders.updateProviderRecharge(
          itemUpdate.id,
          formData,
        );

        if (response.status === 200) {
          handleCloseModal();
          getProvidersRecharge(idCountry);
          successMessage();
        }
      } catch (error) {
      }
    } else {
      return errorMessage("No posee permisos para esta operación");
    }
  };

  return {
    providersList,
    isOpenModal,
    itemUpdate,
    image_design,
    isUpdate,
    hidden,
    isRechargeStatus,
    validateModalRoute,
    tabState,
    isGlobal,
    getProvidersRecharge,
    handleUpdateProvider,
    handleOpenModal,
    handleCloseModal,
    showImage,
    setImage_design,
    handleOnchange,
    isAccess,
    setIsRechargeStatus,
    changeTab,
    editHide,
  };
};

export default useProvidersRecharges;
