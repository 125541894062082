import React from 'react';
import {Col, Container, Row} from 'react-grid-system';
import {Edit, FormTab, TabbedForm, TextInput, BooleanInput} from 'react-admin';

import styles from '../../Users/UserEdit/styles';
import {PostEditToolbar} from '../../Users/UserEdit/CustomComponents';

const SettingsHeadersEdit = (props) => {
    return (
        <Edit
            title={`Editar configuraciòn ${props.id}`}
            {...props}
        >
            <TabbedForm toolbar={<PostEditToolbar />}>
                <FormTab style={styles.titleWhite}>
                    <Container style={{ width: '100%' }}>
                        <Row container direction="row" justify="center" alignItems="center" className={{ flexGrow: 1 }}>
                            <Col xs={4} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Reglas'}
                                    source={'x_rules'}
                                    disabled
                                />
                            </Col>
                            <Col xs={4} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Configuración de residencia'}
                                    source={'x_residence_config'}
                                    disabled
                                />
                            </Col>
                            <Col xs={4} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Configuraciones'}
                                    source={'x_settings'}
                                    disabled
                                />
                            </Col>
                            <Col xs={4} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <BooleanInput
                                    label={'Aumentar reglas'}
                                    source={'x_rules_up'}
                                />
                            </Col>
                            <Col xs={4} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <BooleanInput
                                    label={'Aumentar configuración de residencia'}
                                    source={'x_residence_config_up'}
                                />
                            </Col>
                            <Col xs={4} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <BooleanInput
                                    label={'Aumentar configuraciones'}
                                    source={'x_settings_up'}
                                />
                            </Col>
                        </Row>
                    </Container>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default SettingsHeadersEdit;
