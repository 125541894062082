import { useState } from 'react';

export const useCreateGeneralLimits = (props = {}) => {
    const [ newLimits, setNewLimits ] = useState([]);
    const [ isEdit, setEdit ] = useState(false);
    const [ limitEdit, setLimitEdit ] = useState(null);

    const onDelete = (id) => {
        setNewLimits(newLimits.filter((item) => item.id !== id));
    };

    const addNewLimit = () => {
        setNewLimits((prevLimits) => [...prevLimits, { id: Math.floor(Math.random() * new Date().getTime()) }]);
    };

    const onCountrySelect = (id, country) => {
        setNewLimits(
            newLimits.map((item) => {
                if(item.id === id) {
                    item.country = country;
                }

                return item;
            })
        );
    };

    const onUserTypeSelect = (id, userType) => {
        setNewLimits(
            newLimits.map((item) => {
                if(item.id === id) {
                    item.userType = userType;
                }

                return item;
            })
        );
    };

    const onEdit = (limit) => {
        setEdit(true);
        setLimitEdit(limit);
    };

    const onCancelEdit = () => {
        setEdit(false);
        setLimitEdit(null);
    };

    return {
        limitEdit,
        isEdit,
        newLimits,
        addNewLimit,
        onDelete,
        onCountrySelect,
        onUserTypeSelect,
        onEdit,
        onCancelEdit,
    }
};

export default useCreateGeneralLimits;