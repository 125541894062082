import useAlerts from './useAlerts';
import UserTrxLimitsProvider from '../Providers/UserTrxLimits';

const useCreateActiveUserTrxLimit = (props = {}) => {
    const { user: { id }, updateRequests = () => {} } = props;
    const { showRequest, showLoading, errorMessage, close, showModal } = useAlerts();

    const onCreate = async () => {
        try {
            const { isConfirmed = false, value = 0 } = await showRequest({
                text: "Ingrese el monto a aprobar",
                input: "number",
                reasonLabel: 'El monto a aprobar es requerido',
            });

            if (isConfirmed && parseFloat(value) > 0) {

                const { isConfirmed = false } = await showModal({
                    title: `¿Estás seguro que asignar ${value} USD como límite aprobado?`,
                    text: "Esta operación asignará un nuevo límite activo",
                    icon: "warning",
                    confirmButtonColor: '#009bff',
                    cancelButtonColor: 'transparent',
                    confirmButtonText: 'Aprobar',
                });

                if (isConfirmed) {
                    showLoading();

                    await UserTrxLimitsProvider.createActiveUserTrxLimits({
                        approval_amount: value,
                        user_id: id,
                    });

                    updateRequests();
                }
            }
        } catch (error) {
            errorMessage(error.data.error.message);
        }

        close();
    };

    return {
        onCreate,
    }
};

export default useCreateActiveUserTrxLimit;