import { Accessibility } from '@material-ui/icons';

import MarketingList from './MarketingList';
import MarketingCreate from './MarketingCreate';
import MarketingEdit from './MarketingEdit';
import useAccessRole from '../../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
    name: 'marketing_message',
    icon: Accessibility,
    list: MarketingList,
    create: isAccess('create_marketing_messages') ? MarketingCreate : false,
    edit:isAccess('edit_marketing_messages') ?  MarketingEdit : false
}
