import { useEffect, useState } from 'react';
import VitaPricesProviders from '../Providers/VitaPrices';

const useGetCurrenciesVitaPrices = () => {
    const [ currencies, setCurrencies ] = useState([]);
    const [ targetCurrencies, setTargetCurrencies ] = useState([]);

    useEffect(() => {
        let isFetch = true;

        (async () => {
            if (isFetch) {
                try {
                    const response = await VitaPricesProviders.getCurrencies();
                    setCurrencies(response.data.currencies);
                    setTargetCurrencies(response.data.target_currencies);
                } catch {}
            }
        })();

        return () => {
            isFetch = false;
        }
    }, []);

    return {
        currencies,
        targetCurrencies,
    }
};

export default useGetCurrenciesVitaPrices;