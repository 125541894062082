import React, { useEffect, useState } from 'react';
import { Edit, SimpleForm, TextInput, SaveButton, Toolbar } from 'react-admin';

import { connect } from 'react-redux';
import { change } from 'redux-form';
import { Grid, Chip, Avatar, Tooltip, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { PermissionHelper } from '../../../Helpers';
import styles from '../styles';

import {
  validateRequired,
} from '../../../Helpers/ValidateHelper';

const RoleEdit = (props) => {

  const [permissionsList, setPermissionsList] = useState([]);
  const { dispatch } = props;

  useEffect(() => {

    PermissionHelper.getPermissions().then(data => {
      setPermissionsList(data);
    });

  }, []);

  const RenderFields = (propsRecord) => {

    const [activePermission, setActivePermission] = useState([]);

    const { record: {
      attributes: {
        name,
        permissions
      }
    } } = propsRecord;

    const handlePermission = (item) => {
      const newPermissions = activePermission.map(per => {
        return per === item ? { ...per, active: !per.active } : { ...per }
      })

      dispatch(change('record-form', 'attributes.permissions', newPermissions.filter(data => data.active === true)));

      setActivePermission(newPermissions);
    }

    useEffect(() => {
      setActivePermission(PermissionHelper.Permissions(permissions, permissionsList));
    }, []);

    return (
      <Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          xs={12}
          sm={8}
          item
          style={styles.container}
        >
          <Grid
            xs={12}
            sm={5}
            item
            style={styles.items}
          >
            <TextInput
              source={'attributes.public_name'}
              label={'Nombre Publico'}
              validate={validateRequired}
              fullWidth
            />
          </Grid>
          <Grid
            xs={12}
            sm={5}
            item
            style={styles.items}
          >
            <TextInput
              source={'attributes.name'}
              label={'Nombre'}
              validate={validateRequired}
              disabled
              fullWidth
            />
          </Grid>
          <Grid
            xs={12}
            sm={12}
            item
            style={styles.items}
          >
            <TextInput
              source={'attributes.description'}
              label={'Descripción'}
              validate={validateRequired}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          item
          xs={12}
          sm={10}
          style={styles.container}
        >
          <Grid xs={12} item>
            <Typography
              variant="title"
              gutterBottom
              style={styles.title}
            >
              Permisos del rol <b>{name}</b>
            </Typography>
          </Grid>

          {
            activePermission.map(val => (
              <Grid
                key={val.id}
                xs={12}
                sm={3}
                style={styles.divisor}
                item
                className='permission_container'
              >
                <Tooltip title={val.description} {...props}>
                  <Chip
                    label={val.public_name}
                    style={val.active ? styles.checked : styles.noChecked}
                    onClick={() => handlePermission(val)}
                    className={'role_chip'}
                    avatar={
                      <Avatar style={styles.avatar}>
                        {val.active ?
                          <CheckBoxIcon style={{ color: '#fff' }} /> :
                          <CheckBoxOutlineBlankIcon style={{ color: '#fff' }} />
                        }
                      </Avatar>
                    }
                  />
                </Tooltip>
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    )
  };

  const UserEditToolbar = props => (
    <Toolbar {...props} >
      <SaveButton />
    </Toolbar>
  );

  return (
    <Edit
      title={`Editar rol #${props.id}`}
      {...props}
    >
      <SimpleForm toolbar={<UserEditToolbar />}>
        <RenderFields />
      </SimpleForm>
    </Edit>
  );
};

export default connect()(RoleEdit);
