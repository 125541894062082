import { useEffect, useMemo, useState } from "react";
import useModalComponent from "./useModalComponent";
import { PixProvider } from "../Providers";
import useAlerts from "./useAlerts";
import useAccessRole from "./useAccessRole";

const usePix = () => {
  const { isAccess } = useAccessRole();
  const { showModal, successMessage, errorMessage, showLoading } = useAlerts();
  const { handleCloseModal, handleOpenModal, openModal } = useModalComponent();

  const [dataKey, setDataKey] = useState([]);
  const [returnPixData, setreturnPixData] = useState({
    value: "",
    comment: "",
  });
  const [createPixData, setCreatePixData] = useState({
    option: 0,
    value: "",
  });

  const validateCreatePix = useMemo(
    () => createPixData.option && !!createPixData.value,
    [createPixData]
  );

  const validateReturnPix = useMemo(
    () => returnPixData.value && returnPixData.comment,
    [returnPixData]
  );

  const handleDeleteKeyPix = async (id) => {
    try {
      if (!isAccess("manage_pix_keys")) {
        return errorMessage("No tienes permisos para realizar esta acción");
      }
      const { isConfirmed = false } = await showModal({
        confirmButtonColor: "#d33",
        cancelButtonColor: "#009bff",
        background: "lightgray",
      });

      if (!isConfirmed) return;
      showLoading();
      const response = await PixProvider.deletePix(id);

      successMessage("Llave eliminada con éxito");
      getKeyPix();
    } catch {
      errorMessage("Error en la operación");
    }
  };

  const handleCreateKeyPix = async () => {
    try {
      if (!isAccess("manage_pix_keys")) {
        return errorMessage("No tienes permisos para realizar esta acción");
      }
      showLoading();

      const response = await PixProvider.createPix({
        key_type_id: createPixData.option,
        type_value: createPixData.value,
      });

      if (response) {
        successMessage();
        handleCloseModal();
        setCreatePixData({
          option: 0,
          value: "",
        });
        getKeyPix();
      }
    } catch {
      errorMessage("Error al crear la llave pix");
    }
  };

  const handleReturnWithdrawal = async () => {
    try {
      if (!isAccess("manage_pix_keys")) {
        return errorMessage("No tienes permisos para realizar esta acción");
      }
      const { isConfirmed = false } = await showModal({
        confirmButtonColor: "#d33",
        cancelButtonColor: "#009bff",
        background: "lightgray",
      });

      showLoading();

      if (!isConfirmed) return;

      const response = await PixProvider.returnWithdrawal({
        end_to_end: returnPixData.comment,
        amount: returnPixData.value,
      });

      if (response) {
        successMessage(response.data.message);
        setreturnPixData({
          value: "",
          comment: "",
        });
      }
    } catch {
      errorMessage("Error en la operación");
    }
  };

  const handleOnChangeReturnPix = (key) =>
    setreturnPixData({
      ...returnPixData,
      [key.target.name]: key.target.value,
    });

  const handleOnChangeOption = (key) => {
    if (key.target.name === "option") {
      setCreatePixData({
        option: key.target.value,
        value: "",
      });
    } else {
      setCreatePixData({
        ...createPixData,
        [key.target.name]: key.target.value,
      });
    }
  };

  const handleClearCreateData = () => {
    handleCloseModal();
    setCreatePixData({
      option: 0,
      value: "",
    });
  };

  const getKeyPix = async () => {
    try {
      if (!isAccess("manage_pix_keys")) {
        return errorMessage("No tienes permisos para realizar esta acción");
      }
      const response = await PixProvider.fetchPixes();

      if (response) {
        setDataKey(response.data);
      }
    } catch {}
  };

  useEffect(() => {
    getKeyPix();
  }, []);

  return {
    createPixData,
    dataKey,
    handleCloseModal: handleClearCreateData,
    handleCreateKeyPix,
    handleDeleteKeyPix,
    handleOnChangeOption,
    handleOnChangeReturnPix,
    handleOpenModal,
    handleReturnWithdrawal,
    openModal,
    returnPixData,
    validateCreatePix,
    validateReturnPix,
  };
};

export default usePix;
