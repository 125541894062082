import { GET_LIST, GET_ONE, UPDATE, CREATE, DELETE } from "react-admin";

import { API_URL } from "../../../../Config/env";

const requestHandler = (type, params) => {
  switch (type) {
    case CREATE: {
      return {
        url: `${API_URL}/admin/vita_prices`,
        method: "post",
        data: {
          ...params.data.attributes,
          is_enabled: params.data.attributes['is_enabled'] || false,
        }
      };
    }
    case UPDATE: {
      return {
        url: `${API_URL}/admin/vita_prices/${params.id}`,
        method: "put",
        data: params.data.attributes,
      };
    }
    case DELETE: {
      return {
        url: `${API_URL}/admin/vita_prices/${params.id}`,
        method: "delete",
      };
    }
    case GET_ONE: {
      return {
        url: `${API_URL}/admin/vita_prices/${params.id}`,
        method: "get",
      };
    }
    case GET_LIST: {
      let {
        filter: {
          target_currency = '',
          currency = '',
        },
        pagination: { page, perPage },
        sort: { field, order },
      } = params;

      let url = `${API_URL}/admin/vita_prices?page=${page}&count=${perPage}&q[s]=${field}+${order}`;
      if (target_currency) {
        url = `${url}&q[target_currency_eq]=${target_currency}`;
      }

      if (currency) {
        url = `${url}&q[currency_eq]=${currency}`;
      }

      return {
        url,
        method: 'get',
      };        
    }
    default: {
      return {};
    }
  }
};

export default requestHandler;