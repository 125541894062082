import { CREATE, GET_LIST, GET_ONE, DELETE, UPDATE } from "react-admin";

const responseHandler = (response, type) => {
  switch (type) {
    case GET_LIST: {
      return {
        data: response.data.coupons.data,
        total: response.data["total"],
      };
    }
    case CREATE: {
      return {
        data: response.data.data,
      };
    }
    case UPDATE: {
      return {
        data: response.data.data,
      };
    }
    case GET_ONE: {
      return {
        data: response.data.coupons.data[0],
      };
    }
    case DELETE: {
      return {
        data: null,
      };
    }
    default: {
      return {};
    }
  }
};

export default responseHandler;
