import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const changeActiveStatus = async (id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.MESSAGES.MARKETING.CHANGE_ACTIVE_STATUS(id)
  });

const changeFixedStatus = async (id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.MESSAGES.MARKETING.CHANGE_FIXED_STATUS(id)
  });

const deleteMessage = async (id) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.MESSAGES.MARKETING.DELETE_MESSAGE(id)
  });

const createMessage = async (id, data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.MESSAGES.MARKETING.CREATE_MESSAGE(id),
    data
  });

const editMessage = async (id, data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.MESSAGES.MARKETING.EDIT_MESSAGE(id),
    data
  });

export default {
  changeActiveStatus,
  changeFixedStatus,
  deleteMessage,
  createMessage,
  editMessage
};
