const styles = {
    container: {
        padding: 5,
        paddingBottom: 20,
    },
    containerField: {
        padding: 5,
    },
    divisorTop: {
        paddingTop: 30,
    },
    titleWhite: {
        color: '#FFFFFF',
    },
    card: {
        padding: 10,
    },
};

export default styles;
