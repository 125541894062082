import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const RenderType = ({ type }) => {
  const renderTypeUser = (type) => {
    switch (type) {
      case "both":
        return "Ambos";
      case "natural":
        return "B2C";
      case "business":
        return "B2B";
      default:
        return "No definido";
    }
  };

  return (
    <Typography
      variant="headline"
      align="center"
      style={{
        fontSize: 12,
      }}
    >
      {renderTypeUser(type)}
    </Typography>
  );
};

export default RenderType;

RenderType.propTypes = {
  type: PropTypes.string,
};

RenderType.defaultProps = {
  type: "",
};

