import React from 'react';
import moment from 'moment'
import { List, Datagrid, TextField, FunctionField } from 'react-admin';

import DoneAllIcon from '@material-ui/icons/DoneAll';
import BlockIcon from '@material-ui/icons/Block';
import HistoryFilter from '../../Filters/HistoryFilter';
import { Pagination } from '../../../../Components';
import { WINDOW_FILTER_ACTIONS_HISTORY_MOUNTHS } from '../../../../Config/constants';

const ActionList = (props) => {
  
  return (
    <List
      {...props}
      title={'Historial de acciones'}
      exporter={false}
      filters={<HistoryFilter />}
      pagination={<Pagination />}
      bulkActionButtons={false}
      debounce={1000}
      filterDefaultValues={{
        history_dates: {
          history_date_start: moment().subtract(WINDOW_FILTER_ACTIONS_HISTORY_MOUNTHS, "months").format("YYYY-MM-DD")
        },
      }}
    >
      <Datagrid>
        <TextField
          source={'id'}
          label={'Id'}
        />
        <FunctionField
          label={'Nombre de acción'}
          render={(record) => {
            return `${record.attributes.action.public_name}`;
          }}
        />
        <FunctionField
          label={'Descripcíon de acción'}
          render={(record) => {
            return `${record.attributes.action.description}`;
          }}
        />
        <FunctionField
          label={'Id Usuario'}
          render={(record) => {
            return `${record.attributes.user.user_id}`;
          }}
        />
        <FunctionField
          label={'Email'}
          render={(record) => {
            return `${record.attributes.user.email}`;
          }}
        />
        <FunctionField
          label={'Fecha Ejecutada'}
          source={'attributes.created_at'}
          render={(record) => {
            return `${moment.utc(record.attributes.created_at).format('DD/MM/YYYY HH:mm:ss')} UTC`;
          }}
        />
        <TextField
          source={'attributes.target_id'}
          label={'Id Objectivo'}
        />
        <TextField
          source={'attributes.field'}
          label={'Auxiliar'}
        />
        <TextField
          source={'attributes.target_public_name'}
          label={'Tipo de Objetivo'}
        />
        <FunctionField
          label={'Acción permitida'}
          source={'attributes.is_allowed'}
          render={(record) => {
            return record.attributes.is_allowed ? <DoneAllIcon /> : <BlockIcon />
          }}
        />
        <TextField
          source={'attributes.ip'}
          label={'IP'}
        />
      </Datagrid>
    </List>
  );
};

export default ActionList;