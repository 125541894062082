import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  Card,
  TextField,
  CardContent,
  Typography,
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";

import styles from "./styles";

const EditProviderActive = memo((props) => {
  const {
    isOpenModal,
    isUpdate,
    handleAssignNewProvider,
    handleUpdateProvider,
    item,
    handleCloseModal,
    handleOnchange,
    dataProvider,
  } = props;

  return (
    <Modal
      open={isOpenModal}
      onClose={handleCloseModal}
      style={styles.modalContainer}
    >
      <Card style={styles.cardContainer}>
        <CardContent>
          <Typography
            variant='h2'
            gutterBottom
            style={styles.titleWhite}
            data-testid='Title-proveedor'
          >
            {isUpdate ? "Editar" : "Nuevo"} proveedor
          </Typography>

          <Grid
            container
            direction='row'
            justify='center'
            alignItems='center'
            style={styles.divisorTop}
          >
            <Grid item style={styles.containerField}>
              {isUpdate ? (
                <TextField
                  data-testid='input-country'
                  type='text'
                  value={item.country}
                  label={"País"}
                  name='País'
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ) : (
                <>
                  <Typography
                    gutterBottom
                    style={{ color: "white", fontSize: 12, marginBottom: -10 }}
                    data-testid='Title-País'
                  >
                    País
                  </Typography>
                  <Select
                    data-testid='select-country'
                    value={item.country}
                    name='country'
                    onChange={handleOnchange}
                    style={{
                      width: "100%",
                      marginTop: 15,
                    }}
                    disabled={isUpdate}
                  >
                    {dataProvider.listCountries.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </Grid>

            <Grid item style={styles.containerField}>
              {isUpdate ? (
                <TextField
                  data-testid='input-nameProvider'
                  type='string'
                  value={item.nameProvider}
                  label={"Proveedor"}
                  name='nameProvider'
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ) : (
                <>
                  <Typography
                    variant='h2'
                    gutterBottom
                    style={{ color: "white", fontSize: 12, marginBottom: -10 }}
                    data-testid='Title-proveedor'
                  >
                    Proveedor
                  </Typography>
                  <Select
                    data-testid='select-nameProvider'
                    value={item.nameProvider}
                    name='nameProvider'
                    onChange={handleOnchange}
                    style={{
                      width: "100%",
                      marginTop: 15,
                    }}
                  >
                    {dataProvider.listProviders.length &&
                      dataProvider.listProviders.map((attributes) => (
                        <MenuItem
                          key={attributes.id}
                          value={`${attributes.id}`}
                        >
                          {attributes.public_name}
                        </MenuItem>
                      ))}
                  </Select>
                </>
              )}
            </Grid>

            <Grid item style={styles.containerField}>
              <TextField
                data-testid='input-fixedCost'
                type='number'
                value={item.fixedCost}
                label={"Costo fijo (USD)"}
                onChange={handleOnchange}
                name='fixedCost'
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
              />
            </Grid>

            <Grid item style={styles.containerField}>
              <TextField
                data-testid='text-spread'
                type='number'
                value={item.spread}
                label={"spread"}
                onChange={handleOnchange}
                name='spread'
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
              />
            </Grid>
          </Grid>
        </CardContent>

        <Grid
          container
          direction='row'
          justify='space-evenly'
          alignItems='center'
          style={styles.divisorTop}
        >
          <Grid item>
            <Button
              onClick={props.handleCloseModal}
              data-testid='button-Cerrar'
              style={styles.buttonClose}
            >
              CANCELAR
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={
                isUpdate ? handleUpdateProvider : handleAssignNewProvider
              }
              style={{ backgroundColor: "#33F5A5" }}
              data-testid='button-Actualizar'
              color='primary'
            >
              {isUpdate ? "Actualizar" : "GUARDAR"}
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
});

EditProviderActive.propTypes = {
  item: PropTypes.shape({
    country: PropTypes.string,
    fixed_cost: PropTypes.number,
    id: PropTypes.number,
    nameProvider: PropTypes.string,
    spread: PropTypes.number,
  }),
  handleAssignNewProvider: PropTypes.func,
  handleCloseModal: PropTypes.func,
  handleOnchange: PropTypes.func,
  handleUpdateProvider: PropTypes.func,
  isOpenModal: PropTypes.bool,
  isUpdate: PropTypes.bool,
  dataProvider: PropTypes.shape({
    listCountries: PropTypes.array,
    listProviders: PropTypes.array,
  }),
};

EditProviderActive.defaultProps = {
  item: {
    country: "",
    fixed_cost: 0,
    id: 0,
    nameProvider: "",
    spread: 0,
  },
  handleAssignNewProvider: () => {},
  handleCloseModal: () => {},
  handleOnchange: () => {},
  handleUpdateProvider: () => {},
  isOpenModal: false,
  isUpdate: false,
  dataProvider: {
    listCountries: [],
    listProviders: [],
  },
};

export default EditProviderActive;
