import React, { useState } from "react";
import PropTypes from 'prop-types';
import { FileField, FileInput } from "react-admin";
import { Button } from "@material-ui/core";

export const FRONT_TYPE = 'DOCUMENT/FRONT_TYPE';
export const BACK_TYPE = 'DOCUMENT/BACK_TYPE';

const typeTexts = {
  [FRONT_TYPE]: {
    buttonTitle: 'Cambiar Documento Frontal',
    label: 'Cargar parte frontal del documento de identidad'
  },
  [BACK_TYPE]: {
    buttonTitle: 'Cambiar Documento Trasero',
    label: 'Cargar parte trasera del documento de identidad'
  },
};

const Document = (props) => {
  const {
    source,
    type,
  } = props;

  const [isDocument, setIsDocument] = useState(
    props?.record?.attributes[`${source}_url`]
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {isDocument ? (
        <>
          {/* <Photo {...props} /> */}
          <Button
            style={{ backgroundColor: "#00F5A5", marginTop: '8px' }}
            onClick={() => setIsDocument(!isDocument)}
          >
            { typeTexts[type].buttonTitle }
          </Button>
          <br />
        </>
      ) : (
        <FileInput
          source={`attributes.${source}`}
          label={typeTexts[type].label}
          placeholder={<p>Arrastra o haz click para cargar un documento</p>}
        >
          <FileField
            title={source}
            source={source}
          />
        </FileInput>
      )}
    </div>
  );
};

Document.propTypes = {
  source: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    FRONT_TYPE,
    BACK_TYPE,
  ]),
};

export default Document;