import React from 'react';
import { userLogout } from 'react-admin';
import Schedule from 'react-schedule-job';

const checkForExpiredToken = () => {
  let headers = JSON.parse(localStorage.getItem('headers')) || {};
  if ('expire-token-mobile' in headers) {
    let now_timestamp = Math.floor(Date.now() / 1000); // From millis to seconds.
    let expire_timestamp = parseInt(headers['expire-token-mobile']);

    if (now_timestamp >= expire_timestamp) {
      // Clean local storage
      localStorage.setItem('headers', null);
      localStorage.setItem('role_access', null);
      // Redirect to login
      window.location = '/login';
      userLogout();
    }
  }
};

const jobs = [
  {
    fn: checkForExpiredToken,
    id: '1',
    schedule: '* * * * *', // Every minute.
  },
];

export default function EventsScheduler(props) {
  return <Schedule jobs={jobs} timeZone='UTC' dashboard={{ hidden: true }} />;
}
