import React                        from 'react';
import {List, Datagrid, EditButton, TextField, FunctionField} from 'react-admin';

import {/* ConfigurationInfo, */ Limits} from './components';
import { FIELDS_EXPORTS_FILES } from '../../utils';
import useExportFiles from '../../../hooks/useExportFiles';

const ConfigurationList = (props) => {
    const { exportFilesSimple } = useExportFiles();

    const LimitsComponent = (propsConfiguration) => {
        return <Limits {...propsConfiguration} />;
    };

    const exporter = posts => {
        exportFilesSimple(posts, FIELDS_EXPORTS_FILES.CONFIGURATIONS)
    }

    const Config = (propsConfiguration) => {
        return (
            <div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <LimitsComponent {...props} {...propsConfiguration} />
                    {/*<Configuration {...props} {...propsConfiguration} />*/}
                </div>
            </div>
        );
    };

    return (
        <List
            {...props}
            title={'Configuración'}
            bulkActionButtons={false}
            exporter={exporter}
            debounce={1000}
        >
            <Datagrid>
                <Config />
                <TextField
                    source="vector_capital_spread"
                    label="Spread de vector capital (CLP)"
                />
                <TextField
                    source="setfx_spread"
                    label="Spread de setfx (COP)"
                />
                <TextField
                    source="spot_precision"
                    label="Precisión de precios fiat"
                />
                <TextField
                    source="crypto_precision"
                    label="Precisión de precios crypto"
                />
                <FunctionField
                    label={'Frecuencia de actualización de precios FIAT'}
                    render={record => `Cada ${parseInt(record.minutes_update_fiat_prices,10)} minutos`}
                />
                <FunctionField
                    label={'Frecuencia de actualización de precios BTC'}
                    render={record => `Cada ${parseInt(record.minutes_update_btc_prices,10)} minutos`}
                />
                {props.hasEdit ? <EditButton label={''} /> : null}
            </Datagrid>
        </List>
    );
};

export default ConfigurationList;
