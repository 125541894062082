import React, { useState, useEffect } from "react";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Switch from '@material-ui/core/Switch';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { CountriesProvider, UserProvider } from '../../../../../Providers';
import style from "./styles";
import useAlerts from "../../../../../hooks/useAlerts";
import useReset from "../../../../../hooks/useReset";
import useAccessRole from '../../../../../hooks/useAccessRole';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
});

const AddCurrencyToUser = (props) => {
  const [renderInfo, setRenderInfo] = useState(null);
  const { successMessage, showLoading, errorMessage, close, showModal } = useAlerts();
  const { resetList } = useReset();

  useEffect(() => {
    (async () => {
      let info_currencies = await UserProvider.getAvailableCurrencies(props.record.id);
      let currencies = info_currencies.data;
      let info = [];

      currencies.country_actived_currencies.forEach(currency => {
        info.push({
          ...currency,
          is_active: currencies.user_actived_currencies.some(item => item.toUpperCase() === currency.iso_code)
        })
      });

      setRenderInfo(info);
    })();
  }, []);

  const handleSwitch = async (iso_code) => {
    const { isConfirmed = false } = await showModal({
      title: `¿Estás seguro de activar la moneda ${iso_code} para el usuario?`,
      text: `Esta operación no puede ser revertida!`,
      icon: "warning",
      confirmButtonColor: '#009bff',
      cancelButtonColor: 'transparent',
      confirmButtonText: "Activar",
      cancelButtonText: "Cancelar"
    });

    if(isConfirmed){
      try {
        showLoading();
        await UserProvider.activateCurrencyToUser(props.record.id, {currency: iso_code});
        close();
        resetList();
        successMessage("Operación realizada satisfactoriamente!");
      } catch (error) {
        errorMessage(error.data.error);
      }
    }
  }

  return <>
    <Paper className={styles.root}>
      <Table className={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell align="right">Nombre Singular</TableCell>
            <TableCell align="right">Código</TableCell>
            <TableCell align="right">Activada</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderInfo && renderInfo.map(currency => (
            <TableRow>
              <TableCell align="right">{currency.singular_name}</TableCell>
              <TableCell align="right">{currency.iso_code}</TableCell>
              <TableCell align="right">
                <Switch
                  checked={currency.is_active}
                  disabled={currency.is_active}
                  onClick={() => handleSwitch(currency.iso_code.toLowerCase())}
                />
            </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  </>
};

export default AddCurrencyToUser;