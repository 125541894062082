import React from 'react';
import moment from 'moment';
import ReactCountryFlag from 'react-country-flag';
import * as IconCryptoCurrency from 'react-cryptocoins';
import WarningIcon from '@material-ui/icons/Warning'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

import { formatMoney } from '../../../../Helpers/MoneyHelper';

import Images from "../../../../Assets/images";

const OldPendingTransactionsTable = (props) => {
  const { rows, history } = props;

  const navigateToTransaction = (id) => {
    window.open(`/transacciones?filter={"id"%3A"${id}"}&order=DESC&page=1&perPage=10&sort=id`, '_blank');
  };

  const preparedISO = (iso_code) => {
    switch (iso_code) {
      case 'COCOP':
        return 'CO'
      case 'PEUSD':
        return 'PE'
      case 'CAUSD':
        return 'CA'
      case 'GBEUR':
      case 'GBUSD':
        return 'GB'
      case 'EUUSD':
        return 'EU'
      case 'HKUSD':
        return 'HK'
      case 'USWIRES':
        return 'US'
      default:
        return iso_code;
    }
  };

  return (
    <Paper style={{ margin: 'auto', marginTop: '20px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>ISO</TableCell>
            <TableCell>Id Transacción</TableCell>
            <TableCell>Emisor</TableCell>
            <TableCell>Fecha de creación</TableCell>
            <TableCell>Tiempo de atraso</TableCell>
            <TableCell>Monto Destino</TableCell>
            <TableCell>Bloqueada</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='click_able_row'>
          {rows && rows.map((row, id) => (
            <TableRow key={id} onClick={() => { navigateToTransaction(row.id) }}>
              <TableCell component="th" scope="row">
                {
                  preparedISO(row.iso_code.toUpperCase()) === 'USDT' ? (
                    <IconCryptoCurrency.Usdt size={24} />
                  ) : preparedISO(row.iso_code.toUpperCase()) === 'BTC' ? (
                    <IconCryptoCurrency.Btc size={24} />
                  ) : preparedISO(row.iso_code.toUpperCase()) === 'USDC' ? (
                    <img
                      style={{
                        display: 'inline-block',
                        width: '1em',
                        height: '1em',
                        verticalAlign: 'middle',
                        fontSize: '2em',
                      }}
                      src={Images.USDC}
                    />
                  ) : (
                    <ReactCountryFlag countryCode={preparedISO(row.iso_code.toUpperCase())} style={{ fontSize: '2em' }} svg />
                  )
                }
              </TableCell>
              <TableCell>{row.iso_code.toUpperCase()}</TableCell>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.sender_email}</TableCell>
              <TableCell>{moment.utc(row.created_at).format('LLLL')} UTC</TableCell>
              <TableCell>{row.time_diff}</TableCell>
              <TableCell>{`${formatMoney(row.amount_local_currency, row.local_currency)} ${row.local_currency?.toUpperCase()}`}</TableCell>
              <TableCell>
                {row.locked ? (
                  <WarningIcon style={{ color: 'red', marginRight: '5px', verticalAlign: 'bottom', fontSize: 'large' }} />
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper >
  );
};

export default OldPendingTransactionsTable;