const styles = {
    fee: {
        display: 'flex',
        textAlign: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        paddingTop: 10,
    },
    icon: {
        display: 'flex',
        textAlign: 'center',
        alignContent: 'center',
        justifyContent: 'center',
    },
    box: {
        paddingTop: 10,
        paddingBottom: 10,
    },
};

export default styles;
