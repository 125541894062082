import { useEffect, useMemo, useRef, useState } from "react";
import Swal from "sweetalert2";

import { CountriesProvider, Rest } from "../../../../Providers";
import {
  ALL_COUNTRIES,
  STATUS_GENERAL_SNACKBARS as STATUS_GENERAL,
  styleAlert,
} from "../../../../Components/Comunications/utils";
import useReset from "../../../../hooks/useReset";

const useSessionSnackbar = (props) => {
  const {
    location: { pathname },
    handleCloseModal = () => {},
  } = props;

  const { reloadPage } = useReset();

  const [countriesList, setCountriesList] = useState([])
  const [optionsCountries, setOptionsCountries] = useState([]);
  const [allCountries, setAllCountries] = useState(ALL_COUNTRIES);
  const [statusGeneral, setStatusGeneral] = useState(STATUS_GENERAL);
  const [isEdit, setIsEdit] = useState(false);
  const [counterCaracter, setCounterCaracter] = useState({
    description: 0,
    textButton: 0,
  });

  const isRequestSent = useRef(false)
  const isUpdateScreen = useMemo(() => pathname?.includes("edit"), [pathname]);
  const validateFields = useMemo(
    () =>
      statusGeneral.identifier &&
      statusGeneral.description &&
      statusGeneral.textButton &&
      statusGeneral.segment &&
      statusGeneral.scene &&
      (allCountries.checked ||
        optionsCountries.filter((option) => option.checked).length),
    [statusGeneral, allCountries, optionsCountries]
  );

  const handleCheckboxChangeCountries = (iso_code) =>
    setOptionsCountries((prevOptions) =>
      prevOptions.map((option) =>
        option.iso_code === iso_code
          ? { ...option, checked: !option.checked }
          : option
      )
    );

  const handleOnChangeAllCountry = () => {
    setAllCountries((prevAllCountries) => ({
      ...prevAllCountries,
      checked: !prevAllCountries.checked,
    }));
    setOptionsCountries(countriesList);
  };

  const handleOnChangeStatusGeneral = (event) =>
    setStatusGeneral(
      (prevState) =>
        (prevState = {
          ...prevState,
          [event.target.name]: event.target.value,
        })
    );

  const createSnackbar = async () => {
    if (isRequestSent.current) return
    isRequestSent.current = true
    const data = {
      ...statusGeneral,
      isGlobal: allCountries.checked ? true : false,
      isActive: false,
      snackbarsCountries: allCountries.checked
        ? []
        : optionsCountries.filter((option) => option.checked),
    };

    try {
      const response = await Rest("CREATE", "snackbars", data);
      if (response.data) {
        handleCloseModal();
        await Swal.fire({
          ...styleAlert,
          title: '<span style="color: white;">Snackbar creado!</span>',
        });

        reloadPage();
      }
    } catch (error) {
      if (error.data.error.identifier) {
        Swal.fire({
          ...styleAlert,
          title: '<span style="color: white;">Identificador repetido</span>',
        });
      } else {
        Swal.fire({
          ...styleAlert,
          title: '<span style="color: white;">Error creando snackbar</span>',
        });
      }
    }
  };

  const updateSnackbar = async () => {
    const data = {
      ...statusGeneral,
      id: pathname.split("/")[2],
    };

    try {
      const response = await Rest("UPDATE", "snackbars", data);

      if (response.data) {
        handleCloseModal();
        await Swal.fire({
          ...styleAlert,
          title: '<span style="color: white;">Cambios guardados</span>',
        });
      }

      reloadPage();
    } catch (error) {
      Swal.fire({
        ...styleAlert,
        title: '<span style="color: white;">Error guardando cambios</span>',
      });
    }
  };

  const restarData = () => {
    setAllCountries(ALL_COUNTRIES);
    setStatusGeneral(STATUS_GENERAL);
    setIsEdit(false);
  };

  const getCountries = async () => {
    try {
      let arrayCountries = [];
      let response = await CountriesProvider.fetchCountriesAdmin(true);

      if (response.data) {
        response.data.countries.data.forEach((country) => {
          if (country.attributes.is_register) {
            arrayCountries.push(
              {
                iso_code: country.attributes.iso_code,
                name: country.attributes.name,
                checked: false,
              }
            )
          }
        });
      }
      return arrayCountries;
    } catch { }
  };

  useEffect(() => {
    (async () => {
      try {
        if (isUpdateScreen) {
          const id = pathname.split("/")[2];
          const response = await Rest("GET_ONE", "snackbars", { id });

          if (response.data.attributes) {
            const {
              data: {
                attributes: {
                  identifier,
                  text_button,
                  description,
                  segment,
                  scene,
                  is_global,
                  list_countries,
                  is_active,
                },
              },
            } = response;

            setStatusGeneral({
              identifier,
              description,
              textButton: text_button,
              segment,
              scene,
              status: is_active,
            });

            let listCountriesAPI = await getCountries();
            is_global && setOptionsCountries(listCountriesAPI);
            const listCountries = JSON.parse(list_countries);

            listCountries.forEach((item) => {
              const matchedItem = listCountriesAPI.find(
                (checkItem) => checkItem.iso_code === item.iso_code
              );
              if (matchedItem) {
                matchedItem.checked = true;
              }
              return item;
            });

            is_global
              ? setAllCountries({ ...ALL_COUNTRIES, checked: true })
              : setOptionsCountries(listCountriesAPI);

            setIsEdit(true);
          }
        }
      } catch (error) {
      }
    })();
    return () => {
      restarData();
    };
  }, [pathname, isUpdateScreen]);

  useEffect(() => {
    setCounterCaracter(
      (prevState) =>
        (prevState = {
          description: statusGeneral.description.length,
          textButton: statusGeneral.textButton.length,
        })
    );
  }, [statusGeneral]);

  useEffect(() => {
    isRequestSent.current = false
  }, [statusGeneral.identifier])

  useEffect(() => {
    if (!isUpdateScreen) {
      (async () => {
        const data = await getCountries();
        setOptionsCountries(data);
        setCountriesList(data);
      })();
    }

    return () => {
      setCountriesList([]);
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    handleOnChangeStatusGeneral,
    handleCheckboxChangeCountries,
    handleOnChangeAllCountry,
    createSnackbar,
    updateSnackbar,
    statusGeneral,
    allCountries,
    optionsCountries,
    isEdit,
    counterCaracter,
    validateFields,
  };
};

export default useSessionSnackbar;
