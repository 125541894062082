import React from "react";
import { Datagrid, List, TextField } from "react-admin";
import HeaderMenu from "../../CouponSegmentList/CouponList/Components/HeaderMenu";


const SegmentList = (props) => {
  const { history } = props;

  const RenderList = () => {
    return (
      <List
        {...props}
        title={"segmentListUsers"}
        bulkActionButtons={false}
        debounce={1000}
      >
        <Datagrid>
          <TextField source={"attributes.user.id"} label={"Id user"} />
          <TextField source={"attributes.user.first_name"} label={"First name"} />
          <TextField source={"attributes.user.last_name"} label={"Last name"} />
          <TextField source={"attributes.user.email"} label={"Email"} />
        </Datagrid>
      </List>
    );
  };

  return (
    <>
      <HeaderMenu history={history} />
      <RenderList />
    </>
  );
};

export default SegmentList;
