const styles = {
    container: {
        paddingTop: 15,
        paddingBottom: 15,
        width: '100%',
    },
    containerDark: {
        paddingTop: 15,
        paddingBottom: 15,
        width: '100%',
        backgroundColor: 'rgba(0,0,0,.25)',
    },
    iconWhite: {
        color: '#FFFFFF',
        width: 20,
        height: 20,
    },
    collapseItem: {
        paddingTop: 12,
        paddingRight: 16,
        paddingBottom: 12,
        paddingLeft: 16
    },
    iconCollapseItemm: {
        width: 20,
        height: 20,
        marginRight: 19.2
    }
};

export default styles;
