const styles = {
  container: {
    marginLeft: -10,
    marginTop: 20,
  },
  titleProvider: {
    color: '#FFFFFF',
    fontSize: 20,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 40,
    marginTop: 60,
  },
  noLinks: {
    color: '#FFFFFF',
  },
};

export default styles;
