import { GET_LIST } from 'react-admin';

const responseHandler = (response, type, params) => {
    switch (type) {
        case GET_LIST: {
            const { dlocals, total, count } = response.data;
            return {
                total,
                count,
                data: dlocals,
            }
        }
        default: {
            return {
                data: response.data.dlocals.data,
                total: response.data['total'],
            }
        }
    }
};

export default responseHandler;
