import React from "react";
import {
  Grid,
  TextField,
  Button
} from "@material-ui/core";
import { Show, SimpleShowLayout } from "react-admin";

import styles from "./styles";

const SegmentShow = (props) => {
  const { history } = props

  const RenderFields = (propsRecord) => {
    const {
      record: { id, attributes },
    } = propsRecord;
    const { name } = attributes;

    const showUserSaved = () => {
      return history.push(`/segmentListUsers?filter=%7B%22id%22%3A%22${id}%22%7D&order=DESC&page=1&perPage=10&sort=id`)
    }

    return (
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item style={styles.containerField}>
          <TextField
            style={{ margin: 20 }}
            value={id}
            InputProps={{
              readOnly: true,
            }}
            label={"Id"}
          />
          <TextField
            value={name}
            InputProps={{
              readOnly: true,
            }}
            label={"Nombre de segmento"}
          />
        </Grid>
        <Grid style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={showUserSaved}
            variant='contained'
            color='primary'
          >
            <div style={{ color: "darkslategray" }}>Usuarios guardados </div>
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <RenderFields />
      </SimpleShowLayout>
    </Show>
  );
};

export default SegmentShow;
