import React            from 'react';
import PropTypes        from 'prop-types';
import ReactCountryFlag from 'react-country-flag';

import { flagsCountries } from '../../Config/constants';

import styles from './styles';

const Flag = (props) => {
    const { record } = props;
    const { attributes: { name } } = record;

    return (
        <ReactCountryFlag countryCode={flagsCountries[name]} style={styles.flag} svg/>
    );
};

Flag.propTypes = {
    record: PropTypes.shape({
        attributes: PropTypes.shape({
            name: PropTypes.string,
        }),
    }),
};

Flag.defaultProps = {
    record: {
        attributes: {
            name: '',
        },
    },
};

export default Flag;
