const styles = {
    divisor: {
        padding: 20,
    },
    divisorTop: {
        paddingTop: 50,
    },
    moneyLabel: {
        padding: 20,
        color: '#FFFFFF',
    },
};

export default styles;
