import React, { useCallback, useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  Card
} from '@material-ui/core';

import { UserProvider } from '../../../../../Providers';
import { useAlerts } from '../../../../../hooks';

import { replaceSymbols } from '../../../../../Helpers/ResourceHelper';
import styles from './styles';

const EditEmail = (props) => {
  const { record } = props;
  const { attributes } = record;
  const { successMessage, errorMessage } = useAlerts();
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState([]);
  const [isEmailValid, setEmailValid] = useState(false);

  const validateEmail = useCallback(async (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setErrors(['El email ingresado no es válido.']);
      return;
    }

    setTimeout(async () => {
      try {
        const response = await UserProvider.validateEmail(record.id, replaceSymbols(email));

        if (response.data.message === 'Email válido') setEmailValid(true);
      } catch (error) {
        if (error.data.errors) {
          setErrors(error.data.errors);

          setEmailValid(false);
        }
      }
    }, 1000);
  }, [setErrors, setEmailValid, record.id]);


  const handleChange = useCallback(async (e) => {
    setErrors([]);
    setEmail(e.target.value);
    validateEmail(e.target.value);
  }, []);


  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    try {
      const data = await UserProvider.updateEmail(record.id, replaceSymbols(email));
      await UserProvider.resetUserSession(record.id);

      successMessage(data.data.message);
    } catch (error) {
      errorMessage(error.data.message);
    }

    setEmail('');
  }, [
    email,
    errorMessage,
    record.id,
    successMessage
  ]);


  return (
    <Card style={styles.container}>
      <Typography style={styles.title}>Email</Typography>
      <Typography style={styles.subTitle}>{attributes.email} </Typography>
      <TextField
        label='Nuevo email'
        variant='standard'
        value={email}
        onChange={handleChange}
        required
        id='input'
      />
      <div>
        {errors &&
          errors.map((error, index) => (
            <Typography key={index} style={styles.error}>
              {error}
            </Typography>
          ))
        }
      </div>
      <Button
        variant='contained'
        onClick={handleSubmit}
        style={{ backgroundColor: isEmailValid ? '#00F5A5' : 'gray' }}
        disabled={!isEmailValid}
      >
        Actualizar
      </Button>
    </Card>
  );
};

export default EditEmail;
