import React, { useEffect, useState } from "react";

import { Grid, Button, InputLabel } from "@material-ui/core";
import SegmentSelect from "../../../../../Components/SegmentSelect";

import Swal from "sweetalert2";

import { stylesScreenSegment } from "./styles";

const ScreenSegment = (props) => {
  const {
    history,
    backSegmentToNewCoupon,
    sendScreenSegment,
    couponEditData,
    savedData,
  } = props;
  const [state, setstate] = useState({
    id: "",
  });

  const loadindData = () => {
    if (couponEditData !== null) {
      setstate({ id: couponEditData.id.toString() });
    }
    if (savedData !== null) {
      setstate({ id: savedData });
    }
  };

  useEffect(() => {
    loadindData();
  }, [couponEditData]);

  const sendSegmentId = () => {
    sendScreenSegment(state.id);
  };

  const handleOnchange = (e) => {
    setstate({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const editSegment = async () => {
    if (state.id !== "") {
      return history.push(`/segments/${state.id}`);
    }
    return Swal.fire("No posee un segmento valido");
  };

  return (
    <Grid container style={{ marginTop: 10 }}>
      <Grid container xs={12} style={{ display: "flex", alignContent: "" }}>
        <Grid item xs={6}>
          <Button
            style={stylesScreenSegment.color}
            onClick={() => history.push("/segments/create")}
          >
            {"+ Crear segmento "}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button style={stylesScreenSegment.color} onClick={editSegment}>
            {"Editar segmento "}
          </Button>
        </Grid>
      </Grid>

      <Grid container style={stylesScreenSegment.containerTitle}>
        <InputLabel style={stylesScreenSegment.inputTitle}>
          {"Segmento de usuarios"}
        </InputLabel>
        <SegmentSelect value={state.id} onChange={handleOnchange} name={"id"} />
      </Grid>
      <Grid container xs={12} style={stylesScreenSegment.gridContainerBack}>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3} style={stylesScreenSegment.gridButton}>
          <Button
            style={stylesScreenSegment.color}
            onClick={backSegmentToNewCoupon}
          >
            {"Atras "}
          </Button>
        </Grid>

        <Grid item xs={3}>
          <Button variant="contained" color="primary" onClick={sendSegmentId}>
            <div style={stylesScreenSegment.color}>Continuar</div>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScreenSegment;
