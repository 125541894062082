import React from 'react';
import {Col, Container, Row} from 'react-grid-system';
import {Edit, FormTab, TabbedForm, NumberInput, TextInput, BooleanInput} from 'react-admin';

import styles from '../../Users/UserEdit/styles';
import {PostEditToolbar} from '../../Users/UserEdit/CustomComponents';
import {TO_NUMBER, toMoney} from '../../../Helpers/MoneyHelper';
import useAccessRole from "../../../hooks/useAccessRole";

const PricesEdit = (props) => {
    const {isAccess} = useAccessRole();

    return (
        <Edit
            title={`Editar configuraciòn ${props.id}`}
            {...props}
        >
            <TabbedForm toolbar={<PostEditToolbar />}>
                <FormTab label="Datos Personales" style={styles.titleWhite}>
                    <Container style={{ width: '100%' }}>
                        <Row container direction="row" justify="center" alignItems="center" className={{ flexGrow: 1 }}>
                            <Col xs={4} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Id'}
                                    source={'id'}
                                    disabled
                                />
                            </Col>
                            <Col xs={4} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Moneda'}
                                    source={'attributes.currency_name'}
                                    disabled
                                />
                            </Col>
                            <Col xs={4} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Paìs'}
                                    source={'attributes.country_name'}
                                    disabled
                                />
                            </Col>
                            <Col xs={2} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <NumberInput
                                    label={'SPOT'}
                                    source={'attributes.spot'}
                                    disabled={!isAccess('update_spot_price')}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <NumberInput
                                    label={'Porcentaje de error permitido (%)'}
                                    source={'attributes.allowable_error_rate'}
                                />
                            </Col>
                            <Col xs={2} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Spread de venta'}
                                    source={'attributes.sell_fee'}
                                    format={v => toMoney(v, 'USD')}
                                    parse={v => TO_NUMBER(v, 'USD')}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Spread de venta para envíos internos'}
                                    source={'attributes.sell_fee_send'}
                                    format={v => toMoney(v, 'USD')}
                                    parse={v => TO_NUMBER(v, 'USD')}
                                />
                            </Col>
                            <Col xs={2} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Spread de venta para tarjetas'}
                                    source={'attributes.sell_fee_card'}
                                    format={v => toMoney(v, 'USD')}
                                    parse={v => TO_NUMBER(v, 'USD')}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Costo fijo para retiros a cuenta propia en moneda origen'}
                                    source={'attributes.fixed_cost'}
                                    format={v => toMoney(v, 'USD')}
                                    parse={v => TO_NUMBER(v, 'USD')}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Costo fijo para envíos internacionales'}
                                    source={'attributes.fixed_cost_transfer'}
                                    format={v => toMoney(v, 'USD')}
                                    parse={v => TO_NUMBER(v, 'USD')}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Costo fijo para envíos internos'}
                                    source={'attributes.fixed_cost_send'}
                                    format={v => toMoney(v, 'USD')}
                                    parse={v => TO_NUMBER(v, 'USD')}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <TextInput
                                    label={'Costo fijo para vita card'}
                                    source={'attributes.fixed_cost_vita_card'}
                                    format={v => toMoney(v, 'USD')}
                                    parse={v => TO_NUMBER(v, 'USD')}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <BooleanInput
                                    label={'Costo fijo en retiros'}
                                    source={'attributes.is_apply_fixed_cost_in_withdrawal'}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <BooleanInput
                                    label={'Costo fijo en transferencias'}
                                    source={'attributes.is_apply_fixed_cost_in_transfers'}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <BooleanInput
                                    label={'Costo fijo en envíos'}
                                    source={'attributes.is_apply_fixed_cost_in_sents'}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <BooleanInput
                                    label={'Costo fijo en vita card'}
                                    source={'attributes.is_apply_fixed_cost_in_vita_card'}
                                />
                            </Col>
                            <Col xs={3} style={{ ...styles.divisor, display: 'flex', textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                <BooleanInput
                                    label={'Actualización del precio spot'}
                                    source={'attributes.is_update_spot'}
                                />
                            </Col>
                        </Row>
                    </Container>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default PricesEdit;
