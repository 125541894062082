import { GET_LIST, GET_ONE, UPDATE, CREATE, DELETE } from "react-admin";

import { API_URL } from "../../../../Config/env";

const requestHandler = (type, params) => {
  switch (type) {
    case CREATE: {
      return {
        url: `${API_URL}/admin/prices_configuration`,
        method: "post",
        data: params.data.attributes,
      };
    }
    case UPDATE: {
      return {
        url: `${API_URL}/admin/prices_configuration/${params.id}`,
        method: "put",
        data: params.data.attributes,
      };
    }
    case DELETE: {
      return {
        url: `${API_URL}/admin/prices_configuration/${params.id}`,
        method: "delete",
      };
    }
    case GET_ONE: {
      return {
        url: `${API_URL}/admin/prices_configuration?q[id_eq]=${params.id}`,
        method: "get",
      };
    }
    case GET_LIST: {
      let {
        filter: { currency = "clp" },
        pagination: { page, perPage },
        sort: { field, order },
      } = params;

      let exportFile = '';
      if (parseInt(perPage) === 1000){
        exportFile = `is_export=true&`;
      }

      return {
        url: `${API_URL}/admin/prices_configuration?page=${page}&count=${perPage}&${exportFile}q[s]=${field}+${order}&q[currency_eq]=${currency}`,
        method: 'get',
      };        
    }
    default: {
      return {};
    }
  }
};

export default requestHandler;
