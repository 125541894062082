import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
} from "react-admin";

const CurrencyCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput
          source="attributes.singular_name"
          label="Nombre singular"
        />
        <TextInput
          source="attributes.plural_name"
          label="Nombre plural"
        />
        <TextInput
          source="attributes.iso_code"
          label="Código"
        />
        <TextInput
          source="attributes.quantity_decimals"
          label="Cantidad de decimales"
        />
        <BooleanInput
          source={"attributes.is_crypto"}
          label={"Crypto"}
        />
      </SimpleForm>
    </Create>
  );
};

export default CurrencyCreate;
