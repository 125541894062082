export const styles = {
  container: {
    backgroundColor: "#242424",
    Height: 816,
    width: 852,
    marginTop: 60,
    position: "relative",
    borderRadius: 10,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 50,
    overflowY: "auto",
    maxHeight: "90vh",
  },
  buttonClose: {
    position: "absolute",
    top: 5,
    right: 0,
    border: "none",
    backgroundColor: "transparent",
    fontSize: 16,
    color: "#DFDFDF",
  },
  containerButtons: {
    display: "flex",
    justifyContent: "center",
    marginBottom: -40,
  },
  titleSesionDivider: {
    backgroundColor: "#565656",
    alignSelf: "end",
    marginBottom: 8,
    marginLeft: 10,
    height: 2,
  },
  titleSesionText: {
    color: "#00BB8D",
    fontSize: 16,
    fontWeight: "bold",
  },
  spanStyles: {
    color: "white",
    position: "absolute",
    right: 25,
    top: 24,
  },
  underLineSpan: {
    position: "absolute",
    right: 25,
    top: 51,
    height: 1,
    backgroundColor: "#bab4b4",
    width: 47,
  },
  spanStylesRigth: {
    color: "white",
    position: "absolute",
    right: 5,
    top: 24,
  },
};
