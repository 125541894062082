import React, { useState, useEffect } from 'react';
import styles from '../styles';
import { RoleHelper } from '../../../Helpers';
import { SimpleForm, Create, TextInput, AutocompleteInput } from 'react-admin';
import { Grid } from '@material-ui/core';

import {
  validateEmail,
  validateRequired,
  validateVitaEmail,
  validateEqualPassword
} from '../../../Helpers/ValidateHelper';

const AdminCreate = (props) => {

  

  const RenderFields = (props) => {

    const [roles, setRoles] = useState([]);

    useEffect(() => {
      RoleHelper.getRoles().then(data => {
        setRoles(data);
      });
    }, []);

    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        xs={12}
        sm={6}
        item
        style={styles.container}
      >
        <Grid
          xs={12}
          sm={5}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.first_name'}
            label={'Nombre'}
            validate={validateRequired}
            fullWidth
          />
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.last_name'}
            label={'Apellido'}
            validate={validateRequired}
            fullWidth
          />
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.email'}
            validate={[validateEmail, validateRequired, validateVitaEmail]}
            label={'Correo electrónico'}
            fullWidth
            type="email"
          />
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={styles.items}
        >
          <AutocompleteInput
            source="attributes.role.id"
            label="Rol"
            fullWidth
            choices={roles}
            validate={validateRequired}
            optionText="public_name"
            optionValue="id"
          />
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.password'}
            validate={validateRequired}
            label={'Contraseña'}
            fullWidth
            type="password"
          />
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.password_confirmation'}
            validate={validateRequired}
            label={'Confirma Contraseña'}
            fullWidth
            type="password"
          />
        </Grid>
      </Grid>
    );
  };
  
  return (
    <Create
      title={`Crear nuevo usuario`}
      {...props}
    >
      <SimpleForm validate={validateEqualPassword}>
        <RenderFields />
      </SimpleForm>
    </Create>
  );
};

export default AdminCreate;
