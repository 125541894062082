import React from 'react';
import styles from './styles';
import { SimpleForm, Edit, SaveButton, Toolbar, TextInput, AutocompleteInput } from 'react-admin';
import { Grid } from '@material-ui/core';
import { validateRequired } from '../../../Helpers/ValidateHelper';

const PricesProviderEdit = (props) => {
  const RenderFields = () => {
    const status = [{ name: 'Activo', value: 'active' }, { name: 'Inactivo', value: 'inactive' }]
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        xs={12}
        sm={6}
        item
        style={styles.container}
      >
        <Grid
          xs={12}
          sm={5}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.public_name'}
            label={'Nombre público'}
            validate={validateRequired}
            fullWidth
          />
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.name'}
            label={'Nombre'}
            validate={validateRequired}
            fullWidth
          />
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.order_priority'}
            validate={validateRequired}
            label={'Orden de prioridad'}
            fullWidth
          />
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={styles.items}
        >
          <AutocompleteInput
            source="attributes.status"
            label="Estado"
            fullWidth
            choices={status}
            validate={validateRequired}
            optionText="name"
            optionValue="value"
          />
        </Grid>
      </Grid>
    );
  };

  const UserEditToolbar = props => {
    return (
      <Toolbar {...props} >
        <SaveButton />
      </Toolbar>
    )
  };

  return (
    <Edit
      title={`Editar provedor de precios #${props.id}`}
      {...props}
    >
      <SimpleForm toolbar={<UserEditToolbar />}>
        <RenderFields />
      </SimpleForm>
    </Edit>
  );
};

export default PricesProviderEdit;