const dictionary = {
	"id": "ID",
	"accountStatus": "Estado de cuenta",
	"accountType": "Tipo de cuenta",
	"accountNumber": "Número de cuenta",
	"ownerIdentityId": "ID de identidad del propietario",
	"refCode": "Código de referencia",
	"wire": "Transferencia bancaria",
	"routingNumber": "Número de ruta",
	"receiverName": "Nombre del receptor",
	"receiverAddress": "Dirección del receptor",
	"street1": "Calle",
	"street2": "Calle (opcional)",
	"postalCode": "Código postal",
	"city": "Ciudad",
	"state": "Estado",
	"country": "País",
	"receiverBankName": "Nombre del banco receptor",
	"receiverBankAddress": "Dirección del banco receptor",
	"swiftCode": "Código SWIFT",
	"subAccountsWire": "Subcuentas de transferencia bancaria",
	"assetType": "Tipo de activo",
	"pending": "Pendiente",
	"assetFiatType": "Tipo de activo fiduciario",
	"disbursable": "Reembolsable",
	"locked": "Bloqueado",
	"total": "Total",
	"subBalances": "Subsaldos",
	"pendingFromTrade": "Pendiente de comercio",
	"lockedPendingFromTrade": "Bloqueado pendiente de comercio",
};

export const getTranslatedKey = (key) => {
	return dictionary[key] || key;
}

export const generateTableFromJson = (data) => {
	let html = '<table class="json-table-fortress">';

	for (let key in data) {
		html += '<tr>';
		html += '<td class="json-table-fortress-key">' + getTranslatedKey(key.replace('Wire - ', '')) + '</td>';

		if (typeof data[key] === 'object') {
			html += '<td></td>';
			html += '</tr>';
			for (let subKey in data[key]) {
				html += '<tr>';
				html += '<td class="json-table-fortress-key">' + getTranslatedKey(subKey) + '</td>';

				if (typeof data[key][subKey] === 'object') {
					html += '<td class="json-table-fortress-value">' + getObjectContent(data[key][subKey]) + '</td>';
				} else {
					html += '<td class="json-table-fortress-value">' + data[key][subKey] + '</td>';
				}

				html += '</tr>';
			}
		} else {
			html += '<td class="json-table-fortress-value">' + data[key] + '</td>';
		}

		html += '</tr>';
	}

	html += '</table>';
	return html;
}

export const getObjectContent = (object) => {
	let content = '';

	for (let key in object) {
		content += '<b>' + getTranslatedKey(key) + '</b>: ';

		if (typeof object[key] === 'object') {
			content += getObjectContent(object[key]) + '<br/>';
		} else {
			content += object[key] + '<br/>';
		}
	}

	return content.slice(0, -2);
}

export const WITHDRAWAL_TYPE = 'withdrawal';
export const CREATE_ACCOUNT_TYPE = 'create_account';
export const CREATE_BUSINESS_ACCOUNT_TYPE = 'create_business_account';
export const ASSIGN_WEBHOOK_URL = 'assign_webhook_url';

export const FORMULARY_TYPE = [
	{
		id: WITHDRAWAL_TYPE,
		name: 'Retiro'
	},
	{
		id: CREATE_ACCOUNT_TYPE,
		name: 'Crear cuenta personal',
	},
	{
		id: CREATE_BUSINESS_ACCOUNT_TYPE,
		name: 'Crear cuenta comercial',
	},
	{
		id: ASSIGN_WEBHOOK_URL,
		name: "Asignar URL para eventos",
	},
];

export const STRUCTURE_LEGAL = [
	{
		id: 'ccorp',
		name: 'Corporación C (ccorp)',
	},
	{
		id: 'llc',
		name: 'Compañía de Responsabilidad Limitada (llc)',
	},
	{
		id: 'partnership',
		name: 'Asociación (partnership)',
	},
	{
		id: 'nonprofit',
		name: 'Organización sin ánimo de lucro (nonprofit)',
	},
	{
		id: 'scorp',
		name: 'Corporación S (scorp)',
	},
	{
		id: 'soleprop',
		name: 'Empresa individual (soleprop)',
	},
	{
		id: 'other',
		name: 'Otro (other)',
	},
];

export const LIST_TYPE = [
	{
		id: 'accounts',
		name: 'Cuentas custodia',
	},
	{
		id: 'identities',
		name: 'Identidades',
	},
];

export const ACCOUNTS_FIELDS = [
	{
		source: 'id',
		label: 'Id',
	},
	{
		source: 'ownerIdentityId',
		label: 'ID de identidad del propietario',
	},
	{
		source: 'accountType',
		label: 'Tipo de cuenta',
	},
	{
		source: 'accountStatus',
		label: 'Estado de cuenta',
	},
	{
		source: 'accountNumber',
		label: 'Número de cuenta',
	},
];

export const IDENTITIES_FIELD = [
	{
		source: 'id',
		label: 'Id'
	},
	{
		source: 'kycLevel',
		label: 'Nivel KYC'
	},
	{
		source: 'type',
		label: 'Tipo de identidad'
	},
	{
		source: 'status',
		label: 'Estado'
	},
	{
		source: 'address.country',
		label: 'País'
	},
	{
		source: 'email',
		label: 'Correo'
	},
	{
		source: 'documents',
		label: 'Documentos'
	},
];

export const getType = (history) => {
	let filter = { type: 'accounts' };

	if (history.location.search) {
		const params = new URLSearchParams(history.location.search);

		const obj = {};
		for (const [key, value] of params) {
			obj[key] = value;
		}

		filter = JSON.parse(obj.filter);
	}

	return filter.type || "accounts";
};

export const DOCUMENT_TYPE = [
	{
		id: 'passport',
		name: 'Pasaporte',
	},
	{
		id: 'license',
		name: 'Licencia',
	},
	{
		id: 'identificationCard',
		name: 'Tarjeta de identificación',
	},
];

export const DOCUMENT_TYPE_BUSINESS = [
	{
		id: 'proofOfAddress',
		name: 'Prueba de dirección',
	},
	{
		id: 'proofOfCompanyFormation',
		name: 'Prueba de formación de compañía',
	},
	{
		id: 'other',
		name: 'Otro',
	},
];