import React from 'react';
import styles from './styles';
import { Show, SimpleShowLayout } from 'react-admin';
import { Grid, TextField } from '@material-ui/core';

const PricesProvidersShow = (props) => {

  const RenderFields = (propsRecord) => {

    const { record: {
      attributes: {
        public_name,
        name,
        order_priority,
        status
      }
    } } = propsRecord;

    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        xs={12}
        sm={8}
        item
        style={styles.container}
      >
        <Grid
          xs={12}
          sm={3}
          item
          style={styles.items}
        >
          <TextField
            value={public_name}
            label={'Nombre público'}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          xs={12}
          sm={3}
          item
          style={styles.items}
        >
          <TextField
            value={name}
            label={'Nombre'}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          xs={12}
          sm={3}
          item
          style={styles.items}
        >
          <TextField
            value={order_priority}
            label={'Orden de prioridad'}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={styles.items}
        >
          <TextField
            value={status}
            label={'Estado'}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Show
      title={`Proveedor de precio #${props.id}`}
      {...props}
    >
      <SimpleShowLayout>
        <RenderFields />
      </SimpleShowLayout>
    </Show>
  );
};

export default PricesProvidersShow;
