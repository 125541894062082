import ResourceTransactions from './Transactions';
import ResourceWithdrawals from './Withdrawals';
import ResourceUsers from './Users';
import ResourceConfiguration from './Configurations';
import ResourceLimit from './Limits';
import ResourceCountries from './Countries';
import ResourceDlocals from './Dlocals';
import ResourceBusinesses from './Businesses';
import ResourceLotes from './Lotes';
import ResourceBatchPayout from './BatchPayout';
import ResourceBitcoin from './Bitcoin';
import ResourceBitcoinWithdrawal from './BitcoinWithdrawal';
import ResourceRoles from './Roles';
import ResourceAdministradores from './Administradores';
import ResourceLoginHistories from './Historiales/Login';
import ResourceActionHistories from './Historiales/Action';
import ResourceExportedFilesHistories from './Historiales/ExportedFiles';
import ResourceGeolocationHistories from './Historiales/Geolocation';
import ResourceFintoc from './Fintoc';
import ResourcePrices from './Prices';
import ResourceTransferRules from './TransferRules';
import ResourceSettingsHeaders from './SettingsHeaders';
import ResourceCardAccounts from './CardAccounts';
import ResourceWallets from './Wallets';
import ResourceBusinessTransactions from './BusinessTransactions';
import ResourceMarketingMessage from './Messages/Marketing';
import ResourceReferidos from "./Referrers";
import ResourceTransactionsStatus from './TransactionsStatus';
import ResourceServices from './Services';
import ResourceCurrencies from './Currencies';
import ResourceCategory from './Categories';
import ResourceRequests from './Requests';
import ResourceBankAccountAccesses from './BankAccountAccesses';
import ResourceBankMovements from './BankMovements';
import ResourceSegments from "./Segments";
import ResourceCoupons from "./Coupons";
import ResourceCouponsegmentList from "./CouponSegmentList";
import ResourceSegmentListUsers from './SegmentListUsers';
import ResourceBinanceNetworks from './BinanceNetworks';
import ResourceBinanceWithdrawal from './BinanceWithdrawal';
import ResourceBinanceTransactions from './BinanceTransactions';
import ResourceCircleWithdrawal from './CircleWithdrawal';
import ResourceCircleTransactions from './CircleTransactions';
import ResourceBeneficiaries from './Beneficiaries';
import ResourcePricesProviders from './PricesProviders';
import ResourceCryptoWithdrawal from './CryptoWithdrawal';
import ResourcePreferentialPrices from './PreferentialPrices';
import ResourceFortress from './Fortress';
import ResourceBanners from './Banners';
import ResourceSnackbars from './Snackbars';
import ResourceAlerts from './Alerts';
import ResourceTransactionsProfitability from './TransactionsProfitability';
import ResourceVitaPrices from './VitaPrices';
import ResourceMaintenanceCharges from './MaintenanceCharges';
import ResourcePix from './Pix';

const Resources = [
    ResourceTransferRules,
    ResourceConfiguration,
    ResourcePrices,
    ResourceLimit,
    ResourceCountries,
    ResourceBusinesses,
    ResourceUsers,
    ResourceWallets,
    ResourceTransactions,
    ResourceWithdrawals,
    ResourceBitcoin,
    ResourceDlocals,
    ResourceLotes,
    ResourceBatchPayout,
    ResourceBitcoinWithdrawal,
    ResourceRoles,
    ResourceAdministradores,
    ResourceLoginHistories,
    ResourceActionHistories,
    ResourceFintoc,
    ResourceSettingsHeaders,
    ResourceCardAccounts,
    ResourceBusinessTransactions,
    ResourceMarketingMessage,
    ResourceExportedFilesHistories,
    ResourceGeolocationHistories,
    ResourceReferidos,
    ResourceTransactionsStatus,
    ResourceServices,
    ResourceCurrencies,
    ResourceCategory,
    ResourceRequests,
    ResourceBankAccountAccesses,
    ResourceBankMovements,
    ResourceSegments,
    ResourceCoupons,
    ResourceCouponsegmentList,
    ResourceSegmentListUsers,
    ResourceBinanceNetworks,
    ResourceBinanceWithdrawal,
    ResourceBinanceTransactions,
    ResourceCircleWithdrawal,
    ResourceCircleTransactions,
    ResourceBeneficiaries,
    ResourcePricesProviders,
    ResourceCryptoWithdrawal,
    ResourcePreferentialPrices,
    ResourceFortress,
    ResourceBanners,
    ResourceSnackbars,
    ResourceAlerts,
    ResourceTransactionsProfitability,
    ResourceVitaPrices,
    ResourceMaintenanceCharges,
    ResourcePix,
];

export default Resources;
