import React from 'react';
import { List, Datagrid, TextField, FunctionField, BooleanField } from 'react-admin';

import BusinessFilter from '../Filters/BusinessFilter';
import { Pagination } from '../../../Components';
import { toMoney } from '../../../Helpers/MoneyHelper';
import useExportFiles from '../../../hooks/useExportFiles';
import { FIELDS_EXPORTS_FILES } from '../../utils';

const WalletsList = (props) => {
  const { exportFileWallets } = useExportFiles();

  const exporter = posts => {
    exportFileWallets(posts, FIELDS_EXPORTS_FILES.WALLETS)
  }

  return (
    <>
      <div style={styles.title}>Selecciona un negocio para ver sus wallets</div>
      <List
        {...props}
        title={'Lista de wallets'}
        pagination={<Pagination />}
        filters={<BusinessFilter />}
        bulkActionButtons={false}
        exporter={exporter}
        debounce={1000}
      >
        <Datagrid>
          <BooleanField
            source={'attributes.is_master'}
            label={'Maestra'}
          />
          <TextField
            source={'id'}
            label={'Id'}
          />
          <TextField
            source={'attributes.uuid'}
            label={'UUID'}
          />
          <FunctionField
            label={'Balances'}
            render={(record) => {
              return `${toMoney(record.attributes.balances[record.attributes.default_currency.toLowerCase()], record.attributes.default_currency.toLowerCase())} ${record.attributes.default_currency.toUpperCase()}`;
            }}
          />
          <TextField
            source={'attributes.token'}
            label={'Token'}
          />
        </Datagrid>
      </List>
    </>
  )
};

const styles = {
  title: {
    color: '#FFFFFF',
    paddingBottom: 10,
  },
};

export default WalletsList;
