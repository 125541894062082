import React, { useState } from "react";
import { List, Datagrid, TextField } from "react-admin";
import { Pagination } from "../../../Components";
import useAccessRole from "../../../hooks/useAccessRole";
import { PreferentialPricesFilter } from "../Filters";
import { useReset, useModal } from "../../../hooks";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import styles from "./styles";
import Swal from "sweetalert2";
// import ModalExport from "../../../Components/ModalExport";
import * as XLSX from "xlsx";
import { UserProvider } from "../../../Providers/";

const PreferentialPricesList = (props) => {
  const { isAccess } = useAccessRole();
  const { resetList } = useReset();
  // const { open, showModal, hiddeModal } = useModal();
  const [isSeeList, setIsSeeList] = useState({});

  const seeListHandler = (id) => {
    setIsSeeList({ ...isSeeList, [id]: !isSeeList[id] });
    resetList();
  };

  const inputChangeHandle = (event) => {
    try {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
      if (name === "file") {
        let reader = new FileReader();
        reader.readAsArrayBuffer(target.files[0]);
        reader.onloadend = (e) => {
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data, { type: "array" });

          workbook.SheetNames.forEach(async function (sheetName) {
            let XL_row_object = XLSX.utils.sheet_to_json(
              workbook.Sheets[sheetName]
            );

            try {
              XL_row_object = XL_row_object.map((object) => {
                return {
                  email: object.email.trim().toLowerCase(),
                  iso_code: object.iso_code,
                  discount: parseFloat(object.discount),
                };
              });

              let message = await UserProvider.preferentialAutomaticPrices({
                data: XL_row_object,
              });

              if (message.data.message) {
                Swal.fire({
                  icon: "success",
                  title: message.data.message,
                  text: "Datos ingresados con éxito!",
                });
              }

              if (message.data.existing_routes) {
                let body = "";

                message.data.existing_routes.forEach((route) => {
                  body += `<tr>
                      <td>${route.email}</td>
                      <td>${route.iso_code}</td>
                    </tr>
                    `;
                });

                const table = `<table class="table_route">
                  <thead>
                    <tr>
                      <th>Correo</th>
                      <th>Iso Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    ${body}
                  </tbody>
                </table>`;

                Swal.fire({
                  title: "Rutas existentes",
                  html: table,
                  confirmButtonText: "OK",
                  cancelButtonText: "Ver transacciones",
                  showCancelButton: false,
                  showCloseButton: true,
                });
              }

              resetList();
            } catch (error) {
              Swal.fire({
                icon: "error",
                title: `No se pudo leer el archivo`,
                text: "Por favor, asegurate de seguir el formato requerido",
              });
            }
          });
        };
      }
    } catch (e) {}
  };

  const renderTable = (props) => {
    let userId = props.record.id;
    let routes = isSeeList[userId]
      ? props.record.routes
      : props.record.routes[0]
      ? [props.record.routes[0]]
      : [];

    return routes && routes.length > 0
      ? routes.map((row) => {
          return (
            <TableRow>
              <TableCell component="th" scope="row" style={{ width: "100px" }}>
                {row.country}
              </TableCell>
              <TableCell numeric>{row.sell_fee}</TableCell>
              <TableCell numeric>{row.discount}</TableCell>
              <TableCell numeric>{row.sell_fee - row.discount}</TableCell>
            </TableRow>
          );
        })
      : null;
  };

  const RoutesList = (props) => {
    let id = props.record.id;
    let seeList = isSeeList[id];
    return (
      <Paper style={styles.container}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ruta</TableCell>
              <TableCell numeric>Spread de venta</TableCell>
              <TableCell numeric>DSC valor absoluto</TableCell>
              <TableCell numeric>Spread total</TableCell>
              {seeList ? (
                <ArrowDropUpIcon
                  onClick={() => seeListHandler(id)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <ArrowDropDownIcon
                  onClick={() => seeListHandler(id)}
                  style={{ cursor: "pointer" }}
                />
              )}
              {seeList ? "Ocultar" : "Ver más"}
            </TableRow>
          </TableHead>
          <TableBody>{renderTable(props)}</TableBody>
        </Table>
      </Paper>
    );
  };

  return (
    <>
      <div className="button-import-routes">
        <input
          hidden
          required
          type="file"
          name="file"
          id="file"
          onChange={inputChangeHandle}
        />
        <label
          htmlFor="file"
          style={{
            cursor: "pointer",
          }}
        >
          IMPORTAR EXCEL
        </label>
      </div>
      <List
        {...props}
        title={"Precios preferenciales"}
        filters={<PreferentialPricesFilter />}
        pagination={<Pagination />}
        exporter={isAccess("download_preferential_prices") ? () => {} : false}
        bulkActionButtons={false}
      >
        <Datagrid>
          <TextField source="id" label="Id" />
          <TextField source="email" label="Correo electrónico" />
          <RoutesList />
        </Datagrid>
      </List>
      {/* <ModalExport open={open} hiddeModal={hiddeModal} /> */}
    </>
  );
};

export default PreferentialPricesList;
