import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useSegments from "../../hooks/useSegments";

const SegmentSelect = (props) => {
  return (
    <Select
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      style={{ width: 200 }}
    >
      {props.segments.map((itemField) => (
        <MenuItem key={`field${itemField.value}`} value={itemField.value}>
          {itemField.label}
        </MenuItem>
      ))}
    </Select>
  );
};

const SegmentSelectHoc = (props) => {
  const hook = useSegments();
  const { segments } = hook;

  if (segments.length > 0)
    return (
      <SegmentSelect
        {...hook}
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        label={props.label}
      />
    );
  return null;
};

export default SegmentSelectHoc;
