import React, { useState, useEffect } from 'react';
import styles from '../styles';
import useAccessRole from '../../../hooks/useAccessRole';
import { RoleHelper } from '../../../Helpers';
import { SimpleForm, Edit, SaveButton, DeleteButton, Toolbar, TextInput, AutocompleteInput } from 'react-admin';
import { Grid, Button, Chip } from '@material-ui/core';

import {
  validateEmail,
  validateRequired,
  validateVitaEmail,
  validateEqualPassword
} from '../../../Helpers/ValidateHelper';

const AdminEdit = (props) => {

  const {isAccess} = useAccessRole();
  const [update, setUpdate] = useState(false);

  const RenderFields = () => {

    const [roles,setRoles] = useState([]);
    
    useEffect(() => {
      RoleHelper.getRoles().then( data => {
        setRoles(data);
      });
    }, []);

    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        xs={12}
        sm={6}
        item
        style={styles.container}
      >
        <Grid
          xs={12}
          sm={5}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.first_name'}
            label={'Nombre'}
            validate={validateRequired}
            fullWidth
          />
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.last_name'}
            label={'Apellido'}
            validate={validateRequired}
            fullWidth
          />
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.email'}
            validate={[validateEmail, validateRequired, validateVitaEmail]}
            label={'Correo electrónico'}
            disabled
            fullWidth
            type="email"
          />
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={styles.items}
        >
          <AutocompleteInput
            source="attributes.role.id"
            label="Rol"
            fullWidth
            choices={roles}
            validate={validateRequired}
            optionText="public_name"
            optionValue="id"
          />
        </Grid>
        <Grid
          xs={12}
          item
          style={styles.items}
        >
          <Button 
            onClick={() => setUpdate(!update)}
            variant="contained" 
            color="primary"
          >
            Actualizar contraseña: <b><Chip label={update ? 'SI' : 'NO'} color="danger" style={{marginLeft: 20}}/></b>
          </Button>
        </Grid>
        { update ?
          <>
            <Grid
              xs={12}
              sm={5}
              item
              style={styles.items}
            >
              <TextInput
                source={'attributes.password'}
                validate={validateRequired}
                label={'Contraseña'}
                fullWidth
                type="password"
              />
            </Grid>
            <Grid
              xs={12}
              sm={5}
              item
              style={styles.items}
            >
              <TextInput
                source={'attributes.password_confirmation'}
                validate={validateRequired}
                label={'Confirma Contraseña'}
                fullWidth
                type="password"
              />
            </Grid>
          </>
        : null}
      </Grid>
    );
  };

  const UserEditToolbar = props => { 
    
    const {
      attributes: {
        status
      }
    } = props.record;

    return (
      <Toolbar {...props} >
        <SaveButton />
        {isAccess('delete_admin') ? <DeleteButton style={{'margin-left': 'auto'}} label={status === 'active' ? 'BANEAR' : 'DESBANEAR'}/> : null}
      </Toolbar>
    )
  };

  return (
    <Edit 
      title={`Editar usuario #${props.id}`} 
      {...props}
    >
      <SimpleForm toolbar={<UserEditToolbar />} validate={validateEqualPassword}>
        <RenderFields />
      </SimpleForm>
    </Edit>
  );
};

export default AdminEdit;