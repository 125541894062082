import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import FileDownload from '@material-ui/icons/FileDownload';
import ShowUserLimitRequest from '../ShowUserLimitRequest';
import useModal from '../../../hooks/useModal';
import { REQUEST_STATUS, truncateText } from '../utils';
import Edit from '@material-ui/icons/Edit';
import useUserLimitRequestDownload from '../../../hooks/useUserLimitRequestDownload';

export const UserLimitRequest = (props) => (
    <TableRow className={props.classes.td}>
        <TableCell component="th" scope="row">{props.id}</TableCell>
        <TableCell align="right">{props.email}</TableCell>
        <TableCell align="right">{props.request_date}</TableCell>
        <TableCell align="right">{props.current_limit} USD</TableCell>
        <TableCell align="right">{props.requested_limit} USD</TableCell>
        <TableCell 
            align="right" 
            style={
                props.status != 'approved' ? 
                    styles.redStatus : 
                    styles.greenStatus
            }
        >
            {REQUEST_STATUS[props.status]}
        </TableCell>
        <TableCell align="right">
            <Button
                style={styles.button} 
                onClick={props.onShow}
            >
                Ver detalle
            </Button>
        </TableCell>
        <TableCell align="right">
            <Button
                style={styles.button} 
                onClick={props.toUserLink}
            >
                Ver usuario
            </Button>
        </TableCell>
    </TableRow>
);

UserLimitRequest.propTypes = {
    id: PropTypes.number,
    email: PropTypes.string,
    request_date: PropTypes.string,
    current_limit: PropTypes.number,
    requested_limit: PropTypes.number,
    status: PropTypes.string,
    onShow: PropTypes.func,
    classes: PropTypes.shape({
        td: PropTypes.shape({}),
    }),
    toUserLink: PropTypes.func,
};

UserLimitRequest.defaultProps = {
    classes: {},
    id: 0,
    email: '',
    request_date: '',
    current_limit: 0,
    requested_limit: 0,
    status: 'pending',
    onShow: () => {},
    toUserLink: () => {},
};

export const UserLimitRequestList = (props) => (
    <>
        <div style={styles.container}>
            <div style={styles.dataContent}>
                <div style={styles.title}>Fecha solicitud</div>
                <div style={styles.data}>
                    {props.request_date}
                </div>
            </div>
            <div style={styles.dataContent}>
                <div style={styles.title}>Monto solicitado USD</div>
                <div style={styles.data}>
                    {props.requested_limit}
                </div>
            </div>
            <div style={styles.dataContent}>
                <div style={styles.title}>Monto aprobado USD</div>
                <div style={styles.data}>
                    {props.approval_amount}
                </div>
            </div>
            <div style={styles.dataContent} title={props.economic_activity}>
                <div style={styles.title}>Descripción de actividad</div>
                <div style={styles.data}>
                    {truncateText(props.economic_activity)}
                </div>
            </div>
            <div style={styles.documentContainer}>
                <div style={styles.documentContent} onClick={props.onIncomeVerificationView}>
                    Comprobante ingresos
                </div>
                <div 
                    style={styles.iconButton} 
                    title="Descargar comprobante de ingreso"
                    onClick={props.onIncomeVerificationDownload}
                >
                    <FileDownload style={styles.icon} />
                </div>
            </div>
            <div style={styles.documentContainer}>
                <div style={styles.documentContent} onClick={props.onAddressProofView}>
                    Comprobante domicilio
                </div>
                <div 
                    style={styles.iconButton} 
                    title="Descargar comprobante de domicilio"
                    onClick={props.onAddressProofDownload}
                >
                    <FileDownload style={styles.icon} />
                </div>
            </div>
            <div 
                style={styles.iconButton}
                onClick={props.onShow}
                title="Ver detalle"
            >
                <Edit style={styles.iconDown} />
            </div>
            <div style={styles.dataContentSmall}>
                <div 
                    style={
                        props.status != 'approved' ? 
                            styles.redStatusList : 
                            styles.greenStatusList
                    }
                >
                    <div style={styles.textDown}>{REQUEST_STATUS[props.status]}</div>
                </div>
            </div>
        </div>
    </>
);

UserLimitRequestList.propTypes = {
    id: PropTypes.number,
    request_date: PropTypes.string,
    current_limit: PropTypes.number,
    requested_limit: PropTypes.number,
    status: PropTypes.string,
    onShow: PropTypes.func,
    economic_activity: PropTypes.string,
    onAddressProofDownload: PropTypes.func,
    onIncomeVerificationDownload: PropTypes.func,
    onIncomeVerificationView: PropTypes.func,
    onAddressProofView: PropTypes.func,
};

UserLimitRequestList.defaultProps = {
    id: 0,
    request_date: '',
    current_limit: 0,
    requested_limit: 0,
    status: 'pending',
    onShow: () => {},
    economic_activity: '',
    onAddressProofDownload: () => {},
    onIncomeVerificationDownload: () => {},
    onIncomeVerificationView: () => {},
    onAddressProofView: () => {},
};

const UserLimitRequestListChild = (props) => {
    const hook = useUserLimitRequestDownload(props);
    return <UserLimitRequestList {...props} {...hook} />;
};

const UserLimitRequestHoc = (props) => {
    const hook = useModal();
    
    const Children = props.isDataTable ? 
        UserLimitRequest : 
        UserLimitRequestListChild;

    return (
        <>
            <ShowUserLimitRequest {...hook} {...props} />
            <Children {...props} onShow={hook.showModal}/>
        </>
    );
};

UserLimitRequestHoc.propTypes = {
    isDataTable: PropTypes.bool,
};

UserLimitRequestHoc.defaultProps = {
    isDataTable: true,
};

const styles = {
    textDown: {
        marginTop: 12,
    },
    redStatus: {
        color: '#FF0000',
    },
    greenStatus: {
        color: '#00FF00',
    },
    redStatusList: {
        color: '#FF0000',
        fontSize: 12,
    },
    greenStatusList: {
        color: '#00FF00',
        fontSize: 12,
    },
    button: {
        backgroundColor: '#009bff',
        width: 120,
        height: 35,
        fontSize: 12,
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        color: '#FFFFFF',
        alignItems: 'flex-end',
        height: 50,
    },
    icon: {
        color: '#FFFFFF',
        fontSize: '1.2rem',
    },
    iconDown: {
        color: '#FFFFFF',
        fontSize: '1.2rem',
        marginTop: 12,
    },
    iconButton: {
        paddingLeft: 10,
        paddingRight: 10,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    dataContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 10,
        paddingRight: 20,
        width: 250,
        cursor: 'default',
    },
    dataContentSmall: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: 20,
        width: 100,
        height: '100%',
    },
    title: {
        fontSize: 10,
    },
    data: {
        fontSize: 12,
        borderBottom: '1px solid #FFFFFF',
        paddingBottom: 3,
    },
    documentContent: {
        backgroundColor: '#202020',
        fontSize: 12,
        padding: 10,
        paddingLeft: 5,
        border: '1px dashed #4b4b4b',
        paddingRight: 20,
        width: 150,
        height: '100%',
        cursor: 'pointer',
    },
    documentContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
};

export default UserLimitRequestHoc;