import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from "@material-ui/core";
import ReactCountryFlag from 'react-country-flag';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import moment from "moment";
import { Link } from "react-router-dom";

import { toMoney } from "../../../../../../Helpers/MoneyHelper";
import ShowTransaction from '../../../../../Withdrawals/CustomComponents/ShowTransaction';
import NeitcomStatus from '../../../../../../Components/NeitcomStatus';
import { availableCountriesUsersTruora, flagsCountries } from '../../../../../../Config/constants';
import Alerts from '../../../../../Transactions/components/Alerts';

import styles from '../styles';

moment.locale("es");

const FirstColumn = (props) => {
  const {
    id,
    isLocked,
    created_at,
    amount,
    currency,
    to_address,
    sender_user,
    active_alerts,
    alert_count,
  } = props;
  const {
    data: {
      id: userID,
      attributes: {
        first_name,
        last_name,
        email,
        country,
        biometric_verification,
        criminal_verification_status,
        document_issuing_country_name,
        business_user,
      },
    },
  } = sender_user;

  return (
    <>
      <Grid style={{
        padding: '7%',
        display: 'flex',
        flexDirection: 'flexRow'
      }} >
        <div
          style={{ paddingRight: '10%' }}
        >
          Envío
          <div
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: `${!isLocked ? '#FFFFFF' : '#FF0000'}`
            }}
          >
            {id}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ShowTransaction {...{ 'record': { id } }} />
          </div>
        </div>
        <div>
          <div>{moment.utc(created_at).format("LLLL")} UTC</div>
          <div style={{ fontSize: 25, fontWeight: "bold", color: `${!isLocked ? '#00d9ff' : '#FF0000'}` }}>
            {toMoney(amount, currency.toUpperCase())} {currency.toUpperCase()}
          </div>
        </div>
      </Grid>
      <Grid
        style={{
          fontSize: 20,
          fontWeight: "bold",
          width: "100%",
          padding: '7%'
        }}
      >
        <div style={styles.remitent_container}>
          <ReactCountryFlag countryCode={flagsCountries[country]} style={styles.remitent_flag} svg />
          <label style={styles.remitent_label}>{first_name} {last_name}</label>
          {
            (biometric_verification === 'biometric_accepted' || biometric_verification === 'biometric_rejected') && (
              <CheckBoxIcon style={{ color: (biometric_verification === 'biometric_accepted' ? '#2ba92b' : 'red'), fontSize: 18 }} />
            )
          }
        </div>
        <Link
          to={`usuarios/${userID}/show`}
          style={styles.styleLink}
          target="_blank"
        >
          {email} 🔎
        </Link>
        <div style={{ fontSize: 14 }}>
          <NeitcomStatus
            veredict={criminal_verification_status?.neitcom?.['veredict']}
            has_ignored_items={criminal_verification_status?.neitcom?.['has_ignored_items']}
            max_percentage={criminal_verification_status?.neitcom?.['max_percentage']}
            provider={'neitcom'}
          />
        </div>
        {
          (
            availableCountriesUsersTruora.includes(business_user?.lr_document_issuing_country?.name) ||
            availableCountriesUsersTruora.includes(document_issuing_country_name)
          ) &&
          <div style={{ fontSize: 14 }}>
            <NeitcomStatus
              veredict={criminal_verification_status?.truora?.['veredict']}
              has_ignored_items={criminal_verification_status?.truora?.['has_ignored_items']}
              max_percentage={criminal_verification_status?.truora?.['max_percentage']}
              provider={'truora'}
            />
          </div>
        }
      </Grid>
      <Grid style={{ padding: '7%' }} >
        <div style={{ fontSize: 20, fontWeight: "bold" }}>
          Dirección
        </div>
        {to_address && (
          <div
            style={{
              fontSize: 15,
              fontWeight: "bold",
              width: 250,
            }}
          >
            {to_address}
          </div>
        )}
      </Grid>
      <Grid style={{ padding: '7%', paddingTop: 0 }} >
        <Alerts
          transaction_id={id}
          active_alerts={active_alerts}
          alert_count={alert_count}
        />
      </Grid>
    </>
  );
}

FirstColumn.defaultProps = {
  id: 0,
  isLocked: false,
  created_at: new Date('2000-03-15T16:23:21.666Z'),
  amount: 0,
  currency: '',
  to_address: '',
};

FirstColumn.propTypes = {
  id: PropTypes.number.isRequired,
  created_at: PropTypes.instanceOf(Date).isRequired,
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  to_address: PropTypes.string,
  isLocked: PropTypes.bool.isRequired
};

export default FirstColumn;