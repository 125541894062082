import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

import { BeneficiaryFilter } from '../Filters'
import { Pagination } from '../../../Components';
import BeneficiaryInfo from '../CustomComponents/BeneficiaryInfo';
import BeneficiaryButtons from '../CustomComponents/BeneficiaryButtons';
// import useModal from '../../../hooks/useModal';
// import ModalExport from '../../../Components/ModalExport';
import useAccessRole from '../../../hooks/useAccessRole';

const BeneficiaryList = (props) => {
  const { isAccess } = useAccessRole();
  // const { open, showModal, hiddeModal } = useModal();

  return (
    <>
      <List
        {...props}
        title={'Destinatarios'}
        filters={<BeneficiaryFilter />}
        pagination={<Pagination />}
        bulkActionButtons={false}
        exporter={isAccess('download_beneficiaries') ? () => { } : false}
        debounce={1000}
      >
        <Datagrid>
          <TextField source={'id'} label={'Id'} />
          <BeneficiaryInfo />
          <BeneficiaryButtons {...props} />
        </Datagrid>
      </List>
      {/* <ModalExport
        open={open}
        hiddeModal={hiddeModal}
      /> */}
    </>
  );
}

export default BeneficiaryList;