import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from "@material-ui/core";

import styles from "../styles";

const ModalRejectWithdrawal = (props) => {
  const {
    isRejectWithdrawal,
    setRejectWithdrawal,
    rejectWithdrawal
  } = props;

  const handleClose = () => setRejectWithdrawal(false);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isRejectWithdrawal}
    >
      <div
        style={styles.centerAlert}
      >
        <div
          style={styles.centerColumn}
        >
          <div style={styles.alertTitle}>
            ¿Está seguro de realizar esta operación?
          </div>
          <div
            style={styles.alertBody}
          >
            La operación será irreversible
          </div>
          <div
            style={styles.alertButtom}
          >
            <Button
              onClick={rejectWithdrawal}
              style={{ ...styles.buttonBlue, marginRight: 5 }}
            >
              CONFIRMAR
            </Button>
            <Button
              onClick={handleClose}
              style={styles.buttonRed}
            >
              CANCELAR
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ModalRejectWithdrawal.defaultProps = {
  isRejectWithdrawal: false,
  setRejectWithdrawal: () => {},
  rejectWithdrawal: () => {}
};

ModalRejectWithdrawal.propTypes = {
  isRejectWithdrawal: PropTypes.bool.isRequired,
  setRejectWithdrawal: PropTypes.func.isRequired,
  rejectWithdrawal: PropTypes.func.isRequired
};

export default ModalRejectWithdrawal;