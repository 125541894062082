import { CREATE } from 'react-admin';

const responseHandler = (response, type, resource) => {
  switch (type) {
    case CREATE: {
      if (!response.data.hasOwnProperty('identity_container')) {
        return {
          data: { id: 1 },
        }
      }

      return {
        data: response.data,
      };
    }
    default: {
      return {
        data: response.data.data,
        total: response.data.total,
      }
    }
  }
};

export default responseHandler;