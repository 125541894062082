import { apiCall }      from '../../Middlewares';
import { ENDPOINTS }    from '../../Config/env';

const getListByUser = async (id) => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.FAILED_TRANSACTION.GET_LIST(id),
    });
};

export default {
  getListByUser,
};