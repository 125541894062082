import React from 'react';
import {
    Datagrid, 
    EditButton,
    FunctionField,
    List, 
    TextField, 
    BooleanField,
} from 'react-admin';
import { toMoney } from '../../../Helpers/MoneyHelper';
import useAccessRole from '../../../hooks/useAccessRole';
import DeleteButton from '../components/DeleteButton';
import VitaPricesFilter from '../components/Filters';
import useGetCurrenciesVitaPrices from '../../../hooks/useGetCurrenciesVitaPrices';

export default (props) => {
    const {isAccess} = useAccessRole();
    const hook = useGetCurrenciesVitaPrices();

    return (
    <List
        {...props}
        title={'Vita como proveedor de precios'}
        //pagination={<Pagination/>}
        filters={<VitaPricesFilter {...hook} />}
        bulkActionButtons={false}
        exporter={false}
        debounce={1000}
    >
        <Datagrid>
            <TextField
                source={'id'}
                label={'Id'}
            />
            <TextField
                source={'attributes.currency'}
                label={'Moneda'}
            />
            <TextField
                source={'attributes.target_currency'}
                label={'Moneda destino'}
            />
            <FunctionField
                label={'Precio spot'}
                render={(record) => (
                    `${ toMoney(record.attributes.spot, 'usd') } ${ record.attributes.target_currency.toUpperCase() }`
                )}
            />
            <BooleanField
                label={'Estado'}
                source={'attributes.is_enabled'}
            />
            {isAccess('edit_prices_configuration') && (
                <EditButton label="Editar" />
            )}
            {isAccess('delete_prices_configuration') && (
                <DeleteButton label="Borrar" />
            )}
        </Datagrid>
    </List>
);
            }