import { UPDATE } from 'react-admin';

import { API_URL } from '../../../../Config/env';

const requestHandler = (type, params) => {
    switch (type) {
        case UPDATE: {
            const {
                update_limits,
                delete_daily_limit_amount_clp,
                update_transbank_recharge_limits,
                delete_daily_limit_amount_usd,
                delete_monthly_transbank_recharge_limit,
                delete_daily_transbank_recharge_limit,
                daily_transbank_recharge_limit,
                monthly_transbank_recharge_limit,
                daily_limit_amount_clp,
                daily_limit_amount_usd,
                minutes_update_fiat_prices,
                minutes_update_btc_prices,
                is_email_code_verification,
                verification_code_expiration_time,
                vector_capital_spread,
                setfx_spread,
            } = params.data;

            const payload = {
                ...params.data,
                monthly_transbank_recharge_limit: delete_monthly_transbank_recharge_limit ? null : monthly_transbank_recharge_limit,
                daily_transbank_recharge_limit: delete_daily_transbank_recharge_limit ? null : daily_transbank_recharge_limit,
                daily_limit_amount_clp: delete_daily_limit_amount_clp ? null : daily_limit_amount_clp,
                daily_limit_amount_usd: delete_daily_limit_amount_usd ? null : daily_limit_amount_usd,
                minutes_update_fiat_prices,
                minutes_update_btc_prices,
                is_email_code_verification,
                verification_code_expiration_time,
                vector_capital_spread,
                setfx_spread,
            };

            if (!update_limits) {
                delete payload['daily_limit_amount_clp'];
                delete payload['daily_limit_amount_usd'];
            }

            if (!update_transbank_recharge_limits) {
                delete payload['monthly_transbank_recharge_limit'];
                delete payload['daily_transbank_recharge_limit'];
            }

            return {
                url: `${API_URL}/admin/configuration`,
                method: 'put',
                data: payload,
            };
        }
        default: {
            return {

            }
        }
    }
};

export default requestHandler;
