import React from "react";
import moment from "moment";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
} from "react-admin";
import { BinanceTransactionFilter } from "../Filters";

const BinanceTransactionList = (props) => {
  return (
    <>
      <List
        {...props}
        title={"Lista de transacciones Binance"}
        filters={<BinanceTransactionFilter />}
        bulkActionButtons={false}
        debounce={1000}
      >
        <Datagrid>
          <TextField source={"txnId"} label={"txnId"} />
          <FunctionField
            label={"Fecha de origen"}
            render={(record) => {
              return moment
                .utc(record.time)
                .format("LLLL");
            }}
          />
          <TextField source={"asset"} label={"Coin"} />
          <TextField source={"qty"} label={"Monto"} />
          <TextField source={"status"} label={"Estado"} />
        </Datagrid>
      </List>
    </>
  );
};

export default BinanceTransactionList;
