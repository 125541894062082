const styles = {
  divisor: {
    padding: "8px 12px",
  },
  divisorMoney: {
    padding: 15,
  },
  divisorTop: {
    paddingTop: 30,
  },
  titleWhite: {
    color: "#FFFFFF",
  },
  card: {
    padding: 10,
    borderRadius: 20,
    border: "3px solid white",
  },
  checked: {
    color: "#FFF",
  },
  noChecked: {
    backgroundColor: "transparent",
    color: "rgba(255,255,255,.6)",
  },
  avatar: {
    backgroundColor: "transparent",
  },
};

export default styles;
