import axios from "axios";
import Navigation from "../../Navigation";

const apiCall = async (preConfig, headersUser = null) => {
  preConfig.headers = {
    ...preConfig.headers,
    'app-name': 'BARRY'
  };
  const { authorization = false } = preConfig;
  const headers = headersUser
    ? headersUser
    : JSON.parse(localStorage.getItem("headers"));

  const config = authorization
    ? {
        ...preConfig,
        headers: {...headers, ...preConfig.headers},
    } : preConfig;

  try {
    const resp = await axios(config);

    localStorage.setItem('headers', JSON.stringify(resp.headers));
    return resp;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.setItem("headers", null);
      localStorage.setItem("role_access", null);
      Navigation.nav().goToLogin(error.response.data);
    } else {
      return Promise.reject(error.response)
      // return error.response;
    };
  }
};

export default apiCall;
