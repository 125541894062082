import React, { useEffect, useState } from 'react';

import { Typography, Button, TextField, Switch, FormControlLabel } from '@material-ui/core';
import { SelectInput, DateInput } from 'react-admin';
import { CIVIL_STATUS, DOCUMENT_TYPE, GENDERS } from "../../../../../Utils";

import { CountriesProvider, UserProvider, PublicProvider } from '../../../../../Providers';
import { Col, Container, Row } from 'react-grid-system';
import styles from './styles';
import { CircularProgress } from '@material-ui/core';
import Swal from 'sweetalert2';


const BusinessUserPanel = (props) => {
  const user_id = props?.record?.id
  const {business_user, record} = props;
  const [firstName, setFirstName] = useState(business_user?.lr_first_name || '');
  const [lastName, setLastName] = useState(business_user?.lr_last_name || '');
  const [phoneNumber, setPhoneNumber] = useState(business_user?.lr_phone_number || '');
  const [documentType, setDocumentType] = useState(business_user?.lr_document_type || '');
  const [documentNumber, setDocumentNumber] = useState(business_user?.lr_document_number || '');
  const [secondaryDocumentNumber, setSecondaryDocumentNumber] = useState(business_user?.lr_secondary_document_number || '');
  const [documentIssuingCountryId, setDocumentIssuingCountryId] = useState(String(business_user?.lr_document_issuing_country?.id));
  const [documentExpeditionDate, setDocumentExpeditionDate] = useState(business_user?.lr_document_expedition_date);
  const [documentExpirationDate, setDocumentExpirationDate] = useState(business_user?.lr_document_expiry_at);
  const [birthdate, setBirthdate] = useState(business_user?.lr_birthdate);
  const [tradeName, setTradeName] = useState(business_user?.trade_name || '');
  const [websiteUrl, setWebsiteUrl] = useState(business_user?.website_url || '');
  const [physicalCountryId, setPhysicalCountryId] = useState(String(business_user?.physical_country?.id));
  const [physicalStateId, setPhysicalStateId] = useState(String(business_user?.physical_state?.id));
  const [physicalCityId, setPhysicalCityId] = useState(String(business_user?.physical_city?.id));
  const [physicalZipcode, setPhysicalZipcode] = useState(business_user?.physical_zipcode);
  const [physicalAddress, setPhysicalAddress] = useState(business_user?.physical_address);
  const [operationCountries, setOperationCountries] = useState(business_user?.operation_countries);
  const [sameToLegalLocation, setSameToLegalLocation] = useState(business_user?.same_to_legal_location);
  const [isFinancialCompany, setIsFinancialCompany] = useState(business_user?.is_financial_company);
  const [occupation, setOccupation] = useState(business_user?.lr_occupation);
  const [civilStatus, setCivilStatus] = useState(business_user?.lr_civil_status);
  const [gender, setGender] = useState(business_user?.lr_gender);
  const [hasLicense, setHasLicense] = useState(business_user?.hasLicense);
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [occupations, setOccupations] = useState([]);

  useEffect(() => {
    let isFetch = true;

    (async () => {
      try {
        if(isFetch){
          const response = await PublicProvider.getOccupations();
          setOccupations(
            response.data?.data?.map(({label, value}) => {
              return {
                id: value,
                name: label
              }
            }
          ) || []);
        }
      } catch { }
      isFetch = false;
    })();

    return () => { isFetch = false; };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await CountriesProvider.fetchCountries();
        setCountries(
          response.data.data.map(({ 
            id,
            attributes: {
              name,
              iso_code
            } 
          }) => {
            return {
              id,
              name: iso_code === 'COCOP' ? 'Colombia 2' : name,
            };
          })
        );
      } catch { }
    })();
  }, []);

  useEffect(() => {
    setCities([]);
    (async () => {
      try {
        const response = await CountriesProvider.fetchProvinces(physicalCountryId);
        setStates(response.data.map(({ id, name }) => ({ id: String(id), name })));
      } catch {
        setStates([]);
      }
    })();
  }, [physicalCountryId]);

  useEffect(() => {
    (async () => {
      try {
        const response = await CountriesProvider.fetchCities(physicalStateId);
        setCities(
          response.data.map(({ id, name }) => ({ id: String(id), name }))
        );
      } catch {
        setCities([]);
      }
    })();
  }, [physicalStateId]);

  useEffect(() => {
    if(sameToLegalLocation){
      setPhysicalCountryId(String(record?.attributes?.country_id));
      setPhysicalStateId(String(record?.attributes?.state_id));
      setPhysicalCityId(String(record?.attributes?.city_obj_id));
      setPhysicalAddress(record?.attributes?.address);
      setPhysicalZipcode(record?.attributes?.zipcode);
    }else{
      setPhysicalCountryId(String(business_user?.physical_country?.id || '0'));
      setPhysicalStateId(String(business_user?.physical_state?.id || '0'));
      setPhysicalCityId(String(business_user?.physical_city?.id || '0'));
      setPhysicalAddress(business_user?.physical_address || '');
      setPhysicalZipcode(business_user?.physical_zipcode || '');
    }
  }, [record, business_user, sameToLegalLocation]);

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const data = {
      lr_first_name: firstName,
      lr_last_name: lastName,
      lr_phone_number: phoneNumber,
      lr_document_type: documentType,
      lr_document_number: documentNumber,
      lr_secondary_document_number: secondaryDocumentNumber,
      lr_document_issuing_country_id: documentIssuingCountryId,
      lr_document_expedition_date: documentExpeditionDate,
      lr_document_expiry_at: documentExpirationDate,
      lr_birthdate: birthdate,
      lr_occupation: occupation,
      lr_civil_status: civilStatus,
      lr_gender: gender,
      website_url: websiteUrl,
      trade_name: tradeName,
      physical_country_id: physicalCountryId,
      physical_state_id: physicalStateId,
      physical_city_id: physicalCityId,
      physical_address: physicalAddress,
      physical_zipcode: physicalZipcode,
      operation_countries: operationCountries,
      same_to_legal_location: sameToLegalLocation,
      is_financial_company: isFinancialCompany,
      has_license: isFinancialCompany && hasLicense
    };
    
    try {
      await UserProvider.updateBusiness(user_id, data);
    } catch (error) {
      Swal.fire({
        title: 'No fue posible completar la solicitud',
        text: error.message,
        icon: 'error'
      });
    }
    
    setIsLoading(false);
  };
  
  return (
    <Container style={{ width: "100%" }}>
      <br /><br />
      <form onSubmit={handleSubmit}>
        <Typography
          variant="body1"
          gutterBottom
          style={styles.titleWhite}
        >
          Información empresarial
        </Typography>

        <Row
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={{ flexGrow: 1 }}
        >
          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              label='Nombre comercial'
              type="text"
              id="lr_trade_name"
              name="lr_trade_name"
              value={tradeName}
              onChange={(event) => setTradeName(event.target.value)}
              required
            />
          </Col>

          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              label='Website'
              type="text"
              id="website_url"
              name="website_url"
              value={websiteUrl}
              onChange={(event) => setWebsiteUrl(event.target.value)}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  id="is_financial_company"
                  name="is_financial_company"
                  checked={isFinancialCompany}
                  onChange={(event) => setIsFinancialCompany(event.target.checked)}
                />
              }
              label={'¿Es una empresa financiera?'}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {
              isFinancialCompany &&
                <FormControlLabel
                  control={
                    <Switch
                      id="has_license"
                      name="has_license"
                      checked={hasLicense}
                      onChange={(event) => setHasLicense(event.target.checked)}
                    />
                  }
                  label={'¿Tiene licencia o registro?'}
                />
            }
          </Col>
        </Row>

        <Typography
          variant="body1"
          gutterBottom
          style={styles.titleWhite}
        >
          Representante Legal
        </Typography>

        <Row
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={{ flexGrow: 1 }}
        >
          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              label='Nombre'
              type="text"
              id="lr_first_name"
              name="lr_first_name"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              required
            />
          </Col>

          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              label='Apellido'
              type="text"
              id="lr_last_name"
              name="lr_last_name"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              required
            />
          </Col>

          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              label='Número de documento'
              type="text"
              id="lr_document_number"
              name="lr_document_number"
              value={documentNumber}
              onChange={(event) => setDocumentNumber(event.target.value)}
              required
            />
          </Col>

          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <SelectInput
              label="Tipo de documento"
              id={'lr_document_type'}
              name={'lr_document_type'}
              source={'lr_document_type'}
              choices={DOCUMENT_TYPE}
              onChange={(event, key, payload) => setDocumentType(key)}
              defaultValue={props?.business_user?.lr_document_type}
              required
            />
          </Col>

          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              label='Número de documento secundario'
              type="text"
              id="lr_secondary_document_number"
              name="lr_secondary_document_number"
              value={secondaryDocumentNumber}
              onChange={(event) => setSecondaryDocumentNumber(event.target.value)}
              required
            />
          </Col>

          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <SelectInput
              label="País emisor del documento"
              id={'lr_document_issuing_country'}
              name={'lr_document_issuing_country'}
              source={'lr_document_issuing_country'}
              choices={countries}
              onChange={(event, key, payload) => setDocumentIssuingCountryId(key)}
              defaultValue={String(props?.business_user?.lr_document_issuing_country?.id)}
              required
            />
          </Col>

          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <DateInput
              label={
                <div style={{ whiteSpace: 'nowrap' }}>Expedición del documento</div>
              }
              source={'attributes.business_user.lr_document_expedition_date'}
              onChange={(event, key, payload) => setDocumentExpeditionDate(key)}
            />
          </Col>

          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <DateInput
              label={
                <div style={{ whiteSpace: 'nowrap' }}>Expiración del documento</div>
              }
              source={'attributes.business_user.lr_document_expiry_at'}
              onChange={(event, key, payload) => setDocumentExpirationDate(key)}
            />
          </Col>

          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              label='Número de teléfono'
              type="text"
              id="lr_phone_number"
              name="lr_phone_number"
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
              required
            />
          </Col>

          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <DateInput
              label={
                <div style={{ whiteSpace: 'nowrap' }}>Fecha de nacimiento</div>
              }
              source={'attributes.business_user.lr_birthdate'}
              onChange={(event, key, payload) => setBirthdate(key)}
            />
          </Col>

          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <SelectInput
              label='Ocupación'
              type="text"
              id="lr_occupation"
              name="lr_occupation"
              source="lr_occupation"
              choices={occupations}
              defaultValue={occupation}
              onChange={(event, key, payload) => setOccupation(key)}
              required
            />
          </Col>

          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <SelectInput
              label="Estado civil"
              id={'lr_civil_status'}
              name={'lr_civil_status'}
              source={'lr_civil_status'}
              choices={CIVIL_STATUS}
              onChange={(event, key, payload) => setCivilStatus(key)}
              defaultValue={civilStatus}
              required
            />
          </Col>

          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <SelectInput
              label="Sexo"
              id={'lr_gender'}
              name={'lr_gender'}
              source={'lr_gender'}
              choices={GENDERS}
              onChange={(event, key, payload) => setGender(key)}
              defaultValue={gender}
              required
            />
          </Col>
        </Row>

        <br /><br />
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              variant="body1"
              gutterBottom
              style={styles.titleWhite}
            >
              Locación física
            </Typography>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  id="same_to_legal_location"
                  name="same_to_legal_location"
                  checked={sameToLegalLocation}
                  onChange={(event) => setSameToLegalLocation(event.target.checked)}
                />
              }
              label={'¿La dirección física es igual a la dirección legal?'}
            />
          </Col>
        </Row>
        <Row
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={{ flexGrow: 1 }}
        >
          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <SelectInput
              label="País"
              id={'physical_country'}
              name={'physical_country'}
              source={'physical_country'}
              choices={countries}
              onChange={(event, key, payload) => setPhysicalCountryId(key)}
              defaultValue={String(physicalCountryId)}
              required
              disabled={sameToLegalLocation}
            />
          </Col>

          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <SelectInput
              label="Estado"
              id={'physical_state'}
              name={'physical_state'}
              choices={states}
              onChange={(event, key, payload) => setPhysicalStateId(key)}
              defaultValue={String(physicalStateId)}
              required
              disabled={sameToLegalLocation}
            />
          </Col>

          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <SelectInput
              label="Ciudad"
              id={'physical_city'}
              name={'physical_city'}
              source={'physical_city'}
              choices={cities}
              onChange={(event, key, payload) => setPhysicalCityId(key)}
              defaultValue={String(physicalCityId)}
              required
              disabled={sameToLegalLocation}
            />
          </Col>

          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              label='Dirección'
              type="text"
              id="physical_address"
              name="physical_address"
              value={physicalAddress}
              onChange={(event) => setPhysicalAddress(event.target.value)}
              disabled={sameToLegalLocation}
            />
          </Col>

          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              label='Zipcode'
              type="text"
              id="physical_zipcode"
              name="physical_zipcode"
              value={physicalZipcode}
              onChange={(event) => setPhysicalZipcode(event.target.value)}
              disabled={sameToLegalLocation}
            />
          </Col>

          <Col
            xs={3}
            style={{
              ...styles.divisor,
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              label='Países donde opera'
              type="text"
              id="operation_countries"
              name="operation_countries"
              value={operationCountries}
              onChange={(event) => setOperationCountries(event.target.value)}
            />
          </Col>
        </Row>
        <br /><br />
        <Row style={{justifyContent: 'right'}}>
          <Button type='submit' variant='contained' color='secondary'>
            {
              (isLoading) ? 
              (<CircularProgress size={20} color={'info'}/>) :
              ('Guardar')
            }
          </Button>
        </Row>
      </form>
    </Container>
  );
};

export default BusinessUserPanel;
