import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const getListObjectives = async () =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.OBJECTIVES.GET_OBJECTIVES,
  });

export default {
  getListObjectives,
};