import { EuroSymbol } from "@material-ui/icons";
import useAccessRole from "../../hooks/useAccessRole";

import SegmentList from "./SegmentList";

const { isAccess } = useAccessRole();

export default {
  name: "segmentListUsers",
  icon: EuroSymbol,
  list: isAccess("show_segments") ? SegmentList : false,
};