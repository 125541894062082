import React, { useMemo } from "react";
import moment from "moment";
import {
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { TransactionProvider } from '../../../Providers';
import { Show, SimpleShowLayout } from "react-admin";
import { categories } from "../../../Config/constants";
import { MoneyTransactionField } from "../../../Components";
import { ListComment } from '../../../Components/comments';
import styles from "./styles";
import { toMoney } from '../../../Helpers/MoneyHelper';
import useAccessRole from "../../../hooks/useAccessRole";
import { CATEGORY, DOCUMENT_TYPE } from "../../../Utils";

const TransactionShow = (props) => {
  const { isAccess } = useAccessRole();
  let RenderList = ({ servicesRender }) => {
    return (
      <>
        <Grid
          item
          xs={12}
          style={{ ...styles.header, textAlign: "center", fontSize: "25px" }}
        >
          Servicios asociados
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align={"right"}>Id</TableCell>
              <TableCell align={"right"}>Id del proveedor</TableCell>
              <TableCell align={"right"}>Tipo de transacción</TableCell>
              <TableCell align={"right"}>Fecha de creación</TableCell>
              <TableCell align={"right"}>Nombre del convenio</TableCell>
              <TableCell align={"right"}>Número del convenio</TableCell>
              <TableCell align={"right"}>Número de cuenta</TableCell>
              <TableCell align={"right"}>Total</TableCell>
              <TableCell align={"right"}>Código de autorización</TableCell>
              <TableCell align={"right"}>Nombre del usuario</TableCell>
              <TableCell align={"right"}>Correo del usuario</TableCell>
              <TableCell align={"right"}>Identificación del usuario</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {servicesRender.map((service) => {
              const {
                id,
                category,
                date_time,
                utility_name,
                utility_number,
                client_number,
                amount,
                authorization_code,
                first_name,
                last_name,
                email,
                user_document_number,
                transaction_provider_id
              } = service;
              const name = `${first_name} ${last_name}`;

              return (
                <TableRow>
                  <TableCell align={"right"}>{id}</TableCell>
                  <TableCell align={"right"}>{transaction_provider_id}</TableCell>
                  <TableCell align={"right"}>{category}</TableCell>
                  <TableCell align={"right"}>{moment.utc(date_time).format("L HH:mm:ss")} UTC</TableCell>
                  <TableCell align={"right"}>{utility_name}</TableCell>
                  <TableCell align={"right"}>{utility_number}</TableCell>
                  <TableCell align={"right"}>{client_number}</TableCell>
                  <TableCell align={"right"}>{amount}</TableCell>
                  <TableCell align={"right"}>{authorization_code}</TableCell>
                  <TableCell align={"right"}>{name}</TableCell>
                  <TableCell align={"right"}>{email}</TableCell>
                  <TableCell align={"right"}>{user_document_number}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </>
    );
  };

  const RenderFinalCustomerFields = (final_customer) => {
    switch (final_customer.customer_type) {
      case 'natural':
        return (
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="flex-start"
            xs={12}
            sm={12}
            item
            style={{
              border: "3px solid white",
              borderRadius: 20,
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 30,
              marginBottom: 30,
            }}
          >
            <Grid item xs={12} style={styles.header}>
              Cliente final
            </Grid>
            <Grid item style={styles.containerField} xs={12} sm={6}>
              <TextField
                value={CATEGORY.find(e => e.id === final_customer.customer_type)?.name || final_customer.customer_type}
                label={"TIPO DE CLIENTE"}
                fullWidth
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item style={styles.containerField} xs={12} sm={6}>
              <TextField
                value={final_customer.legal_name}
                label={"NOMBRE COMPLETO"}
                fullWidth
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item style={styles.containerField} xs={12} sm={6}>
              <TextField
                value={DOCUMENT_TYPE.find(e => e.id === final_customer.document_type)?.name || final_customer.document_type}
                label={"TIPO DE DOCUMENTO DE IDENTIDAD"}
                fullWidth
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item style={styles.containerField} xs={12} sm={6}>
              <TextField
                value={final_customer.document_number}
                label={"NÚMERO DE DOCUMENTO DE IDENTIDAD"}
                fullWidth
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item style={styles.containerField} xs={12} sm={6}>
              <TextField
                value={final_customer.birthdate}
                label={"FECHA DE NACIMIENTO"}
                fullWidth
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item style={styles.containerField} xs={12} sm={6}>
              <TextField
                value={final_customer.legal_address}
                label={"DOMICILIO COMPLETO"}
                fullWidth
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
          </Grid>
        );
      case 'business':
        return (
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="flex-start"
            xs={12}
            sm={12}
            item
            style={{
              border: "3px solid white",
              borderRadius: 20,
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 30,
              marginBottom: 30,
            }}
          >
            <Grid item xs={12} style={styles.header}>
              Cliente final
            </Grid>
            <Grid item style={styles.containerField} xs={12} sm={6}>
              <TextField
                value={CATEGORY.find(e => e.id === final_customer.customer_type)?.name || final_customer.customer_type}
                label={"TIPO DE CLIENTE"}
                fullWidth
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item style={styles.containerField} xs={12} sm={6}>
              <TextField
                value={final_customer.legal_name}
                label={"RAZÓN SOCIAL"}
                fullWidth
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item style={styles.containerField} xs={12} sm={6}>
              <TextField
                value={final_customer.trade_name}
                label={"NOMBRE FANTASÍA"}
                fullWidth
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item style={styles.containerField} xs={12} sm={6}>
              <TextField
                value={final_customer.tax_id}
                label={"NÚMERO DE TAX ID"}
                fullWidth
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item style={styles.containerField} xs={12} sm={6}>
              <TextField
                value={final_customer.legal_address}
                label={"DOMICILIO LEGAL"}
                fullWidth
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
          </Grid>
        );
      default:
        return <></>;
    }
  }

  const RenderFields = (propsRecord) => {
    const {
      record: { id, attributes },
    } = propsRecord;

    attributes.geolocation = attributes.geolocation ? attributes.geolocation : {};

    const {
      created_at,
      category,
      total,
      currency,
      status,
      description,
      recipient_user,
      destination_country,
      sender_user,
      associated_services,
      account_bank,
      data_service_transaction,
      coupon_user,
      coupon_transfer,
      bonus_amount,
      reason_reject,
      final_customer,
      geolocation: {
        ip_address,
        user_agent,
        country_name,
        state_name,
        city_name,
        location_longitude,
        location_latitude,
      }
    } = attributes;

    const isGeolocationAvailable = useMemo(() => {
      return (ip_address || user_agent || country_name || state_name || city_name || location_longitude || location_latitude)
    }, [
      ip_address,
      user_agent,
      country_name,
      state_name,
      city_name,
      location_longitude,
      location_latitude,
    ]);

    const recipientUser = () => {
      const { document_type, document_number, first_name, last_name } =
        recipient_user.data.attributes;

      return (
        <>
          {document_type && category !== "service_payment" && (
            <Grid item style={styles.containerField} xs={12} sm={6}>
              <TextField
                value={document_type}
                label={"TIPO DE DOCUMENTO DE IDENTIDAD DE DESTINATARIO"}
                fullWidth
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
          )}
          {document_number && category !== "service_payment" && (
            <Grid item style={styles.containerField} xs={12} sm={6}>
              <TextField
                value={document_number}
                label={"NUMERO DE DOCUMENTO DE DESTINATARIO"}
                fullWidth
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
          )}
          {first_name && last_name && category !== "service_payment" && (
            <Grid item style={styles.containerField} xs={12} sm={6}>
              <TextField
                value={`${first_name} ${last_name}`}
                label={"NOMBRE COMPLETO DE DESTINATARIO"}
                fullWidth
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
          )}
        </>
      );
    };

    const transferInternational = () => {
      if (category === 'tax') {
        return null;
      }

      if (account_bank) {
        const {
          address,
          currency,
          document_number,
          document_type,
          first_name,
          last_name,
          account_bank: { bank_branch }
        } = account_bank;

        return (
          <>
            {address && (
              <Grid item style={styles.containerField} xs={12} sm={6}>
                <TextField
                  value={address}
                  label={"DIRECCION DE DESTINATARIO"}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
              </Grid>
            )}
            {currency && (
              <Grid item style={styles.containerField} xs={12} sm={6}>
                <TextField
                  value={currency}
                  label={"TIPO DE MONEDA DESTINATARIO"}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
              </Grid>
            )}
            {document_type && (
              <Grid item style={styles.containerField} xs={12} sm={6}>
                <TextField
                  value={document_type}
                  label={"TIPO DE DOCUMENTO DE IDENTIDAD DE DESTINATARIO"}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
              </Grid>
            )}
            {document_number && (
              <Grid item style={styles.containerField} xs={12} sm={6}>
                <TextField
                  value={document_number}
                  label={"NUMERO DE DOCUMENTO DE DESTINATARIO"}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
              </Grid>
            )}
            {first_name && last_name && (
              <Grid item style={styles.containerField} xs={12} sm={6}>
                <TextField
                  value={`${first_name} ${last_name}`}
                  label={"NOMBRE COMPLETO DE DESTINATARIO"}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
              </Grid>
            )}
            {bank_branch !== undefined && (
              <Grid item style={styles.containerField} xs={12} sm={6}>
                <TextField
                  value={`${bank_branch}`}
                  label={"SUCURSAL BANCARIA"}
                  fullWidth
                  InputProps={{
                    readOnly: false,
                    disableUnderline: true,
                  }}
                />
              </Grid>
            )}
          </>
        );
      }

      return <></>
    };

    const infoPaidServiceTransaction = () => {
      const {
        utility_name,
        utility_number,
        client_number,
        email,
        user_document_number,
      } = data_service_transaction;

      return (
        <>
          <Grid item style={styles.containerField} xs={12} sm={6}>
            <TextField
              value={utility_number}
              label={"NÚMERO DE CONVENIO"}
              fullWidth
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
          </Grid>
          <Grid item style={styles.containerField} xs={12} sm={6}>
            <TextField
              value={utility_name}
              label={"NOMBRE DE CONVENIO"}
              fullWidth
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
          </Grid>
          <Grid item style={styles.containerField} xs={12} sm={6}>
            <TextField
              value={client_number}
              label={"NÚMERO DE CLIENTE PARA EL CONVENIO"}
              fullWidth
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
          </Grid>
          <Grid item style={styles.containerField} xs={12} sm={6}>
            <TextField
              value={email}
              label={"EMAIL"}
              fullWidth
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
          </Grid>
          <Grid item style={styles.containerField} xs={12} sm={6}>
            <TextField
              value={user_document_number}
              label={"NÚMERO DE DOCUMENTO"}
              fullWidth
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
          </Grid>
        </>
      );
    };

    return (
      <>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="flex-start"
          xs={12}
          sm={12}
          item
          style={{
            border: "3px solid white",
            borderRadius: 20,
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 30,
            marginBottom: 30,
          }}
        >
          <Grid item xs={12} style={styles.header}>
            Información de la transacción
          </Grid>
          <Grid item style={styles.containerField} xs={12} sm={6}>
            <TextField
              value={id}
              label={"ID TRANSACCION"}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
          </Grid>

          {sender_user.data && (
            <Grid item style={styles.containerField} xs={12} sm={6}>
              <TextField
                value={`${sender_user.data.attributes.first_name} ${sender_user.data.attributes.last_name}`}
                label={"NOMBRE DE USUARIO"}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
          )}

          <Grid item style={styles.containerField} xs={12} sm={6}>
            <TextField
              value={categories[category]}
              label={"CATEGORIA"}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
          </Grid>

          <Grid item style={styles.containerField} xs={12} sm={6}>
            <Grid style={{ paddingBottom: 12 }} xs={12} sm={6}>
              <MoneyTransactionField
                record={{ attributes: { total, currency } }}
                attribute={"total"}
              />
            </Grid>
          </Grid>

          <Grid item style={styles.containerField} xs={12} sm={6}>
            <TextField
              value={`${moment.utc(created_at).format("L HH:mm:ss")} UTC`}
              label={"FECHA DE CREACION"}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
          </Grid>

          <Grid item style={styles.containerField} xs={12} sm={6}>
            <TextField
              value={status}
              label={"STATUS"}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
          </Grid>

          <Grid item style={styles.containerField} xs={12} sm={12}>
            <TextField
              value={description}
              label={"DESCRIPCION"}
              fullWidth
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
          </Grid>

          {status === 'denied' && (
            <Grid item style={styles.containerField} xs={12} sm={12}>
              <TextField
                value={reason_reject || ''}
                label={"RAZON DEL RECHAZO"}
                fullWidth
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
          )
          }

          {recipient_user.data ? recipientUser() : transferInternational()}

          {
            category === "service_payment" &&
            data_service_transaction &&
            infoPaidServiceTransaction()
          }

          {destination_country && (
            <Grid item style={styles.containerField} xs={12} sm={6}>
              <TextField
                value={destination_country}
                label={"PAIS DE DESTINO"}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Grid>
          )}

          {/*  campos que se dejan de usar
                  <Grid item style={styles.containerField}>
                      <TextField
                          value={currency}
                          label={'Moneda'}
                      />
                  </Grid>
                  <Grid item style={styles.containerField}>
                      <TextField
                          value={source}
                          label={'Fuente'}
                      />
                  </Grid>*/}
        </Grid>

        {
          final_customer && RenderFinalCustomerFields(final_customer)
        }

        {category === "service_payment" ? (
          <Grid
            container
            direction="row"
            alignItems="center"
            xs={12}
            sm={12}
            item
            style={{
              border: "3px solid white",
              borderRadius: 20,
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 30,
            }}
          >
            <Grid item style={styles.items}>
              {associated_services && associated_services.length !== 0 ? (
                <RenderList servicesRender={associated_services} />
              ) : (
                <Grid style={{ color: "white" }}>
                  Transacción no posee servicios
                </Grid>
              )}
            </Grid>
          </Grid>
        ) : null}
        {
          coupon_user && (
            <Grid
              container
              direction='row'
              alignItems='center'
              xs={12}
              sm={12}
              item
              style={{
                border: "3px solid white",
                borderRadius: 20,
                paddingTop: 20,
                paddingBottom: 20,
                paddingLeft: 30,
                marginTop: 10
              }}
            >
              <Grid item style={styles.containerField} xs={12} sm={6}>
                <TextField
                  value={coupon_user.coupon.identifier}
                  label={'Cupón'}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
              </Grid>
              <Grid item style={styles.containerField} xs={12} sm={6}>
                <TextField
                  value={coupon_user.coupon.coupon_type}
                  label={'Tipo de cupón'}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
              </Grid>
              <Grid item style={styles.containerField} xs={12} sm={6}>
                <TextField
                  value={coupon_user.coupon.amount}
                  label={'Monto del cupón'}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
              </Grid>
              <Grid item style={styles.containerField} xs={12} sm={6}>
                <TextField
                  value={coupon_user.coupon.bonus_type}
                  label={'Tipo de bono'}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
              </Grid>
              {
                category !== 'cash_back_coupon' &&
                <Grid item style={styles.containerField} xs={12} sm={6}>
                  <TextField
                    value={
                      `${toMoney(
                        (bonus_amount ? (bonus_amount - total) : coupon_transfer.amount),
                        coupon_user.coupon.currency
                      )} ${coupon_user.coupon.currency.toUpperCase()}`
                    }
                    label={coupon_user.coupon.coupon_type === 'cash_back' ? 'Se devuelven' : 'Se abona al envio'}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              }
              {
                coupon_user.coupon.coupon_type === 'bonus' &&
                <Grid item style={styles.containerField} xs={12} sm={6}>
                  <TextField
                    value={
                      `${toMoney(
                        bonus_amount,
                        coupon_user.coupon.currency
                      )} ${coupon_user.coupon.currency.toUpperCase()}`
                    }
                    label={'Total envio mas cupón'}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              }
            </Grid>
          )}
        {isGeolocationAvailable ? (
          <>

            <Grid
              container
              direction="row"
              alignItems="center"
              xs={12}
              sm={12}
              item
              style={{
                border: "3px solid white",
                borderRadius: 20,
                paddingTop: 20,
                paddingBottom: 20,
                paddingLeft: 30,
                marginTop: 10,
              }}
            >
              <div style={styles.title}>Información de ubicación del usuario que envía</div>
              {ip_address && (
                <Grid item style={styles.containerField} xs={12} sm={6}>
                  <TextField
                    value={ip_address}
                    label={"DIRECCIÓN IP"}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              )}
              {user_agent && (
                <Grid item style={styles.containerField} xs={12} sm={12}>
                  <TextField
                    value={`${user_agent}`}
                    label={"AGENTE DE USUARIO"}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              )}
              {country_name && (
                <Grid item style={styles.containerField} xs={12} sm={6}>
                  <TextField
                    value={country_name}
                    label={"PAÍS"}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              )}
              {state_name && (
                <Grid item style={styles.containerField} xs={12} sm={6}>
                  <TextField
                    value={`${state_name}`}
                    label={"ESTADO"}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              )}
              {city_name && (
                <Grid item style={styles.containerField} xs={12} sm={6}>
                  <TextField
                    value={`${city_name}`}
                    label={"CIUDAD"}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              )}
              {location_longitude && (
                <Grid item style={styles.containerField} xs={12} sm={6}>
                  <TextField
                    value={`${location_longitude}`}
                    label={"LONGITUD"}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              )}
              {location_latitude && (
                <Grid item style={styles.containerField} xs={12} sm={6}>
                  <TextField
                    value={`${location_latitude}`}
                    label={"LATITUD"}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </>
        ) : null}
        {
          isAccess('show_transaction_comments') && (
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="center"
              xs={12}
              sm={12}
              item
              style={{
                border: "3px solid white",
                borderRadius: 20,
                paddingTop: 20,
                paddingBottom: 20,
                paddingLeft: 30,
                marginTop: 10,
              }}
            >
              <div style={styles.title}>Comentarios de la transacción</div>
              <ListComment
                {...propsRecord}
                service={TransactionProvider.getTransactionComments}
              />
            </Grid>
          )
        }
      </>
    );
  };

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <RenderFields />
      </SimpleShowLayout>
    </Show>
  );
};
export default TransactionShow;
