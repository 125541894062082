import React from "react";
import { Button } from "@material-ui/core";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
} from "@material-ui/core";
import PropTypes from "prop-types";

import ModalComponent from "../../../../Components/ModalComponent";
import { OPTIONS_CREATE_PIX } from "../utils";

import { styles } from "../styles";

const ModalCreatePix = (props) => {
  const {
    open,
    onClose,
    handleCreateKeyPix,
    createPixData,
    handleOptionChange,
    validateCreatePix,
  } = props;

  return (
    <ModalComponent open={open} onClose={onClose}>
      <Card style={styles.container_create_pix}>
        <FormControl>
          <InputLabel id="select-label">Selecciona una opción</InputLabel>
          <Select
            value={createPixData.option}
            name="option"
            onChange={handleOptionChange}
            style={{ width: 265 }}
          >
            {OPTIONS_CREATE_PIX.map((item) => (
              <MenuItem value={item.id} key={item}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label={"Ingrese Valor"}
          name="value"
          value={createPixData.value}
          onChange={handleOptionChange}
          disabled={createPixData.option === 5}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateKeyPix}
          data-testid="delete-button"
          style={{ marginTop: 50 }}
          disabled={!validateCreatePix && createPixData.option !== 5}
        >
          Crear
        </Button>
      </Card>
    </ModalComponent>
  );
};

ModalCreatePix.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleCreateKeyPix: PropTypes.func,
  createPixData: PropTypes.shape({
    option: PropTypes.string,
    value: PropTypes.string,
  }),
  handleOptionChange: PropTypes.func,
  validateCreatePix: PropTypes.bool,
};

ModalCreatePix.defaultProps = {
  open: false,
  onClose: () => {},
  handleCreateKeyPix: () => {},
  createPixData: {
    option: "",
    value: "",
  },
  handleOptionChange: () => {},
  validateCreatePix: false,
};

export default ModalCreatePix;
