import colors from "../../../Assets/Colors";

const styles = {
  container: {
    padding: 5,
    paddingBottom: 20,
  },
  containerField: {
    padding: 5,
  },
  items: {
    marginBottom: 30,
  },
  header: {
    color: colors.white,
    textAlign: "center",
    fontSize: 20,
    padding: 20,
  },
};

export default styles;
