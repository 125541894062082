import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const checkTransactionalSegment = async (transactional_id) => (
	await apiCall({
		authorization: true,
		...ENDPOINTS.ADMIN.EMAIL_SEGMENTS.TEST_SEGMENT,
    data: {
      transactional_id
    }
	})
);

export default {
	checkTransactionalSegment,
};
