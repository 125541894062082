import colors from '../../../Assets/Colors';

const styles = {
    header: {
        color: colors.white,
        textAlign: 'center',
        fontSize: 20,
        padding: 20,
    },
    description: {
        color: colors.white,
        textAlign: 'center',
        fontSize: 14,
    },
    container: {
        flexGrow: 1,
    },
    dlocalImage: {
        width: 200,
    },
    content: {
        color: colors.white,
    },
    boxCenter: {
        paddingTop: 20,
        paddingBottom: 20,
        textAlign: 'center',
    },
};

export default styles;
