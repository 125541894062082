import React from "react";
import {
  BooleanField,
  Datagrid,
  FunctionField,
  List,
  TextField
} from "react-admin";

import RequestsFilter from '../Filters';
import ShowResponseAndRequest from '../components/ShowResponseAndRequest';
import { Pagination } from "../../../Components";
import moment from "moment";
import useExportFiles from "../../../hooks/useExportFiles";
import { FIELDS_EXPORTS_FILES } from "../../utils";

const RequestsList = (props) => {
  const { exportFileRequest } = useExportFiles();

  const exporter = (posts) => {
    exportFileRequest(posts, FIELDS_EXPORTS_FILES.REQUEST);
  };

  return (
    <List
      {...props}
      title={'Lista de solicitudes'}
      pagination={<Pagination />}
      filters={<RequestsFilter />}
      bulkActionButtons={false}
      exporter={exporter}
      debounce={1000}
    >
      <Datagrid>
        <TextField
          source={'id'}
          label={'Id'}
        />
        <TextField
          source={'attributes.external_id'}
          label={'Case ID'}
        />
        <TextField
          source={'attributes.response_time'}
          label={'Tiempo de respuesta en microsegundos'}
        />
        <FunctionField
          label={'Fecha'}
          source={'attributes.created_at'}
          render={(record) => {
            return `${moment.utc(record.attributes.created_at).format('LLLL')} UTC`;
          }}
        />
        <TextField
          source={'attributes.provider'}
          label={'Proveedor'}
        />
        <BooleanField
          label={'Exito?'}
          source={'attributes.is_successful'}
        />
        <TextField
          source={'attributes.target_type'}
          label={'Recurso'}
        />
        <TextField
          source={'attributes.target_id'}
          label={'Id del recurso'}
        />
        <TextField
          source={'attributes.url'}
          label={'Url'}
        />
        <TextField
          source={'attributes.method'}
          label={'Método'}
        />
        <TextField
          source={'attributes.successful_message'}
          label={'Mensaje de exito'}
        />
        <TextField
          source={'attributes.error_message'}
          label={'Mensaje de error'}
        />
        <TextField
          source={'attributes.error_code'}
          label={'Código de error'}
        />
        <ShowResponseAndRequest {...props} />
        <ShowResponseAndRequest {...props} type='response' />
      </Datagrid>
    </List>
  )
};

export default RequestsList;