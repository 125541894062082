const styles = {
  modal: {
    width: '500px',
    height: 'auto',
    margin: '0 auto',
    padding: '25px',
    borderRadius: '2px',
    top: '50%',
    transform: 'translate(0, -50%)',
    left: '0',
    right: '0',
    position: 'absolute',
    backgroundColor: '#424242',
    color: '#b4b4b4',
    boxShadow: '5px 5px 5px #000',
    textAlign: 'center'
  }
};

export default styles;