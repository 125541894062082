import React, { memo, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import RichTextInput from 'ra-input-rich-text';

import useAlerts from '../../../hooks/useAlerts';
import useReset from '../../../hooks/useReset';
import Images from '../../../Assets/images';

const CreateComment = (props) => {
  const { service, serviceExtraArgs = {}, content, id, onClose } = props;

  const { successMessage, errorMessage } = useAlerts();
  const { resetList } = useReset();

  const disabled = useMemo(() => !content || (content && content.length < 1), [content]);

  useEffect(() => {
    resetRichTextInput();
  }, []);

  const resetRichTextInput = () => {
    const qlEditor = document.querySelector('.ql-editor');
    
    if (qlEditor) {
      qlEditor.innerHTML = '';
    }
  };

  const handleCreatComment = async (e) => {
    e.preventDefault();

    try {
      await service(id, { content, ...serviceExtraArgs });
      await onClose();

      resetList();
      successMessage('Comentario agregado');
    } catch (error) {
      resetList();
      errorMessage(error.data.message);
    }
  };

  return (
    <div className='comments-container'>
      <div className='ra-rich-text-custom'>
        <RichTextInput
          source={'content'}
          label={'content'}
          theme='snow'
        />
        <button
          onClick={(e) => handleCreatComment(e)}
          disabled={disabled}
        >
          <img
            src={Images.Send}
          />
        </button>
      </div>
    </div>
  );
};

CreateComment.propTypes = {
  service: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

CreateComment.defaultProps = {
  service: () => {},
  onClose: () => {},
};

export default memo(CreateComment, (prev, next) => {
  return (
    prev.content === next.content &&
    prev.service === next.service
  );
});