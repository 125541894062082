import React, { useState } from 'react';
import moment from 'moment';
import { Animated } from "react-animated-css";
import { Button, TextField, Switch, FormControlLabel, CircularProgress } from '@material-ui/core';
import styles from '../../../Withdrawals/CustomComponents/MakeWithdrawalButton/styles';
import Images from "../../../../Assets/images";
import TransactionProvider from "../../../../Providers/Transactions";
import { toMoney } from '../../../../Helpers/MoneyHelper';
import { useAccessRole, useReset } from '../../../../hooks';
import Swal from 'sweetalert2';

moment.locale('es');

const BitcoinInfo = (props) => {
  const { record: {
    id,
    attributes: {
      is_locked,
      created_at,
      amount,
      to_address,
      sender_user: {
        data: {
          attributes: {
            first_name,
            last_name,
            email,
          },
        },
      },
    },
  } } = props;

  const { isAccess } = useAccessRole();
  const [isLocked, setIsLocked] = useState(is_locked);
  const [isAutomaticPayment, setAutomaticPayment] = useState(false);
  const [isManualPayment, setManualPayment] = useState(false);
  const [isFetch, setFetch] = useState();
  const [isError, setError] = useState(false);
  const [error, setE] = useState('');
  const [hash, setHash] = useState('');
  const { resetList } = useReset();

  const handleChangeLockedStatus = async () => {
    Swal.showLoading();
    try {
      await TransactionProvider.updateLockedStatus(id, { is_locked: !is_locked });
      resetList();
      Swal.close();
    } catch (error) {
      Swal.close();
      Swal.fire(
        'Lo sentimos, algo no fue bien',
        'No fue posible cambiar el estado de bloqueo de este registro.',
        'error',
      )
    }
  }

  const upLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const changeHash = (e) => {
    setHash(e.target.value);
  };

  const manualPayment = async () => {
    try {
      setFetch(true);
      await TransactionProvider.assignHash(id, hash);
      resetList();
    } catch (e) {
      setE(e.data.message ? e.data.message : JSON.stringify(e.response.data));
      setError(true);
    }
    setFetch(false);
  };


  // TO DELETE WHEN FUNCIONALITY HAS COMPLETED

  const [score, setScore] = useState(null);
  const [loadingScore, setLoadingScore] = useState(false);

  const STATUS_AVAILABLE = [
    {
      owner: 'Binance',
      risk: 0,
      message: '',
    },
    {
      owner: 'UNKNOWN',
      risk: 10,
      message: 'Interacted directly with one or more sanctioned addresses',
    },
    {
      owner: 'Circle',
      risk: 5,
      message: '',
    },
  ];

  const validRiskColor = (risk) => {
    const risks = {
      '0': 'low',
      '1': 'low',
      '5': 'medium',
      '10': 'high',
      '15': 'high'
    }

    return risks[`${risk}`] || "low";
  };

  const calculateScore = () => {
    setLoadingScore(true);

    setTimeout(() => {
      const index = Math.floor(Math.random() * STATUS_AVAILABLE.length);
      setScore(STATUS_AVAILABLE[index]);

      setLoadingScore(false);
    }, 2000);
  };

  return (
    <div style={{ width: '100%', height: isAccess('edit_transaction_locked_status') ? 400 : 300 }}>
      <div style={{ width: '100%', maxWidth: 300, padding: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          Envío
          <div style={{ fontSize: 18, fontWeight: 'bold' }}>
            {id}
          </div>
        </div>
        <div>
          <div>{moment.utc(created_at).format('LLLL')} UTC</div>
          <div style={{ fontSize: 25, fontWeight: 'bold', color: '#00d9ff' }}>{toMoney(amount, 'btc')} BTC</div>
        </div>
      </div>
      <div style={{ paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 50, justifyContent: 'flex-start' }}>
            <div style={{ fontSize: 20, fontWeight: 'bold' }}>
              {first_name ? upLetter(first_name) : ''} {last_name ? upLetter(last_name) : ''}
            </div>
            <div style={{ fontSize: 12 }}>
              {email}
            </div>
          </div>
          {
            score && (
              <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 50, width: 385 }}>
                <div className='score-row'>
                  <div>
                    <h4 className={`score-risk-${validRiskColor(score.risk)}`}>Risk {score.risk}</h4>
                  </div>
                  <div>
                    <h4 className='score-owner'>OWNER <br /><span>{score.owner}</span></h4>
                  </div>
                </div>
                <div className='score-row'>
                  <p>{score.message}</p>
                </div>
              </div>
            )
          }
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ width: 100, height: isAccess('edit_transaction_locked_status') ? 200 : 120, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
              {
                isAccess('edit_transaction_locked_status') && (<FormControlLabel
                  control={
                    <Switch checked={is_locked} onChange={handleChangeLockedStatus} />
                  }
                  label={is_locked ? 'Desbloquear' : 'Bloquear'}
                />)
              }

              <Button
                className='btn-score'
                onClick={calculateScore}
                disabled={loadingScore}
              >
                {
                  loadingScore ? (
                    <CircularProgress size={14} style={{ 'color': 'white' }} />
                  ) : (
                    'Score'
                  )
                }
              </Button>
              <Button
                className='btn'
                disabled={is_locked}
                onClick={() => setManualPayment(true)}
                style={is_locked ? styles.buttonDisabled : styles.buttonBlue}
              >
                PAGO MANUAL
              </Button>
              <Button
                className='btn'
                disabled={is_locked}
                onClick={() => setAutomaticPayment(true)}
                style={is_locked ? styles.buttonDisabled : styles.buttonBlue}
              >
                PAGO AUTOMÁTICO
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: '100%', paddingTop: 5, fontSize: 15, textAlign: 'center' }}>Wallets</div>
      {
        to_address && (
          <div style={{ width: '100%', padding: 5, paddingBottom: 15, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ padding: 5 }}>
              <div style={{ fontSize: 12, padding: 10, textAlign: 'center', borderRadius: 5, width: 250, backgroundColor: '#00b7ff', }}>
                {to_address}
              </div>
            </div>
          </div>
        )
      }
      {isAutomaticPayment && (
        <div style={{ width: '100%', position: 'relative', top: -300, backgroundColor: 'rgba(0,0,0,.78)', height: 300, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          {isFetch ? (
            <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
              <Animated style={{ animationIterationCount: 'infinite' }} animationIn="jello" animationOut="fadeOut" isVisible={true}>
                <img src={Images.vita} width={60} height={60} />
              </Animated>
            </Animated>
          ) : isError ? (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ fontSize: 18, textAlign: 'center', }}>
                Ha ocurrido un error, intente mas tarde
              </div>
              <div style={{ fontSize: 14, paddingBottom: 20, textAlign: 'center', }}>
                {error}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Button onClick={() => { setError(false); setAutomaticPayment(false); }} style={styles.buttonRed}>
                  ATRAS
                </Button>
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ fontSize: 18, textAlign: 'center', fontWeight: 'bold' }}>
                ¿Está seguro de realizar esta operación?
              </div>
              <div style={{ fontSize: 14, paddingBottom: 20, textAlign: 'center', }}>
                la operación será  irreversible
              </div>
              <div style={{ width: 190, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button onClick={() => setAutomaticPayment(false)} style={styles.buttonRed}>
                  CANCELAR
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      {isManualPayment && (
        <div style={{ width: '100%', position: 'relative', top: -300, backgroundColor: 'rgba(0,0,0,.78)', height: 300, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          {isFetch ? (
            <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
              <Animated style={{ animationIterationCount: 'infinite' }} animationIn="jello" animationOut="fadeOut" isVisible={true}>
                <img src={Images.vita} width={60} height={60} />
              </Animated>
            </Animated>
          ) : isError ? (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ fontSize: 18, textAlign: 'center', }}>
                Ha ocurrido un error, intente mas tarde
              </div>
              <div style={{ fontSize: 14, paddingBottom: 20, textAlign: 'center', }}>
                {error}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Button onClick={() => { setError(false); setManualPayment(false); }} style={styles.buttonRed}>
                  ATRAS
                </Button>
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'center', }}>
                HASH RESULTANTE
              </div>
              <div style={{ fontSize: 14, width: '100%', maxWidth: 300, paddingBottom: 20, textAlign: 'center', }}>
                Por favor ejecute la transacción manualmente y al finalizar complete el hash resultante
              </div>
              <div style={{ paddingBottom: 30, width: '100%', maxWidth: 500 }}>
                <TextField
                  style={{ width: '100%' }}
                  value={hash}
                  onChange={changeHash}
                  placeholder={'hash'}
                />
              </div>
              <div style={{ width: 190, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button onClick={manualPayment} style={{ ...styles.buttonBlue, opacity: hash ? 1 : .5 }} disabled={!hash}>
                  PAGAR
                </Button>
                <Button onClick={() => setManualPayment(false)} style={styles.buttonRed}>
                  CANCELAR
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BitcoinInfo;