import { Person } from '@material-ui/icons';

import DlocalList   from './DlocalList';
import DlocalCreate from './DlocalCreate';
import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
    name: 'dlocal',
    icon: Person,
    list: DlocalList,
    create: isAccess('create_dlocal') ? DlocalCreate : false,
}
