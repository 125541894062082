import React from 'react';
import PropTypes from 'prop-types';
import { Filter, SelectInput } from 'react-admin';

const VitaPricesFilter = (props) => (
    <Filter {...props}>
        <SelectInput
            alwaysOn
            label={'Moneda'}
            source={'currency'}
            choices={props.currencies}
        />
        <SelectInput
            alwaysOn
            label={'Moneda destino'}
            source={'target_currency'}
            choices={props.targetCurrencies}
        />
    </Filter>
);

VitaPricesFilter.propTypes = {
    currencies: PropTypes.array,
    targetCurrencies: PropTypes.array,
};

VitaPricesFilter.defaultProps = {
    currencies: [],
    targetCurrencies: [],
};

export default VitaPricesFilter;