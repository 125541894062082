import React from 'react';
import VitaPricesProvider from '../../../../Providers/VitaPrices';
import useModal from '../../../../hooks/useModal';
import useReset from '../../../../hooks/useReset';
import { ModalConfirm } from '../../../../Components';

const DeleteButton = (props) => {
    const { open, setOpen, showModal, message, action } = useModal();
    const { resetList } = useReset();

    const deletePrice = async () => {
        try {
            await VitaPricesProvider.deleteVitaPrice(props.record.id);
            resetList();
        } catch {
            await showModal("Ha ocurrido un error, intente mas tarde", null);
        }
    }

    const deleteHandler = async () => {
        await showModal("¿Desea borrar esté vita precio?", deletePrice);
    }

    return (
        <>
            <ModalConfirm
                message={message}
                open={open}
                setOpen={setOpen}
                action={action}
            />
            <div style={{cursor: 'pointer'}} onClick={deleteHandler}>BORRAR</div>
        </>
    );
};

export default DeleteButton;