import { DELETE_MANY } from 'react-admin';

import { API_URL } from '../../../../Config/env';

const requestHandler = (type, params) => {
    switch (type) {
        case DELETE_MANY: {
            return {
                url: `${API_URL}/admin/bank_movements`,
                method: 'delete',
                data: {
                    ids: params.ids,
                },
            };
        }
        default: {
            return {

            }
        }
    }
};

export default requestHandler;