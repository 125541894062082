import React from "react";
import ModalComponent from "../../../Components/ModalComponent";
import {
  Grid,
  TextField,
  Card,
  Typography,
  CardContent,
  Button,
} from "@material-ui/core";
import PropTypes from "prop-types";

import {
  Routes,
  UploadImage,
  ShowBannerDetails
} from "./components";
import useSessionBanner from "./hooks/useSessionBanner";
import { TitleSesion, RenderCountries } from '../../../Components/Comunications';

import { styles } from "./styles";

const Banner = (props) => {
  const { open, handleCloseModal } = props;
  const {
    handleCheckboxChange,
    handleOptionChangeRoute,
    handleOnChangeUrlOptionRoute,
    handleOnChangeStatusGeneral,
    handleOnChangeImageDesktop,
    handleOnChangeImageMobile,
    handleCheckboxChangeCountries,
    handleOnChangeAllCountry,
    showImage,
    createBanner,
    updateBanner,
    statusRoute,
    statusGeneral,
    imageDesktop,
    imageMobile,
    allCountries,
    optionsCountries,
    selectedOptionRoute,
    urlOptionRoute,
    isEdit,
    counterCaracter,
    validateFields,
    isShowBannerdetailes,
    sceneShowBanner,
    hiddenImageDetails,
    selectedOptionTypeClient,
    handleOptionChangeTypeUser,
    timeTransition,
    handleChangeTimeTransition,
  } = useSessionBanner({...props});

  return (
    <ModalComponent open={open} onClose={handleCloseModal}>
      <Card style={styles.container}>
        <Button style={styles.buttonClose} onClick={handleCloseModal}>
          x
        </Button>
        <Typography
          variant='headline'
          align='center'
          style={{
            fontWeight: "bold",
            paddingTop: 30,
          }}
        >
          Nuevo banner
        </Typography>

        <CardContent>
          <TitleSesion title={"General"} width={"92%"} />
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <TextField
                label={"Nombre banner"}
                name='identifier'
                value={statusGeneral.identifier}
                onChange={handleOnChangeStatusGeneral}
                disabled={isEdit}
                style={{ width: "95%" }}
              />
            </Grid>
            <Grid item xs={6} style={{position: 'relative'}}>
              <TextField
                label={"Texto del banner"}
                name='textBanner'
                value={statusGeneral.textBanner}
                onChange={handleOnChangeStatusGeneral}
                inputProps={{
                  maxLength: 48,
                }}
                style={{ width: "88%" }}
              />
              <span style={styles.spanStyles}>
                {counterCaracter.textBanner}/48
              </span>
              <div style={styles.underLineSpan} />
            </Grid>

            <Grid container spacing={8}>
              <Grid item xs={6} style={styles.containerFondo}>
                <Typography style={styles.textFondo}>Fondo</Typography>
                <TextField
                  label={"Hexadecimal inicio"}
                  name='fontColor1'
                  value={statusGeneral.fontColor1}
                  onChange={handleOnChangeStatusGeneral}
                  style={styles.styleTextField}
                />
                <TextField
                  label={"Hexadecimal fin"}
                  name='fontColor2'
                  value={statusGeneral.fontColor2}
                  onChange={handleOnChangeStatusGeneral}
                  style={{ width: 100 }}
                />
              </Grid>
            </Grid>

            <Grid item xs={6} style={{ marginBottom: 10, display: "flex" }}>
              <Typography style={styles.textBotonDesktop}>
                Botón desktop
              </Typography>
              <TextField
                label={"Color fondo"}
                name='btColorBg'
                value={statusGeneral.btColorBg}
                onChange={handleOnChangeStatusGeneral}
                style={styles.styleTextField}
              />
              <TextField
                label={"Color texto"}
                name='btColorText'
                value={statusGeneral.btColorText}
                onChange={handleOnChangeStatusGeneral}
                style={{ width: 100 }}
              />
            </Grid>
            <Grid item xs={6} style={{position:'relative'}}> 
              <TextField
                label={"Texto"}
                name='btText'
                value={statusGeneral.btText}
                onChange={handleOnChangeStatusGeneral}
                inputProps={{
                  maxLength: 20,
                }}
                fullWidth
              />
              <span style={styles.spanStyles}>
                {counterCaracter.btText}/20
              </span>
            </Grid>

            <Grid container>
              <Grid item xs={6} style={styles.gridItem}>
                <Typography style={styles.textImagen}>Imagen</Typography>
                <UploadImage
                  title={"desktop"}
                  handleOnChange={handleOnChangeImageDesktop}
                  showImage={showImage}
                  image_design={imageDesktop}
                />
              </Grid>
              <Grid item xs={6} style={styles.gridItem}>
                <UploadImage
                  title={"mobile"}
                  handleOnChange={handleOnChangeImageMobile}
                  showImage={showImage}
                  image_design={imageMobile}
                />
              </Grid>
            </Grid>

            {isShowBannerdetailes && (
              <ShowBannerDetails
                {...statusGeneral}
                sceneShowBanner={sceneShowBanner}
                imageDesktop={ imageDesktop}
                imageMobile={ imageMobile}
                hiddenImageDetails={hiddenImageDetails}
                validateFields={validateFields}
              />
            )}
          </Grid>
        </CardContent>

        <CardContent>
          <TitleSesion title={"Ruta"} width={"95%"} />
          <Routes
            handleCheckboxChange={handleCheckboxChange}
            handleOptionChange={handleOptionChangeRoute}
            selectedOption={selectedOptionRoute}
            handleOnChangeUrlOption={handleOnChangeUrlOptionRoute}
            urlOption={urlOptionRoute}
            statusRoute={statusRoute}
            ValueTypeClient={selectedOptionTypeClient}
            handleOptionTypeUser={handleOptionChangeTypeUser}
            timeTransition={timeTransition}
            handleChangeTimeTransition={handleChangeTimeTransition}
          />
        </CardContent>

        <CardContent>
          <TitleSesion title={"Visibilidad"} width={"90%"} />
          <RenderCountries
            handleCheckboxChange={handleCheckboxChangeCountries}
            handleOnChangeAllCountry={handleOnChangeAllCountry}
            allCountries={allCountries}
            optionsCountries={optionsCountries}
            isEdit={isEdit}
          />
        </CardContent>

        <CardContent style={styles.containerButtons}>
          <Button
            variant='contained'
            onClick={handleCloseModal}
            style={{ backgroundColor: "#E9320A", marginRight: 60 }}
          >
            Cancelar
          </Button>
            <Button
              variant='contained'
              style={{ backgroundColor: validateFields ? "#005FEE" : 'gray'}}
              onClick={ isEdit ? updateBanner : createBanner }
              disabled={!validateFields}              
            >
              { isEdit ? 'GUARDAR CAMBIOS' : 'CREAR BANNER' } 
            </Button>
        </CardContent>
      </Card>
    </ModalComponent>
  );
};

Banner.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

Banner.defaultProps = {
  open: false,
  handleCloseModal: () => {},
};

export default Banner;
