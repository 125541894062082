import React from 'react';
import DOMPurify from 'dompurify';
import moment from 'moment';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
} from 'react-admin';
import { Button, IconButton } from '@material-ui/core/';
import { CheckBox, CheckBoxOutlineBlank, OpenInNew } from '@material-ui/icons/';

import AlertsFilter from '../Filters/AlertsFilter';
import { Pagination } from '../../../Components';
import { AlertsProvider } from '../../../Providers';
import { useAccessRole, useAlerts, useReset } from '../../../hooks';

const AlertList = (props) => {
  const { errorMessage } = useAlerts();
  const { isAccess } = useAccessRole();
  const { resetList } = useReset();

  const updateAlertStatus = async (id) => {
    try {
      if (isAccess('edit_general_alerts')) {
        await AlertsProvider.updateAlertStatus(id);
        resetList();
      } else {
        errorMessage('No tienes permisos para realizar esta acción');
      }
    } catch {
      errorMessage('Hubo un error al actualizar el estado.');
    };
  };

  const goTo = (type, id) => {
    let url = ''

    switch (type) {
      case 'Transaction':
        url = `/transacciones?filter=%7B"id"%3A"${id}"%7D&order=DESC&page=1&perPage=10&sort=id`
        break;
      case 'User':
        url = `/usuarios/${id}/show`
        break;
      default:
        break;
    }

    window.open(url, '_blank');
  };

  const Buttons = (props) => {
    const { id, attributes: { status, alertable_type, alertable_id } } = props;
    const statusBool = status === 'Pendiente'

    return (
      <>
        <IconButton
          disabled={!statusBool}
          onClick={() => updateAlertStatus(id)}
        >
          {statusBool ? <CheckBoxOutlineBlank /> : <CheckBox />}
        </IconButton>
      </>
    );
  };

  return (
    <List
      {...props}
      title={'Lista de alertas'}
      pagination={<Pagination />}
      filters={<AlertsFilter />}
      bulkActionButtons={false}
      export={false}
    >
      <Datagrid>
        <TextField
          source={'id'}
          label={'Id'}
        />
        <FunctionField
          label={'Fecha'}
          render={(record) => {
            return moment(record.attributes.created_at).format('LLL');
          }}
        />
        <FunctionField
          label={'Mensaje'}
          render={(record) => (
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(record.attributes.message) }} />
          )}
        />
        <FunctionField
          label={'Id de la operación'}
          render={(record) => (
            <Button
              onClick={() => goTo(record.attributes.alertable_type, record.attributes.alertable_id)}
            >
              {record.attributes.alertable_id}
              <OpenInNew
                style={{ width: '19', marginLeft: '2' }}
              />
            </Button>
          )}
        />
        <TextField
          source={'attributes.alert_type'}
          label={'Tipo de alerta'}
        />
        <TextField
          source={'attributes.status'}
          label={'Estado'}
        />
        <FunctionField
          label={'Acción'}
          render={(record) => (
            <Buttons {...record} />
          )}
        />
      </Datagrid>
    </List>
  )
};

export default AlertList;