import { Person } from '@material-ui/icons';

import BeneficiaryList from './BeneficiaryList';
import BeneficiaryShow from './BeneficiaryShow';
import BeneficiaryEdit from './BeneficiaryEdit';

export default {
  name: 'beneficiaries',
  icon: Person,
  list: BeneficiaryList,
  show: BeneficiaryShow,
  edit: BeneficiaryEdit
}