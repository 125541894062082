import { UPDATE, CREATE, DELETE } from "react-admin";

import { API_URL } from "../../../../Config/env";

const requestHandler = (type, params) => {
  switch (type) {
    case CREATE: {
      let { couponDetails, statusView } = params;

      if (statusView === "first") {
        return {
          url: `${API_URL}/admin/coupons`,
          method: "post",
          data: couponDetails,
        };
      }

      if (statusView === "third") {
        const {
          design_type,
          coupon_id,
          image_design,
          message_body,
          message_transactional,
        } = params;

        let data = {
          design_type,
          message_body,
          message_transactional,
          coupon_id,
        };
        if (design_type === "without_design") {
          return {
            url: `${API_URL}/admin/coupon_designs`,
            method: "post",
            data,
          };
        }
        if (design_type === "customized") {
          const formData = new FormData();
          formData.append("image_design", image_design);
          formData.append("design_type", design_type);
          formData.append("coupon_id", coupon_id);
          formData.append("message_body", message_body);
          formData.append("message_transactional", message_transactional);

          return {
            url: `${API_URL}/admin/coupon_designs`,
            method: "post",
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
        }

        return {
          url: `${API_URL}/admin/coupon_designs`,
          method: "post",
          data,
        };
      }
    }

    case UPDATE: {
      let { couponDetails, statusView } = params;

      if (params.state === "active") {
        return {
          url: `${API_URL}/admin/coupons/${params.id}/active`,
          method: "put",
        };
      }

      if (params.state === "inactive") {
        return {
          url: `${API_URL}/admin/coupons/${params.id}/inactive`,
          method: "put",
        };
      }

      if (statusView === "first") {
        return {
          url: `${API_URL}/admin/coupons/${params.id}`,
          method: "put",
          data: couponDetails,
        };
      }

      if (statusView === "second") {
        const { segment_id, coupon_id } = params;

        return {
          url: `${API_URL}/admin/segments/${segment_id}/assign_coupon`,
          method: "put",
          data: { coupon_id: coupon_id },
        };
      }

      if (statusView === "third") {
        const {
          design_type,
          coupon_id,
          image_design,
          message_body,
          message_transactional,
          id_desing,
        } = params;

        const data = {
          coupon_id,
          design_type,
          message_body,
          message_transactional,
        };
        if (design_type === "without_design") {
          return {
            url: `${API_URL}/admin/coupon_designs/${id_desing.id}}`,
            method: "put",
            data,
          };
        }
        if (design_type === "customized") {
          const formData = new FormData();

          if (image_design) formData.append("image_design", image_design);
          if (design_type) formData.append("design_type", design_type);
          if (message_body) formData.append("message_body", message_body);
          if (message_transactional)
            formData.append("message_transactional", message_transactional);
          formData.append("coupon_id", coupon_id);

          return {
            url: `${API_URL}/admin/coupon_designs/${id_desing.id}`,
            method: "put",
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
        }
        return {
          url: `${API_URL}/admin/coupon_designs/${id_desing.id}}`,
          method: "put",
          data,
        };
      }
    }

    case DELETE: {
      return {
        url: `${API_URL}/admin/coupons/${params}/delete`,
        method: "delete",
      };
    }

    default: {
      return {};
    }
  }
};

export default requestHandler;
