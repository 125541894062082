import { UPDATE } from 'react-admin';

import { API_URL } from '../../../../Config/env';

const requestHandler = (type, params) => {
    switch (type) {
        case UPDATE: {
            let {
                attributes: {
                    account_bank,
                    accept_funds,
                    accept_real_info,
                    accept_pep,
                    serie_number,
                    zipcode,
                    city,
                    category,
                    state_id,
                    balances,
                    default_currency,
                    first_name,
                    last_name,
                    birthdate,
                    document_type,
                    document_image,
                    back_document_image,
                    document_number,
                    document_expedition_date,
                    document_expiry_at,
                    phone,
                    country_id,
                    address,
                    daily_limit_amount,
                    daily_transbank_recharge_limit,
                    monthly_transbank_recharge_limit,
                    civil_status,
                    residence_country_id,
                    is_user_card_available,
                    is_service_payment,
                    description,
                    is_usdt,
                    is_usdc,
                    city_obj_id,
                    document_issuing_country_id,
                    secondary_document_number,
                    secondary_document_type,
                    payment_orders_service_status,
                    is_multi_currency,
                    is_trusted,
                    is_usd_account,
                },
                daily_limit_amount_add,
                daily_transbank_recharge_limit_add,
                monthly_transbank_recharge_limit_add,
                delete_daily_transbank_recharge_limit_amount,
                delete_monthly_transbank_recharge_limit_amount,
                delete_daily_limit_amount,
            } = params.data;
            let formData = new FormData();
            formData.append(
                'account_bank', (
                        account_bank && account_bank.hasOwnProperty('account_bank') &&
                        account_bank.account_bank && account_bank.hasOwnProperty('type_account_bank') &&
                        account_bank.type_account_bank && account_bank.hasOwnProperty('bank') &&
                        account_bank.bank
                    ) ? {
                        account_bank: account_bank.account_bank,
                        type_account_bank: account_bank.type_account_bank,
                        bank: account_bank.bank,
                        } : null
            );
            formData.append('accept_funds', accept_funds);
            formData.append('accept_real_info', accept_real_info);
            formData.append('accept_pep', accept_pep);
            formData.append('serie_number', serie_number);
            formData.append('zipcode', zipcode);
            formData.append('city', city);
            formData.append('category', category);
            formData.append('state_id', state_id);
            formData.append('balances', balances);
            formData.append('default_currency', default_currency);
            formData.append('first_name', first_name);
            formData.append('last_name', last_name);
            formData.append('birthdate', birthdate);
            formData.append('residence_country_id', residence_country_id);
            formData.append('document_type', document_type);
            if (document_image !== undefined && document_image !== null) {
                formData.append('document_image', document_image.rawFile);
            }
            if (back_document_image !== undefined && back_document_image !== null) {
                formData.append('back_document_image', back_document_image.rawFile);
            }
            formData.append('document_number', document_number);
            formData.append('country_id', country_id);
            formData.append('address', address);
            formData.append('daily_limit_amount', daily_limit_amount);
            formData.append('daily_transbank_recharge_limit', daily_transbank_recharge_limit);
            formData.append('monthly_transbank_recharge_limit', monthly_transbank_recharge_limit);
            formData.append('civil_status', civil_status);
            formData.append('is_user_card_available', is_user_card_available);
            formData.append('phone', phone);
            formData.append('document_expedition_date', document_expedition_date);
            formData.append('document_expiry_at', document_expiry_at);
            formData.append('is_service_payment', is_service_payment);
            formData.append('is_usdt', is_usdt);
            formData.append('is_usdc', is_usdc);
            formData.append('city_obj_id', city_obj_id);
            formData.append('document_issuing_country_id', document_issuing_country_id);
            formData.append('secondary_document_type', secondary_document_type);
            formData.append('secondary_document_number', secondary_document_number);
            formData.append('payment_orders_service_status', payment_orders_service_status);
            formData.append('is_multi_currency', is_multi_currency);
            formData.append('is_trusted', is_trusted);
            formData.append('is_usd_account', is_usd_account);

            default_currency = default_currency.toLowerCase();

            if (
                delete_daily_transbank_recharge_limit_amount || Math.abs(parseFloat(daily_transbank_recharge_limit_add) - parseFloat(daily_transbank_recharge_limit ? daily_transbank_recharge_limit : 0)) > 0
                ||
                delete_monthly_transbank_recharge_limit_amount || Math.abs(parseFloat(monthly_transbank_recharge_limit_add) - parseFloat(monthly_transbank_recharge_limit ? monthly_transbank_recharge_limit : 0)) > 0
            ) {
                return {
                    url: `${API_URL}/admin/users/${params.id}/set_daily_limit_transbank`,
                    method: 'post',
                    data: {
                        daily_transbank_recharge_limit: delete_daily_transbank_recharge_limit_amount ? null : daily_transbank_recharge_limit_add,
                        monthly_transbank_recharge_limit: delete_monthly_transbank_recharge_limit_amount ? null : monthly_transbank_recharge_limit_add,
                    }
                };
            }

            const setBalances = Object.entries(balances).filter(f => f[0].includes('_add') && f[1] > 0);

            if(setBalances.length === 0) {
                return {
                    url: `${API_URL}/admin/users/${params.id}/update_profile_user`,
                    method: 'put',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                };
            }

            return {
                url: `${API_URL}/admin/users/${params.id}/add_balance`,
                method: 'post',
                data: {
                    currency: setBalances[0][0].replace('_add', ''),
                    total: setBalances[0][1],
                    description,
                },
            };
        }
        default: {
            return {

            }
        }
    }
};

export default requestHandler;