import { useEffect, useMemo, useState } from "react";

import { Rest } from "../../../../../../Providers";

const useBannerDetails = (props) => {
  const { pathname } = props;
  const [listDetailBanner, setListDetailBanner] = useState([]);
  const [detailsBanner, setDetailsBanner] = useState("");

  const isShowDetails = useMemo(
    () => pathname?.includes("show_details"),
    [pathname]
  );

  useEffect(() => {
    (async () => {
      try {
        if (isShowDetails) {
          const id = pathname.split("/")[2];
          const response = await Rest("GET_ONE", "banners", {
            id,
            isShowDetails,
          });

          setListDetailBanner(response.data.attributes.details_banner);
          setDetailsBanner(
            JSON.parse(response.data.attributes.list_countries)
          );
        }
      } catch (error) {
      }
    })();

    return () => {
      setListDetailBanner([]);
    };
  }, [pathname, isShowDetails]);

  return {
    listDetailBanner,
    detailsBanner,
  };
};

export default useBannerDetails;
