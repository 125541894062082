import CategoryList from './CategoryList';
import CategoryEdit from './CategoryEdit';
import CategoryCreate from './CategoryCreate';

import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
  name: 'categories',
  list: CategoryList,
  create: isAccess('create_category') ? CategoryCreate : false,
  edit: isAccess('edit_category') ? CategoryEdit : false,
}