const styles = {
  divisor: {
      padding: '8px 12px',
  },
  divisorMoney: {
      padding: 15,
  },
  divisorTop: {
      paddingTop: 30,
  },
  titleWhite: {
      color: '#FFFFFF',
  },
  card: {
      padding: 10,
  },
  checked: {
      color: '#FFF'
  },
  noChecked: {
      backgroundColor: 'transparent',
      color: 'rgba(255,255,255,.6)',
  },
  avatar: {
      backgroundColor: 'transparent'
  },
  spinning: {
    marginLeft: '38%',
    marginTop: '1%'
  },
};

export default styles;
