import {useCallback, useState, useEffect} from 'react';

import CountriesProvider from '../Providers/Countries';

const useCountries = () => {
    const [pricesCountriesWhitOutConfig, setPricesCountriesWhitOutConfig] = useState([]);
    const [pricesCountriesWhitOutRules, setPricesCountriesWhitOutRules] = useState([]);
    const [transferCountries, setTransferCountries] = useState([]);
    const [countries, setCountries] = useState([]);
    const [paises, setPaises] = useState([]);

    useEffect(() => {
        let isFetch = true;

        (async () => {
            try {
                if (isFetch) {
                    let response = await CountriesProvider.fetchCountriesAdmin(true);
                    const data = [];
                    const transferData = [];
                    const data2 = [];
                    const paisesAuxiliar = [];
                    response.data.countries.data.map((country) => {
                        data2.push({
                            ...country,
                            ...country.attributes,
                        });

                        if (!country.attributes.is_prices_config) {
                            data.push({
                                ...country,
                                ...country.attributes,
                            });
                        }

                        if (!country.attributes.is_rules) {
                            transferData.push({
                                ...country,
                                ...country.attributes,
                            });
                        }

                        paisesAuxiliar.push({
                          ...country,
                          ...country.attributes,
                          value: country.id,
                          label: country.attributes.name,
                        });

                        return null;
                    });

                    setPricesCountriesWhitOutConfig(data);
                    setPaises(paisesAuxiliar);
                    setPricesCountriesWhitOutRules(transferData);
                    setCountries(data2);

                    response = await CountriesProvider.fetchCountries();
                    const transferTx = [];
                    response.data.data.map((country) => {
                        if (country.attributes.is_transfer) {
                            transferTx.push({
                                id: country.id,
                                ...country.attributes,
                            });
                        }
                    });

                    setTransferCountries(transferTx);

                    isFetch = false;
                }
            } catch {}
        })();

        return () => {
            isFetch = false;
        };
    }, []);

    const isTransferCountry = useCallback((isoCode) => {
        let isTransfer = false;

        transferCountries.map((country) => {
            if (country.iso_code.toUpperCase() === isoCode.toUpperCase()) {
                isTransfer = true;
            }
        });

        return isTransfer;
    }, [transferCountries]);

    return {
        countries,
        transferCountries,
        isTransferCountry,
        pricesCountriesWhitOutConfig,
        pricesCountriesWhitOutRules,
        paises
    };
};

export default useCountries;
