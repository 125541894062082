import React, { useEffect, useState } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { InputLabel, Button, TextField, Input, Grid } from "@material-ui/core";
import { OPTIONS_DESING } from "../utils";
import Swal from "sweetalert2";

import { stylesCuponDetails } from "./styles";

const CuponDetails = (props) => {
  const { backCuponDetailstoScreenSegment, sendCuponDetails, couponEditData } =
    props;

  const [state, setstate] = useState({
    design_type: "",
    message_body: "",
    message_transactional: "",
    image_design_url: null,
  });
  const [idDesing, setIdDesing] = useState(null);
  const [image_design, setImage_design] = useState([]);

  const loadindData = () => {
    if (couponEditData !== null) {
      const {
        id,
        attributes: {
          design_type,
          message_body,
          message_transactional,
          image_design_url,
        },
      } = couponEditData;

      setstate({
        design_type,
        message_body,
        message_transactional,
        image_design_url,
      });
      setIdDesing({ id });
    }
  };

  useEffect(() => {
    loadindData();
  }, [couponEditData]);

  const sendDataCouponDetails = () => {
    let data = { ...state, image_design: image_design[0], id_desing: idDesing };
    sendCuponDetails(data);
  };

  const handleOnchange = (e) => {
    setstate({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const verImage = () => {
    Swal.fire({
      position: "center",
      heightAuto: true,
      imageUrl: URL.createObjectURL(image_design[0]),
      text: "Imagen Cargada Correctamente.",
      width: 350,
      imageWidth: 200,
      imageHeight: 300,
      imageAlt: "Error",
    });
  };

  return (
    <Grid container style={{ marginTop: 20 }}>
      <Grid container style={stylesCuponDetails.gridContainer1}>
        <Grid item alignContent="center" style={{ /*display: "grid"*/ }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: 234 }}>
            <Input
              style={{ width: '100%' }}
              value="Diseño del cupón"
              disabled={"true"}
              disableUnderline={true}
            />
            <Select
              value={state.design_type}
              onChange={handleOnchange}
              name={"design_type"}
              style={{ width: 234 }}
            >
              {OPTIONS_DESING.map((itemField, index) => (
                <MenuItem key={index} value={itemField.id}>
                  {`${itemField.type}`}
                </MenuItem>
              ))}
            </Select>
          </div>
        </Grid>

        <Grid item>
          <TextField
            id="multiline-static"
            label="Cuerpo del mensaje en el modal"
            name={"message_body"}
            onChange={handleOnchange}
            value={state.message_body}
            multiline
            rows="4"
            placeholder={"Tu mensaje"}
            margin="normal"
            style={{
              width: 234,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ maxlength: 110 }}
          />
        </Grid>
        <Input
          style={{ marginLeft: "82%" }}
          value={`${state.message_body.length}/110`}
          disabled={"true"}
          disableUnderline={true}
        />
        <Grid item>
          <TextField
            id="multiline-static"
            label="Mensaje en sección transaccional"
            name={"message_transactional"}
            onChange={handleOnchange}
            value={state.message_transactional}
            margin="normal"
            style={{
              width: 234,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ maxlength: 34 }}
          />
        </Grid>
        <Input
          style={{ marginLeft: "82%" }}
          value={
            state.message_transactional &&
            `${state.message_transactional.length}/34`
          }
          disabled={"true"}
          disableUnderline={true}
        />
        <Grid item>
          {state.design_type === "customized" ? (
            <Grid container style={{ display: "grid" }}>
              <Input
                value="Imagen personalizada"
                disabled={"true"}
                disableUnderline={true}
              />
              <Button
                variant="contained"
                component="label"
                style={{
                  padding: 50,
                  backgroundColor: "transparent",
                  borderStyle: "dotted",
                  borderColor: "white",
                  borderWidth: 1,
                }}
              >
                <Grid container style={{ display: "grid" }}>
                  <Grid item style={{ marginBottom: 15 }}>
                    <InputLabel style={{ fontSize: 30 }}>🖼</InputLabel>
                  </Grid>
                  <Grid item>Subir imagen</Grid>
                </Grid>

                <input
                  type="file"
                  name="files"
                  accept=".jpg, .jpeg, .png"
                  hidden
                  onChange={(e) => setImage_design(e.target.files)}
                />
              </Button>
              {image_design.length > 0 && (
                <Button onClick={verImage}>Ver Imagen</Button>
              )}
              {state.image_design_url && (
                <a
                  href={state.image_design_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Descargar Imagen
                </a>
              )}
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      <Grid container style={stylesCuponDetails.gridContainerButton}>
        <Grid item md={3}>
          <Button
            style={stylesCuponDetails.color}
            onClick={backCuponDetailstoScreenSegment}
          >
            {"Atras"}
          </Button>
        </Grid>
        <Grid item md={2}></Grid>
        <Grid item md={4}>
          <Button
            style={stylesCuponDetails.buttonDraft}
            fullWidth
            onClick={sendDataCouponDetails}
          >
            {"Guardar como borrador"}
          </Button>
        </Grid>
        <Grid item md={3}>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 10 }}
            onClick={sendDataCouponDetails}
          >

            <div style={stylesCuponDetails.color}>{idDesing !== null ? 'Actualizar' : 'Crear'} Cupon</div>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CuponDetails;