import React from "react";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";

import { Rest } from "../../../../../Providers";
import { useReset } from "../../../../../hooks";
import { styleAlert } from "../../../../../Components/Comunications/utils";

const StatusSwich = (record) => {
  const { reloadPage } = useReset();

  const handleChange = async (event) => {
    const data = {
      id: record.attributes.id,
      is_active: event.target.checked,
      is_change_status: true,
      is_global: record.attributes.is_global,
    };

    try {
      const response = await Rest("UPDATE", "snackbars", data);

      if (response.data) {
        const {
          data: { is_active },
        } = response;

        Swal.fire({
          ...styleAlert,
          title: is_active
            ? '<span style="color: white;">Snackbar activo!</span>'
            : '<span style="color: white;">Snackbar inactivo!</span>',
        });
        reloadPage();
      }
    } catch (error) {
      Swal.fire({
        ...styleAlert,
        title:
          '<span style="color: white;">Error cambiando status de Snackbar</span>',
      });
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <FormControlLabel
        control={
          <Switch
            checked={record.attributes.is_active}
            onChange={handleChange}
            name={`${record.attributes.id}`}
            color={record.attributes.is_active ? "primary" : "default"}
          />
        }
      />
      <p>{record.attributes.is_active ? "Activo" : "Inactivo"}</p>
    </div>
  );
};

StatusSwich.propTypes = {
  record: PropTypes.shape({
    attributes: PropTypes.shape({
      id: PropTypes.number.isRequired,
      is_active: PropTypes.bool.isRequired,
    }).isRequired,
  }),
};

StatusSwich.defaultProps = {
  record: {
    attributes: {
      id: 0,
      is_active: false,
    },
  },
};

export default StatusSwich;
