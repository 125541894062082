import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import {
  Typography,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core";

import { TYPE_PROFILE_VERIFICATION, modalCopyValues } from "../../../../../../../Utils";
import { URL_PAGE } from "../../../../../../../Config/env";

const Referrer = ({ referralUser, referral_token, modal }) => (
  <CardContent style={{ marginBottom: 30 }}>
    <Typography variant='h4' style={{ color: "white" }}>
      Referidos
    </Typography>
    <Typography variant='h4' style={{ color: "white" }}>
      Enlace para referir:
        <Button
        onClick={() => modalCopyValues(`${URL_PAGE}register?referrer=${referral_token}`, modal)}
        variant="contained"
        color="primary"
        style={{ marginLeft: 10 }}
        >
        Link
        </Button>
    </Typography>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Id</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Fecha de registro</TableCell>
          <TableCell>Verificado</TableCell>
        </TableRow>
      </TableHead>
      {
        <TableBody>
          {referralUser && referralUser.length > 0 ? (
            referralUser.map(({ id, email, created_at, profile_verification }) => {
              return (
                <TableRow key={id}>
                  <TableCell>{id}</TableCell>
                  <TableCell component='th' scope='row'> {email} </TableCell>
                  <TableCell>{moment.utc(created_at).format("LL")}</TableCell>
                  <TableCell>{TYPE_PROFILE_VERIFICATION[profile_verification]}</TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={4} style={{ textAlign: 'center' }}>No hay nada para mostrar</TableCell>
            </TableRow>
          )}
        </TableBody>
      }
    </Table>
  </CardContent>
);

Referrer.propTypes = {
  referralUser: PropTypes.array,
  profile_verification: PropTypes.string,
};

Referrer.defaultProps = {
  referralUser: [],
  profile_verification: '',
};

export default Referrer;
