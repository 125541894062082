const styles = {
  container: {
    paddingBlock: 45,
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '100%',
    alignItems: 'center',
    gap: 30,
  },
  title: {
    fontSize: 24,
  },
  subTitle: {
    fontSize: 16,
  },
  error: {
    color: 'red',
    margin: 10,
    marginInline: 40,
    textAlign: 'center',
  },
};

export default styles;