import {DELETE_MANY} from 'react-admin';

const responseHandler = (response, type, params) => {
    switch (type) {
        case DELETE_MANY: {
            return {
                data: params.ids,
            }
        }
        default: {
            return {}
        }
    }
};

export default responseHandler;
