import { Accessibility } from '@material-ui/icons';

import TransferRulesList from './TransferRulesList';
import useAccessRole from "../../hooks/useAccessRole";

const {isAccess} = useAccessRole();

export default {
    name: 'transferRules',
    icon: Accessibility,
    list: isAccess('transfer_rules') ? TransferRulesList : false,
}
