import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from "@material-ui/core";

import styles from "../styles";

const ErrorAlert = (props) => {
  const {
    error,
    setError,
    setAction,
    showError,
  } = props;
  const [isOpen, setIsOpen] = useState(showError);

  const handleClose = () => {
    setIsOpen(false);
    setError(false);
    setAction(false);
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpen}
    >
      <div
        style={styles.centerAlert}
      >
        <div
          style={styles.centerColumn}
        >
          <div style={styles.alertTitle}>
            Ha ocurrido un error, intentelo más tarde
          </div>
          <div
            style={styles.alertBody}
          >
            {error}
          </div>
            <Button
              onClick={handleClose}
              style={styles.buttonRed}
            >
              ATRAS
            </Button>
        </div>
      </div>
    </Modal>
  );
}

ErrorAlert.propTypes = {
  error: PropTypes.string.isRequired,
  setError: PropTypes.string.isRequired,
  setAction: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired
};

export default ErrorAlert;