import React from "react";
import moment from "moment";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
} from "react-admin";
import { CircleTransactionFilter } from "../Filters";

const CircleTransactionList = (props) => {
  return (
    <>
      <List
        {...props}
        title={"Lista de transacciones Circle"}
        filters={<CircleTransactionFilter />}
        bulkActionButtons={false}
        debounce={1000}
      >
        <Datagrid>
          <TextField source={"id"} label={"Id"} />
          <FunctionField
            label={"Fecha de origen"}
            render={(record) => {
              return moment
                .utc(record.createDate)
                .format("LLLL");
            }}
          />
          <TextField source={"amount.currency"} label={"Coin"} />
          <TextField source={"amount.amount"} label={"Monto"} />
          <TextField source={"status"} label={"Estado"} />
        </Datagrid>
      </List>
    </>
  );
};

export default CircleTransactionList;
