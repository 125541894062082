import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
  Filter,
  TextInput,
  SelectInput,
  DateInput,
  BooleanInput
} from 'react-admin';
import { Grid, withStyles } from '@material-ui/core';

import { COUNTRIES } from '../../../../Utils'
import {
  CATEGORIES,
  STATUS,
  SOURCES,
  CURRENCIES,
  CRYPTO_CURRENCIES,
  EXTERNAL_PROVIDERS,
} from './utils';
import { BankProvider } from '../../../../Providers';
import { USER_CATEGORIES } from '../../../Users/Filters/UserFilter/utils';

const styles = {
  helperText: {
    color: ' rgba(255, 255, 255, 0.7)',
    margin: '8px 0',
    fontSize: '0.75rem',
    textAlign: 'left',
    lineHeight: '1em'
  },
  dateInput: {
    margin: '8px 0 0 0',
    '&:nth-child(1)': {
      paddingRight: '5px'
    },
    '&:nth-child(2)': {
      paddingLeft: '5px'
    },
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      backgroundColor: ' rgb(140, 140, 140)',
      borderRadius: '5px',
      display: 'block'
    }
  }
};

const TransactionFilter = (props) => {
  const { isWithdrawal = false, isBitcoin = false, classes, filterValues, setFilters } = props;
  const [banks, setBanks] = useState([]);

  // Currently, this filter is used for Transactions, but also is implemented for Withdrawals and Bitcoin.
  // is_default is true only for Transactions. 
  const isDefault = useMemo(() => (!isWithdrawal && !isBitcoin), [isWithdrawal, isBitcoin])

  const renderBody = () => {
    if (!isWithdrawal && !isBitcoin)
      return (
        <SelectInput
          label={'Categoria'}
          source={'category'}
          choices={CATEGORIES}
          helperText={"Categorias"}
        />
      );
  };

  const filterBanks = useCallback(async (event, value) => {
    setBanks([]);
    const validValue = value || filterValues?.country_iso_code;

    try {
      const response = await BankProvider.getCountryCodeBanks({ iso_code: validValue });

      if (response.data) {
        setBanks(response.data.data);
        setFilters({
          ...filterValues,
          country_iso_code: validValue,
          bank_id: null
        });
      } else {
        console.error("ERROR inesperado al listar bancos -> ", response);
      }
    } catch (error) {
      console.error(error);
    }
  }, [filterValues]);

  useEffect(() => {
    if (filterValues.country_iso_code) {
      filterBanks();
    }
  }, [filterValues.country_iso_code]);

  return (
    <Filter {...props}>
      <TextInput
        label={'Id'}
        source={'id'}
        alwaysOn
        helperText={"Id de transacción"}
      />
      <TextInput
        label={'Id público'}
        source={'alt_id'}
        alwaysOn
        helperText={"Id público de transacción"}
      />
      {
        !isWithdrawal && (
          <TextInput
            label={'Emisor y destinatario'}
            source={'user_key'}
            alwaysOn
            helperText={"Busca las transacciones de un usuario por su id, nombre, apellido, email o DNI"}
          />
        )
      }
      {
        isWithdrawal && (
          <TextInput
            label={'Emisor y destinatario'}
            source={'keyEmisor'}
            alwaysOn
            helperText={"Busca las transacciones de un usuario por su id, nombre, apellido, email o DNI"}
          />
        )
      }
      {
        // Countries filter korks for WithdrawalList and TransactionList. 
        (isWithdrawal || isDefault) && (
          <SelectInput
            label={'País'}
            source={'country_iso_code'}
            choices={COUNTRIES}
            alwaysOn
            onChange={filterBanks}
            helperText={"Paises"}
          />
        )
      }
      {
        (isWithdrawal || isDefault) && (filterValues && filterValues.country_iso_code) && (
          <SelectInput
            label={'Banco'}
            source={'bank_id'}
            choices={banks}
            optionText="attributes.name"
            optionValue="id"
            helperText={"Bancos"}
            alwaysOn
          />
        )
      }
      {isDefault && (<SelectInput
        label={'Proveedor'}
        source={'external_provider'}
        choices={EXTERNAL_PROVIDERS}
        helperText={"Proveedor externo"}
      />)}
      {isDefault && (
        <TextInput
          label={'Id del proveedor'}
          source={'external_id'}
          helperText={"Buscar por el id del proveedor"}
        />
      )}
      <SelectInput
        label={'Fuente'}
        source={'source'}
        choices={SOURCES}
        helperText={"Fuente de operación"}
      />
      {
        /*isWithdrawal && (
            <TextInput
                label={'Destinatario'}
                source={'recipient_email'}
                alwaysOn
                helperText={"Usuario receptor"}
            />
        )*/
      }
      {
        isWithdrawal && (
          <BooleanInput
            label={'Retiros con pagos pendientes'}
            source={'is_pending_payment'}
            helperText={"¿Retiros?"}
          />
        )
      }
      {
        isWithdrawal && (
          <BooleanInput
            label={'Transacciones bloqueadas'}
            source={'is_locked'}
            helperText={"¿Retiros?"}
          />
        )
      }
      <SelectInput
        label={'Divisa'}
        source={'currency'}
        choices={isBitcoin ? CRYPTO_CURRENCIES : CURRENCIES}
        helperText={"Divisa"}
      />
      {renderBody()}
      {isDefault && (<SelectInput
        label={'Estado'}
        source={'status'}
        choices={STATUS}
        helperText={"Estado de operación"}
      />)}
      {!isBitcoin &&
        <SelectInput
          label={'Categoría del emisor'}
          source={'sender_category'}
          choices={USER_CATEGORIES}
          helperText={"Categoría del emisor"}
        />
      }
      <Grid
        label="Fecha"
        source={'transactions_dates'}
        alwaysOn
      >
        <Grid
          container
        >
          <DateInput
            label={'Desde'}
            source="transactions_dates.from_date"
            className={classes.dateInput}
          />
          <DateInput
            label={'Hasta'}
            source="transactions_dates.to_date"
            className={classes.dateInput}
          />
        </Grid>
        <p className={classes.helperText}>Fecha de transacciones</p>
      </Grid>
      <BooleanInput
        label={'Pagos rápidos'}
        source={'is_fast'}
        helperText={"¿Pagos rápidos?"}
      />
    </Filter>
  );
};

export default withStyles(styles)(TransactionFilter);
