const styles = {
    container: {
        padding: 5,
        paddingBottom: 20,
    },
    containerField: {
        padding: 5,
    },
    header: {
        color: "#fff",
        textAlign: "center",
        fontSize: "25px",
        margin: "10px",
    },
    title: {
        color: "#fff",
        textAlign: "center",
        fontSize: 20,
        fontWeight: 500,
        width: "100%",
        marginBottom: 20,
    }
};

export default styles;