import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  AutocompleteInput,
  Toolbar,
  SaveButton,
  BooleanInput,
} from "react-admin";
import Icon from "./icon";

import { SEGMENTS_CATEGORY } from "../../../Utils";
import { validateRequired } from "../../../Helpers/ValidateHelper";
import { validateIconCategory } from "../../../Helpers/ValidateHelper";

const CategoryEdit = (props) => {
  const CategoryEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  const toCategories = () => {
    window.location.href = "/categories";
  };

  const GoCategories = () => {
    return (
      <button
        onClick={toCategories}
        style={{
          position: "absolute",
          zIndex: "1",
          textAlign: "center",
          backgroundColor: "#00F5A5",
          border: "none",
          borderRadius: "4px",
          borderColor: "#fff",
          padding: "7px",
          cursor: "pointer",
          color: "white",
          marginTop: "19px",
          right: "50px"
        }}
      >
        Ir a lista de categorías
      </button>
    );
  };

  return (
    <>
      <GoCategories />
      <Edit {...props} title={`Editar categoría ${props.id}`}>
        <SimpleForm
          toolbar={<CategoryEditToolbar />}
          validate={validateIconCategory}
        >
          <TextInput disabled label="Id" source="id" />
          <TextInput
            label="Título"
            source="attributes.title"
            validate={validateRequired}
          />
          <AutocompleteInput
            label={"Segmento"}
            source={"attributes.segment_id"}
            choices={SEGMENTS_CATEGORY}
            optionText={"segment"}
            optionValue={"segment_id"}
            validate={validateRequired}
          />
          <Icon />
          <BooleanInput
            label={"Solo Icon"}
            source={"attributes.is_only_icon"}
          />
        </SimpleForm>
      </Edit>
    </>
  );
};

export default CategoryEdit;
