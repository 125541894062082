import { GET_LIST, GET_ONE, UPDATE, GET_MANY, CREATE, DELETE, DELETE_MANY } from 'react-admin';

import { RESOURCES, replaceSymbols } from '../../Helpers/ResourceHelper';
import { apiCall } from '../../Middlewares';
import { API_URL, ENDPOINTS } from '../../Config/env';

import {
    userRestHandler,
    configurationRestHandler,
    transactionRestHandler,
    roleRestHandler,
    adminRestHandler,
    fintocRestHandler,
    countriesRestHandler,
    pricesRestHandler,
    settingsHeaderRestHandler,
    walletsRestHandler,
    businessRestHandler,
    MarketingMessageRestHandler,
    ExportedFilesRestHandler,
    WithdrawalsRestHandler,
    TransactionsStatusRestHandler,
    servicesRestHandler,
    categoriesRestHandler,
    RequestsRestHandler,
    BankMovementsHandler,
    BankAccountAccesses,
    CardAccountsHandler,
    SegmentsRestHandler,
    CouponsRestHandler,
    BinanceNetworksRestHandler,
    BeneficiariesRestHandler,
    PricesProvidersRestHandler,
    FortressRestHandler,
    BannersRestHandler,
    SnackbarsRestHandler,
    CurrenciesRestHandler,
    VitaPricesHandler,
    MaintenanceChargesRestHandler,
} from './handlers';

const requestHandler = async (type, resource, params, isWithdrawal, positionGlobal, isBitcoin, isBinance, isCircle) => {
    switch (resource) {
        case RESOURCES.vita_prices: {
            return VitaPricesHandler.requestHandler(type, params);
        }
        case RESOURCES.fortress: {
            return FortressRestHandler.requestHandler(type, params);
        }
        case RESOURCES.requests: {
            return RequestsRestHandler.requestHandler(type, params);
        }
        case RESOURCES["estados de las transacciones"]: {
            return TransactionsStatusRestHandler.requestHandler(type, params);
        }
        case RESOURCES.retiros: {
            return WithdrawalsRestHandler.requestHandler(type, params);
        }
        case RESOURCES.prices: {
            return pricesRestHandler.requestHandler(type, params);
        }
        case RESOURCES.wallets: {
            return walletsRestHandler.requestHandler(type, params);
        }
        case RESOURCES.settingsHeaders: {
            return settingsHeaderRestHandler.requestHandler(type, params, resource);
        }
        case RESOURCES.business_transactions: {
            return businessRestHandler.requestHandler(type, params, resource);
        }
        case RESOURCES.couponsSegmentList: {
            return SegmentsRestHandler.requestHandler(type, params, resource)
        }
        case RESOURCES.segmentListUsers: {
            return SegmentsRestHandler.requestHandler(type, params, resource)
        }

        default: {
            switch (type) {
                case GET_LIST: {
                    let { filter: { alt_id, source_name_bank_movements = null, source_bank_movements = null, user_key = null, recipient_key = null, country_name = null, iso_code = null, payment_type, country_iso_code = null, is_fintoc_unassigned = false, is_bank_transfers_unassigned = true, is_pending_payment = false, transaction_id, destinary_country, local_currency, recipient_email, from_date, to_date, email, category, status, id, document_number, verification, source_deposit, source, search_key, transaction_dates, register_dates, login_dates, execution_dates, history_dates, export_dates, action, transactions_dates, name_segment, utility_name, service_category_id, utility_type, utility_number, vita_enable, currency, external_provider, id_goal, type_goal, geolocatable_type, geolocatable_id, type_binance_transaction, residence_country, country, name_provider, external_id, preferential_prices_filter, preferential_prices_route, user_category, alert_type, alerts_dates, alertable_id, alertable_type, destination_currency, source_currency, bank_id, sender_category, verification_dates, is_fast }, pagination: { page, perPage }, sort: { field, order } } = params;

                    if (positionGlobal)
                        email = positionGlobal ? 'soporte@vitawallet.io' : null;

                    field = field.replace('attributes.', '');

                    let isExport = false;
                    if (parseInt(perPage) === 1000) {
                        perPage = 100000000;
                        isExport = true;
                    }
                    let prepareUrl = `${API_URL}/admin/${resource}?page=${page}&count=${perPage}&q[s]=${field}+${order}&`;
                    prepareUrl += isExport ? `is_export=${isExport}&` : '';
                    prepareUrl += external_id ? `q[external_id_i_cont]=${external_id}&` : '';
                    prepareUrl += isWithdrawal ? `type=withdrawal&q[category_in][]=3&q[category_in][]=7&q[status_eq]=2&q[is_pending_payment_eq]=${is_pending_payment ? '1' : '0'}&` : '';
                    prepareUrl += isBitcoin ? `type=withdrawal_btc&q[category_eq]=0&q[status_eq]=2&q[currency_eq]=1&q[is_pending_payment_eq]=${is_pending_payment ? '1' : '0'}&` : '';
                    prepareUrl += isBinance ? `type=withdrawal_usdt&q[category_eq]=0&q[status_eq]=2&q[currency_eq]=5&q[is_pending_payment_eq]=${is_pending_payment ? '1' : '0'}&` : '';
                    prepareUrl += isCircle ? `type=withdrawal_usdc&q[category_eq]=0&q[status_eq]=2&q[currency_eq]=6&q[is_pending_payment_eq]=${is_pending_payment ? '1' : '0'}&` : '';
                    prepareUrl += transaction_id ? `q[transaction_id_eq]=${transaction_id}&` : '';
                    prepareUrl += currency ? `q[currency_eq]=${currency}&` : '';
                    prepareUrl += category ? `q[category_eq]=${category}&` : '';
                    prepareUrl += source_currency ? `q[source_currency_eq]=${source_currency}&` : '';
                    prepareUrl += destination_currency ? `q[destination_currency_eq]=${destination_currency}&` : '';
                    prepareUrl += type_binance_transaction ? `q[type]=${type_binance_transaction}&` : '';
                    prepareUrl += source ? `q[source_eq]=${source}&` : '';
                    prepareUrl += status ? `q[status_eq]=${status}&` : '';
                    prepareUrl += user_key ? `user_key=${replaceSymbols(user_key)}&` : '';
                    prepareUrl += recipient_key ? `recipient_key=${replaceSymbols(recipient_key)}&` : '';
                    prepareUrl += destinary_country ? `q[destinary_country_withdrawal]=${destinary_country}&` : '';
                    prepareUrl += local_currency ? `q[local_currency_eq]=${local_currency}&` : '';
                    prepareUrl += country_iso_code ? `q[country_iso_code_eq]=${country_iso_code}&` : '';
                    prepareUrl += document_number ? `q[beneficiary_document_number_eq]=${document_number}&` : '';
                    prepareUrl += search_key ? `q[search_key]=${replaceSymbols(search_key)}&` : '';
                    prepareUrl += id_goal ? `q[id_goal]=${id_goal}&` : '';
                    prepareUrl += type_goal ? `q[type_goal]=${type_goal}&` : '';
                    prepareUrl += action ? `q[action]=${action}&` : '';
                    prepareUrl += name_segment ? `q[name_i_cont]=${name_segment}&` : "";
                    prepareUrl += is_fintoc_unassigned ? `is_fintoc_unassigned=1&` : '';
                    prepareUrl += is_bank_transfers_unassigned ? `is_bank_transfers_unassigned=1&` : '';
                    prepareUrl += source_name_bank_movements ? `source_name_bank_movements=${source_name_bank_movements}&` : '';
                    prepareUrl += source_bank_movements ? `source_bank_movements=${source_bank_movements}&` : '';
                    prepareUrl += country_iso_code ? `q[country_iso_code_eq]=${country_iso_code}&` : '';
                    prepareUrl += country_name ? `q[name_scont]=${country_name}&` : '';
                    prepareUrl += iso_code ? `q[iso_code_scont]=${iso_code}&` : '';
                    prepareUrl += type === GET_LIST && resource === RESOURCES.usuarios ? `is_lite_users=1&` : '';
                    prepareUrl += type === GET_LIST && resource === RESOURCES.transacciones ? `is_lite_transactions=1&` : '';
                    prepareUrl += preferential_prices_filter ? `q[preferential_prices_filter]=${replaceSymbols(preferential_prices_filter)}&` : '';
                    prepareUrl += preferential_prices_route ? `q[preferential_prices_route]=${preferential_prices_route}&` : '';
                    prepareUrl += utility_name ? `q[utility_name_cont]=${utility_name}&` : '';
                    prepareUrl += service_category_id ? `q[service_category_id_eq]=${service_category_id}&` : '';
                    prepareUrl += utility_type ? `q[utility_type_cont]=${utility_type}&` : '';
                    prepareUrl += utility_number ? `q[utility_number_eq]=${utility_number}&` : '';
                    prepareUrl += vita_enable ? `q[vita_enable_eq]=${vita_enable}&` : '';
                    prepareUrl += external_provider ? `q[external_provider_eq]=${external_provider}&` : '';
                    prepareUrl += residence_country ? `q[residence_country_id_eq]=${residence_country}&` : '';
                    prepareUrl += country ? `q[country_id_eq]=${country}&` : '';
                    prepareUrl += geolocatable_type ? `q[geolocatable_type_eq]=${geolocatable_type}&` : '';
                    prepareUrl += geolocatable_id ? `q[geolocatable_id_eq]=${geolocatable_id}&` : '';
                    prepareUrl += name_provider ? `q[name_i_cont]=${name_provider}&` : '';
                    prepareUrl += user_category ? `q[user_category_eq]=${user_category}&` : '';
                    prepareUrl += alert_type !== undefined && alert_type !== null ? `q[alert_type_eq]=${alert_type}&` : '';
                    prepareUrl += alertable_id ? `q[alertable_id_eq]=${alertable_id}&` : '';
                    prepareUrl += alertable_type ? `q[alertable_type_eq]=${alertable_type}&` : '';
                    prepareUrl += bank_id ? `q[bank_id_eq]=${bank_id}&` : '';
                    prepareUrl += sender_category ? `q[sender_category_eq]=${sender_category}&` : '';
                    prepareUrl += id ? `q[id_eq]=${id}&` : '';
                    prepareUrl += is_fast ? `q[is_fast_eq]=${is_fast}&` : '';

                    if (email && (resource === RESOURCES.transacciones || resource === RESOURCES.card_transactions)) {
                        try {
                            const resp = await apiCall({
                                authorization: true,
                                ...ENDPOINTS.ADMIN.USERS.USERS_LIST(`?page=1&count=10&q[s]=id+DESC&q[search_key]=${encodeURIComponent(email)}&is_lite_users=1&`)
                            });

                            const sender_id = resp.data.users.data.length > 0 ? resp.data.users.data[0].id : -7;
                            if (sender_id !== -7) {
                                prepareUrl += email ? `q[sender_id_eq]=${sender_id}&` : '';
                            }
                        } catch (error) { }
                    }

                    if (recipient_email) {
                        try {
                            const resp = await apiCall({
                                authorization: true,
                                ...ENDPOINTS.ADMIN.USERS.USERS_LIST(`?page=1&count=10&q[s]=id+DESC&q[search_key]=${encodeURIComponent(recipient_email)}&is_lite_users=1&`)
                            });

                            const recipient_id = resp.data.users.data.length > 0 ? resp.data.users.data[0].id : -7;
                            if (recipient_id !== -7) {
                                prepareUrl += recipient_email ? `q[recipient_id_eq]=${recipient_id}&` : '';
                            }
                        } catch (error) { }
                    }

                    // Preparing date start and date end to filter users by transactions date
                    prepareUrl += transaction_dates ?
                        transaction_dates['transaction_date_start'] ?
                            `q[transaction_date_start]=${transaction_dates['transaction_date_start']}&` : ''
                        : '';

                    prepareUrl += transaction_dates ?
                        transaction_dates['transaction_date_end'] ?
                            `q[transaction_date_end]=${transaction_dates['transaction_date_end']}&` : ''
                        : '';

                    prepareUrl += transactions_dates ?
                        transactions_dates['from_date'] ?
                            `q[created_at_gteq]=${transactions_dates['from_date']}&` : ''
                        : '';

                    prepareUrl += transactions_dates ?
                        transactions_dates['to_date'] ?
                            `q[created_at_lteq]=${transactions_dates['to_date']}&` : ''
                        : '';

                    prepareUrl += login_dates ?
                        login_dates['login_date_start'] ?
                            `q[login_date_start]=${login_dates['login_date_start']}&` : ''
                        : '';
                    prepareUrl += login_dates ?
                        login_dates['login_date_end'] ?
                            `q[login_date_end]=${login_dates['login_date_end']}&` : ''
                        : '';

                    prepareUrl += execution_dates ?
                        execution_dates['execution_date_start'] ?
                            `q[execution_date_start_gteq]=${execution_dates['execution_date_start']}&` : ''
                        : '';
                    prepareUrl += execution_dates ?
                        execution_dates['execution_date_end'] ?
                            `q[execution_date_end_lteq]=${execution_dates['execution_date_end']}&` : ''
                        : '';

                    prepareUrl += history_dates ?
                        history_dates['history_date_start'] ?
                            `q[history_date_start]=${history_dates['history_date_start']}&` : ''
                        : '';
                    prepareUrl += history_dates ?
                        history_dates['history_date_end'] ?
                            `q[history_date_end]=${history_dates['history_date_end']}&` : ''
                        : '';

                    prepareUrl += export_dates ?
                        export_dates['export_date_start'] ?
                            `q[export_date_start]=${export_dates['export_date_start']}&` : ''
                        : '';
                    prepareUrl += export_dates ?
                        export_dates['export_date_end'] ?
                            `q[export_date_end]=${export_dates['export_date_end']}&` : ''
                        : '';

                    // Preparing date start and date end to filter users by register date
                    prepareUrl += register_dates ?
                        register_dates['register_date_start'] ?
                            `q[register_date_start]=${register_dates['register_date_start']}&` : ''
                        : '';
                    prepareUrl += register_dates ?
                        register_dates['register_date_end'] ?
                            `q[register_date_end]=${register_dates['register_date_end']}&` : ''
                        : '';

                    prepareUrl += alerts_dates ?
                        alerts_dates['from_date'] ?
                            `q[created_at_gteq]=${alerts_dates['from_date']}&` : ''
                        : '';

                    prepareUrl += alerts_dates ?
                        alerts_dates['to_date'] ?
                            `q[created_at_lteq]=${alerts_dates['to_date']}&` : ''
                        : '';

                    prepareUrl += verification_dates ?
                        verification_dates['verification_date_start'] ?
                            `q[verification_date_start]=${verification_dates['verification_date_start']}&` : ''
                        : '';

                    prepareUrl += verification_dates ?
                        verification_dates['verification_date_end'] ?
                            `q[verification_date_end]=${verification_dates['verification_date_end']}&` : ''
                        : '';

                    prepareUrl += verification ? `q[profile_verification_eq]=${verification}&` : '';
                    prepareUrl += id ? `q[id_eq]=${id}&` : '';
                    prepareUrl += alt_id ? `q[alt_id_eq]=${alt_id}&` : '';
                    prepareUrl += email ? `q[email_cont]=${replaceSymbols(email)}&` : '';
                    prepareUrl += payment_type ? `q[${payment_type === 'payin' ? 'is_payin' : 'is_payout'}_eq]=1&` : '';

                    if (source_deposit) {
                        prepareUrl += '';
                        switch (source_deposit) {
                            case 'DEPOSIT_ADMIN':
                                prepareUrl += 'q[category_eq]=2&q[source_eq]=5&';
                                break;
                            case 'DEPOSIT_TRANSBANK':
                                prepareUrl += 'q[category_in][]=2&q[category_in][]=6&q[currency_eq]=2&q[source_not_eq]=5&q[source_not_eq]=7&q[source_not_eq]=4&';
                                break;
                            case 'FINTOC':
                                prepareUrl += 'q[category_eq]=2&q[source_eq]=7&';
                                break;
                            default:
                                prepareUrl += 'q[category_eq]=2&q[currency_eq]=3&q[source_not_eq]=5&';
                                break;
                        }
                    }

                    if (from_date)
                        prepareUrl += `q[created_at_gteq]=${from_date}&q[created_at_lteq]=${from_date}&`;

                    if (to_date) {
                        if (from_date) {
                            prepareUrl += `q[created_at_gteq]=${from_date}&q[created_at_lteq]=${to_date}&`;
                        } else {
                            prepareUrl += `q[created_at_lteq]=${to_date}&`;
                        }
                    }

                    return {
                        url: prepareUrl,
                        method: 'get',
                    };
                }
                case GET_ONE: {
                    if (resource === RESOURCES.snackbars)
                        return SnackbarsRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.banners)
                        return BannersRestHandler.requestHandler(type, params);

                    return {
                        url: `${API_URL}/admin/${resource}?q[id_eq]=${params.id}`,
                        method: 'get'
                    };
                }
                case GET_MANY: {
                    let query = '';
                    const { ids } = params;

                    if (resource === RESOURCES.btc_addresses) {
                        resource = `users/${ids[0]}/btc_addresses`;
                    } else {
                        ids.map((id) => { query += `q[id_in][]=${id}&` });
                    }

                    let url = `${API_URL}/admin/${resource}${query !== '' ? `?${query}` : ''}`;

                    if (resource === RESOURCES.usuarios) {
                        url += '&is_lite_users=2&'
                    }

                    return {
                        url,
                        method: 'get',
                    }
                }
                case CREATE: {
                    if (resource === RESOURCES.prices)
                        return pricesRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.roles)
                        return roleRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.administrators)
                        return adminRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.marketing_message)
                        return MarketingMessageRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.categories)
                        return categoriesRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.bank_account_accesses)
                        return BankAccountAccesses.requestHandler(type, params);

                    if (resource === RESOURCES.segments)
                        return SegmentsRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.coupons)
                        return CouponsRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.binanceNetworks)
                        return BinanceNetworksRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.banners)
                        return BannersRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.snackbars)
                        return SnackbarsRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.currencies)
                        return CurrenciesRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.maintenance_charges)
                        return MaintenanceChargesRestHandler.requestHandler(type, params)

                    throw new Error(`No se soporta accion para ${type} y ${resource}`);
                }
                case UPDATE: {
                    if (resource === RESOURCES.bank_account_accesses)
                        return BankAccountAccesses.requestHandler(type, params);

                    if (resource === RESOURCES.usuarios)
                        return userRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.configuraciones)
                        return configurationRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.transacciones)
                        return transactionRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.roles)
                        return roleRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.administrators)
                        return adminRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES["países"])
                        return countriesRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.services)
                        return servicesRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.categories)
                        return categoriesRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.card_accounts)
                        return CardAccountsHandler.requestHandler(type, params);

                    if (resource === RESOURCES.segments)
                        return SegmentsRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.coupons)
                        return CouponsRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.marketing_message)
                        return MarketingMessageRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.binanceNetworks)
                        return BinanceNetworksRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.beneficiaries)
                        return BeneficiariesRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.prices_providers)
                        return PricesProvidersRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.banners)
                        return BannersRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.snackbars)
                        return SnackbarsRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.currencies)
                        return CurrenciesRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.maintenance_charges)
                        return MaintenanceChargesRestHandler.requestHandler(type, params, resource)
                    
                    throw new Error(`No se soporta accion para ${type} y ${resource}`);
                }
                case DELETE: {
                    if (resource === RESOURCES.bank_account_accesses)
                        return BankAccountAccesses.requestHandler(type, params);

                    if (resource === RESOURCES.prices)
                        return pricesRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.administrators)
                        return adminRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.marketing_message)
                        return MarketingMessageRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.categories)
                        return categoriesRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.segments)
                        return SegmentsRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.coupons)
                        return CouponsRestHandler.requestHandler(type, params);

                    throw new Error(`No se soporta accion para ${type} y ${resource}`);
                }
                case DELETE_MANY: {
                    if (resource === RESOURCES.bank_account_accesses)
                        return BankAccountAccesses.requestHandler(type, params);

                    if (resource === RESOURCES.bank_movements)
                        return BankMovementsHandler.requestHandler(type, params);

                    if (resource === RESOURCES.fintoc)
                        return fintocRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.exported_files)
                        return ExportedFilesRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.roles)
                        return roleRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.administrators)
                        return adminRestHandler.requestHandler(type, params);

                    if (resource === RESOURCES.marketing_message)
                        return MarketingMessageRestHandler.requestHandler(type, params);

                    throw new Error(`No se soporta accion para ${type} y ${resource}`);
                }
                default:
                    throw new Error(`Unsupported Data Provider request type ${type}`);
            }
        }
    }
};

export default requestHandler;
