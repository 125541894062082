import React          from 'react';
import Button         from '@material-ui/core/Button';
import ExitIcon       from '@material-ui/icons/PowerSettingsNew';
import { connect }    from 'react-redux';
import { userLogout } from 'react-admin';

import {apiCall} from "../../Middlewares";
import {ENDPOINTS} from "../../Config/env";

const LogoutButton = ({ userLogout, ...rest }) => {
    const exit = async () => {
        const headers = JSON.parse(localStorage.getItem('headers'));
        await apiCall({
            ...ENDPOINTS.ADMIN.AUTH.SIGN_OUT,
            authorization: true
        }, headers);

        localStorage.setItem('headers', null);
        localStorage.setItem('role_access', null);
        window.location = "/login";
        userLogout();

    };

    return (
        <Button
            onClick={exit}
            size="small"
            {...rest}
        >
            <ExitIcon />
        </Button>
    );
}
export default connect(undefined, { userLogout })(LogoutButton);
