const style = {
    buttonSeccion: {
        marginTop: '10px',
        width: '100%',
    },
    buttonStyle: {
        backgroundColor: "#00F5A5",
        color: "white",
        border: "none",
        borderRadius: "4px",
        borderColor: "#fff",
        padding: "15px",
        cursor: "pointer",
        margin: "20px"
    }
};

export default style;
