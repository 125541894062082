import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { createBrowserHistory as createHistory } from 'history';
import { Grid, TextField, Typography, CardContent, Button } from '@material-ui/core';
import useAlerts from '../../../../hooks/useAlerts';
import { BeneficiariesProvider } from '../../../../Providers';
import { toMoney } from '../../../../Helpers/MoneyHelper';

import styles from "./styles";

const FilterAmount = (props) => {
  const { id, document_number } = props;
  const { errorMessage, /* successMessage */ } = useAlerts();
  const [amount, setAmount] = useState(null);
  const [date, setDate] = useState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD')
  });

  const ShowTransactions = (props) => {
    const goToFilterTransaction = (document_number, from_date, to_date) => {
      const history = createHistory();

      history.push(`/transacciones?filter={"document_number"%3A"${document_number}"%2C"from_date"%3A"${from_date}"%2C"to_date"%3A"${to_date}"}`);
      history.go();
    }

    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => { goToFilterTransaction(document_number, date.startDate, date.endDate) }}
      >
        Ver transacciones
      </Button>
    );
  }

  const search = async () => {
    try {
      const response = await BeneficiariesProvider.getAmounts(id, date.startDate, date.endDate);
      setAmount(response.data.amount);
    } catch (error) {
      errorMessage(error.data.error.message);
    }
  }

  const handleOnchange = (e) => {
    setDate({
      ...date,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    search();
  }, [])


  const RenderAmount = (props) => {
    const { status } = props;
    return (
      <Grid
        style={styles.divisor}
        item
        xs={12}
        sm={4}
      >
        <div style={{ paddingBottom: 10, borderRadius: 5, textAlign: 'center', alignItems: 'center', paddingTop: 10, backgroundColor: '#393939', fontSize: 14 }}>
          <div style={{ paddingTop: 20 }}>
            <span style={{ color: 'white' }}>Total {status}</span>
            <div style={{ fontSize: 30, color: '#00d9ff', fontWeight: 'bold' }}>
              {amount ? (amount[status] && amount[status].amount > 0 ? `${toMoney(amount[status].amount, amount[status].currency)} ${amount[status].currency}` : 0) : 0}
            </div>
          </div>
        </div>
      </Grid>
    )
  }

  return (
    <CardContent>
      <Typography variant="h4" gutterBottom style={styles.titleWhite}>
        Montos del destinatario
      </Typography>
      <Grid container direction="row" justify="center" alignItems="center" style={styles.divisorTop}>
        <Grid
          style={styles.divisor}
          item
          xs={12}
          sm={5}
        >
          <TextField
            value={date.startDate}
            label='Desde'
            name={"startDate"}
            type='date'
            onChange={handleOnchange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid
          style={styles.divisor}
          item
          xs={12}
          sm={5}
        >
          <TextField
            value={date.endDate}
            label='Hasta'
            name={"endDate"}
            type='date'
            onChange={handleOnchange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid
          style={styles.divisor}
          item
          xs={12}
          sm={2}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#00b7ff" }}
            onClick={search}
          >
            Buscar
          </Button>
        </Grid>
        <RenderAmount status={"pending"} />
        <RenderAmount status={"processed"} />
        <RenderAmount status={"completed"} />
        <Grid
          style={styles.divisor}
          item
          xs={12}
          sm={2}
        >
          <ShowTransactions />
        </Grid>
      </Grid>
    </CardContent>
  );
}

export default FilterAmount;