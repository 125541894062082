import { Accessibility } from '@material-ui/icons';

import RequestsList from './RequestsList';
import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
    name: 'solicitudes',
    icon: Accessibility,
    list: isAccess('show_requests') ? RequestsList : false,
}