import { Person }  from '@material-ui/icons';

import WalletsList  from './WalletsList';
import useAccessRole from "../../hooks/useAccessRole";

const {isAccess} = useAccessRole();

export default {
    name: 'wallets',
    icon: Person,
    list: isAccess('show_business_wallets') ? WalletsList : false,
}
