import React from "react";
import { Col, Row } from "react-grid-system";
import PropTypes from "prop-types";
import DownloadIcon from "@material-ui/icons/FileDownload";
import EditIcon from "@material-ui/icons/Edit";
import {
  TextField,
  IconButton,
  Select,
  MenuItem,
  Avatar,
  Typography,
  FormHelperText,
} from "@material-ui/core";

import EditGeneralInfo from "../EditGeneralInfo";
import { PAYMENT_ORDERS_SERVICE_STATUSES } from "../../../../../../../Utils";

import styles from "./styles";

const GeneralInfo = (props) => {
  const {
    configUser,
    configUserUpdate,
    handleCloseModalInfo,
    handleDownloadLogoUser,
    handleOnchangeConfigUser,
    handleShowImage,
    handleUpdateConfigUser,
    image_design,
    isOpenModalInfo,
    setImage_design,
    updateConfigUser,
  } = props;

  return (
    <div style={styles.container}>
      <Typography 
        gutterBottom 
        variant='body1' 
        style={styles.titleWhite}
        data-testid='Title-general-info'
      >
        Datos generales
      </Typography>

      <Row
        container
        direction='row'
        justify='center'
        alignItems='center'
        className={{ flexGrow: 1 }}
      >
        <Col
          xs={3}
          style={{
            ...styles.divisor,
            textAlign: "center",
            height: 45,
            marginTop: 50,
          }}
        >
          <FormHelperText
            data-testid='Title-status'
            style={styles.text_aux}
          >
            Estado de solicitud
          </FormHelperText>
          <Select
            data-testid='select-payment-orders-service-status'
            value={configUser.status}
            name='status'
            style={{
              width: "100%",
            }}
            label={"label"}
            disabled
          >
            {PAYMENT_ORDERS_SERVICE_STATUSES.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Col>

        <Col xs={4} style={styles.divisor}>
          <TextField
            data-testid='Title-webHook'
            label='URL para notificaciones'
            type='text'
            id='webHook'
            name='webHook'
            value={configUser.webHook}
            style={styles.url}
            disabled
          />
        </Col>

        <Col
          xs={3}
          style={{
            ...styles.divisor,
            marginTop: 30,
          }}
        >
          <Avatar
            alt={`Error image`}
            src={configUser.logoCompany}
            style={styles.image}
          />
        </Col>

        <Col
          xs={2}
          style={{
            ...styles.divisor,
            paddingTop: 30,
          }}
        >
          <IconButton onClick={() => handleDownloadLogoUser()} data-testid='Button-DownloadIcon'>
            <DownloadIcon />
          </IconButton>
          <IconButton onClick={() => handleUpdateConfigUser()} data-testid='Button-EditIcon'>
            <EditIcon />
          </IconButton>
        </Col>

        <Col
          xs={3}
          style={{
            ...styles.divisor,
            textAlign: "center",
            marginTop: 50,
          }}
        >
          <FormHelperText style={styles.text_aux}>
            Validez de los enlaces
          </FormHelperText>
          <Select
            data-testid='select-timePaymentLink'
            value={configUser.timePaymentLink}
            name='timePaymentLink'
            style={{
              width: "100%",
            }}
            disabled
          >
            {configUser.timePaymentList.map((item) => (
              <MenuItem key={item} value={item}>
                {item >= 60 ? item / 60 : item} {item >= 60 ? 'Horas' : 'Minutos'}
              </MenuItem>
            ))}
          </Select>
        </Col>

        <Col
          xs={4}
          style={{
            ...styles.divisor,
            textAlign: "center",
            marginTop: 50,
          }}
        >
          <FormHelperText style={styles.text_aux}>
            Moneda recepción dinero
          </FormHelperText>
          <Select
            data-testid='select-currencyPayment'
            value={`${configUser.currencyPayment}`}
            name='currencyPayment'
            style={{
              width: "100%",
            }}
            disabled
          >
            {configUser.CurrencyPaymentList.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Col>

        <Col xs={5} />
      </Row>

      <EditGeneralInfo
        configUser={configUserUpdate}
        handleCloseModal={handleCloseModalInfo}
        handleOnchangeConfigUser={handleOnchangeConfigUser}
        image_design={image_design}
        isOpenModal={isOpenModalInfo}
        listServices={PAYMENT_ORDERS_SERVICE_STATUSES}
        setImage_design={setImage_design}
        showImage={handleShowImage}
        updateConfigUser={updateConfigUser}
      />
    </div>
  );
};

GeneralInfo.propTypes = {
  configUser: PropTypes.shape({
    webHook: PropTypes.string,
    logoCompany: PropTypes.string,
    timePaymentLink: PropTypes.number,
    timePaymentList: PropTypes.array,
    currencyPayment: PropTypes.string,
    CurrencyPaymentList: PropTypes.array,
    status: PropTypes.string,
  }),
  configUserUpdate: PropTypes.shape({
    webHook: PropTypes.string,
    logoCompany: PropTypes.string,
    timePaymentLink: PropTypes.number,
    timePaymentList: PropTypes.array,
    currencyPayment: PropTypes.string,
    CurrencyPaymentList: PropTypes.array,
    status: PropTypes.string,
  }),
  handleCloseModalInfo: PropTypes.func,
  handleDownloadLogoUser: PropTypes.func,
  handleOnchangeConfigUser: PropTypes.func,
  handleShowImage: PropTypes.func,
  handleUpdateConfigUser: PropTypes.func,
  image_design: PropTypes.array,
  isOpenModalInfo: PropTypes.bool,
  setImage_design: PropTypes.func,
  updateConfigUser: PropTypes.func,
};

GeneralInfo.defaultProps = {
  configUser: {
    webHook: "",
    logoCompany: "",
    timePaymentLink: 1,
    timePaymentList: [],
    currencyPayment: "",
    CurrencyPaymentList: [],
    status: "Activo",
  },
  configUserUpdate: {
    webHook: "",
    logoCompany: "",
    timePaymentLink: 1,
    timePaymentList: [],
    currencyPayment: "",
    CurrencyPaymentList: [],
    status: "Activo",
  },
  handleCloseModalInfo: () => {},
  handleDownloadLogoUser: () => {},
  handleOnchangeConfigUser: () => {},
  handleShowImage: () => {},
  handleUpdateConfigUser: () => {},
  image_design: [],
  isOpenModalInfo: false,
  setImage_design: () => {},
  updateConfigUser: () => {},
};

export default GeneralInfo;
