import { EuroSymbol } from "@material-ui/icons";
import useAccessRole from "../../hooks/useAccessRole";

import CouponList from "./CouponList";

const { isAccess } = useAccessRole();

export default {
  name: "couponsList",
  icon: EuroSymbol,
  list: isAccess("show_coupons") ? CouponList : false,
};
