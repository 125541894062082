const styles = {
	divisor: {
		padding: '8px 12px',
	},
	divisorTop: {
		paddingTop: 30,
	},
	titleWhite: {
		color: '#FFFFFF',
	},
	card: {
		padding: 10,
	},
};

export default styles;
