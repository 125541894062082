import React, { useState }          from 'react';
import { Grid }                     from '@material-ui/core';
import { TextInput, BooleanInput }  from 'react-admin';

import { TO_NUMBER, toMoney }  from '../../../../../Helpers/MoneyHelper';

import styles  from './styles';

const SetDailyLimit = (props) => {
    const { record } = props;
    const { attributes: { daily_limit_amount, default_currency } } = record;
    const [isCheckedDeleteDailyLimitAMount, setCheckedDeleteDailyLimitAMount] = useState(false);

    const formatValue = (value) =>
        toMoney(
            value,
            default_currency
        );

    const parseValue = (value) =>
        TO_NUMBER(
            value,
            default_currency
        );

    return(
        <Grid container direction="row" justify="center" alignItems="center">
            {
                !isCheckedDeleteDailyLimitAMount ? (
                    <Grid style={styles.divisor}>
                        <TextInput
                            format={formatValue}
                            parse={parseValue}
                            record={record}
                            defaultValue={null}
                            source="daily_limit_amount_add"
                            label={daily_limit_amount ? `Fijar nuevo límite ${default_currency.toUpperCase()}` : `Fijar límite ${default_currency.toUpperCase()}`}
                        />
                    </Grid>
                ) : null
            }
            { daily_limit_amount ? (
                <Grid style={styles.divisor}>
                    <BooleanInput
                        label="Eliminar limite"
                        source="delete_daily_limit_amount"
                        onChange={(event) => {
                            const { target : { checked }} = event;
                            setCheckedDeleteDailyLimitAMount(checked);
                        }}
                    />
                </Grid>
            ) : null}
        </Grid>
    );
};

export default SetDailyLimit;
