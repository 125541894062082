import { apiCall }    from '../../Middlewares';
import { ENDPOINTS }  from '../../Config/env';

const deleteFintoc = async (fintoc_id) =>
    await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.FINTOC.DELETE,
        data: {
            fintoc_id,
        },
    });

const assignFintocRecharge = async (user_id, fintoc_id, amount) =>
    await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.FINTOC.ASSING,
        data: {
            fintoc_id,
            user_id,
            amount,
        },
    });

const lastUpdate = async () => {
    let response = await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.FINTOC.LAST_UPDATE
    })

    return response.data;
}

export default {
    deleteFintoc,
    assignFintocRecharge,
    lastUpdate
}