import React, { useState, useEffect } from 'react';
import { BankAccountsProvider } from '../../../../../../../Providers';
import { 
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Button,
 } from '@material-ui/core';
import CreateOrEditBankAccount from '../CreateOrEditBankAccount';

const BankAccountList = (props) => {
  const [ favoriteList, setFavoriteList ] = useState([]);
  const [ editFavorite, setEditFavorite ] = useState({});
  const [ action, setAction ] = useState(null);
  const [ pageList, setPageList ] = useState({
    count: 10,
    page: 0,
    total: 0
  });
  const { record } = props;
  const { attributes: { iso_code_country } } = record;

  let colums = [
    { id: 'id', label: 'ID' },
    { id: 'alias', label: 'Alias' },
    { id: 'bank_name', label: 'Banco' },
    { id: 'account_type', label: 'Tipo de cuenta' },
    { id: 'account_number', label: 'Cuenta' },
    { id: 'destination_iso_code', label: 'País' }
  ];

  if (iso_code_country === 'AR'){
    colums.splice(
      5,
      0,
      { id: 'document_type', label: 'Tipo de documento' },
      { id: 'document_number', label: 'Número de documento' }
    );
  }

  const location = props.location.slice(-1)
  
  const getBankAccount = async (userId, page, count = 0) => {
    try {
      const perPage = count == 0 ? pageList.count : count;
      const resp = await BankAccountsProvider.getBankAccountList(userId, (page + 1), perPage);

      setPageList({
        ...pageList,
        total: resp.data.total,
        page: page,
        count: perPage
      });

      const list = resp.data.banks_accounts.data.map((f) => {
        return f.attributes;
      });

      setFavoriteList(list);
    } catch (e) { }
  }

  useEffect(() => {
    location === '7' && getBankAccount(record.id, 0);
  }, []);

  const handleChangePage = (e, page) => {
    setPageList({
      ...pageList,
      page: page,
    });
    getBankAccount(record.id, page);
  }

  const handleChangeRowsPerPage = e => {
    setPageList({
      ...pageList,
      count: e.target.value,
      page: 0
    });
    getBankAccount(record.id, 0, e.target.value);
  }

  const goToCreateAccount = () => {
    setAction('create')
  }

  const goToEditAccount = favoriteAccount => {
    setEditFavorite(favoriteAccount);
    setAction('edit')
  }

  return (
    <div>
    {
      action === null ?
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                { colums.map(colum => {
                  return (
                    <TableCell
                      key={colum.id}
                    >
                      {colum.label}
                    </TableCell>
                  );
                }) }
              </TableRow>
            </TableHead>
            <TableBody>
              {favoriteList.map(row => {
                return (
                  <TableRow key={row.id}>
                    <TableCell> {row.id}</TableCell>
                    <TableCell> {row.alias}</TableCell>
                    <TableCell> {row.bank_name}</TableCell>
                    <TableCell> {row.account_type}</TableCell>
                    <TableCell> {row.account_number}</TableCell>
                    {
                      iso_code_country === 'AR' && (
                        <>
                          <TableCell> {row.document_type?.toUpperCase()}</TableCell>
                          <TableCell> {row.document_number}</TableCell>
                        </>
                      )
                    }
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => { goToEditAccount(row) }}
                      >
                        Editar
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={pageList.total}
            rowsPerPage={pageList.count}
            page={pageList.page}
            backIconButtonProps={{
              'aria-label': 'Atras',
            }}
            nextIconButtonProps={{
              'aria-label': 'Siguiente',
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => { goToCreateAccount() }}
          >
            Crear
          </Button>
        </Paper>
      : <CreateOrEditBankAccount
          {...props}
          editFavorite={editFavorite}
          action={action}
          /> }
    
    </div>
  );
}
 
export default BankAccountList;