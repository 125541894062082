const senderInfo = ({attributes: {from_address, currency, sender_user, sender_email}}) =>
    (currency === 'clp' || currency === 'usd') ?
        sender_email ? '' : sender_user.data ? sender_user.data.attributes.email : '' :
        currency === 'btc' ?
            from_address :
            from_address;

const recipientInfo = ({attributes: { to_address, currency, recipient_user, recipient_email}}) =>
    (currency === 'clp' || currency === 'usd') ?
        recipient_email ? '' : recipient_user.data ? recipient_user.data.attributes.email : '' :
        currency === 'btc' ?
            to_address :
            to_address;

const recipientEmail = ({attributes: {currency, recipient_user, recipient_email}}) =>
    (!recipient_email && recipient_user.data && (currency !== 'clp' && currency !== 'usd')) ?
        recipient_user.data.attributes.email :
        '';

const senderEmail = ({attributes: {currency, sender_user, sender_email}}) =>
    (!sender_email && sender_user.data && (currency !== 'clp' && currency !== 'usd')) ?
        sender_user.data.attributes.email :
        '';

export default {
    senderInfo,
    recipientInfo,
    senderEmail,
    recipientEmail
};
