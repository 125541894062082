import React, { useEffect, useState }  from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper
}                                      from '@material-ui/core';

import { apiCall }  from '../../Middlewares';
import { API_URL }  from '../../Config/env';

import styles  from './styles';

const BtcAddressView = (props) => {
    const { user } = props;
    const [data, setData] = useState([]);

    useEffect(() => {
        let isFetch = true;

        (async () => {
            try {
                if (isFetch) {
                    if (user) {
                        const config = {
                            url: `${API_URL}/admin/users/${user}/btc_addresses`,
                            method: 'get'
                        };

                        const res = await apiCall({
                            authorization: true,
                            ...config,
                        });

                        setData(res.data.data);
                    }
                }
            } catch (e) { }
        })();

        return () => { isFetch = false; };
    }, [user]);

    const render = () =>
        data.map((btc_address) => {
            const {attributes: {balance, multisig_address, prev_tx_hash, received, sent, status, btc_balances}} = btc_address;
            return(
                <div style={styles.containerTab}>
                    <Paper style={styles.paper}>
                        <Table style={styles.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align={'right'}>Balance</TableCell>
                                    <TableCell align={'right'}>Direcciones multigrado</TableCell>
                                    <TableCell align={'right'}>Hash PREV TX</TableCell>
                                    <TableCell align={'right'}>Recibido</TableCell>
                                    <TableCell align={'right'}>Enviado</TableCell>
                                    <TableCell align={'right'}>Estado</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={balance}>
                                    <TableCell align={'right'}>{balance}</TableCell>
                                    <TableCell align={'right'}>{multisig_address}</TableCell>
                                    <TableCell align={'right'}>{prev_tx_hash}</TableCell>
                                    <TableCell align={'right'}>{received}</TableCell>
                                    <TableCell align={'right'}>{sent}</TableCell>
                                    <TableCell align={'right'}>{status}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align={'right'}>Balance</TableCell>
                                    <TableCell align={'right'}>Hash TX</TableCell>
                                    <TableCell align={'right'}>Hash Output</TableCell>
                                    <TableCell align={'right'}>Email</TableCell>
                                    <TableCell align={'right'}>user</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {btc_balances.map((btc_balance) => (
                                    <TableRow key={btc_balance.attributes.balance}>
                                        <TableCell align={'right'}>{btc_balance.attributes.balance}</TableCell>
                                        <TableCell align={'right'}>{btc_balance.attributes.tx_hash}</TableCell>
                                        <TableCell align={'right'}>{btc_balance.attributes.tx_output_n}</TableCell>
                                        <TableCell align={'right'}>{btc_balance.attributes.user_email}</TableCell>
                                        <TableCell align={'right'}>{btc_balance.attributes.user_name}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper><br/><br/>
                </div>
            );
        });

    return(
        <div style={styles.container}>
            <div style={{color: '#FFFFFF'}}>
                {data.length === 0 ? 'No hay billeteras bitcoin por los momentos' : ''}
            </div>
            {render()}
        </div>
    );
};

export default BtcAddressView;
