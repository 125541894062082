import moment from 'moment';
import Swal from "sweetalert2";

export const standarMessage = (value = '', type = 'sucess', title = 'completed') => {
    Swal.fire({
        icon: type,
        title,
        text: value,
        confirmButtonColor: 'blue'
    });
}

export const toDate = (dateAttr) => {
    moment.locale('es');
    const date = moment(dateAttr).format('LL');
    return `${date}`;
};

export const capializeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const scientificToDecimal = function (num) {
    var nsign = Math.sign(num);
    //remove the sign
    num = Math.abs(num);
    //if the number is in scientific notation remove it
    if (/\d+\.?\d*e[\+\-]*\d+/i.test(num)) {
        var zero = '0',
            parts = String(num).toLowerCase().split('e'), //split into coeff and exponent
            e = parts.pop(), //store the exponential part
            l = Math.abs(e), //get the number of zeros
            sign = e / l,
            coeff_array = parts[0].split('.');
        if (sign === -1) {
            l = l - coeff_array[0].length;
            if (l < 0) {
                num = coeff_array[0].slice(0, l) + '.' + coeff_array[0].slice(l) + (coeff_array.length === 2 ? coeff_array[1] : '');
            }
            else {
                num = zero + '.' + new Array(l + 1).join(zero) + coeff_array.join('');
            }
        }
        else {
            var dec = coeff_array[1];
            if (dec)
                l = l - dec.length;
            if (l < 0) {
                num = coeff_array[0] + dec.slice(0, l) + '.' + dec.slice(l);
            } else {
                num = coeff_array.join('') + new Array(l + 1).join(zero);
            }
        }
    }

    return nsign < 0 ? '-' + num : num;
};

export const maxSizeIconCategory = 100000;
export const maxSizeDocumentImage = 10485760;
export const sizeBinaryByte = 1048576;

export const COUNTRIES_CURRENCY = [
    { id: 'ARS', name: 'Argentina' },
    { id: 'BOB', name: 'Bolivia' },
    { id: 'BRL', name: 'Brasil' },
    { id: 'CLP', name: 'Chile' },
    { id: 'CNY', name: 'China' },
    { id: 'COP', name: 'Colombia' },
    { id: 'CRC', name: 'Costa Rica' },
    { id: 'ECS', name: 'Ecuador' },
    { id: 'USD', name: 'Estados Unidos' },
    { id: 'HTG', name: 'Haití' },
    { id: 'MXN', name: 'México' },
    { id: 'PAB', name: 'Panamá' },
    { id: 'PYG', name: 'Paraguay' },
    { id: 'PEN', name: 'Perú' },
    { id: 'USD', name: 'Perú (USD)' },
    { id: 'GBP', name: 'Reino Unido' },
    { id: 'UYU', name: 'Uruguay' },
    { id: 'VES', name: 'Venezuela' },
    { id: 'DOP', name: 'República Dominicana' },
    { id: 'EUR', name: 'Union Europea' },
];

export const COUNTRIES = [
    { id: 'AR', name: 'Argentina' },
    { id: 'BO', name: 'Bolivia' },
    { id: 'BR', name: 'Brasil' },
    { id: 'CL', name: 'Chile' },
    { id: 'CN', name: 'China' },
    { id: 'CNUSD', name: 'China (USD)' },
    { id: 'COCOP', name: 'Colombia' },
    { id: 'CR', name: 'Costa Rica' },
    { id: 'EC', name: 'Ecuador' },
    { id: 'US', name: 'Estados Unidos' },
    { id: 'HT', name: 'Haití' },
    { id: 'MX', name: 'México' },
    { id: 'PA', name: 'Panamá' },
    { id: 'PY', name: 'Paraguay' },
    { id: 'PE', name: 'Perú' },
    { id: 'PEUSD', name: 'Perú (USD)' },
    { id: 'GB', name: 'Reino Unido' },
    { id: 'UY', name: 'Uruguay' },
    { id: 'VE', name: 'Venezuela' },
    { id: 'DO', name: 'República Dominicana' },
    { id: 'EU', name: 'Union Europea' },
    { id: 'CAUSD', name: 'Canadá' },
    { id: 'GBUSD', name: 'Reino Unido (USD)' },
    { id: 'EUUSD', name: 'Unión Europea (USD)' },
    { id: 'AU', name: 'Australia' },
    { id: 'HKUSD', name: 'Hong Kong (USD)' },
    { id: 'GBEUR', name: 'Reino Unido (EUR)' },
    { id: 'USWIRES', name: 'Estados Unidos - WIRE' },
];

export const DOCUMENT_TYPE = [
    { id: 'dni', name: 'Documento de identidad (dni)' },
    { id: 'company_id', name: 'Documento de empresa (company_id)' },
    { id: 'passport', name: 'Pasaporte (passport)' },
    { id: 'dni_foreigner', name: 'Documento de identidad de extranjería (dni_foreigner)' },
    { id: 'company_ruc', name: 'Documento de compañia (company_ruc)' },
];

export const REFERRER_PRIZE_TYPE = [
    { id: 'referrer', name: 'Solo el referidor recibe' },
    { id: 'both', name: 'Ambos reciben' },
];

export const PAYMENT_PROCESSORS = [
    { id: 'manual', name: 'Manual' },
    { id: 'wyre', name: 'Wyre' },
    { id: 'dlocal', name: 'Dlocal' },
    { id: 'ripple', name: 'Ripple' },
    { id: 'reserve', name: 'Reserve' },
    { id: 'bancolombia', name: 'Bancolombia' },
    { id: 'fortress', name: 'Fortress' },
    { id: 'nium', name: 'Nium' },
    { id: 'stp', name: 'STP' },
    { id: 'sandya', name: 'Sandya' },
    { id: 'bind', name: 'Bind' },
    { id: 'bci', name: 'BCI' },
    { id: 'alfin', name: 'Alfin Banco' },
    { id: 'rendimento', name: 'Rendimento' },
];

export const CIVIL_STATUS = [
    { id: 'single', name: 'Soltero(a)' },
    { id: 'married', name: 'Casado(a)' },
    { id: 'divorced', name: 'Divorciado(a)' },
    { id: 'separated', name: 'Separado(a)' },
    { id: 'widowed', name: 'Viudo(a)' },
];

export const TRANSACTIONS_STATUS = {
    started: 'Iniciada',
    completed: 'Completada',
    pending: 'Pendiente',
    denied: 'Rechazada',
    processed: 'Procesada',
    failed: 'Fallida',
    checking: 'Revisando',
};

export const TRANSACTIONS_OPERATION = {
    created: 'Creación',
    updated: 'Actualización',
    deleted: 'Eliminación',
};

export const SEGMENTS_CATEGORY = [
    { segment_id: 1, segment: 'Servicios del hogar' },
    { segment_id: 2, segment: 'Servicios complementarios' },
    { segment_id: 3, segment: 'Servicios varios' },
];

export const GENDERS = [
    { id: 'male', name: 'Masculino' },
    { id: 'female', name: 'Femenino' },
    { id: 'other', name: 'Otro' },
]

// Use this object to replace messages from the API. The key must be EXACTLY the message from the API
// and the value must be the equivalent that you want.
export const API_MESSAGES = {
    'Your account has been locked due to an excessive number of unsuccessful sign in attempts.':
        'Tu cuenta fue bloqueada debido a varios intentos fallidos de inicio de sesión.',
    'Invalid pin.': 'Pin inválido.',
    'You need to sign in or sign up before continuing.':
        'Necesitas registrarte o iniciar sesión antes de continuar.'
}

export const parseAPIMessage = (original) => {
    return original in API_MESSAGES ? [true, API_MESSAGES[original]] : [false, original];
}

export const alertTypeToEmoji = (alert_type) => {
    switch (alert_type) {
        case 'older_adult':
            return '🎅';
        case 'new_user':
            return '📅';
        case 'high_sending_amount':
            return '💰';
        case 'warning':
            return '⚠️'
        default:
            break;
    };
};

export const ALERT_STATUS = [
    { id: '0', key: 'pending', name: 'Pendiente' },
    { id: '1', key: 'completed', name: 'Revisado' }
];

export const ALERT_TYPES = [
    { id: 0, name: 'Envío' },
    { id: 1, name: 'Recepción de envío' },
    { id: 2, name: 'Retiro' },
    { id: 3, name: 'Intercambio' },
    { id: 4, name: 'Transferencia' },
    { id: 5, name: 'Usuarios' },
];

export const TYPE_PROFILE_VERIFICATION = {
    accepted: 'Aceptada',
    rejected: 'Rechazada',
    incomplete: 'Incompleta',
    verification_required: 'Requerida',
    verification_profile_completed: 'Completada',
    initial: 'Sin solicitud',
    started: 'Iniciada',
    profile_update_alerted: 'Alerta de Actualización',
};

export const ALERT_TABLE = [
    { id: 'Transaction', name: 'Transacciones' },
    { id: 'User', name: 'Usuarios' },
];

export const PAYMENT_ORDERS_SERVICE_STATUSES = [
    { id: 'payment_orders_service_inactive', name: 'Inactivo' },
    { id: 'payment_orders_service_requested', name: 'Solicitado' },
    { id: 'payment_orders_service_not_set', name: 'Por configurar' },
    { id: 'payment_orders_service_active', name: 'Activo' },
]

export const isImageFromUrl = (url) => {
    const extension = (url || "").split('.').pop().toLowerCase();
    return ["jpg", "jpeg", "png", "gif", "bmp"].includes(extension);
};

export const downloadFromUrl = async (url = '', name = '') => {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Error al descargar el archivo: ${response.status}`);
        }

        const contentType = response.headers.get('content-type');
        const blob = await response.blob();

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);

        link.download = name + (contentType ? '.' + contentType.split('/')[1] : '');
        link.type = contentType;

        link.click();

        URL.revokeObjectURL(link.href);
    } catch (error) {
        console.error('Error:', error);
    }
};

export const modalCopyValues = async (value, modal) => {
    await navigator.clipboard.writeText(value);
    modal(`Valor copiado ${value}`);
};

export const CATEGORY = [
    { id: 'natural', name: 'Natural' },
    { id: 'business', name: 'Empresa' }
];