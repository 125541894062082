import { UPDATE } from 'react-admin';

const responseHandler = (response, type) => {
    switch (type) {
        case UPDATE: {
            return {
                data: response.data.data,
            }
        }
        default: {
            return {
                data: response.data.transactions.data,
                total: response.data['total'],
            }
        }
    }
};

export default responseHandler;
