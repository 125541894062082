import { UPDATE, CREATE, DELETE, GET_LIST } from "react-admin";

import { API_URL } from "../../../../Config/env";

const requestHandler = (type, params, resource) => {

  switch (type) {
    case GET_LIST: {

      if (resource === 'segmentListUsers') {
        const { filter: { id }, pagination: { page, perPage }, sort: { order } } = params

        return {
          url: `${API_URL}/admin/user_segments?page=${page}&count=${perPage}&q[s]=id+${order}&q[segment_id_eq]=${id}`,
          method: "get",
        };
      }

      if (resource === 'couponsList') {
        let { filter: { id, emailSegment, status }, pagination: { page, perPage } } = params
        const statusUsers = status ? `&status=${status}` : ''
        const email = emailSegment ? `&email=${emailSegment}` : ''
        const order = params.sort.order ? params.sort.order : ''

        let isExport = false;
        if (parseInt(perPage) === 1000){
            perPage = 100000000;
            isExport = true;
        }

        let url = `${API_URL}/admin/user_segments/get_by_coupon?page=${page}&count=${perPage}&coupon_id=${id}&q[s]=id+${order}&${email}${statusUsers}`;
        url += isExport ? `&is_export=${isExport}`: '';
        return {
          url,
          method: "get",
        };
      }

      return {};
    }

    case CREATE: {
      let { data } = params;
      const formData = new FormData();
      formData.append("document_file", data.document_file.rawFile);
      formData.append("name", data.name);

      return {
        url: `${API_URL}/admin/segments`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
    }

    case UPDATE: {
      let { data } = params;

      const formData = new FormData();
      formData.append("name", data.attributes.name);

      if (data.attributes.document_file) {
        formData.append("document_file", data.attributes.document_file);
      }

      return {
        url: `${API_URL}/admin/segments/${params.id}`,
        method: "put",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
    }

    case DELETE: {
      return {
        url: `${API_URL}/admin/segments/${params}/delete`,
        method: "delete",
      };
    }

    default: {
      return {};
    }
  }
};

export default requestHandler;
