const styles = {
  items: {
    marginBottom: 16
  },
  divisor: {
    padding: '5px 0',
  },
  checked: {
    color: '#FFF'
  },
  noChecked: {
      backgroundColor: 'transparent',
      color: 'rgba(255,255,255,.6)',
  },
  avatar: {
    backgroundColor: 'transparent'
  },
  title: {
    textDecoration: 'underline',
    marginBottom: 16,
    marginTop: 20
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
    marginBottom: 20
  }
};

export default styles;