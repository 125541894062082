import {UPDATE, GET_LIST, GET_ONE} from 'react-admin';

import { API_URL } from '../../../../Config/env';

const requestHandler = (type, params, resource) => {
    switch (type) {
        case GET_LIST: {
            let prepareUrl = `${API_URL}/admin/${resource}`
            return {
                url: prepareUrl,
                method: 'get',
            };
        }
        case GET_ONE: {
            return {
                url: `${API_URL}/admin/${resource}?q[id_eq]=${params.id}`,
                method: 'get'
            };
        }
        case UPDATE: {
            const {
                x_rules,
                x_residence_config,
                x_settings,
                x_rules_up = false,
                x_residence_config_up = false,
                x_settings_up = false,
            } = params.data;

            const payload = {
                x_rules: x_rules + (x_rules_up ? 1 : 0),
                x_residence_config: x_residence_config + (x_residence_config_up ? 1 : 0),
                x_settings: x_settings + (x_settings_up ? 1 : 0),
            };

            return {
                url: `${API_URL}/admin/settings_headers`,
                method: 'put',
                data: payload,
            };
        }
        default: {
            return {

            }
        }
    }
};

export default requestHandler;
