import React from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  ShowButton,
} from "react-admin";

import PricesProvidersFilter from '../Filters/PricesProvidersFilter';

const PricesProviderList = (props) => {
  return (
    <List
      {...props}
      title={"Proveedores de precios"}
      filters={<PricesProvidersFilter />}
      bulkActionButtons={false}
      exporter={false}
      debounce={1000}
    >
      <Datagrid>
        <TextField source={"id"} label={"Id"} />
        <TextField source={"attributes.public_name"} label={"Nombre público"} />
        <TextField source={"attributes.name"} label={"Nombre"} />
        <TextField source={"attributes.order_priority"} label={"Orden de prioridad"} />
        <TextField source={"attributes.status"} label={"Estado"} />
        <ShowButton basePath='/prices_providers' label='' />
        <EditButton basePath='/prices_providers' />
      </Datagrid>
    </List>
  );
};

export default PricesProviderList;