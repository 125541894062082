import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@material-ui/core';

import UsersProvider from '../../../../../../../Providers/Users';
import useModal from '../../../../../../../hooks/useModal';
import useReset from '../../../../../../../hooks/useReset';
import ModalConfirm from '../../../../../../../Components/ModalConfirm';

const Suppress = ({id, status}) => {
  const { open, setOpen, showModal, message, action } = useModal();
  const { resetList } = useReset();

  const suppressUser = async () => {
    try {
      await UsersProvider.suppressUser({id});
      resetList();
    } catch {
      showModal("Ha ocurrido un error, intente mas tarde", null);
    }
  }

  const supressHandler = () => {
    showModal(`¿${status === 'active' ? 'Eliminar' : 'Activar'} el usuario del sistema?`, suppressUser);
  }

  return (
    <>
      <ModalConfirm
        message={message}
        open={open}
        setOpen={setOpen}
        action={action}
      />
      <Button variant='outlined' onClick={supressHandler} style={{marginLeft: 20}}>
        {
          status === 'active' ? 'Suprimir usuario' : 'Activar usuario'
        }
      </Button>
    </>
  );
};

Suppress.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

Suppress.defaultProps = {};

export default Suppress;