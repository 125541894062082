import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Grid, Button } from '@material-ui/core';
import moment from 'moment';
import Swal from 'sweetalert2';

import useAlerts from '../../../../../hooks/useAlerts';
import useAccessRole from '../../../../../hooks/useAccessRole';
import { CardRequestProvider } from '../../../../../Providers'
import { statusCard } from '../../../../../Config/constants';
import styles from './styles';

const VitaCard = (props) => {
  const { record: {
    attributes: {
      fullname,
      email,
      provider,
      user: {
        category,
        currencies
      },
      currency: {
        iso_code
      },
      card_requests: {
        id,
        created_at,
        status
      }
    }
  } } = props;

  const [statusCardRequest, setStatusCardRequest] = useState(status);
  const [currencyIsoCode, setCurrencyIsoCode] = useState(iso_code);
  const { successMessage, errorMessage } = useAlerts();
  const { isAccess } = useAccessRole();

  const updateStatusCard = async (status, comment) => {
    try {
      let data = {
        status: status,
        card_id: id,
        comment: comment
      }

      const response = await CardRequestProvider.updateStatus(data);
      successMessage(response.data.message);
      setStatusCardRequest(status);
    } catch (error) {
      errorMessage(error.data.error.message);
    }
  };

  const updateCurrencyCard = async (currency, comment) => {
    try {
      let data = {
        card_id: id,
        currency_iso_code: currency,
        comment: comment
      }

      const response = await CardRequestProvider.updateCurrency(data);
      successMessage(response.data.message);
      setCurrencyIsoCode(currency);
    } catch (error) {
      errorMessage(error.data.error.message);
    }
  };

  const SelectCurrency = () => (
    <select id='select-currency' className='swal2-input'>
      {currencies.map(currency => (
        <option key={currency} value={currency}>{currency}</option>
      ))}
    </select>
  );

  const changeCurrencyHandler = () => {
    Swal.fire({
      title: `¿Cambiar moneda de la tarjeta ID ${id}`,
      showCancelButton: true,
      input: 'text',
      inputAttributes: {
        placeholder: 'Escribe tu comentario aquí...',
      },
      confirmButtonColor: '#005FEE',
      cancelButtonColor: '#63666b',
      confirmButtonText: 'Cambiar moneda',
      cancelButtonText: 'Cancelar',
      html: ReactDOMServer.renderToString(<SelectCurrency />),
      focusConfirm: false,
      preConfirm: () => {
        const selectElement = document.getElementById('select-currency');
        const selectedCurrency = selectElement ? selectElement.value : null;
        const inputComment = Swal.getInput().value;

        if (!selectedCurrency) {
          Swal.showValidationMessage('Debes seleccionar una moneda.');
        }

        if (!inputComment) {
          Swal.showValidationMessage('El comentario es obligatorio.');
        }

        return {
          comment: inputComment,
          currency: selectedCurrency
        };
      },
      inputValidator: (value) => {
        if (!value) {
          return 'El comentario es obligatorio.';
        }
        return null;
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        updateCurrencyCard(result.value.currency, result.value.comment);
      }
    });
  };

  const blockCardHandler = () => {
    Swal.fire({
      title: `¿Congelar la tarjeta ID ${id}`,
      showCancelButton: true,
      input: 'text',
      inputAttributes: {
        placeholder: 'Escribe tu comentario aquí...',
      },
      confirmButtonColor: '#005FEE',
      cancelButtonColor: '#63666b',
      confirmButtonText: 'Congelar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        if (!value) {
          return 'El comentario es obligatorio.';
        }
        return null;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        updateStatusCard('blocked', result.value);
      }
    });
  };

  const getCardFromPomeloHandler = async () => {    
    const response = await CardRequestProvider.getCardFromPomelo(id);
    successMessage(response.data.message);
    setStatusCardRequest(response.data.status);
  };

  const canceledCardHandler = () => {
    Swal.fire({
      title: `¿Eliminar la tarjeta ID ${id}`,
      showCancelButton: true,
      input: 'text',
      inputAttributes: {
        placeholder: 'Escribe tu comentario aquí...',
      },
      confirmButtonColor: '#E9320A',
      cancelButtonColor: '#63666b',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        if (!value) {
          return 'El comentario es obligatorio.';
        }
        return null;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        updateStatusCard('cancelled', result.value);
      }
    });
  };

  return (
    <>
      <Grid
        alignItems='center'
        container
        style={styles.paddingButtonTop}
      >
        {isAccess('add_balance_for_admin') &&
          <>
            <Button
              onClick={changeCurrencyHandler}
              style={{
                ...(statusCardRequest === 'active'
                  ? styles.buttonBlue
                  : styles.buttonDisabled
                ),
                marginRight: '10px'
              }}
              disabled={statusCardRequest !== 'active'}
            >
              CAMBIAR MONEDA
            </Button>
            <Button
              onClick={blockCardHandler}
              style={{
                ...(statusCardRequest === 'active'
                  ? styles.buttonBlue
                  : styles.buttonDisabled
                ),
                marginRight: '10px'
              }}
              disabled={statusCardRequest !== 'active'}
            >
              CONGELAR
            </Button>
            <Button
              onClick={canceledCardHandler}
              style={statusCardRequest !== 'cancelled'
                ? styles.buttonRed
                : styles.buttonDisabled
              }
              disabled={statusCardRequest === 'cancelled'}
            >
              ELIMINAR
            </Button>
            <Button
              onClick={getCardFromPomeloHandler}
              style={{
                ...styles.buttonGreen,
                marginLeft: '10px'
              }}
              disabled={statusCardRequest !== 'active'}
            >
              Actualiza con proveedor
            </Button>
          </>
        }
      </Grid>
      <Grid
        alignItems='center'
        container
      >
        <Grid item xs={2}>
          <div style={styles.titleWhite}>
            <strong>TIPO DE USUARIO</strong><br />
            {category}
          </div>
        </Grid>
        <Grid item xs={1}>
          <div style={styles.titleWhite}>
            <strong>ESTADO</strong><br />
            {statusCard[statusCardRequest]}
          </div>
        </Grid>
        <Grid item xs={1}>
          <div style={styles.titleWhite}>
            <strong>MONEDA</strong><br />
            {currencyIsoCode}
          </div>
        </Grid>
        <Grid item xs={2}>
          <div style={styles.titleWhite}>
            <strong>FECHA DE CREACIÓN</strong><br />
            {moment(created_at).format('DD/MM/YYYY')}
          </div>
        </Grid>
        <Grid item xs={2}>
          <div style={styles.titleWhite}>
            <strong>PROVEEDOR</strong><br />
            {provider}
          </div>
        </Grid>
        <Grid item xs={2}>
          <div style={styles.titleWhite}>
            <strong>USUARIO</strong><br />
            {fullname}
          </div>
        </Grid>
        <Grid item xs={2}>
          <div style={styles.titleWhite}>
            <strong>CORREO</strong><br />
            {email}
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default VitaCard;