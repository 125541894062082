import React from 'react';
import styles from '../styles';
import { SimpleForm, Create, TextInput } from 'react-admin';
import { Grid } from '@material-ui/core';

import {
  validateRequired
} from '../../../../Helpers/ValidateHelper';

const AdminCreate = (props) => {

  const RenderFields = (props) => {

    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        xs={12}
        sm={10}
        item
        style={styles.container}
      >
        <Grid
          xs={12}
          sm={3}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.title_es'}
            label={'Titulo Español'}
            fullWidth
            validate={validateRequired}
          />
        </Grid>
        <Grid
          xs={12}
          sm={3}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.title_en'}
            label={'Titulo Ingles'}
            fullWidth
            validate={validateRequired}
          />
        </Grid>
        <Grid
          xs={12}
          sm={2}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.place'}
            label={'Lugar'}
            fullWidth
            validate={validateRequired}
          />
        </Grid>
        <Grid
          xs={12}
          sm={2}
          item
          style={styles.items}
        >
          <TextInput
            source={'attributes.max_messages'}
            label={'Cantidad'}
            fullWidth
            validate={validateRequired}
            type="number"
          />
        </Grid>
      </Grid>
    );
  };
  
  return (
    <Create
      title={`Crear titulo para mesaje marketing`}
      {...props}
    >
      <SimpleForm>
        <RenderFields />
      </SimpleForm>
    </Create>
  );
};

export default AdminCreate;
