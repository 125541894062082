import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStatusPricesProviders from '../../hooks/useStatusPricesProviders';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const clases = theme => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 'bold',
    },
    rootTable: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
});

const FailDetails = (props) => (
    <ExpansionPanelDetails>
        <Typography>
            {props.children}
        </Typography>
    </ExpansionPanelDetails>
);

FailDetails.propTypes = {
    children: PropTypes.node,
};

FailDetails.defaultProps = {
    children: <></>,
};

export const FailList = withStyles(clases)((props) => (
    <Paper className={props.classes.rootTable}>
      <Table className={props.classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>URL</TableCell>
            <TableCell align="right">Error</TableCell>
            <TableCell align="right">Fecha</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {props.isFail && props.fails.map((fail) => (
                <TableRow key={fail.id}>
                    <TableCell component="th" scope="row">
                        {fail.url}
                    </TableCell>
                    <TableCell align="right">
                        {fail.error_message}
                    </TableCell>
                    <TableCell align="right">
                        {fail.created_at}
                    </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Paper>
));

FailList.propTypes = {
    fails: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            url: PropTypes.string,
            provider: PropTypes.string,
            error_message: PropTypes.string,
            created_at: PropTypes.string,
        }),
    ),
};

FailList.defaultProps = {
    fails: [],
};

export const Statuses = withStyles(clases)((props) => (
    <>
        <ExpansionPanel>
            <ExpansionPanelSummary 
                style={props.isFail ? styles.errorContent : {}}
                expandIcon={
                    props.isFail && <ExpandMoreIcon 
                        style={styles.errorIcon}
                    />
                }
            >
                {
                    props.isFail ? 
                        <ErrorIcon style={styles.errorIcon} /> : 
                        <CheckIcon />
                }
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Typography className={props.classes.heading}>
                    {props.name} {props.isFail ? `(${props.failsSize})` : ''}
                </Typography>
            </ExpansionPanelSummary>
            {props.isFail && (
                <FailDetails>
                    <FailList
                        isFail={props.isFail}
                        fails={props.fails}
                    />
                </FailDetails>
            )}
        </ExpansionPanel>
    </>
));

Statuses.propTypes = {
    name: PropTypes.string,
    isFail: PropTypes.bool,
    failsSize: PropTypes.number,
    fails: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            url: PropTypes.string,
            provider: PropTypes.string,
            error_message: PropTypes.string,
            created_at: PropTypes.string,
        }),
    ),
};

Statuses.defaultProps = {
    name: '',
    isFail: false,
    failsSize: 0,
    fails: [],
};

export const StatusPricesProviders = (props) => (
    <>
        <span style={styles.title}>Estado de los proveedores de precios</span>
        <Button 
            title="Cargar"
            onClick={props.onGetStatusPricesProviders} 
            disabled={props.isDisabledButton} 
            style={styles.button}
        >
            {props.isLoading && <CircularProgress size={14} style={styles.loading} />}
            {props.labelButton}
        </Button>
        <div style={styles.statusContainer}>
            {props.statuses.map((status) => (
                <Statuses key={status.name} {...status} />
            ))}
        </div>
    </>
);

StatusPricesProviders.propTypes = {
    labelButton: PropTypes.string,
    isLoading: PropTypes.bool,
    isDisabledButton: PropTypes.bool,
    onGetStatusPricesProviders: PropTypes.func,
    statuses: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        isFail: PropTypes.bool,
        fails: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                url: PropTypes.string,
                provider: PropTypes.string,
                error_message: PropTypes.string,
                created_at: PropTypes.string,
            }),
        ),
    })),
};

StatusPricesProviders.defaultProps = {
    labelButton: 'Cargar',
    isLoading: false,
    isDisabledButton: false,
    onGetStatusPricesProviders: () => {},
    statuses: [],
};

const styles = {
    title: {
        marginRight: '2em',
    },
    loading: { 
        color: 'white',
    },
    button: {
        border: '1px white solid',
    },
    errorIcon: {
        color: '#FFFFFF',
    },
    errorContent: {
        backgroundColor: '#FF0000',
    },
    statusContainer: {
        paddingTop: 20,
    },
};

const StatusPricesProvidersHoc = (props) => {
    const hook = useStatusPricesProviders(props);
    return <StatusPricesProviders {...hook} />;
};

export default StatusPricesProvidersHoc;