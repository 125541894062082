const styles = {
  emojiContainer: {
      display: 'flex',
      flexDirection: 'row',
  },
  emoji: {
      margin: 7,
      fontSize: 22,
  },
};

export default styles;
