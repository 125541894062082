import React from 'react';
import {Col, Container, Row} from 'react-grid-system';

import TbkLogo   from './../../../../../Assets/images/tbk.svg';
import {toMoney} from '../../../../../Helpers/MoneyHelper';
import {payment_types} from '../../../../../Config/constants';

import styles from './styles';

const Limits = (props) => {
    const {
        record: {
            daily_transbank_recharge_limit,
            monthly_transbank_recharge_limit,
            daily_limit_amount_clp,
            daily_limit_amount_usd,
            payment_type,
            verification_code_expiration_time,
            is_email_code_verification,
        }
    } = props;

    return (
        <Container style={{ padding: 20, heigth: '100%'}}>
            <Row>
                <Col sm={12} style={{...styles.box, textAlign: 'center', paddingTop: 70, paddingBottom: 70}}>
                    <img style={{width: 150, paddingBottom: 20}} src={TbkLogo} />
                    <Row>
                        <Col sx={12} style={{...styles.fee, ...styles.title}}>
                            Límite diario de recargas
                        </Col>
                        <Col sx={12} style={{...styles.fee, ...styles.title}}>
                            Límite mensual de recargas
                        </Col>
                    </Row>
                    <Row>
                        <Col sx={12} style={{...styles.fee, ...styles.title}}>
                            {
                                daily_transbank_recharge_limit ?
                                    toMoney(daily_transbank_recharge_limit, 'CLP') :
                                    'Sin límite'
                            }
                        </Col>
                        <Col sx={12} style={{...styles.fee, ...styles.title}}>
                            {
                                monthly_transbank_recharge_limit ?
                                    toMoney(monthly_transbank_recharge_limit, 'CLP') :
                                    'Sin límite'
                            }
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.fee}>
                            <b style={styles.title}>Tipo de Pago</b>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.fee}>
                            {payment_types[payment_type]}
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.fee}>
                            <b style={styles.title}>Límite diario CLP</b>
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            <b style={styles.title}>Límite diario USD</b>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.fee}>
                            {
                                daily_limit_amount_clp ?
                                    toMoney(daily_limit_amount_clp, 'CLP') :
                                    'Sin límite'
                            }
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {
                                daily_limit_amount_usd ?
                                    toMoney(daily_limit_amount_usd, 'USD') :
                                    'Sin límite'
                            }
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.fee}>
                            <b style={styles.title}>Expiración del código</b>
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            <b style={styles.title}>Método de verificación de cuenta</b>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} style={styles.box}>
                    <Row>
                        <Col sx={12} style={styles.fee}>
                            {
                                verification_code_expiration_time && `${verification_code_expiration_time} segundos`
                            }
                        </Col>
                        <Col sx={12} style={styles.fee}>
                            {
                                is_email_code_verification ? "Código" : "Enlace"
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Limits;
