import { GET_LIST, GET_ONE, UPDATE } from 'react-admin';

const responseHandler = (response, type, params) => {
    switch (type) {
        case GET_LIST: {
            return {
                total: 1,
                data: [response.data],
            }
        }
        case GET_ONE: {
            return {
                data: {
                    ...response.data,
                }
            }
        }
        case UPDATE: {
            return {
                data: params.data,
            }
        }
        default: {
            return {
                data: response.data.configuration.data,
                total: response.data['total'],
            }
        }
    }
};

export default responseHandler;
