const styles = {
  cardContainer: {
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    width: 660,
    marginTop: 10,
  },
  headerStyles: {
    display: "flex",
    justifyContent: "center",
  },
};

export default styles;
