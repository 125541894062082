import CurrencyList from './CurrencyList';
import CurrencyEdit from './CurrencyEdit';
import CurrencyCreate from './CurrencyCreate';
import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
  name: 'currencies',
  list: CurrencyList,
  create: isAccess('create_currencies') ? CurrencyCreate : false,
  edit: isAccess('edit_currencies') ? CurrencyEdit : false,
}