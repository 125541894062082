import React from "react";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import PropTypes from "prop-types";

import { Routes_APP, orderObject, SEGMENTS_USERS } from "../../../../../Components/Comunications/utils";

const Routes = ({
  handleCheckboxChange,
  handleOptionChange,
  statusRoute,
  selectedOption,
  urlOption,
  handleOnChangeUrlOption,
  ValueTypeClient,
  handleOptionTypeUser,
  handleChangeTimeTransition,
  timeTransition,
}) => (
  <Grid container spacing={8} style={{ marginTop: 15 }}>
    <Grid container>
      <Grid item xs={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={statusRoute[0].checked}
              onChange={() => handleCheckboxChange(statusRoute[0].id)}
            />
          }
          label={statusRoute[0].name}
        />
      </Grid>
      <Grid item xs={10}>
        <FormControl>
          <InputLabel id='select-label'>Selecciona una opción</InputLabel>
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            style={{ width: 677 }}
            disabled={statusRoute[1].checked || !statusRoute[0].checked}
          >
            {Object.keys(orderObject(Routes_APP)).map((route) => (
              <MenuItem value={route} key={route}>{Routes_APP[route]}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={statusRoute[1].checked}
              onChange={() => handleCheckboxChange(statusRoute[1].id)}
              style={{ alignItems: "end" }}
            />
          }
          label={statusRoute[1].name}
          style={{ alignItems: "end" }}
        />
      </Grid>
      <Grid item xs={10}>
        <TextField
          label={"Ingresa la URL destino, debe contener https://"}
          onChange={handleOnChangeUrlOption}
          value={urlOption}
          disabled={statusRoute[0].checked || !statusRoute[1].checked}
          error={urlOption.length > 0 && !/^https:\/\//.test(urlOption)}
          fullWidth
        />
      </Grid>
    </Grid>
    <Grid container style={{marginTop: 15}}>
      <Grid item xs={9} >
        <FormControl>
          <InputLabel id='select-label'>Segmentación de usuarios</InputLabel>
          <Select
            value={ValueTypeClient}
            onChange={handleOptionTypeUser}
            style={{ width: 580 }}
          >
            {SEGMENTS_USERS.map((typeClient) => (
              <MenuItem value={typeClient.key} key={typeClient.key}>{typeClient.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <TextField
          label='Tiempo de transición:'
          name={"time_transition"}
          value={timeTransition}
          onChange={handleChangeTimeTransition}
          type='number'
          InputProps={{ inputProps: { min: 1 } }}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Segundos"
        />
      </Grid>

    </Grid>
  </Grid>
);

Routes.propTypes = {
  handleCheckboxChange: PropTypes.func.isRequired,
  handleOptionChange: PropTypes.func.isRequired,
  statusRoute: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
    })
  ).isRequired,
  selectedOption: PropTypes.string.isRequired,
  urlOption: PropTypes.string.isRequired,
  handleOnChangeUrlOption: PropTypes.func.isRequired,
};

Routes.defaultProps = {
  handleCheckboxChange: () => {},
  handleOptionChange: () => {},
  statusRoute: [
    [
      {
        id: "INTERNAL",
        name: "Ruta interna",
        checked: false,
      },
    ],
    [
      {
        id: "EXTERNAL",
        name: "Ruta externa",
        checked: false,
      },
    ],
  ],
  selectedOption: "",
  urlOption: "",
  handleOnChangeUrlOption: () => {},
};

export default Routes;
