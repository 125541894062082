export const BONIFICATION_TYPE = [
  { id: "percentage", name: "Porcentaje" },
  { id: "amount", name: "Monto" },
];

export const COUPON_TYPE = [
  { id: "cash_back", name: "Cashback" },
  { id: "bonus", name: "Bonus" },
];

export const COUNTRIES_CURRENCY_COUPONS = [
  { id: "clp", name: "Chile" },
  { id: "cop", name: "Colombia" },
  { id: "usd", name: "Estados Unidos" },
  { id: "btc", name: "Cripto Moneda" },
];

export const CATEGORIES = [
  { id: "transfer", name: "Transferencias internacionales" },
  /* { id: "sent", name: "Envios" },
  { id: "received", name: "Recibidos" },
  { id: "deposit", name: "Recargas" },
  { id: "withdrawal", name: "Retiros" },
  { id: "exchange", name: "Intercambios" },
  { id: "fee", name: "Comisión" },
  { id: "payment", name: "Pagos" }, */
];

export const OPTIONS_DESING = [
  { id: "without_design", type: "Sin diseño" },
  { id: "customized", type: "Personalizado" },
];
