import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Grid, Button, FormControl, InputLabel, Select } from '@material-ui/core';
import { BankProvider, BankAccountsProvider } from '../../../../../../../Providers';
import ModalConfirm from '../../../../../../../Components/ModalConfirm';
import { useReset } from '../../../../../../../hooks';

const CreateOrEditBankAccount = (props) => {
  const { record, editFavorite, action } = props;
  const { resetList } = useReset();
  let editFavoriteEmpty = null;

  if (Object.keys(editFavorite).length === 0) {
    editFavoriteEmpty = {
      account_type: '',
      account_type_code: ''
    };
  }

  const [banks, setBanks] = useState([]);
  const [accountType, setAccountType] = useState([]);
  const [bankAccount, setBankAccount] = useState(editFavoriteEmpty === null ? editFavorite : editFavoriteEmpty);
  const [openModal, setOpenModal] = useState(false);
  const [ openModalError, setOpenModalError ] = useState(false);
  const [ messageError, setMessageError ] = useState('');
  const { attributes: { iso_code_country } } = record;

  const getBanks = async () => {
    try {
      const iso_code = record.country.attributes.iso_code;
      const resp = await BankProvider.getCountryCodeBanks({iso_code: iso_code});
      setBanks(resp.data.data);
      setAccountType(resp.data.data[0].attributes.account_types_codes);
    } catch (e) { }
  }

  useEffect(() => {
    getBanks();
  }, [record]);

  const changeValue = (e, field) => {
    const name = e.target.name;
    const value = e.target.value;
    let attr = {
      ...bankAccount,
      [name]: e.target.value
    }

    if (name === 'account_type_code' && field) {
      const label = field.find( option => {
        if (value === option.value)
          return option.label;
      }).label;
      attr['account_type'] = label;
    }

    if (name === 'bank_id')
      setAccountType(banks.find( bank => bank.id === value).attributes.account_types_codes);

    setBankAccount(attr)
  }

  const createBankAccount = async (e) => {
    e.preventDefault();
    try {
      bankAccount.user_id = record.id;

      const resp = await BankAccountsProvider.createBankAccount(bankAccount);

      if( resp.data.hasOwnProperty('error') ) {
        setOpenModalError(true);
        setMessageError(`${Object.keys(resp.data.error)[0]}: ${resp.data.error[Object.keys(resp.data.error)[0]]}`);
      } else {
        resetList();
      }
    } catch (e) {
      console.error('ERROR AL CREAR CUENTA', e);
    }
  }

  const updateBankAccount = async (e) => {
    e.preventDefault();
    try {
      const resp = await BankAccountsProvider.updateBankAccount(bankAccount.id, bankAccount);

      if( resp.data.hasOwnProperty('error') ) {
        setOpenModalError(true);
        setMessageError(`${Object.keys(resp.data.error)[0]}: ${resp.data.error[Object.keys(resp.data.error)[0]]}`);
      } else {
        resetList();
      }
    } catch (e) {
      console.error('ERROR AL ACTUALIZAR CUENTA', e);
    }
  }

  const deleteBankAccount = async e => {
    try {
      await BankAccountsProvider.deleteBankAccount(bankAccount.id);
      setOpenModal(false)
      const element = document.querySelector('[aria-label="Refresh"]');
			element.click();
    } catch (e) {
      console.error('ERROR AL ELIMINAR CUENTA', e);
    }
  }

  return ( 
    <form onSubmit={(e) => {action === 'create' ? createBankAccount(e) : updateBankAccount(e)}}>
      <Grid container>
        <Grid item xs={12}><h3>Datos</h3></Grid>
        <Grid item xs={12} sm={4} style={{paddingLeft: 10, paddingRight: 10}}>
          <TextField
            id="bank_id"
            name="bank_id"
            select
            label="Banco"
            required
            fullWidth
            margin="normal"
            value={bankAccount.bank_id ? bankAccount.bank_id.toString() : ''}
            onChange={(e) => changeValue(e)}
          >
            {banks.map(option => (
              <MenuItem
                key={option.id}
                value={option.id}
              >
                {option.attributes.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4} style={{paddingLeft: 10, paddingRight: 10}}>
          <TextField
            id="account_type_code"
            name="account_type_code"
            select
            label="Tipo de cuenta"
            required
            fullWidth
            margin="normal"
            value={bankAccount.account_type_code}
            onChange={(e) => changeValue(e, accountType)}
          >
            {accountType.map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4} style={{paddingLeft: 10, paddingRight: 10}}>
          <TextField
            id="account_number"
            name="account_number"
            label="Número de cuenta"
            type="text"
            required
            fullWidth
            margin="normal"
            value={bankAccount.account_number}
            onChange={(e) => changeValue(e)}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{paddingLeft: 10, paddingRight: 10}}>
          <TextField
            id="alias"
            name="alias"
            label="Alias"
            type="text"
            required
            fullWidth
            margin="normal"
            value={bankAccount.alias}
            onChange={(e) => changeValue(e, 'alias')}
          />
        </Grid>
        { iso_code_country === 'AR' && (
          <>
            <Grid item xs={12} sm={4} style={{paddingLeft: 10, paddingRight: 10}}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="document_type_label">Tipo de documento</InputLabel>
                <Select
                  labelId="document_type_label"
                  id="document_type"
                  name="document_type"
                  value={bankAccount.document_type}
                  onChange={(e) => changeValue(e, 'document_type')}
                >
                  <MenuItem value="cuit">CUIT</MenuItem>
                  <MenuItem value="cuil">CUIL</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} style={{paddingLeft: 10, paddingRight: 10}}>
              <TextField
                id="document_number"
                name="document_number"
                label="Número de documento"
                type="text"
                required
                fullWidth
                margin="normal"
                value={bankAccount.document_number}
                onChange={(e) => changeValue(e, 'document_number')}
              />
            </Grid>
          </>
        )}
        <Grid item xs={10}></Grid>
        <Grid item xs={2}>
          <Button 
            variant="contained"
            color="primary"
            type="submit"
          >
            {action === 'create' ? 'Guardar' : 'Actualizar'}
          </Button>
          {action === 'edit' && <Button 
            variant="contained"
            color="primary"
            onClick={ (e) => { setOpenModal(true) } }>
            Eliminar
          </Button>}
        </Grid>

        <ModalConfirm
          open={openModal}
          setOpen={setOpenModal}
          message='Esta seguro de eliminar esta cuenta?'
          action={deleteBankAccount}
        />

        <ModalConfirm
          open={openModalError}
          setOpen={setOpenModalError}
          message={messageError}
        />
      </Grid>
    </form>
  );
}
 
export default CreateOrEditBankAccount;