import React from 'react';
import {
  Typography, CardContent,
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import moment from 'moment';

import { statusCard, cardType } from '../../../../../Config/constants';

const Cards = (props) => {
  const {
    listCardActive,
    listCardNotActive
  } = props;

  const RenderList = (props) => {
    const { label, listCard } = props;
    if (!listCard || (listCard && listCard.length == 0)) return <></>;

    return (
      <>
        <Typography
          variant="h4"
          gutterBottom
          style={{
            color: "#FFFFFF",
            paddingTop: "20px"
          }}
        >
          {label}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID TARJETA</TableCell>
              <TableCell>TIPO TARJETA</TableCell>
              <TableCell>TIPO USUARIO</TableCell>
              <TableCell>MONEDA</TableCell>
              <TableCell>ESTADO</TableCell>
              <TableCell>FECHA CREACIÓN</TableCell>
              <TableCell>FECHA ELIMINACIÓN</TableCell>
              <TableCell>COMENTARIOS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listCard.map(item => (
              <TableRow key={item.card_id}>
                <TableCell>{item.card_id}</TableCell>
                <TableCell>{cardType[item.card_type]}</TableCell>
                <TableCell>{item.category}</TableCell>
                <TableCell>{item.currency_iso_code}</TableCell>
                <TableCell>{statusCard[item.status]}</TableCell>
                <TableCell>{moment(item.created_at).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{item.updated_at ? moment(item.updated_at).format('DD/MM/YYYY') : '---------'}</TableCell>
                <TableCell>{item.comment}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  };
  return (
    <CardContent>
      <RenderList label={"TARJETAS ACTIVAS"} listCard={listCardActive} />
      <RenderList label={"OTRAS TARJETAS"} listCard={listCardNotActive} />
    </CardContent>
  );
}

export default Cards;