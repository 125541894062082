import React from "react";
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';

import styles from './styles';

const DetailsRecipient = (props) => {
  const {
    isTransaction,
    beneficiaryAmount,
    beneficiary,
    getTotalAmountRecipient,
    goToFilterTransaction,
  } = props;

  return (
    <div style={styles.container}>
      {beneficiary &&
        <Button
          variant="contained"
          color="primary"
          onClick={() => { goToFilterTransaction(beneficiary.document_number) }}
        >
          TRANSACCIONES DEL DESTINATARIO
        </Button>
      }
      {
        !isTransaction && (
          <Button
            onClick={getTotalAmountRecipient}
            style={styles.buttonBlue}
          >
            {
              !beneficiaryAmount
                ? 'TOTAL RECIBIDO POR DESTINATARIO'
                : beneficiaryAmount
            }
          </Button>
        )
      }
    </div>
  );
};

DetailsRecipient.propTypes = {
  isTransaction: PropTypes.bool,
  beneficiaryAmount: PropTypes.object,
  getTotalAmountRecipient: PropTypes.func,
  beneficiary: PropTypes.shape({
    document_number: PropTypes.string,
  }),
  goToFilterTransaction: PropTypes.func,
};


DetailsRecipient.defaultProps = {
  isTransaction: false,
  beneficiaryAmount: {},
  getTotalAmountRecipient: () => { },
  beneficiary: PropTypes.shape({
    document_number: ""
  })
  ,
  goToFilterTransaction: () => { },
};

export default DetailsRecipient;
