const styles = {
  buttonRed: {
    backgroundColor: '#FF0000',
    color: '#FFFFFF',
  },
  buttonGreen: {
    backgroundColor: '#1ade9c',
    color: '#000000',
  },
  buttonBlue: {
    backgroundColor: '#4887de',
    color: '#000000',
  },
  buttonPurple: {
    backgroundColor: '#8F00FF',
    color: '#000000',
  },
  buttonYellow: {
    backgroundColor: '#F1C807',
    color: '#000000',
  },
  buttonOrange: {
    backgroundColor: '#ff7f26',
    color: '#000000',
  },
  separator: {
    padding: 10,
  },
  centerAlert: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    color: 'white'
  },
  centerColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    padding: '2% 4% 2% 4%',
    borderRadius: '4px'
  },
  alertTitle: {
    fontSize: 18,
    textAlign: "center",
    fontWeight: "bold"
  },
  alertBody: {
    fontSize: 14,
    paddingTop: 10,
    paddingBottom: 20,
    textAlign: "center"
  },
  alertButtom: {
    width: 190,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  buttonDisabled: {
    backgroundColor: '#63666b',
    color: '#000000',
  },
  remitent_container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  remitent_flag: {
    fontSize: 24,
    marginRight: 8
  },
  remitent_label: {
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: 'bold'
  },
  styleLink: {
    display: "flex",
    color: '#FFFFFF',
    fontWeight: 700,
    marginBottom: 5,
    justifyContent: 'flex-start',
    fontSize: 14
  },
};

export default styles;