import React, { useMemo, useState, useEffect, useCallback } from "react";
import { CountriesProvider } from "../../../../../Providers";

import useAlerts from "../../../../../hooks/useAlerts";
import useReset from "../../../../../hooks/useReset";

import { NumberInput } from "react-admin";
import { Card, Typography, Grid } from "@material-ui/core";
import styles from "./styles";

const TransactionLimits = (props) => {
  const {
    transfer_daily_limit = 0.0,
    transfer_monthly_limit = 0.0,
    withdrawal_daily_limit = 0.0,
    withdrawal_monthly_limit = 0.0,
    id,
  } = props;
  const { successMessage, errorMessage } = useAlerts();
  const { resetList } = useReset();
  const [limits, setLimits] = useState({
    transfer_daily_limit,
    transfer_monthly_limit,
    withdrawal_daily_limit,
    withdrawal_monthly_limit,
  });

  const valid = useMemo(() => (
      transfer_daily_limit ||
      transfer_monthly_limit ||
      withdrawal_daily_limit ||
      withdrawal_monthly_limit
    ),[
      transfer_daily_limit,
      transfer_monthly_limit,
      withdrawal_daily_limit,
      withdrawal_monthly_limit,
    ]);

  const setInitialState = useCallback((data) => {
    let initialState = {};

    data.forEach((limit) => {
      const {
        attributes: { transaction_type, daily_limit, monthly_limit },
      } = limit;
      if (transaction_type === "transfer") {
        initialState.transfer_daily_limit = daily_limit;
        initialState.transfer_monthly_limit = monthly_limit;
      }
      if (transaction_type === "withdrawal") {
        initialState.withdrawal_daily_limit = daily_limit;
        initialState.withdrawal_monthly_limit = monthly_limit;
      }
    });

    setLimits(initialState);
  }, [setLimits]);

  const handleSetLimits = async (e) => {
    e.preventDefault();
    const data = [
      {
        transaction_type: "transfer",
        daily_limit: transfer_daily_limit,
        monthly_limit: transfer_monthly_limit,
      },
      {
        transaction_type: "withdrawal",
        daily_limit: withdrawal_daily_limit,
        monthly_limit: withdrawal_monthly_limit,
      },
    ];

    try {
      await CountriesProvider.setLimits(id, data);
      resetList();
      successMessage("Límite agregado");
    } catch (error) {
      errorMessage(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await CountriesProvider.getLimits(id);
        setInitialState(response.data.data);
      } catch (error) {
        errorMessage(error);
      }
    })();
  }, [id]);

  return (
    <Card style={styles.container}>
      <Typography style={styles.title}>
        Límites por transferencia
      </Typography>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        style={styles.grid}
      >
        <Grid>
          <NumberInput
            source={"transfer_daily_limit"}
            defaultValue={limits.transfer_daily_limit}
            label={"Límite de monto diario"}
            style={styles.input}
          />
        </Grid>
        <Grid>
          <NumberInput
            source={"transfer_monthly_limit"}
            defaultValue={limits.transfer_monthly_limit}
            label={"Límite de monto mensual"}
            style={styles.input}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        style={styles.grid}
      ></Grid>
      <Typography style={styles.title}>
        Límites por retiro a cuenta propia
      </Typography>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        style={styles.grid}
      >
        <Grid>
          <NumberInput
            source={"withdrawal_daily_limit"}
            defaultValue={limits.withdrawal_daily_limit}
            label={"Límite de monto diario"}
            style={styles.input}
          />
          <Grid>
            <NumberInput
              source={"withdrawal_monthly_limit"}
              defaultValue={limits.withdrawal_monthly_limit}
              label={"Límite de monto mensual"}
              style={styles.input}
            />
          </Grid>
        </Grid>
      </Grid>
      <button
        onClick={(e) => handleSetLimits(e)}
        disabled={!valid}
        style={styles.button}
      >
        Save
      </button>
    </Card>
  );
};

export default TransactionLimits;
