import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styles from './styles';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

function UserStatusLogTable(props) {

  return (
    <Paper style={styles.container}>
      <Typography variant="h4" gutterBottom style={styles.title}>
          Seguimientos de estado
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Acción</TableCell>
            <TableCell numeric>Estado</TableCell>
            <TableCell numeric>Fecha y hora</TableCell>
            <TableCell numeric>Activador de evento</TableCell>
            <TableCell numeric>Descripción o Motivo </TableCell>
            <TableCell numeric>Actualizo datos</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.logs ? props.logs.map(row => {
            return (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.attributes.action}
                </TableCell>
                <TableCell numeric>{row.attributes.status}</TableCell>
                <TableCell numeric>{moment.utc(row.attributes.created_at).format('LLLL')} UTC</TableCell>
                <TableCell numeric>{row.attributes.owner_request}</TableCell>
                <TableCell numeric>{row.attributes.reason_to_change}</TableCell>
                <TableCell numeric>{row.attributes.updated_data_profile ? <CheckBoxIcon style={{ color: '#fff' }} /> : <CheckBoxOutlineBlankIcon style={{ color: '#fff' }} />}</TableCell>
              </TableRow>
            );
          }) : null}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(UserStatusLogTable);
