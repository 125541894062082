import { apiCall }    from '../../Middlewares';
import { ENDPOINTS }  from '../../Config/env';

const deleteBankMovement = async (external_id) =>
    await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BANK_MOVEMENTS.DELETE,
        data: {
            external_id,
        },
    });

const assignBankMovementsRecharge = async (user_id, external_id, amount) =>
    await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BANK_MOVEMENTS.ASSING,
        data: {
            external_id,
            user_id,
            amount,
        },
    });

const lastUpdate = async () =>
    await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BANK_MOVEMENTS.LAST_UPDATE
    });

const assignNewIdentification = async (id, document) =>
    await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.BANK_MOVEMENTS.UPDATE_DOCUMENT,
        data: {
            id,
            document,
        },
    });

export default {
    deleteBankMovement,
    assignBankMovementsRecharge,
    lastUpdate,
    assignNewIdentification,
}