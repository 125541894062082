import { apiCall }    from '../../Middlewares';
import { ENDPOINTS }  from '../../Config/env';

const getProviders = async () => {
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.PROVIDERS.GET,
    });
};

const getProviderBalance = async (provider) => {
    const url = ENDPOINTS.ADMIN.PROVIDERS.GET_PROVIDER_BALANCE
        .url
        .replace(':provider', provider);
        
    return await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.PROVIDERS.GET_PROVIDER_BALANCE,
        url,
    });
};

export default {
    getProviders,
    getProviderBalance,
}