const styles = {
    colorGreen: {
        color: '#00f5ab'
    },
    colorWhite: {
        color: '#FFFFFF'
    },
    PB10: {
        padding: 10,
    }
};

export default styles;
