import { AttachMoney } from '@material-ui/icons';

import FintocList   from './fintocList';
import FintocEdit   from './fintocEdit';

import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
    name: 'fintoc',
    icon: AttachMoney,
    list: FintocList,
    edit: isAccess('assign_fintoc') ? FintocEdit : false,
}