import React from 'react';
import {Datagrid, EditButton, FunctionField, List, TextField, BooleanField} from 'react-admin';

import PricesFilter from '../Filters';
import useAccessRole from "../../../hooks/useAccessRole";
import useModal from "../../../hooks/useModal";
import ModalConfirm from "../../../Components/ModalConfirm";
import PricesProvider from "../../../Providers/Prices";
import {Pagination} from '../../../Components';
import {toMoneyWithDecimals, cryptoCurrencies} from '../../../Helpers/MoneyHelper';
import useReset from '../../../hooks/useReset';
import { FIELDS_EXPORTS_FILES } from '../../utils';
import useExportFiles from '../../../hooks/useExportFiles';

const DeleteButton = (props) => {
    const {open, setOpen, showModal, message, action} = useModal();
    const { resetList } = useReset();

    const deletePrice = async () => {
        try {
            await PricesProvider.deletePriceConfiguration(props.record.id);
            resetList();
        } catch {
            await showModal("Ha ocurrido un error, intente mas tarde", null);
        }
    }

    const deleteHandler = async () => {
        await showModal("¿Desea borrar esta configuración de precios?", deletePrice);
    }

    return (
        <>
            <ModalConfirm
                message={message}
                open={open}
                setOpen={setOpen}
                action={action}
            />
            <div style={{cursor: 'pointer'}} onClick={deleteHandler}>BORRAR</div>
        </>
    );
};

const PricesList = (props) => {
    const {isAccess} = useAccessRole();
    const { exportFilesAttributes } = useExportFiles();

    const exporter = posts => {
        exportFilesAttributes(posts, FIELDS_EXPORTS_FILES.PRICES)
    }

    return (
        <List
            {...props}
            title={'Lista de precios'}
            pagination={<Pagination/>}
            filters={<PricesFilter />}
            bulkActionButtons={false}
            exporter={exporter}
            debounce={1000}
        >
            <Datagrid>
                <TextField
                    source={'id'}
                    label={'Id'}
                />
                <TextField
                    source={'attributes.currency'}
                    label={'Moneda'}
                />
                <FunctionField
                    label={'Destino'}
                    render={record => record.attributes.code.replaceAll(record.attributes.currency.toUpperCase(), "")}
                />
                <TextField
                    label={'Paìs'}
                    source={'attributes.country_name'}
                />
                <BooleanField
                    label={'Actualización del precio spot'}
                    source={'attributes.is_update_spot'}
                />
                <FunctionField
                    label={'Precio spot'}
                    render={record => `${toMoneyWithDecimals(record.attributes.spot, cryptoCurrencies.includes(record.attributes.currency) || cryptoCurrencies.includes(record.attributes.code.replaceAll(record.attributes.currency.toUpperCase(), "").toLowerCase()) ? 12 : 8)} ${record.attributes.target_currency.toUpperCase()}`}
                />
                <FunctionField
                    label={'Porcentaje de error permitido'}
                    render={record => record.attributes.allowable_error_rate ? `${record.attributes.allowable_error_rate} %` : ''}
                />
                <FunctionField
                    label={'Costo fijo para retiros a cuenta propia en moneda origen'}
                    render={record => `${toMoneyWithDecimals(record.attributes.fixed_cost, 2)} ${record.attributes.currency}`}
                />
                <FunctionField
                    label={'Costo fijo para envíos internacionales'}
                    render={record => `${toMoneyWithDecimals(record.attributes.fixed_cost_transfer, 2)} ${record.attributes.target_currency.toUpperCase()}`}
                />
                <FunctionField
                    label={'Costo fijo para envíos internos'}
                    render={record => `${toMoneyWithDecimals(record.attributes.fixed_cost_send, 2)} ${record.attributes.country_currency.toUpperCase()}`}
                />
                <FunctionField
                    label={'Costo fijo para vita card'}
                    render={record => `${toMoneyWithDecimals(record.attributes.fixed_cost_vita_card, 2)} ${record.attributes.country_currency.toUpperCase()}`}
                />
                <FunctionField
                    label={'Spread de venta'}
                    render={record => toMoneyWithDecimals(record.attributes.sell_fee, 2)}
                />
                <FunctionField
                    label={'Spread de venta para envíos internos'}
                    render={record => toMoneyWithDecimals(record.attributes.sell_fee_send, 2)}
                />
                <FunctionField
                    label={'Spread de venta para tarjetas'}
                    render={record => toMoneyWithDecimals(record.attributes.sell_fee_card, 2)}
                />
                <BooleanField
                    label={'Costo fijo en retiros'}
                    source={'attributes.is_apply_fixed_cost_in_withdrawal'}
                />
                <BooleanField
                    label={'Costo fijo en envíos'}
                    source={'attributes.is_apply_fixed_cost_in_sents'}
                />
                <BooleanField
                    label={'Costo fijo en transferencias'}
                    source={'attributes.is_apply_fixed_cost_in_transfers'}
                />
                <BooleanField
                    label={'Costo fijo en vita cards'}
                    source={'attributes.is_apply_fixed_cost_in_vita_card'}
                />
                {isAccess('edit_prices_configuration') && (
                    <EditButton label="Editar" />
                )}
                {isAccess('delete_prices_configuration') && (
                    <DeleteButton label="Borrar" />
                )}
            </Datagrid>
        </List>
    );
};

export default PricesList;
