import DLocalLogo from './dlocal-logo.png';
import VitaLogo from './vita_logo.svg';
import DLocalLogoSvg from './dlocal.svg';
import BusinessLogo from './business.svg';
import vita from './favicon.ico';
import USDC from './USDC.png';
import Send from './send.png';

const Images = {
    DLocalLogo,
    VitaLogo,
    DLocalLogoSvg,
    BusinessLogo,
    vita,
    USDC,
    Send,
};

export default Images;
