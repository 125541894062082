import {Settings} from '@material-ui/icons';

import VitaPricesList from './VitaPricesList';
import VitaPricesEdit from './VitaPricesEdit';
import VitaPricesCreate from './VitaPricesCreate';
import useAccessRole from "../../hooks/useAccessRole";

const {isAccess} = useAccessRole();

export default {
    name: 'vita_prices',
    icon: Settings,
    list: isAccess('show_prices_configuration') ? VitaPricesList : false,
    edit: isAccess('edit_prices_configuration') ? VitaPricesEdit : false,
    create: isAccess('create_prices_configuration') ? VitaPricesCreate : false,
};