import React from 'react';
import moment from 'moment'
import { List, Datagrid, TextField, FunctionField } from 'react-admin';

import DoneAllIcon from '@material-ui/icons/DoneAll';
import BlockIcon from '@material-ui/icons/Block';
import LoginFilter from '../../Filters/LoginFilter';
import { Pagination } from '../../../../Components';

const LoginList = (props) => {
  return (
    <List
      {...props}
      title={'Historial de sesiones'}
      exporter={false}
      filters={<LoginFilter />}
      pagination={<Pagination />}
      bulkActionButtons={false}
      debounce={1000}
    >
      <Datagrid>
        <TextField
          source={'id'}
          label={'Id'}
        />
        <FunctionField
          label={'Id Usuario'}
          render={(record) => {
            return `${record.attributes.user.user_id}`;
          }}
        />
        <FunctionField
          label={'Email'}
          render={(record) => {
            return `${record.attributes.user.email}`;
          }}
        />
        <FunctionField
          label={'Fecha de login'}
          source={'attributes.created_at'}
          render={(record) => {
            return `${moment(record.attributes.created_at).format('DD/MM/YYYY HH:mm:ss')}`;
          }}
        />
        <FunctionField
          label={'Agente de sesión'}
          render={(record) => {
            return `${record.attributes.user_agent}`;
          }}
        />
        <TextField
          source={'attributes.geolocation.ip_address'}
          label={'IP'}
        />
        <TextField
          source={'attributes.geolocation.country_name'}
          label={'País'}
        />
        <TextField
          source={'attributes.geolocation.state_name'}
          label={'Estado'}
        />
        <TextField
          source={'attributes.geolocation.city_name'}
          label={'Ciudad'}
        />
        <FunctionField
          label={'Acceso'}
          source={'attributes.success_login'}
          render={(record) => {
            return record.attributes.success_login ? <DoneAllIcon /> : <BlockIcon />
          }}
        />
      </Datagrid>
    </List>
  );
};

export default LoginList;