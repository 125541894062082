import React            from 'react';
import {
    Filter, SelectInput,
    TextInput,
} from 'react-admin';
import { withStyles } from '@material-ui/core';
import {STATUS} from '../../Transactions/Filters/TransactionFilter/utils';

const styles = {
    helperText: {
        color:' rgba(255, 255, 255, 0.7)',
        margin: '8px 0',
        fontSize: '0.75rem',
        textAlign: 'left',
        lineHeight: '1em'
    },
    dateInput: {
        margin: '8px 0 0 0',
        '&:nth-child(1)' : {
            paddingRight: '5px'
        },
        '&:nth-child(2)' : {
            paddingLeft: '5px'
        },
        '& input[type="date"]::-webkit-calendar-picker-indicator': {
            backgroundColor:' rgb(140, 140, 140)',
            borderRadius: '5px',
            display: 'block'
        }
    }
};

const Filters = (props) => {
    return (
        <Filter {...props}>
            <TextInput
                label={'Id'}
                source={'id'}
                alwaysOn
                helperText={"Id de transacción"}
            />
            <SelectInput
                label={'Estado'}
                source={'status'}
                choices={STATUS}
                helperText={"Estado de operación"}
            />
        </Filter>
    );
};

export default withStyles(styles)(Filters);
