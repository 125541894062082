import React, { useState, useEffect } from 'react';
import { UserProvider } from '../../../../../Providers';
import { PersonalInformation } from '../../../../CardAccounts/CardAccountShow/CustomComponents';
import { CardContent, Typography } from "@material-ui/core";

const UsdNaturalAccount = (props) => {
  const [bridgeAccountInfo, setBridgeAccountInfo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseCA = await UserProvider.getInfoUsdAccount(props.id);
        const attributes = responseCA.data.data.attributes;
        setBridgeAccountInfo(attributes);
      } catch (error) {

      }
    };

    fetchData();
  }, [props.id]);

  return (
    <>
    <CardContent style={{ marginBottom: 30 }}>
    <Typography variant='h4' style={{ color: "white" }}>
      Cuenta en EE.UU.
    </Typography>
      { bridgeAccountInfo &&
       <PersonalInformation
        formType="bridge"
        record={{ attributes: bridgeAccountInfo }}
      />
      }
    </CardContent>
    </>
  );
};

export default UsdNaturalAccount;
