import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  AutocompleteInput,
  FileInput,
  FileField,
  BooleanInput,
} from "react-admin";
import { validateRequired } from "../../../Helpers/ValidateHelper";
import { SEGMENTS_CATEGORY } from "../../../Utils";
import { validateIconCategory } from "../../../Helpers/ValidateHelper";

const CategoryCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list" validate={validateIconCategory}>
        <TextInput
          source="attributes.title"
          label="Título"
          validate={validateRequired}
        />
        <AutocompleteInput
          label={"Segmento"}
          source={"attributes.segment_id"}
          choices={SEGMENTS_CATEGORY}
          optionText={"segment"}
          optionValue={"segment_id"}
          validate={validateRequired}
        />
        <BooleanInput
          label={"Solo Icon"}
          source={"attributes.is_only_icon"}
        />
        <FileInput
          source="attributes.icon"
          label="Carga un ícono"
          placeholder={<p>Arrastra el ícono o haz click aquí. Tamaño máximo 272x272.</p>}
          validate={validateRequired}
        >
          <FileField source="icon" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export default CategoryCreate;
