import React, { useState, useEffect } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Modal,
	Button,
	TextField
} from '@material-ui/core';
import { FailedTransactions } from '../../../../../Providers';

const FailedTransactionList = (props) => {
	const [ failedTransactionList, setFailedTransactionList ] = useState([]);
	const [ failedTransaction, setFailedTransaction ] = useState({});
	const [open, setOpen] = useState(false);
	const { record } = props;
	const location = props.location.slice(-1)

	const openModal = () => setOpen(!open);

	const getFailedTransaction = async (user) => {
		try {
			const resp = await FailedTransactions.getListByUser(user.id);
			
			setFailedTransactionList(resp.data.data.map( (t) => {
				const failedTransaction = t.attributes;
				failedTransaction.id = t.id;
				return failedTransaction;
			}));
		} catch (e) { }
	};
	
	const goToShowTransaction = (t) => {
		setFailedTransaction(t);
		openModal();
	};
	
	useEffect(() => {
		location === '6' && getFailedTransaction(record);
	}, []);

	return (
		<>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>ID</TableCell>
						<TableCell>Receptor</TableCell>
						<TableCell>Categoria</TableCell>
						<TableCell>Error</TableCell>
						<TableCell>Acción</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{
						failedTransactionList.length > 0 ? (
							failedTransactionList.map(t => {
								return (
									<TableRow key={t.id}>
										<TableCell>{t.id}</TableCell>
										<TableCell>{t.recipient_email ? t.recipient_email : 'No se encontro'}</TableCell>
										<TableCell>{t.category}</TableCell>
										<TableCell>{t.failure_motive}</TableCell>
										<TableCell>
											<Button
												variant="contained"
												color="primary"
												style={{ backgroundColor: '#00b7ff' }}
												onClick={ (e) => goToShowTransaction(t) }
											>
												Detalles
											</Button>
										</TableCell>
									</TableRow>
								);
							})
						) : (
							<TableRow>
								<TableCell colSpan={5} style={{textAlign: 'center'}}><strong>SIN TRANSACCIONES FALLIDAS</strong></TableCell>
							</TableRow>
						)
					}
				</TableBody>
			</Table>

			<Modal
					open={open}
					onClose={openModal}
					className="export_modal"
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					disablePortal
					disableEnforceFocus
					disableAutoFocus
			>
					<div className="export_paper">
							<h2 id="server-modal-title" className="text-center">Detalle de la transacción</h2>
							<TextField
								id="recipient_email"
								label="Receptor"
								disabled
								value={ failedTransaction.recipient_email }
							/>
							<TextField
								id="category"
								label="Categoria"
								disabled
								value={ failedTransaction.category }
							/>
							<TextField
								id="country_iso_code"
								label="Código iso del país"
								disabled
								value={ failedTransaction.country_iso_code }
							/>
							<TextField
								id="account_bank"
								label="Cuenta bancaria"
								disabled
								value={ failedTransaction.account_bank }
							/>
							<TextField
								id="amount"
								label="Monto"
								disabled
								value={ failedTransaction.amount }
							/>
							<TextField
								id="total"
								label="Total"
								disabled
								value={ failedTransaction.total }
							/>
							<TextField
								id="currency"
								label="Moneda"
								disabled
								value={ failedTransaction.currency }
							/>
							<TextField
								id="amount_local_currency"
								label="Cantidad en moneda local"
								disabled
								value={ failedTransaction.amount_local_currency }
							/>
							<TextField
								id="total_in_default_currency"
								label="Total en moneda por default"
								disabled
								value={ failedTransaction.total_in_default_currency }
							/>
							<TextField
								id="currency_to_default_currency_price"
								label="Precio de la moneda por default"
								disabled
								value={ failedTransaction.currency_to_default_currency_price }
							/>
							<TextField
								id="default_currency"
								label="Moneda por default"
								disabled
								value={ failedTransaction.default_currency }
							/>
							<TextField
								id="purpose"
								label="Proposito"
								disabled
								value={ failedTransaction.purpose }
								fullWidth
							/>
							<TextField
								id="purpose_comentary"
								label="Comentario"
								disabled
								value={ failedTransaction.purpose_comentary }
								fullWidth
							/>
							<TextField
								id="description"
								label="descripción"
								disabled
								value={ failedTransaction.description }
								fullWidth
							/>
							<TextField
								id="failure_motive"
								label="Error"
								disabled
								value={ failedTransaction.failure_motive }
								fullWidth
							/>
							
							<TextField
								id="source"
								label="Fuente"
								disabled
								value={ failedTransaction.source }
							/>
							<Button 
									className="mx-auto d-block" 
									variant="outlined" 
									color="secondary"
									onClick={openModal}
							>OK</Button>
					</div>
			</Modal>
		</>
	);
}

export default FailedTransactionList;
