import React from "react";

import { Grid, CardContent, Button, InputLabel } from "@material-ui/core";

import { stylesCuponComplete, stylesCuponDetails } from "./styles";

const CuponComplete = (props) => {
  const { history, newCoupon } = props;

  return (
    <Grid container>
      <CardContent style={stylesCuponComplete.cardContainer}>
        <Grid item style={stylesCuponComplete.gridItemEmoji}>
          <InputLabel style={{ fontSize: 30 }}> 😄 </InputLabel>
        </Grid>
        <Grid item>
          <InputLabel style={stylesCuponComplete.textSize}>
            Felicidades se creo tu cupón exitosamente{" "}
          </InputLabel>
        </Grid>
        <Grid item>
          <InputLabel style={stylesCuponComplete.textSize}>
            Puedes volver a crear otro nuevo cupón{" "}
          </InputLabel>
        </Grid>
      </CardContent>

      <Grid container style={stylesCuponComplete.gridContainerButton}>
        <Button
          variant="contained"
          color="primary"
          style={stylesCuponComplete.marginButton}
          onClick={() => history.push("/coupons")}
        >
          <div style={{ color: "#000000" }}>Volver</div>
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={stylesCuponComplete.marginButton}
          onClick={newCoupon}
        >
          <div style={stylesCuponDetails.color}>Crear nuevo Cupon</div>
        </Button>
      </Grid>
    </Grid>
  );
};

export default CuponComplete;
