import { Accessibility } from '@material-ui/icons';

import RolesList from './RolesList';
import RoleShow from './RoleShow';
import RoleEdit from './RoleEdit';
import RoleCreate from './RoleCreate';
import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
    name: 'roles',
    icon: Accessibility,
    list: RolesList,
    show: RoleShow,
    create: isAccess('create_roles') ? RoleCreate : false,
    edit: isAccess('edit_roles') ? RoleEdit : false,
}
