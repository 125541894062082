import React      from 'react';
import PropTypes  from 'prop-types';

import { toMoney } from '../../Helpers/MoneyHelper';

import style  from './styles';

const MoneyTransactionField = (props) => {
    const { record = {}, attribute, isLabel, isCurrency, label, fieldCurrency } = props;
    const { attributes } = record;
    return(
        <div style={style.divisorTop}>
            {
                isLabel ? (
                    <div style={style.title}>
                        {attribute.charAt(0).toUpperCase() + attribute.slice(1)}
                    </div>
                ) : null
            }
            <span style={style.fontWhite}>{
                toMoney(
                    attributes[attribute],
                    attributes.currency
                )
            }</span>
            {isCurrency && <span style={style.fontWhite}>&nbsp;{(fieldCurrency ? fieldCurrency : attributes.currency).toUpperCase()}</span>}
        </div>
    );
};

MoneyTransactionField.propTypes = {
    record: PropTypes.object,
    attribute: PropTypes.string.isRequired,
    isCurrency: PropTypes.bool,
};

MoneyTransactionField.defaultProps = {
    isLabel: true,
    isCurrency: true,
};

export default MoneyTransactionField;
