export const styles = {
  container: {
    backgroundColor: "#242424",
    Height: 816,
    width: 852,
    marginTop: 60,
    position: "relative",
    borderRadius: 10,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 50,
    overflowY: 'auto',
    maxHeight: '90vh',
  },
  buttonClose:{
    position: "absolute",
    top: 5,
    right: 0,
    border: "none",
    backgroundColor: "transparent",
    fontSize: 16,
    color: "#DFDFDF",
  },
  uploadContainerButton:{
    padding: 15,
    backgroundColor: "#333333",
    borderStyle: "dashed",
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 10,
    width: 240,
    textAlign: "center",
  },
  uploadShowImage:{
    fontSize: 12,
    position: "absolute",
    bottom: -45,
    right: 0,
    left: 0,
    borderRadius: 10,
  },
  containerFondo:{
    marginBottom: 10,
    display: "flex",
    marginTop: 10,
  },
  textFondo:{
    display: "flex",
    alignItems: "end",
    fontSize: 16,
    fontWeight: "bold",
    marginRight: 100,
  },
  textBotonDesktop:{
    display: "flex",
    alignItems: "end",
    fontSize: 16,
    fontWeight: "bold",
    marginRight: 30,
  },
  styleTextField:{
    width: 100,
    marginRight: 35,
  },
  textImagen:{
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    fontWeight: "bold",
    marginRight: 80,
  },
  gridItem:{
    display: "flex",
    marginTop: 20,
  },
  containerButtons:{
    display: "flex",
    justifyContent: "center",
    marginBottom: -40,
  },
  titleSesionDivider:{
    backgroundColor: "#565656",
    alignSelf: "end",
    marginBottom: 8,
    marginLeft: 10,
    height: 2,
  },
  titleSesionText:{
    color: "#00BB8D",
    fontSize: 16,
    fontWeight: "bold",
  },
  spanStyles:{
    color: 'white',
    position: 'absolute',
    right: 5,
    top: 24,
  },
  underLineSpan: {
    position: 'absolute', 
    right: 5, 
    top: 51, 
    height: 1, 
    backgroundColor: '#bab4b4', 
    width: 46,
  }
}