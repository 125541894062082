import React from 'react';
import { Filter, TextInput, SelectInput, DateInput } from 'react-admin';

import { Grid, withStyles } from '@material-ui/core';

const GEOLOCATION_TYPE = [
  { id: 'LoginLog', name: 'Sesiones' },
  { id: 'Transaction', name: 'Transacciones' },
];

const styles = {
  helperText: {
    color: ' rgba(255, 255, 255, 0.7)',
    margin: '8px 0',
    fontSize: '0.75rem',
    textAlign: 'left',
    lineHeight: '1em'
  },
  dateInput: {
    margin: '8px 0 0 0',
    '&:nth-child(1)': {
      paddingRight: '5px'
    },
    '&:nth-child(2)': {
      paddingLeft: '5px'
    },
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      backgroundColor: ' rgb(140, 140, 140)',
      borderRadius: '5px',
      display: 'block'
    }
  }
};

const GeolocationFilter = (props) => {
  const { classes } = props;

  return (
    <Filter {...props}>
      <SelectInput
        label={'Geolocalización'}
        source="geolocatable_type"
        choices={GEOLOCATION_TYPE}
        helperText={"Target de ubicación"}
        alwaysOn
      />
      <TextInput
        label={'Buscar'}
        source={'geolocatable_id'}
        helperText={"Busca por id de Usuario o Transacción"}
        alwaysOn
      />
      <Grid
        label="Fecha de ejecución"
        source={'execution_dates'}
      >
        <Grid
          container
        >
          <DateInput
            label={'Desde'}
            source="execution_dates.execution_date_start"
            className={classes.dateInput}
          />
          <DateInput
            label={'Hasta'}
            source="execution_dates.execution_date_end"
            className={classes.dateInput}
          />
        </Grid>
        <p className={classes.helperText}>Fecha de sesíon</p>
      </Grid>
    </Filter>
  );
};

export default withStyles(styles)(GeolocationFilter);
