import React, { useState, useEffect } from "react";
import { List, Datagrid } from "react-admin";
import { Pagination } from "../../../Components";
import { CircleInfo } from "../components";
import CircleProvider from "../../../Providers/Circle";

const CircleWithdrawalList = (props) => {
  const [balances, setBalances] = useState({});

  const getBalancesMasterAdmin = async () => {
    try {
      let balances = await CircleProvider.masterAdminBalances();
      setBalances({
        master: balances.data.message[0],
        admin: balances.data.message[1]
      })

    } catch (error) {}
  };

  useEffect(() => {
    getBalancesMasterAdmin();
  }, []);

  const RenderInfo = (dataGridProps) => {
    return <CircleInfo {...dataGridProps} {...props} />;
  };

  const RenderBalances = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            position: "absolute",
            zIndex: "1",
            textAlign: "center",
            border: "none",
            borderRadius: "4px",
            borderColor: "#fff",
            padding: "7px",
            color: "white",
            marginTop: "35px",
            marginRight: "30px",
            marginLeft: "30px",
          }}
        >
          Balance USDC Circle: {balances.master}
        </div>
        <div
          style={{
            position: "absolute",
            zIndex: "1",
            textAlign: "center",
            border: "none",
            borderRadius: "4px",
            borderColor: "#fff",
            padding: "7px",
            color: "white",
            marginTop: "15px",
            marginRight: "30px",
            marginLeft: "30px",
          }}
        >
          Balance USDC Vita: {balances.admin}
        </div>
      </div>
    );
  };

  return (
    <>
      <RenderBalances />
      <List
        {...props}
        title={"Lista de retiros Circle"}
        pagination={<Pagination />}
        exporter={false}
        bulkActionButtons={false}
        debounce={1000}
      >
        <Datagrid>
          <RenderInfo />
        </Datagrid>
      </List>
    </>
  );
};

export default CircleWithdrawalList;