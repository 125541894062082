import {GET_ONE, UPDATE, CREATE, DELETE} from 'react-admin';

const responseHandler = (response, type, resource) => {
    switch (type) {
        case CREATE: {
            return {
                data: response.data.data,
            }
        }
        case UPDATE: {
            return {
                data: response.data.data,
            }
        }
        case GET_ONE: {
            return {
                data: response.data.configurations.data[0],
            };
        }
        case DELETE: {
            return {
                data: null,
            };
        }
        default: {
            return {
                data: response.data.configurations.data,
                total: response.data['total'],
            };
        }
    }
};

export default responseHandler;
