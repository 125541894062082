export const VERIFICATION = [
    {id: '0', name: 'Sin solicitud'},
    {id: '1', name: 'Solicitud iniciada'},
    {id: '2', name: 'Solicitud verificada'},
    {id: '3', name: 'Solicitud rechazada'},
    {id: '4', name: 'Solicitud incompleta'},
    {id: '5', name: 'Actualización solicitada'},
    {id: '6', name: 'Actualización completada'},
    {id: '7', name: 'Alerta de actualización'},
];

export const CATEGORIES = [
    {id: '0', name: 'Envios'},
    {id: '1', name: 'Recibidos'},
    {id: '2', name: 'Recargas'},
    {id: '3', name: 'Retiros'},
    {id: '4', name: 'Intercambios'},
    {id: '5', name: 'Comisión'},
    {id: '6', name: 'Pagos'},
    {id: '7', name: 'Transferencias internacionales'},
    {id: '9', name: 'Impuestos'},
    {id: '10', name: 'Cupón'},
    {id: '11', name: 'Pago de servicios'},
    {id: '12', name: 'Ajustes'},
    {id: '15', name: 'Tarjetas'},
];

export const USER_CATEGORIES = [
    {id: '0', name: 'Naturales'},
    {id: '1', name: 'Empresas'}
]