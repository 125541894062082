import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useEditGeneralLimit from '../../../hooks/useEditGeneralLimit';
import { USERS_TYPES, USER_TYPES_SELECT } from '../utils';

export const GeneralLimit = (props) => (
    <div style={props.isDisabled ? styles.containerDisabled : styles.container}>
        <TextField
            style={styles.fieldContainer}
            label="País"
            value={props.country_name}
            disabled={props.isEdit}
        />
        <TextField
            style={styles.fieldContainer}
            label="Tipo de usuario"
            value={
                USER_TYPES_SELECT.find(
                    item => item.id === props.user_type
                ).name
            }
            disabled={props.isEdit}
        />
        <TextField
            style={styles.inputContainer}
            label="Monto límite USD"
            value={props.isEdit ? props.amount : props.limit_amount}
            onChange={props.isEdit ? props.changeAmount : null}
            disabled={props.isDisabled}
        />
        <Button 
            style={styles.button} 
            onClick={props.isEdit ? props.onSave : props.onEdit}
            disabled={!props.isUpdate || props.isDisabled}
        >
            { props.isEdit ? 'GUARDAR' : 'EDITAR' }
        </Button>
        {props.isEdit && (
            <Button 
                style={styles.deleteButton} 
                onClick={props.onCancelEdit}
                disabled={props.isDisabled}
            >
                CANCELAR
            </Button>
        )}
        {!props.isEdit && (
            <Button 
                style={styles.deleteButton} 
                onClick={props.onDelete}
                disabled={!props.isDelete || props.isDisabled}
            >
                ELIMINAR
            </Button>
        )}
    </div>
);

GeneralLimit.propTypes = {
    amount: PropTypes.number,
    isDisabled: PropTypes.bool,
    isDelete: PropTypes.bool,
    isUpdate: PropTypes.bool,
    isEdit: PropTypes.bool,
    id: PropTypes.number,
    country_name: PropTypes.string,
    country_id: PropTypes.number,
    user_type: PropTypes.oneOf(USERS_TYPES),
    limit_amount: PropTypes.number,
    onLimitChange: PropTypes.func,
    onCancelEdit: PropTypes.func,
    changeAmount: PropTypes.func,
    onSave: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
};

GeneralLimit.defaultProps = {
    amount: 0,
    isDisabled: false,
    isDelete: false,
    isUpdate: false,
    isEdit: false,
    id: 0,
    country_name: '',
    country_id: 0,
    user_type: 'personal',
    limit_amount: 0,
    onLimitChange: () => {},
    onCancelEdit: () => {},
    changeAmount: () => {},
    onSave: () => {},
    onEdit: () => {},
    onDelete: () => {},
};

const styles = {
    deleteButton: {
        backgroundColor: 'red',
    },
    disabledButton: {
        opacity: 0.3,
    },
    button: {
        backgroundColor: '#009bff',
        width: 100,
    },
    deleteButton: {
        backgroundColor: 'red',
    },
    fieldContainer: {
        width: 170,
    },
    inputContainer: {
        width: 170,
    },
    container: {
        width: 822, 
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'space-between',
        paddingBottom: 10,
    },
    containerDisabled: {
        width: 822, 
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'space-between',
        paddingBottom: 10,
        opacity: 0.35,
    },
};

const GeneralLimitHoc = (props) => {
    const hook = useEditGeneralLimit(props);
    return <GeneralLimit {...hook} />;
};

export default GeneralLimitHoc;