import { Person } from '@material-ui/icons';

import BusinessList     from './BusinessList';
import BusinessesCreate from './BusinessesCreate';
import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
    name: 'negocios',
    icon: Person,
    list: BusinessList,
    create: isAccess('create_business') ? BusinessesCreate : false,
}
