import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  Card,
  TextField,
  CardContent,
  Typography,
  Grid,
  InputLabel,
} from "@material-ui/core";

import styles from "./styles";

const EditLinkPayment = memo((props) => (
  <Modal
    open={props.isOpenModal}
    onClose={props.handleCloseModal}
    style={styles.modalContainer}
  >
    <Card style={styles.cardContainer}>
      <CardContent>
        <Typography
          variant='h2'
          gutterBottom
          style={styles.titleWhite}
          data-testid='Title-proveedor'
        >
          Detalles del proveedor
        </Typography>

        <Grid
          container
          direction='row'
          justify='center'
          alignItems='center'
          style={styles.divisorTop}
        >
          <Grid item style={styles.containerField}>
            <TextField
              type='number'
              value={props.itemUpdate.id}
              name='id'
              label={"Id"}
              InputProps={{
                readOnly: true,
              }}
              data-testid='text-id'
            />
          </Grid>

          <Grid item style={styles.containerField}>
            <TextField
              value={props.itemUpdate.name}
              label={"Nombre"}
              onChange={props.handleOnchange}
              name='name'
              InputProps={{
                readOnly: true,
              }}
              data-testid='text-name'
            />
          </Grid>

          <Grid item style={styles.containerField}>
            <TextField
              type='string'
              value={props.itemUpdate.public_name}
              label={"Nombre Público *"}
              onChange={props.handleOnchange}
              name='public_name'
              InputProps={{
                readOnly: false,
              }}
              data-testid='text-public_name'
            />
          </Grid>

          <Grid item>
            <Grid container style={styles.gridButton}>
              <Button
                variant='contained'
                component='label'
                style={styles.styleButtonImage}
              >
                <Grid container style={styles.girdTextImage}>
                  <Grid item style={styles.gridImage}>
                    <InputLabel style={{ fontSize: 30 }}>🖼</InputLabel>
                  </Grid>
                  <Grid item>Subir imagen *</Grid>
                </Grid>
                <input
                  type='file'
                  name='files'
                  accept='.jpg, .jpeg, .png'
                  hidden
                  onChange={(e) => props.setImage_design(e.target.files)}
                />
              </Button>
              {props.image_design.length > 0 && (
                <Button onClick={props.showImage}>Ver Imagen</Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>

      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
        style={styles.divisorTop}
      >
        <Grid item style={styles.containerField}>
          <Button
            onClick={props.handleCloseModal}
            data-testid='button-Cerrar'
            style={styles.buttonClose}
          >
            Cerrar
          </Button>
        </Grid>
        <Grid item style={styles.containerField}>
          <Button
            onClick={props.handleUpdateProvider}
            style={{ backgroundColor: "#33F5A5" }}
            data-testid='button-Actualizar'
            color='primary'
          >
            Actualizar
          </Button>
        </Grid>
      </Grid>
    </Card>
  </Modal>
));

EditLinkPayment.propTypes = {
  handleCloseModal: PropTypes.func,
  handleOnchange: PropTypes.func,
  handleUpdateProvider: PropTypes.func,
  setImage_design: PropTypes.func,
  isOpenModal: PropTypes.bool,
  image_design: PropTypes.array,
  itemUpdate: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    public_name: PropTypes.string,
  }),
  showImage: PropTypes.func,
};

EditLinkPayment.defaultProps = {
  handleCloseModal: () => {},
  handleOnchange: () => {},
  handleUpdateProvider: () => {},
  setImage_design: () => {},
  isOpenModal: false,
  image_design: [],
  itemUpdate: {
    id: 1,
    name: "",
    public_name: "",
  },
  showImage: () => {},
};

export default EditLinkPayment;
