const useReset = () => {
  const resetList = () => {
    const element = document.querySelector('[aria-label="Refresh"]');
    if(element) element.click();
  };

  return {
    resetList,
    reloadPage: resetList,
  }
};

export default useReset;
