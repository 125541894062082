import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Modal, Button } from '@material-ui/core';
import styles from './styles';

const ModalConfirm = withStyles(styles)((props) => {
  const { open, setOpen, action, message } = props;
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setOpenModal(open)
  }, [open]);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openModal}
    >
      <div style={styles.modal}>
        <Typography variant="title" id="modal-title">
          {message}
        </Typography>
        <br />

        {action ? (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: '#00a6ff' }}
              onClick={(e) => {
                action(e);
                setOpenModal(false);
                setOpen(false);
              }}>
              Si
            </Button>
            <div style={{ width: 20 }} />
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: '#ff0015' }}
              onClick={(e) => { setOpenModal(false); setOpen(false) }}>
              No
            </Button>
          </div>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => { setOpenModal(false); setOpen(false) }}>
            Aceptar
          </Button>
        )}

      </div>
    </Modal>
  );
})

export default ModalConfirm;
