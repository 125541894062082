import React, { useEffect, useRef, useState } from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Swal from 'sweetalert2';
import moment from 'moment';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
  CircularProgress,
  Typography,
  Button,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { BeneficiaryProvider } from '../../../../../Providers';

import styles from './styles';
import useReset from '../../../../../hooks/useReset';
import useAlerts from '../../../../../hooks/useAlerts';

function NeitcomStatusLogTable(props) {
  const {beneficiary_id, verification, provider, title} = props;

  const { resetList } = useReset();
  const { errorMessage } = useAlerts();

  const defaultNeitcomItemsToShow = [10, Math.min()]; // [0] for short, [1] for extended
  const neitcomResults = useRef(verification?.['data']?.['attributes']?.['results'] || []);
  const [neitcomTableMode, setNeitcomTableMode] = useState(0); // 0 for short, 1 for extended
  const [neitcomIsLoading, setNeitcomIsLoading] = useState(false);

  const shortOrExtendNeitcomTable = () => {
    setNeitcomTableMode(1-neitcomTableMode);
  }

  const handleIgnore = async (item_id, nextValue) => {
    try {
      setNeitcomIsLoading(true);
      const response = await BeneficiaryProvider.updateCriminalVerificationItemIgnoreStatus(beneficiary_id, item_id, nextValue);
      response.status === 204 && resetList();
    } catch (error) {
      errorMessage('No fue posible actualizar el estado del elemento');
    } finally {
      setNeitcomIsLoading(false)
    }
  }

  const NeitcomRecordsTable = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Severidad</TableCell>
            <TableCell>A. Ingreso</TableCell>
            <TableCell>Fuente</TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell>CI</TableCell>
            <TableCell>Sanción</TableCell>
            <TableCell>Crimen</TableCell>
            <TableCell>Ignorado</TableCell>
          </TableRow>
        </TableHead>
        { !neitcomIsLoading && <TableBody>
          {neitcomResults.current.length > 0 ? neitcomResults.current.slice(0, defaultNeitcomItemsToShow[neitcomTableMode]).map(row => {
          return (
            <TableRow key={ row.id } selected={ row.is_ignored }>
              <TableCell>{ row.percentage }%</TableCell>
              <TableCell>{ row.entry_date?.split('-')?.[0] }</TableCell>
              <TableCell component="th" scope="row">
                { row.source }
              </TableCell>
              <TableCell>{ row.full_name }</TableCell>
              <TableCell>{ row.ci_number }</TableCell>
              <TableCell>{ row.sanction }</TableCell>
              <TableCell>{ row.crime }</TableCell>
              <TableCell> <Button onClick={()=>handleIgnore(row.id, !row.is_ignored)}>{ row.is_ignored ? 'Retomar' : 'Ignorar'}</Button> </TableCell>
            </TableRow>
          );
        }) : 
        <TableRow>
          <TableCell>No hay nada para mostrar</TableCell>
        </TableRow>}
        </TableBody> }
      </Table>
    )
  }

  const TruoraRecordsTable = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Severidad</TableCell>
            <TableCell>Fuente</TableCell>
            <TableCell>Resultado</TableCell>
            <TableCell>Ignorado</TableCell>
          </TableRow>
        </TableHead>
        { !neitcomIsLoading && <TableBody>
          {neitcomResults.current.length > 0 || verification?.['data']?.['attributes']?.['status'] === 'pending' ? neitcomResults.current.slice(0, defaultNeitcomItemsToShow[neitcomTableMode]).map(row => {
          return (
            <TableRow key={ row.id } selected={ row.is_ignored }>
              <TableCell>{ row.percentage }%</TableCell>
              <TableCell component="th" scope="row">
                { row.source }
              </TableCell>
              <TableCell>{ row.sanction }</TableCell>
              <TableCell> <Button onClick={()=>handleIgnore(row.id, !row.is_ignored)}>{ row.is_ignored ? 'Retomar' : 'Ignorar'}</Button> </TableCell>
            </TableRow>
          );
        }) : 
        <TableRow>
          <TableCell>No hay nada para mostrar</TableCell>
        </TableRow>}
        </TableBody> }
      </Table>
    )
  }

  const requestCriminalVerification = async () => {
    try {
      const response = await Swal.fire({
        title: '¿Estás seguro?',
        text: `¿Quieres solicitar una consulta del beneficiario con ${provider.toUpperCase()}?`,
        icon: 'warning',
        showCancelButton: true,
        background: 'rgb(19,19,19)',
        confirmButtonColor: '#9BA40E',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, Solicitar!',
      })

      if (response.isConfirmed) {
            setNeitcomIsLoading(true);
            let response = await BeneficiaryProvider.requestCriminalVerification(beneficiary_id, provider);
            if(response?.data?.data?.attributes?.status === 'pending'){
              setTimeout(resetList, 15000);
            }else{
              resetList();
            }
          }
    } catch (error) {
      setNeitcomIsLoading(false);
      Swal.fire({
        title: `No fue posible consultar con ${provider.toUpperCase()}`,
        text: error?.data?.message || error.message || 'Intente luego',
        icon: 'error',
        background: 'rgb(19, 19, 19)',
      });
    }
  };

  return (
    <>
      <Typography variant='h4' gutterBottom style={styles.title}>
        {title}
        {
          verification?.data?.attributes?.provider_report_url && (
            <a href={verification?.data?.attributes?.provider_report_url || '#'} rel="noopener noreferrer" target="_blank">
              <OpenInNewIcon
                style={{ color: 'white', fontSize: 13, verticalAlign: 'top' }}
              />
            </a>
          )
        }
      </Typography>
      <div style={styles.container_header}>
      <Typography variant='h4' gutterBottom style={styles.title}>
          <strong>Estado:</strong>
          {verification?.['data']?.['attributes']?.['status'] === 'successful' && ' Exitosa'}
          {verification?.['data']?.['attributes']?.['status'] === 'failed' && ' Fallida'}
          {verification?.['data']?.['attributes']?.['status'] === 'pending' && ' Pendiente'}
          {verification?.['data']?.['attributes']?.['status'] == null && ' /'}
        </Typography>
        <Typography variant='h4' gutterBottom style={styles.title}>
          <strong>Fecha:</strong>
          {(verification?.['data']?.['attributes'] ? ` ${moment.utc(verification?.['data']['attributes']['created_at']).format('DD/MM/YYYY HH:mm:ss')} UTC` : ' /')}
        </Typography>
        <Typography variant='h4' gutterBottom style={styles.title}>
          <strong>Origen:</strong>
          {verification?.['data']?.['attributes']?.['generation_way'] === 'requested_by_operator' && ' Solicitado por un operador'}
          {verification?.['data']?.['attributes']?.['generation_way'] === 'periodic_verification' && ' Verificación recurrente'}
          {verification?.['data']?.['attributes']?.['generation_way'] === 'verification_flow' && ' Por solicitud de verificación'}
          {verification?.['data']?.['attributes']?.['generation_way'] == null && ' /'}
        </Typography>
        <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#242424", width: '10rem' }}
            onClick={requestCriminalVerification}
            disabled={neitcomIsLoading}
          >
            { !neitcomIsLoading && 'Consultar'}
            { neitcomIsLoading && <CircularProgress size={20} color={'secondary'}/> }
        </Button>
      </div>
      <Paper style={styles.container}>
        { provider === 'neitcom' && <NeitcomRecordsTable />}
        { provider === 'truora' && <TruoraRecordsTable />}
        { neitcomIsLoading && <LinearProgress size={100} color={'secondary'}/> }
      </Paper>
      {neitcomResults.current.length>defaultNeitcomItemsToShow[0] && <div style={styles.container}>
        <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#242424" }}
            onClick={shortOrExtendNeitcomTable}
          >
            Mostrar 
             { neitcomTableMode===0 && ' más' }
             { neitcomTableMode===1 && ' menos' }
        </Button>
      </div>}
    </>
  );
}

export default withStyles(styles)(NeitcomStatusLogTable);
