import {UPDATE} from 'react-admin';

import {API_URL} from '../../../../Config/env';

const requestHandler = (type, params) => {
  switch (type) {
    case UPDATE: {
      return {
        url: `${API_URL}/admin/beneficiaries/${params.id}`,
        method: 'put',
        data: {...params.data.attributes},
      };
    }
    default: {
      return {

      }
    }
  }
};

export default requestHandler;
