import { Accessibility } from '@material-ui/icons';

import RolesList from './AdminList';
import AdminShow from './AdminShow';
import AdminEdit from './AdminEdit';
import AdminCreate from './AdminCreate';
import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
    name: 'administrators',
    icon: Accessibility,
    list: RolesList,
    show: AdminShow,
    edit: isAccess('edit_admin') ? AdminEdit : false,
    create: isAccess('create_admin') ? AdminCreate : false,
}
