import {AttachMoney} from '@material-ui/icons';

import TransactionList from './BusinessTransactionList';
import useAccessRole from "../../hooks/useAccessRole";

const {isAccess} = useAccessRole();
export default {
    name: 'business_transactions',
    icon: AttachMoney,
    list: isAccess('show_business_transactions') ? TransactionList : false,
};
