import React                    from 'react';
import { SaveButton, Toolbar }  from 'react-admin';

const PostEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export default PostEditToolbar;
