import React                                        from 'react';
import { List, Datagrid, TextField, FunctionField } from 'react-admin';
import moment from 'moment';

import useAccessRole from '../../../hooks/useAccessRole';
import { statusPayinLabel } from '../../../Config/constants';
import { Pagination } from '../../../Components';
import { toMoney }    from '../../../Helpers/MoneyHelper';
import { Filters } from '../components';

import { InformationDlocal } from './components';
import { FIELDS_EXPORTS_FILES } from '../../utils';
import useExportFiles from '../../../hooks/useExportFiles';

const DlocalList = (props) => {
    const {isAccess} = useAccessRole();
    const { exportFilesSimple } = useExportFiles();

    const exporter = posts => {
        exportFilesSimple(posts, FIELDS_EXPORTS_FILES.DLOCAL)
    }

    return (
        <List
            {...props}
            title={'Lista de Payin y Payout hechos a Dlocal'}
            bulkActionButtons={false}
            exporter={isAccess('download_dlocal') && exporter}
            pagination={<Pagination />}
            filters={<Filters />}
            debounce={1000}
        >
            <Datagrid>
                <TextField
                    source={'id'}
                    label={'Id'}
                />
                <FunctionField
                    label={'Creación'}
                    render={(record) => {
                        moment.locale('es');
                        const date = moment.utc(record.created_at).format('LL');
                        return `${date}`;
                    }}
                />
                <FunctionField
                    label={'Tipo de pago'}
                    render={(record) => {
                        const { is_payin } = record;
                        return is_payin ? 'PayIn' : 'PayOut'
                    }}
                />
                <InformationDlocal {...props} />
                <FunctionField
                    label={'Actualización de estado del pago'}
                    render={(record) => {
                        moment.locale('es');
                        const date = record.last_status_update_date ? moment(record.last_status_update_date).format('LL') : '';
                        return `${date}`;
                    }}
                />
                <FunctionField
                    label={'Estado'}
                    render={(record) => {
                        const { status } = record;
                        return statusPayinLabel[status];
                    }}
                />
                <TextField
                    source={'status_code'}
                    label={'Código'}
                />
                <TextField
                    source={'status_reason'}
                    label={'Razón de rechazo'}
                />
                <FunctionField
                    label={'Monto'}
                    render={(record) => {
                        const { amount, currency } = record;
                        return amount ?
                            `${toMoney(amount, 'CLP')} ${currency}` :
                            '';
                    }}
                />
            </Datagrid>
        </List>
    )
};

export default DlocalList;
