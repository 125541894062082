import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { Datagrid, FunctionField, List, TextField, Filter, BooleanInput, BulkDeleteButton } from 'react-admin';
import moment from "moment";
import { toMoney } from "../../../Helpers/MoneyHelper";
import useAccessRole from '../../../hooks/useAccessRole';
import FintocProvider from '../../../Providers/Fintoc';

// import useModal from '../../../hooks/useModal';
// import ModalExport from '../../../Components/ModalExport';

const FintocList = (props) => {
	const [updateDate, setUpdateDate] = useState("");
	const { isAccess } = useAccessRole();
	// const { open, showModal, hiddeModal } = useModal();
	
	const lastUpdate = async () => {
		let date = await FintocProvider.lastUpdate();
		setUpdateDate(`Última actualización: ${date.refreshed_at}`);		
	}

	useEffect(() => {
		lastUpdate();
	}, [])

	const { hasEdit } = props;

	const AssignedButton = (recordProps) => {
		const { record } = recordProps;
		const { history } = props;

		return record.hasOwnProperty('status') && record.status === 'unassigned' && record.fintoc_type !== 'other' ? (
			<Button size="small" style={{ backgroundColor: '#0090ff' }} onClick={() => {
				history.push(`/fintoc/${record.id}`);
			}}>
				ASIGNAR
			</Button>
		) : null;
	};

	return (
		<>
			<div 
				style={{
					color: "white", 
					position: "absolute",
					zIndex: "1", 
					textAlign: "center",
					width: "100%",
					marginTop: "15px"
				}}>
					{updateDate}
			</div>
			<List
				{...props}
				title={'Fintoc'}
				exporter={isAccess('download_fintoc') ? () => {} : false}
				bulkActionButtons={isAccess('delete_fintoc') ? <BulkDeleteButton {...props} /> : false}
				debounce={1000}
				filters={
					<Filter>
						<BooleanInput
							label={'Transacciones sin asignación'}
							source={'is_fintoc_unassigned'}
							alwaysOn
						/>
					</Filter>
				}
			>
				<Datagrid>
					<TextField
						source={'fintoc_id'}
						label={'Id'}
					/>
					<FunctionField
						label={'Creación'}
						render={(record) => {
							moment.locale('es');
							const date = moment.utc(record.post_date).format('LL');
							return `${date}`;
						}}
					/>
					<TextField
						source={'fintoc_type'}
						label={'Tipo'}
					/>
					<TextField
						source={'reference_id'}
						label={'Referencia'}
					/>
					<TextField
						source={'sender_account.holder_id'}
						label={'Identificación del titular'}
					/>
					<TextField
						source={'sender_account.holder_name'}
						label={'Nombre del titular'}
					/>
					<FunctionField
						label={'Monto'}
						render={(record) => {
							return `${toMoney(
								record.amount,
								record.currency
							)}`;
						}}
					/>
					<TextField
						source={'currency'}
						label={'Moneda'}
					/>
					<TextField
						source={'description'}
						label={'Descripción'}
					/>
					{hasEdit ? <AssignedButton /> : null}
				</Datagrid>
			</List>
			{/* <ModalExport
				open={open}
				hiddeModal={hiddeModal}
			/> */}
		</>
	);
};

export default FintocList;