import { UPDATE, CREATE } from "react-admin";

import { API_URL } from "../../../../Config/env";

const requestHandler = (type, params) => {
  switch (type) {
    case UPDATE: {
      let {
        id,
        attributes: { quantity_decimals, is_available, is_crypto },
      } = params.data;

      let formData = new FormData();

      formData.append("id", id);
      formData.append("quantity_decimals", quantity_decimals);
      formData.append("is_available", is_available);
      formData.append("is_crypto", is_crypto);

      return {
        url: `${API_URL}/admin/currencies/${id}`,
        method: "put",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
    }
    case CREATE: {
      const {
        data: {
          attributes: { singular_name, plural_name, quantity_decimals, is_crypto, iso_code },
        },
      } = params;

      let formData = new FormData();

      formData.append("singular_name", singular_name);
      formData.append("plural_name", plural_name);
      formData.append("quantity_decimals", quantity_decimals);
      formData.append("is_crypto", is_crypto);
      formData.append("iso_code", iso_code);

      return {
        url: `${API_URL}/admin/currencies`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
    }
  }
};

export default requestHandler;
