const styles = {
    containerButton: {
        width: '100%',
        display: "flex",
        justifyContent: "center"
    },
    saveButton: {
        backgroundColor: "#00F5A5",
        color: "white",
        border: "none",
        marginTop: "10px",
        borderRadius: "4px",
        borderColor: "#fff",
        padding: "15px",
        cursor: "pointer",
    }
};

export default styles;
