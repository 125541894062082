const styles = {
  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "auto",
  },
  cardContainer: {
    width: "100%",
    backgroundColor: "#727272",
    padding: "1rem",
    maxWidth: 400,
    maxHeight: "400",
  },
  divisorTop: {
    paddingTop: 30,
  },
  titleWhite: {
    color: "#FFFFFF",
    textAlign: "center",
  },
  containerField: {
    padding: 5,
    width: "60%",
  },
  containerButtons: {
    marginTop: 20,
    marginBottom: 20,
    overflow: "auto",
  },
  gridButton: {
    display: "grid",
    marginTop: 20,
  },
  styleButtonImage: {
    padding: 50,
    backgroundColor: "transparent",
    borderStyle: "dotted",
    borderColor: "white",
    borderWidth: 1,
  },
  gridImage: {
    marginBottom: 15,
    justifyContent: "center",
    display: "flex",
  },
  girdTextImage: {
    display: "grid",
    textAlign: "center",
  },
  buttonClose: {
    backgroundColor: "red",
    color: "black",
  },
  text_aux: {
    marginBottom: 10,
  },
};

export default styles;
