export const COLUNMS_PROVIDERS_ACTIVES = [
  'País',
  'Proveedor',
  'Costo fijo (USD)',
  'Spread',
  'Acciones',
];

export const COLUNMS_LIST_LINKS = [
  'Fecha creación',
  'Fecha expiración',
  'Monto',
  'Estado',
  'Enlace',
  'link',
];

export const PREFERENTIAL_TYPES = [
  {id: 0, name: "price"},
  {id: 1, name: "exchange"},
  {id: 2, name: "fixed_cost_transfer"},
];

export const MAP_PREFERENTIAL_TYPES_TO_NAMES = {
  price: "Spread Payout",
  exchange: "Spread Exchange",
  fixed_cost_transfer: "Costo Fijo Payout"
};

export const MAP_NAMES_TO_PREFERENTIAL_TYPES = {
  "Spread Payout": "price",
  "Spread Exchange": "exchange",
  "Costo Fijo Payout": "fixed_cost_transfer"
};