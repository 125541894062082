const styles = {
    separator: {
        height: 20,
    },
    title: {
        fontSize: 13,
        paddingBottom: 10,
    },
    container: {
        padding: 20,
    },
    buttonBlue: {
        backgroundColor: '#4887de',
        color: '#000000',
    },
};

export default styles;
