import { useEffect, useMemo, useRef, useState } from "react";
import Swal from "sweetalert2";

import { CountriesProvider, Rest } from "../../../../Providers";
import {
  ALL_COUNTRIES,
  STATUS_GENERAL,
  STATUS_ROUTES,
  styleAlert,
} from "../../../../Components/Comunications/utils";

const useSessionBanner = (props) => {
  const {
    location: { pathname },
    handleCloseModal= () => {},
  } = props;

  const [countriesList, setCountriesList] = useState([])
  const [optionsCountries, setOptionsCountries] = useState([]);
  const [allCountries, setAllCountries] = useState(ALL_COUNTRIES);
  const [statusRoute, setStatusRoute] = useState(STATUS_ROUTES);
  const [statusGeneral, setStatusGeneral] = useState(STATUS_GENERAL);
  const [selectedOptionRoute, setSelectedOptionRoute] = useState("");
  const [selectedOptionTypeClient, setselectedOptionTypeClient] = useState("");
  const [urlOptionRoute, setUrlOptionRoute] = useState("");
  const [imageDesktop, setImageDesktop] = useState([]);
  const [imageMobile, setImageMobile] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [counterCaracter, setCounterCaracter] = useState({
    textBanner: 0,
    btText: 0,
  });
  const [isShowBannerdetailes, setIsShowBannerdetailes] = useState(false);
  const [sceneShowBanner, setsceneShowBanner] = useState('');
  const [timeTransition, setTimeTransition] = useState(0);

  const isRequestSent = useRef(false)
  const isUpdateScreen = useMemo(() => pathname?.includes("edit"), [pathname]);
  const validateFields = useMemo(
    () =>
      statusGeneral.identifier &&
      statusGeneral.textBanner &&
      statusGeneral.fontColor1 &&
      statusGeneral.fontColor2 &&
      statusGeneral.btColorBg &&
      statusGeneral.btColorText &&
      statusGeneral.btText &&
      (allCountries.checked ||
        optionsCountries.filter((option) => option.checked).length) &&
      (selectedOptionRoute || urlOptionRoute) &&
      imageDesktop.length &&
      selectedOptionTypeClient &&
      timeTransition &&
      imageMobile.length,
    [
      statusGeneral,
      allCountries,
      optionsCountries,
      selectedOptionRoute,
      urlOptionRoute,
      imageDesktop,
      imageMobile,
      selectedOptionTypeClient,
      isUpdateScreen,
      timeTransition,
    ]
  );

  const handleCheckboxChangeCountries = (iso_code) =>
    setOptionsCountries((prevOptions) =>
      prevOptions.map((option) =>
        option.iso_code === iso_code ? { ...option, checked: !option.checked } : option
      )
    );

  const handleOnChangeAllCountry = () => {
    setAllCountries((prevAllCountries) => ({
      ...prevAllCountries,
      checked: !prevAllCountries.checked,
    }));
    setOptionsCountries(countriesList);
  };

  const handleCheckboxChange = (id) =>
    setStatusRoute((prevOptions) =>
      prevOptions.map((option) =>
        option.id === id
          ? option.checked
            ? {...option}
            : { ...option, checked: !option.checked }
          : {...option, checked: false}
      )
    );

  const handleOnChangeStatusGeneral = (event) =>
    setStatusGeneral(
      (prevState) =>
        (prevState = {
          ...prevState,
          [event.target.name]: event.target.value,
        })
    );

  const handleOptionChangeRoute = (event) =>
    setSelectedOptionRoute(event.target.value);

  const handleOptionChangeTypeUser = (event) =>
    setselectedOptionTypeClient(event.target.value);

  const handleChangeTimeTransition = (event) =>
    setTimeTransition(event.target.value);

  const handleOnChangeImageDesktop = (event) => setImageDesktop(event);

  const handleOnChangeImageMobile = (event) => setImageMobile(event);

  const handleOnChangeUrlOptionRoute = (event) =>
    setUrlOptionRoute(event.target.value);

  const showImage = (name) => {
    setsceneShowBanner(name);
    setIsShowBannerdetailes(true);
  };

  const hiddenImageDetails = () => {
    setIsShowBannerdetailes(false);
    setsceneShowBanner('');
  };

  const createBanner = async () => {
    if (isRequestSent.current) return
    isRequestSent.current = true
    const data = {
      ...statusGeneral,
      imageDesktop: imageDesktop,
      imageMobile: imageMobile,
      typeRoute: statusRoute[0].checked ? "internal" : "external",
      urlRoute: statusRoute[0].checked ? selectedOptionRoute : urlOptionRoute,
      isGlobal: allCountries.checked ? true : false,
      isActive: false,
      bannersCountries: allCountries.checked
        ? []
        : optionsCountries.filter((option) => option.checked),
      typeUser: selectedOptionTypeClient,
      timeTransition,
    };
    try {
      const response = await Rest("CREATE", "banners", data);
      if (response.data) {
        handleCloseModal();
        await Swal.fire({
          ...styleAlert,
          title: '<span style="color: white;">¡Banner creado!</span>',
        });
        window.location.reload();
      }
    } catch (error) {
      if (error.data.error?.identifier) {
        Swal.fire({
          ...styleAlert,
          title: '<span style="color: white;">Identificador repetido</span>',
        });
      } else {
        Swal.fire({
          ...styleAlert,
          title: '<span style="color: white;">Error creando banner</span>',
        });
      }
    }
  };

  const updateBanner = async () => {
    const data = {
      ...statusGeneral,
      imageDesktop: typeof imageDesktop !== "string" && imageDesktop,
      imageMobile: typeof imageMobile !== "string" && imageMobile,
      typeRoute: statusRoute[0].checked ? "internal" : "external",
      urlRoute: statusRoute[0].checked ? selectedOptionRoute : urlOptionRoute,
      id: pathname.split("/")[2],
      typeUser: selectedOptionTypeClient,
      timeTransition,
    };

    try {
      const response = await Rest("UPDATE", "banners", data);

      if (response.data) {
        handleCloseModal();
        await Swal.fire({
          ...styleAlert,
          title: '<span style="color: white;">Cambios guardados</span>',
        });

        window.location.reload();
      }
    } catch {
      Swal.fire({
        ...styleAlert,
        title: '<span style="color: white;">Error guardando cambios</span>',
      });
    }
  };

  const restarData = () => {
    setAllCountries(ALL_COUNTRIES);
    setStatusRoute(STATUS_ROUTES);
    setStatusGeneral(STATUS_GENERAL);
    setSelectedOptionRoute("");
    setUrlOptionRoute("");
    setImageDesktop([]);
    setImageMobile([]);
    setIsEdit(false);
  };

  const getCountries = async () => {
    try {
      let arrayCountries = [];
      let response = await CountriesProvider.fetchCountriesAdmin(true);

      if (response.data) {
        response.data.countries.data.forEach((country) => {
          if (country.attributes.is_register) {
            arrayCountries.push(
              {
                iso_code: country.attributes.iso_code,
                name: country.attributes.name,
                checked: false,
              }
            )
          }
        });
      }
      return arrayCountries;
    } catch { }
  };

  useEffect(() => {
    (async () => {
      try {
        if (isUpdateScreen) {
          const id = pathname.split("/")[2];
          const response = await Rest("GET_ONE", "banners", { id });

          if (response.data.attributes) {
            const {
              data: {
                attributes: {
                  identifier,
                  text_banner,
                  background_color_1,
                  background_color_2,
                  bt_backgroud_color,
                  bt_text_color,
                  bt_text,
                  type_route,
                  url_route,
                  is_global,
                  list_countries,
                  image_desktop,
                  image_mobile,
                  type_user,
                  time_transition,
                },
              },
            } = response;

            setStatusGeneral({
              identifier,
              textBanner: text_banner,
              fontColor1: background_color_1,
              fontColor2: background_color_2,
              btColorBg: bt_backgroud_color,
              btColorText: bt_text_color,
              btText: bt_text,
            });

            setStatusRoute(
              STATUS_ROUTES.map((route) =>
                route.id.toLocaleLowerCase() === type_route
                  ? { ...route, checked: true }
                  : route
              )
            );

            type_route === "external"
              ? setUrlOptionRoute(url_route)
              : setSelectedOptionRoute(url_route);

            let listCountriesAPI = await getCountries();
            is_global && setOptionsCountries(listCountriesAPI);

            const listCountries = JSON.parse(list_countries);

            listCountries.forEach((item) => {
              const matchedItem = listCountriesAPI.find(
                (checkItem) => checkItem.iso_code === item.iso_code
              );
              if (matchedItem) {
                matchedItem.checked = true;
              }
              return item;
            });

            is_global
              ? setAllCountries({ ...ALL_COUNTRIES, checked: true })
              : setOptionsCountries(listCountriesAPI);

            image_desktop && setImageDesktop(image_desktop);
            image_mobile && setImageMobile(image_mobile);
            setselectedOptionTypeClient(type_user);
            setTimeTransition(time_transition);

            setIsEdit(true);
          }
        }
      } catch{}
    })();
    return () => {
      restarData();
    };
  }, [pathname, isUpdateScreen]);

  useEffect(() => {
    if (!statusRoute[0].checked) {
      setSelectedOptionRoute("");
    }

    if (!statusRoute[1].checked) {
      setUrlOptionRoute("");
    }
  }, [statusRoute]);

  useEffect(() => {
    setCounterCaracter(
      (prevState) =>
        (prevState = {
          textBanner: statusGeneral.textBanner.length,
          btText: statusGeneral.btText.length,
        })
    );
  }, [statusGeneral]);

  useEffect(() => {
    isRequestSent.current = false
  }, [statusGeneral.identifier])

  useEffect(() => {
    if (!isUpdateScreen) {
      (async () => {
        const data = await getCountries();
        setOptionsCountries(data);
        setCountriesList(data);
      })();
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    handleCheckboxChange,
    handleOptionChangeRoute,
    handleOptionChangeTypeUser,
    handleChangeTimeTransition,
    handleOnChangeUrlOptionRoute,
    handleOnChangeStatusGeneral,
    handleOnChangeImageDesktop,
    handleOnChangeImageMobile,
    handleCheckboxChangeCountries,
    handleOnChangeAllCountry,
    showImage,
    createBanner,
    updateBanner,
    selectedOptionRoute,
    selectedOptionTypeClient,
    timeTransition,
    statusRoute,
    statusGeneral,
    imageDesktop,
    imageMobile,
    allCountries,
    optionsCountries,
    urlOptionRoute,
    isEdit,
    counterCaracter,
    validateFields,
    isShowBannerdetailes,
    sceneShowBanner,
    hiddenImageDetails,
  };
};

export default useSessionBanner;
