import { RolProvider } from '../Providers';

const getRoles = async () => {
  try {
    const response = await RolProvider.getListRoles();

    if (response.data) {
      return response.data;
    } else {
    }
  } catch (error) {
  }
}

export default {
  getRoles
}