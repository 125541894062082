const styles = {
  container: {
    width: "100%",
    marginTop: 50,
  },
  text_aux: {
    position: "absolute",
    top: -30,
  },
  divisor: {
    padding: 5,
  },
  titleWhite: {
    color: "#FFFFFF",
    marginLeft: -8,
    fontSize: 20,
  },
  url: {
    width: "100%",
    paddingTop: 16,
    marginTop: 20,
  },
  image: {
    borderRadius: 0,
    width: "100%",
    height: "100%",
  },
};

export default styles;
