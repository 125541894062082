const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        padding: 30,
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 10,
        paddingBottom: 10,
    },
    delete_button: {
        backgroundColor: '#FF0000',
    },
    cancel_button: {
        backgroundColor: '#000000',
        marginLeft: 20,
    },
    search_button: {
        backgroundColor: '#00ccff',
    },
    user_data: {
        color: '#FFFFFF',
    },
    buttons_content: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    subtitle: {
        color: '#bdbdbd',
        fontsize: 12,
    },
    info: {
        color: '#FFFFFF',
        paddingTop: 5,
    },
    title: {
        color: '#FFFFFF',
        fontSize: 18,
        paddingTop: 5,
        paddingBottom: 20,
    },
};

export default styles;