import React from "react";
import { Container, Row, Col } from "react-grid-system";
import {
  Edit,
  FormTab,
  TabbedForm,
  TextInput,
} from "react-admin";

import styles from "./styles";

const BeneficiaryEdit = (props) => {
  return (
    <Edit title={`Editar beneficiario ${props.id}`} {...props}>
      <TabbedForm
        className='tabbed-form-custom'
      >
        <FormTab
          label="Datos Personales"
          style={styles.titleWhite}
        >
          <Container style={{ width: "100%" }}>
            <Row
              container
              direction="row"
              justify="center"
              alignItems="center"
              className={{ flexGrow: 1 }}
            >
              <Col
                xs={5}
                style={{
                  ...styles.divisor,
                  display: "flex",
                  textAlign: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                
                <TextInput
                  label={"Nombre"}
                  source={"attributes.first_name"}
                />
              </Col>
              <Col
                xs={5}
                style={{
                  ...styles.divisor,
                  display: "flex",
                  textAlign: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <TextInput
                  label={"Apellidos"}
                  source={"attributes.last_name"}
                />
              </Col>
              <Col
                xs={5}
                style={{
                  ...styles.divisor,
                  display: "flex",
                  textAlign: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <TextInput
                  label={"Nombre de la empresa"}
                  source={"attributes.company_name"}
                />
              </Col>
              <Col
                xs={5}
                style={{
                  ...styles.divisor,
                  display: "flex",
                  textAlign: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <TextInput
                  label={"Tipo de documento"}
                  source={"attributes.document_type"}
                  disabled={true}
                />
              </Col>
              <Col
                xs={5}
                style={{
                  ...styles.divisor,
                  display: "flex",
                  textAlign: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <TextInput
                  label={"Número de documento"}
                  source={"attributes.document_number"}
                  disabled={true}
                />
              </Col>
              <Col
                xs={5}
                style={{
                  ...styles.divisor,
                  display: "flex",
                  textAlign: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <TextInput
                  label={"Correo"}
                  source={"attributes.email"}
                />
              </Col>
              <Col
                xs={5}
                style={{
                  ...styles.divisor,
                  display: "flex",
                  textAlign: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <TextInput
                  label={"Tipo de beneficiario"}
                  source={"attributes.beneficiary_type"}
                  disabled={true}
                />
              </Col>
              <Col
                xs={5}
                style={{
                  ...styles.divisor,
                  display: "flex",
                  textAlign: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <TextInput
                  label={"País"}
                  source={"attributes.country.name"}
                  disabled={true}
                />
              </Col>
              <Col
                xs={5}
                style={{
                  ...styles.divisor,
                  display: "flex",
                  textAlign: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <TextInput
                  label={"Ciudad"}
                  source={"attributes.city"}
                />
              </Col>
              <Col
                xs={5}
                style={{
                  ...styles.divisor,
                  display: "flex",
                  textAlign: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <TextInput
                  label={"direción"}
                  source={"attributes.address"}
                />
              </Col>
              <Col
                xs={5}
                style={{
                  ...styles.divisor,
                  display: "flex",
                  textAlign: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <TextInput
                  label={"Teléfono"}
                  source={"attributes.phone"}
                />
              </Col>
            </Row>
          </Container>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default BeneficiaryEdit;