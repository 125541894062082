import React from "react";
import moment from "moment";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  UrlField,
} from "react-admin";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { TRANSACTIONS_STATUS, TRANSACTIONS_OPERATION } from "../../../Utils";
import Filters from "../Filters";
import { FIELDS_EXPORTS_FILES } from "../../utils";
import useExportFiles from "../../../hooks/useExportFiles";

const TransactionsStatusList = (props) => {
  const { exportFileTransactionStatus } = useExportFiles();

  const exporter = posts => {
    exportFileTransactionStatus(posts, FIELDS_EXPORTS_FILES.TRANSACTIONS_STATUS)
  }

  const AssocitatedState = (renderRecord) => {
    const { 
        unique,
        attributes: {
            history_paid_services
        }
    } = renderRecord.record;

    if (unique && history_paid_services) {
      return (
        <>
          <Grid
            item
            style={{textAlign: "center"}}
          >
            Estados del pago
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align={"right"}>Id</TableCell>
                <TableCell align={"right"}>Estado</TableCell>
                <TableCell align={"right"}>Fecha</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history_paid_services.map((service) => {
                const {
                  id,
                  status_code,
                  created_at,
                } = service;

                return (
                  <TableRow>
                    <TableCell align={"right"}>{id}</TableCell>
                    <TableCell align={"right"}>{status_code === 'confirmed' ? 'Completado' : 'Pendiente'}</TableCell>
                    <TableCell align={"right"}>{`${moment.utc(created_at).format("DD/MM/YYYY HH:mm:ss")} UTC`}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      );
    }
    return <></>;
  };

  return (
    <List
      {...props}
      title={"Historial de estados de una transacción"}
      filters={<Filters />}
      bulkActionButtons={false}
      exporter={exporter}
      debounce={1000}
    >
      <Datagrid>
        <TextField source={"id"} label={"Id"} />
        <FunctionField
          label={"Fecha"}
          source={"attributes.created_at"}
          render={(record) => {
            return `${moment.utc(record.attributes.created_at).format(
              "LLLL"
            )} UTC`;
          }}
        />
        <FunctionField
          label={"Responsable del cambio"}
          render={(record) =>
            record.attributes.user ? record.attributes.user.email : ""
          }
        />
        <FunctionField
          label={"Estado"}
          render={(record) => TRANSACTIONS_STATUS[record.attributes.status]}
        />
        <FunctionField
          label={"Operación"}
          render={(record) =>
            TRANSACTIONS_OPERATION[record.attributes.transaction_operation_type]
          }
        />
        <FunctionField 
          label={"Descripción"}
          render={(record) => 
            record.attributes.description
             ? record.attributes.description
             : record.attributes.original_transaction.description
          }
        />
        <TextField
          source={"attributes.original_transaction.id"}
          label={"Transacción"}
        />
        <TextField
          source={'attributes.original_transaction.external_id'}
          label={'Id del proveedor'}
        />
        <UrlField source="transactions_status_url" label="Url de transacción" style={{color: "#fff", textDecoration: "none"}}/>
        <AssocitatedState />
      </Datagrid>
    </List>
  );
};

export default TransactionsStatusList;