export const styles = {
  container: {
    backgroundColor: "#242424",
    minHeight: 756,
    maxHeight: 756,
    marginTop: 60,
    position: "relative",
    borderRadius: 10,
    paddingLeft: 20,
    paddingRight: 20,
    overflowY: "auto",
    overflowX: "auto",
  },
  containerButton: {
    bottom: 10,
    width: '94%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
    position: 'relative',
    marginBottom: 20,
  },
  buttonClose: {
    border: "none",
    backgroundColor: "blue",
    fontSize: 16,
    color: "#DFDFDF",
  },
};
