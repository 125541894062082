import React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

import { styles } from "../styles";

const HeaderList = ({ handleCreate, styleContainer, nameButton }) => {
  return (
    <div style={{ ...styles.containerHeader, ...styleContainer }}>
      <Button
        variant='contained'
        style={{ backgroundColor: "#005FEE", fontWeight: "bold", fontSize: 12 }}
        onClick={handleCreate}
      >
        {nameButton}
      </Button>
    </div>
  );
};

HeaderList.propTypes = {
  handleCreate: PropTypes.func.isRequired,
  styleContainer: PropTypes.object,
  nameButton: PropTypes.string,
};

HeaderList.defaultProps = {
  handleCreate: () => {},
  styleContainer: {},
  nameButton: "Button create",
};

export default HeaderList;
