import React from "react";

import ModalComponent from "../../../../../Components/ModalComponent";
import {
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
} from "@material-ui/core";

const UsdAccountModal = (props) => {
  const {
    openModal,
    handleCloseModal,
    setRejectionReason,
    handleRejectConfirmed,
    rejectionReason,
  } = props;
  return (
    <ModalComponent
      open={openModal}
      onClose={handleCloseModal}
      marginTop={100}
      backdropStyle={{
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <Card>
        <Typography
          variant='headline'
          align='center'
          style={{
            fontWeight: "bold",
            paddingTop: 30,
          }}
        >
          ¿Quieres rechazar la cuenta en usd para el usuario?
        </Typography>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            label='Razón de rechazo (este mensaje será visible por el usuario)'
            name='btText'
            inputProps={{
              maxLength: 120,
            }}
            fullWidth
            style={{ marginBottom: 20 }} // Add margin to create space between the TextField and buttons
            value={rejectionReason}
            onChange={setRejectionReason}
          />
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <Button
              style={{
                fontSize: 16,
                fontWeight: "bold",
                textTransform: "none",
                backgroundColor: "rgb(0, 166, 255)",
              }}
              onClick={handleRejectConfirmed}
            >
              Sí
            </Button>
            <Button
              style={{
                fontSize: 16,
                fontWeight: "bold",
                textTransform: "none",
                backgroundColor: "#6e7d88",
              }}
              onClick={handleCloseModal}
            >
              No
            </Button>
          </div>
        </CardContent>
      </Card>
    </ModalComponent>
  );
};

export default UsdAccountModal;
