const styles = {
    palette: {
        type: 'dark',
        borderColor: '#C7C7C7',
        textColor: '#FFF',
        primary1Color: '#00f5ab',
    },
    datePicker: {
        width: '120px',
        color: '#6ff5a2',
        textColor: '#FFF',
        calendarTextColor: '#c7c7c7',
        selectColor: '#27f5eb',
        selectTextColor: '#FFF',
        calendarYearBackgroundColor: '#FFF',
        headerColor: '#00f1f5',
    },
    textField: {
        textColor: '#FFF',
    },
    fileField: {
        textColor: '#FFF',
        color: '#00f1f5',
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    overrides: {
        MuiAppBar: {
            root: {
                color: 'colorDefault',
            },
        },
        MuiButton: { // override the styles of all instances of this component
            root: {
                color: 'white',
            },
            label: {
                color: 'white',
            },
            raised: {
                color: 'white',
            },
            containedPrimary: {
                backgroundColor: '#00f5a5',
                '&:hover': {
                    backgroundColor: '#00f0f5',
                },
            },
        },
        MuiSvgIcon: {
            root: {
                color: '#00d7f5',
            },
        },
        MuiIcon: {
            root: {
                color: '#00c2f5',
            },
        },
        MuiDialogContentText: {
            root: {
                color: 'white',
            },
        },
        MuiDialogContent: {
            root: {
                color: 'white',
            },
        },
    },
};

export default styles;
