import React from 'react';
import PropTypes from 'prop-types';
import { Animated } from "react-animated-css";
import { Modal } from "@material-ui/core";

import Images from "../../../../../../Assets/images";

const AnimatedVita = (props) => {
  const { isFetch } = props;

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isFetch}
    >
      <Animated
        animationIn="fadeIn"
        animationOut="fadeOut"
        isVisible={true}
      >
        <Animated
          style={{ animationIterationCount: "infinite" }}
          animationIn="jello"
          animationOut="fadeOut"
          isVisible={true}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh'
            }}
          >
            <img
              style={{
                margin: '0 auto',
                maxWidth: '100%',
                height: 'auto'
              }}
              src={Images.vita}
              width={60}
              height={60}
            />
          </div>
        </Animated>
      </Animated>
    </Modal>
  );
}

AnimatedVita.propTypes = {
  isFetch: PropTypes.bool.isRequired
};

export default AnimatedVita;