import React from "react";
import Button from "@material-ui/core/Button";
import { useAccessRole } from "../../../hooks";

const HeaderMenu = () => {
  const { isAccess } = useAccessRole();

  return (
    <div style={{ backgroundColor: "#595c5d" }}>
      <Button
        size='small'
        onClick={() => {
          window.location.assign('/banners');
        }}
      >
        BANNERS
      </Button>
      {isAccess("show_snackbars") && (
        <Button
          size='small'
          onClick={() => {
            window.location.assign("/snackbars");
          }}
        >
          SNACKBARS
        </Button>
      )}
    </div>
  );
};

export default HeaderMenu;
