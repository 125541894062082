import { useState } from 'react';
import useAccessRole from './useAccessRole';

const useTrxLimits = (props = {}) => {
    const [ isGeneral, setGeneral ] = useState(true);
    const { isAccess } = useAccessRole();

    const onGeneralLimits = () => {
        setGeneral(true);
    };

    const onUserTrxLimits = () => {
        setGeneral(false);
    };

    return {
        isGeneralLimits: isAccess('show_general_limits') && isGeneral,
        isUserTrxLimits: isAccess('show_general_limits') && !isGeneral,
        onGeneralLimits,
        onUserTrxLimits,
    }
};

export default useTrxLimits;