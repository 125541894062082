import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Typography,
    CardContent,
    Button,
    FormControlLabel,
    Switch
} from "@material-ui/core";
import { FormDataConsumer, SimpleForm } from 'react-admin';

import usePayouts from '../../../../hooks/usePayouts';
import DetailsRecipient from '../DetailsRecipient';
import ModalComponent from '../../../../Components/ModalComponent';
import CreateComment from '../../../../Components/comments/CreateComment';
import TransactionProvider from '../../../../Providers/Transactions';

export const PayoutsButtons = (props) => {
    const [commentTags, setCommentTags] = useState([]);

    return <>
        {
            props.isPayoutsButtons && (
                <>
                    {
                        props.isEditTransactionLockedStatus && (
                            <div style={styles.block}>
                                <FormControlLabel
                                    label={props.lockedLabel}
                                    control={
                                        <Switch
                                            checked={props.isLocked}
                                            onChange={() => {
                                                setCommentTags(
                                                    props.isLocked ? ['transaction_unlock'] : ['transaction_lock']
                                                );
                                                props.showCommentModal(true);
                                            }}
                                        />
                                    }
                                />
                            </div>
                        )
                    }
                    {
                        props.defaultPaymentProcessor !== "" && props.isCreatePayout && (
                            <Button
                                disabled={props.isLocked}
                                onClick={props.createDefaultPayout}
                                style={styles.buttonGreen}
                            >
                                REALIZAR PAGO {props.defaultPaymentProcessor.toUpperCase() === "MANUAL" ? props.defaultPaymentProcessor.toUpperCase() : `CON ${props.defaultPaymentProcessor.toUpperCase()}`}
                            </Button>
                        )
                    }
                    {
                        props.isCreatePayout && (
                            <>
                                <div style={styles.separator} />
                                <Button
                                    disabled={props.isLocked}
                                    onClick={props.createPayout}
                                    style={styles.buttonWhite}
                                >
                                    REALIZAR PAGO CON OTROS PROVEEDORES
                                </Button>
                            </>
                        )
                    }
                    {
                        (props.isProcessStatus || props.isMobilePayment) && (
                            <>
                                <div style={styles.separator} />
                                <Button
                                    disabled={props.isLocked}
                                    onClick={props.toCompletedPayout}
                                    style={props.isMobilePayment ? styles.buttonGreen : styles.buttonPurple}
                                >
                                    {props.isMobilePayment ? 'REALIZAR PAGO CON MANUAL' : 'MARCAR COMPLETADA'}
                                </Button>
                            </>
                        )
                    }
                    {
                        props.isProcessPayout && (
                            <>
                                <div style={styles.separator} />
                                <Button
                                    disabled={props.isLocked}
                                    onClick={props.toProcessPayout}
                                    style={styles.buttonPurple}
                                >
                                    MARCAR PROCESADA
                                </Button>
                            </>
                        )
                    }
                    {
                        props.isRejectPayout && (
                            <>
                                <div style={styles.separator} />
                                <Button
                                    disabled={props.isLocked}
                                    onClick={props.rejectPayout}
                                    style={styles.buttonRed}
                                >
                                    {props.rejectLabel}
                                </Button>
                            </>
                        )
                    }
                    <DetailsRecipient {...props.beneficiaryParams} />
                    {
                        props.isAddComment && (
                            <>
                                <div style={styles.separator} />
                                <Button
                                    onClick={() => {
                                        setCommentTags([]);
                                        props.showCommentModal(false)
                                    }}
                                    style={styles.buttonGray}
                                >
                                    AGREGAR COMENTARIO
                                </Button>
                            </>
                        )
                    }
                </>
            )
        }
        {
            props.isShowModal && (
                <ModalComponent
                    open={props.isShowModal}
                    onClose={() => props.hiddenCommentModal(false)}
                    marginTop={100}
                    backdropStyle={{
                        backgroundColor: 'rgba(0,0,0,0.5)',
                    }}
                >
                    <Card>
                        <Typography
                            variant='headline'
                            align='center'
                            style={{
                                fontWeight: "bold",
                                paddingTop: 30,
                            }}
                        >
                            Agregar comentario
                        </Typography>
                        <CardContent style={{ paddingLeft: 100 }}>
                            <SimpleForm {...props} toolbar={null}>
                                <FormDataConsumer>
                                    {
                                        ({ formData, ...rest }) => (
                                            <CreateComment
                                                {...formData}
                                                {...rest}
                                                id={props.id}
                                                onClose={() => props.hiddenCommentModal(true)}
                                                service={TransactionProvider.postTransactionComments}
                                                serviceExtraArgs={{ 'tags': commentTags }}
                                            />
                                        )
                                    }
                                </FormDataConsumer>
                            </SimpleForm>
                        </CardContent>
                    </Card>
                </ModalComponent>
            )
        }
    </>
};

PayoutsButtons.propTypes = {
    isMobilePayment: PropTypes.bool,
    isProcessStatus: PropTypes.bool,
    isCreatePayout: PropTypes.bool,
    isRejectPayout: PropTypes.bool,
    isPayoutsButtons: PropTypes.bool,
    isProcessPayout: PropTypes.bool,
    isCompletedPayout: PropTypes.bool,
    isEditTransactionLockedStatus: PropTypes.bool,
    isLocked: PropTypes.bool,
    createPayout: PropTypes.func,
    rejectPayout: PropTypes.func,
    toProcessPayout: PropTypes.func,
    toCompletedPayout: PropTypes.func,
    changeLockedStatus: PropTypes.func,
    rejectLabel: PropTypes.string,
    lockedLabel: PropTypes.string,
    beneficiaryParams: PropTypes.shape({
        beneficiaryAmount: PropTypes.number,
        isTransaction: PropTypes.bool,
        getTotalAmountRecipient: PropTypes.func,
        beneficiary: PropTypes.shape({}),
        goToFilterTransaction: PropTypes.func,
    }),
    defaultPaymentProcessor: PropTypes.string,
    createDefaultPayout: PropTypes.func,
};

PayoutsButtons.defaultProps = {
    isMobilePayment: false,
    isProcessStatus: false,
    isCreatePayout: false,
    isRejectPayout: false,
    isPayoutsButtons: false,
    isProcessPayout: false,
    isCompletedPayout: false,
    isEditTransactionLockedStatus: false,
    isLocked: false,
    createPayout: () => { },
    rejectPayout: () => { },
    toProcessPayout: () => { },
    toCompletedPayout: () => { },
    changeLockedStatus: () => { },
    rejectLabel: '',
    lockedLabel: '',
    beneficiaryParams: {
        beneficiaryAmount: 0,
        isTransaction: false,
        getTotalAmountRecipient: () => { },
        beneficiary: {},
        goToFilterTransaction: () => { },
    },
    defaultPaymentProcessor: "",
    createDefaultPayout: () => { },
};

const HOC = (props) => {
    const hook = usePayouts(props);
    return <PayoutsButtons {...hook} />
};

HOC.propTypes = {
    isTransferCountry: PropTypes.func,
    isTransaction: PropTypes.bool,
    transaction: PropTypes.shape({
        id: PropTypes.number.isRequired,
        attributes: PropTypes.shape({
            payment_processors: PropTypes.shape({}).isRequired,
            currency: PropTypes.string.isRequired,
            category: PropTypes.string.isRequired,
            external_provider: PropTypes.string,
            external_id: PropTypes.string,
            is_locked: PropTypes.bool,
            beneficiary: PropTypes.shape({}),
        }).isRequired,
    }),
};

HOC.defaultProps = {
    isTransferCountry: () => { return true; },
    isTransaction: false,
    transaction: {
        id: 1,
        attributes: {
            payment_processors: { manual: 'manual' },
            currency: 'clp',
            category: 'transfer',
            external_provider: '',
            external_id: '',
            is_locked: false,
            beneficiary: {},
        },
    },
};

const styles = {
    buttonRed: {
        backgroundColor: '#FF0000',
        color: '#FFFFFF',
    },
    buttonGreen: {
        backgroundColor: '#1ade9c',
        color: '#000000',
    },
    buttonWhite: {
        backgroundColor: '#F1C807',
        color: '#000000',
    },
    separator: {
        padding: 10,
    },
    buttonBlue: {
        backgroundColor: '#4887de',
        color: '#000000',
    },
    buttonPurple: {
        backgroundColor: '#8F00FF',
        color: '#000000',
    },
    buttonYellow: {
        backgroundColor: '#F1C807',
        color: '#000000',
    },
    buttonGray: {
        backgroundColor: '#717171',
        color: '#000000',
    },
    block: {
        display: 'block',
    },
};

export default HOC;