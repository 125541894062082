import React from 'react';
import PropTypes from 'prop-types';
import GeneralLimits from './GeneralLimits';
import useTrxLimits from '../../hooks/useTrxLimits';
import UserLimitRequests from './UserLimitRequests';
import Selector from './Selector';

const TrxLimits = (props) => (
    <>
        <Selector {...props} />
        { props.isUserTrxLimits && <UserLimitRequests /> }
        { props.isGeneralLimits && <GeneralLimits /> }
    </>
);

TrxLimits.propTypes = {
    isGeneralLimits: PropTypes.bool,
    isUserTrxLimits: PropTypes.bool,
};

TrxLimits.defaultProps = {
    isGeneralLimits: false,
    isUserTrxLimits: false,
};

const TrxLimitsHoc = (props) => {
    const hook = useTrxLimits(props);
    return <TrxLimits {...hook} />;
};

export default TrxLimitsHoc;