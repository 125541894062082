import { ENCRYPT_HASH } from '../Config/env';

const crypto = require('crypto');
const Buffer = require("buffer").Buffer;

const useEncrypt = () => {

  const { key } = ENCRYPT_HASH;
  const algorithm = 'aes-256-gcm';
  const iv = crypto.randomBytes(12);

  const encrypt = (value) => {

    const cipher = crypto.createCipheriv(algorithm, key, Buffer.from(iv, 'base64'));
    let crypted = cipher.update(value, "utf-8", "base64");
    crypted += cipher.final("base64");
    const authTag64 = Buffer(cipher.getAuthTag()).toString("base64");
    const iv64 = Buffer(iv).toString("base64");

    return `${crypted}__${iv64}__${authTag64}`;
  }

  const decrypt = (value) => {

    try {
      let [encryptedValue, iv, authTag] = value.split('__');
      let decipher = crypto.createDecipheriv(algorithm, key, Buffer.from(iv, 'base64'));

      decipher.setAuthTag(Buffer.from(authTag, 'base64'));

      let decrypted = decipher.update(encryptedValue, 'base64', 'utf8');
      decrypted += decipher.final('utf8');

      return decrypted;

    } catch (error) {
    }
  }

  return {
    encrypt,
    decrypt,
  };
};

export default useEncrypt;