import { useEffect, useMemo, useState } from "react";

import { Rest } from "../../../../Providers";

const useSnackbarDetails = (props) => {
  const { pathname } = props;

  const [listDetailSnackbar, setListDetailSnackbar] = useState([]);
  const [detailsSnackbar, setDetailsSnackbar] = useState("");

  const isShowDetails = useMemo(
    () => pathname?.includes("show_details"),
    [pathname]
  );

  useEffect(() => {
    (async () => {
      try {
        if (isShowDetails) {
          const id = pathname.split("/")[2];
          const response = await Rest("GET_ONE", "snackbars", {
            id,
            isShowDetails,
          });

          setListDetailSnackbar(response.data.attributes.details_snackbar);
          setDetailsSnackbar(
            JSON.parse(response.data.attributes.list_countries)
          );
        }
      } catch (error) {
      }
    })();

    return () => {
      setListDetailSnackbar([]);
    };
  }, [pathname, isShowDetails]);

  return {
    listDetailSnackbar,
    detailsSnackbar,
  };
};

export default useSnackbarDetails;
