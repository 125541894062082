import React, { useState, useEffect } from 'react';
import styles from '../styles';
import { SimpleForm, Create, TextInput, AutocompleteInput } from 'react-admin';
import { Grid } from '@material-ui/core';

import { validateRequired } from '../../../Helpers/ValidateHelper';
import CountryProvider from "../../../Providers/Countries";
import { frequencyMaintenance, maintenanceProvider, maintenanceType } from '../../../Config/constants';

const ChargeCreate = (props) => {
  const [countries, setCountries] = useState([]);

  const getCountries = async () => {
    try {
      const response = await CountryProvider.fetchFilterCountriesAdmin("q[is_register_eq]=true");
      setCountries(response.data.countries.data);
    } catch (error) { }
  };

  useEffect(() => {
    getCountries();
  }, []);
  
  return (
    <Create
      title={`Crear nueva cuota de mantenimiento`}
      {...props}
    >
      <SimpleForm>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          xs={12}
          sm={10}
          item
          style={styles.container}
        >
          <Grid
            xs={12}
            sm={4}
            item
            style={styles.items}
          >
            <AutocompleteInput
              source="attributes.provider"
              label="Proveedor"
              fullWidth
              choices={maintenanceProvider}
              validate={validateRequired}
              optionText="name"
              optionValue="id"
            />
          </Grid>
          <Grid
            xs={12}
            sm={4}
            item
            style={styles.items}
          >
            <AutocompleteInput
              source="attributes.frequency"
              label="Frecuencia"
              fullWidth
              choices={frequencyMaintenance}
              validate={validateRequired}
              optionText="name"
              optionValue="id"
            />
          </Grid>
          <Grid
            xs={12}
            sm={4}
            item
            style={styles.items}
          >
            <AutocompleteInput
              source="attributes.country.id"
              label="País"
              fullWidth
              choices={countries}
              validate={validateRequired}
              optionText="attributes.name"
              optionValue="id"
            />
          </Grid>
          <Grid
            xs={12}
            sm={4}
            item
            style={styles.items}
          >
            <TextInput
              source={'attributes.amount'}
              label={'Monto (natural) en USD'}
              validate={validateRequired}
              fullWidth
            />
          </Grid>
          <Grid
            xs={12}
            sm={4}
            item
            style={styles.items}
          >
            <TextInput
              source={'attributes.amount_to_business'}
              label={'Monto (business) en USD'}
              validate={validateRequired}
              fullWidth
            />
          </Grid>
          <Grid
            xs={12}
            sm={4}
            item
            style={styles.items}
          >
            <AutocompleteInput
              source="attributes.type_charge"
              label="Tipo"
              fullWidth
              choices={maintenanceType}
              validate={validateRequired}
              optionText="name"
              optionValue="id"
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default ChargeCreate;
