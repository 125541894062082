import { useState, useEffect } from 'react';
import { REQUEST_STATUS_SELECT } from '../Components/trxLimits/utils';
import UserTrxLimitsProvider from '../Providers/UserTrxLimits';
import UsersProvider from '../Providers/Users';
import useAlerts from './useAlerts';
import { toUserLink } from '../Components/trxLimits/utils';

const useFetchUserLimitRequests = (props = {}) => {
    const {
        isFilters = true,
        user,
        emailSearch = '',
        statusSearch = '',
        pageFilter = 0,
        rowsPerPageFilter = 25,
    } = props;
    const [email, setEmail] = useState(emailSearch);
    const [status, setStatus] = useState(statusSearch);
    const [requests, setRequests] = useState([]);
    const [activeRequest, setActiveRequest] = useState(null);
    const [page, setPage] = useState(pageFilter);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageFilter);
    const [total, setTotal] = useState(0);
    const [userLimit, setUserLimit] = useState(null);
    const { showLoading, close, errorMessage } = useAlerts();

    const getRequests = async () => {
        try {
            let query = `?${email ? `email=${encodeURIComponent(email)}` : ''
                }${status ? `&status=${encodeURIComponent(status)}` : ''
                }&page=${page}&per_page=${rowsPerPage}`;

            const response = await UserTrxLimitsProvider.getUserTrxLimits(query);

            setTotal(response.data.total);
            setRequests(response.data.data.data.map((request) => ({
                ...request,
                ...request.attributes,
                request_date: request.attributes.created_at,
                toUserLink: () => toUserLink(request.attributes.email),
            })));
        } catch (error) {
            errorMessage(error.data.error.message);
        }
    };

    const getRequestsHandler = () => {
        getRequests();
    };

    const getRequestsFromUser = async () => {
        try {
            let query = `?&page=${page}&per_page=${rowsPerPage}`;
            const response = await UsersProvider.getTrxLimits(user.id, query);

            setTotal(response.data.total);
            setRequests(response.data.data.data.map((request) => ({
                ...request,
                ...request.attributes,
                request_date: request.attributes.created_at,
            })));
        } catch (error) {
            errorMessage(error.data.error.message);
        }
    };

    const getActiveRequest = async () => {
        try {
            let query = `?is_active=1&page=${page}&per_page=${rowsPerPage}`;
            const response = await UsersProvider.getTrxLimits(user.id, query);

            setUserLimit(response.data.user_limit);
            setActiveRequest(response.data.data.data.map((request) => ({
                ...request,
                ...request.attributes,
                request_date: request.attributes.created_at,
            }))[0]);
        } catch (error) {
            errorMessage(error.data.error.message);
        }
    };

    const getRequestsFromUserHandler = () => {
        getRequestsFromUser();
        getActiveRequest();
    };

    useEffect(() => {
        let isFetch = true;

        if (isFetch) {
            showLoading();
            isFetch = false;
        }

        return () => {
            isFetch = false;
        }
    }, []);

    useEffect(() => {
        let isFetch = true;

        (async () => {
            try {
                if (isFetch) {
                    if (user) {
                        await getRequestsFromUser();
                        await getActiveRequest();
                    } else {
                        await getRequests();
                    }
                    close();
                }
            } catch { }
        })();

        return () => {
            isFetch = false;
        }
    }, [email, status, page, rowsPerPage, user]);

    const changeEmail = (event) => {
        setEmail(event.target.value);
    };

    const changeStatus = (event) => {
        setStatus(event.target.value);
    };

    const changePage = (event, page) => {
        setPage(page);
    };

    const changeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
    };

    return {
        ...props,
        userLimit,
        page,
        total,
        requests,
        rowsPerPage,
        isFilters,
        isCreateActiveUserTrxLimit: !!user,
        isDataTable: !user,
        changePage,
        changeRowsPerPage,
        activeRequest,
        updateRequests: user ?
            getRequestsFromUserHandler :
            getRequestsHandler,
        filtersProps: {
            email,
            status,
            changeEmail,
            changeStatus,
            statuses: REQUEST_STATUS_SELECT,
        },
    }
};

export default useFetchUserLimitRequests;