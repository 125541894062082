import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
} from "@material-ui/core";

import EditProviderActive from "../EditProviderActive";
import { COLUNMS_PROVIDERS_ACTIVES } from "../../../utils";

import styles from "./styles";

const ProvidersActives = (props) => {
  const {
    dataProvider,
    disableButton,
    handleAssignNewProvider,
    handleCloseModal,
    handleDeleteProvider,
    handleOnchangeItemUpdate,
    handleOpenModal,
    handleUpdateProviderActive,
    handleUpdateProviderItemUpdate,
    isOpenModal,
    isUpdate,
    listProvidersActives,
    providerUpdate,
  } = props;

  return (
    <div style={styles.container}>
      <Typography variant='body1' gutterBottom style={styles.titleProvider}>
        Proveedores habilitados
      </Typography>

      <Button
        variant='contained'
        color='primary'
        onClick={() => handleOpenModal()}
        style={styles.button}
        data-testid='edit-button'
        disabled={disableButton}
      >
        Nuevo proveedor
      </Button>

      <Paper style={styles.containerPaper}>
        <Table>
          <TableHead>
            <TableRow>
              {COLUNMS_PROVIDERS_ACTIVES.map((colum, index) => {
                return <TableCell key={index}>{colum}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {listProvidersActives.length > 0 &&
              listProvidersActives.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell> {item.country} </TableCell>
                    <TableCell> {item.nameProvider} </TableCell>
                    <TableCell> {item.fixed_cost} </TableCell>
                    <TableCell> {item.spread} </TableCell>

                    <TableCell>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => handleUpdateProviderActive(item)}
                        style={{ marginRight: 10 }}
                        data-testid='edit-button'
                      >
                        Editar
                      </Button>
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => handleDeleteProvider(item.id)}
                        data-testid='delete-button'
                      >
                        Eliminar
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
      <EditProviderActive
        dataProvider={dataProvider}
        handleAssignNewProvider={handleAssignNewProvider}
        handleCloseModal={handleCloseModal}
        handleOnchange={handleOnchangeItemUpdate}
        handleUpdateProvider={handleUpdateProviderItemUpdate}
        isOpenModal={isOpenModal}
        isUpdate={isUpdate}
        item={providerUpdate}
      />
    </div>
  );
};

ProvidersActives.propTypes = {
  dataProvider: PropTypes.shape({
    listCountries: PropTypes.array,
    listProviders: PropTypes.array,
  }),
  handleAssignNewProvider: PropTypes.func,
  handleCloseModal: PropTypes.func,
  handleDeleteProvider: PropTypes.func,
  handleOnchangeItemUpdate: PropTypes.func,
  handleOpenModal: PropTypes.func,
  handleUpdateProviderActive: PropTypes.func,
  handleUpdateProviderItemUpdate: PropTypes.func,
  isOpenModal: PropTypes.bool,
  isUpdate: PropTypes.bool,
  listProvidersActives: PropTypes.array,
  providerUpdate: PropTypes.shape({
    id: PropTypes.number,
    country: PropTypes.string,
    spread: PropTypes.number,
    fixed_cost: PropTypes.number,
    nameProvider: PropTypes.string,
  }),
};

ProvidersActives.defaultProps = {
  dataProvider: {
    listCountries: [],
    listProviders: [],
  },
  handleAssignNewProvider: () => {},
  handleCloseModal: () => {},
  handleDeleteProvider: () => {},
  handleOnchangeItemUpdate: () => {},
  handleOpenModal: () => {},
  handleUpdateProviderActive: () => {},
  handleUpdateProviderItemUpdate: () => {},
  isOpenModal: false,
  isUpdate: false,
  listProvidersActives: [],
  providerUpdate: {
    id: 1,
    country: "",
    spread: 0,
    fixed_cost: 0,
    nameProvider: "",
  },
};

export default ProvidersActives;
