import { AttachMoney }  from '@material-ui/icons';

import TransactionList  from './TransactionList';
import TransactionShow  from './TransactionShow';
import TransactionEdit  from './TransactionEdit';
import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
    name: 'transacciones',
    icon: AttachMoney,
    list: TransactionList,
    show: TransactionShow,
    edit: isAccess('edit_transactions') ? TransactionEdit : false,
}
