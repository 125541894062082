import { apiCall } from '../../Middlewares';
import { ENDPOINTS } from '../../Config/env';

const updateAlertStatus = async (id) => {
  return await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.ALERTS.UPDATE_ALERT(id)
  });
};

export default {
  updateAlertStatus,
};
