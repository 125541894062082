import { apiCall }    from '../../Middlewares';
import { ENDPOINTS }  from '../../Config/env';

const getGeolocationList = async () =>
    await apiCall({
        authorization: true,
        ...ENDPOINTS.ADMIN.GEOLOCATION.GEOLOCATION_LIST,
    });

export default {
  getGeolocationList,
}