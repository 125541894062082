import React from 'react';
import { List, Datagrid, TextField, FunctionField, BooleanField } from 'react-admin';

import BusinessFilter from '../../Wallets/Filters/BusinessFilter';
import { Pagination } from '../../../Components';
import { toMoney } from "../../../Helpers/MoneyHelper";
import { RESOLVE_CATEGORY_TRANSACTIONS, RESOLVE_STATUS_TRANSACTIONS } from "../../../Helpers/ResourceHelper";
import useExportFiles from '../../../hooks/useExportFiles';
import { FIELDS_EXPORTS_FILES } from '../../utils';

const BusinessTransactionList = (props) => {
  const { exportFileBusinessTransactions } = useExportFiles();

  const exporter = posts => {
    exportFileBusinessTransactions(posts, FIELDS_EXPORTS_FILES.BUSINESS_TRANSACTION)
  }

  return (
    <>
      <div style={styles.title}>Selecciona un negocio para ver sus transacciones</div>
      <List
        {...props}
        title={'Lista de transacciones'}
        filters={<BusinessFilter isWalletSearch />}
        pagination={<Pagination />}
        bulkActionButtons={false}
        exporter={exporter}
        debounce={1000}
      >
        <Datagrid>
          <TextField
            source={'id'}
            label={'Id'}
          />
          <TextField
            source={'attributes.alt_id'}
            label={'Id público'}
          />
          <FunctionField
            label={'Moneda'}
            render={(record) => {
              return record.attributes.currency.toUpperCase();
            }}
          />
          <FunctionField
            label={'Categoria'}
            render={RESOLVE_CATEGORY_TRANSACTIONS}
          />
          <FunctionField
            label={'Estado'}
            render={RESOLVE_STATUS_TRANSACTIONS}
          />
          <TextField
            source={'attributes.sender_uuid'}
            label={'Emisor'}
          />
          <TextField
            source={'attributes.recipient_uuid'}
            label={'Receptor'}
          />
          <TextField
            source={'attributes.order'}
            label={'Orden'}
          />
          <TextField
            source={'attributes.transaction_id'}
            label={'N. de transacción vita'}
          />
          <FunctionField
            label={'Fee'}
            render={(record) => {
              return `${toMoney(record.attributes.fee_value, record.attributes.currency.toLowerCase())} ${record.attributes.currency.toUpperCase()}`;
            }}
          />
          <FunctionField
            label={'Total fee'}
            render={(record) => {
              return `${toMoney(record.attributes.total_fee, record.attributes.currency.toLowerCase())} ${record.attributes.currency.toUpperCase()}`;
            }}
          />
          <FunctionField
            label={'Monto'}
            render={(record) => {
              return `${toMoney(record.attributes.amount, record.attributes.currency.toLowerCase())} ${record.attributes.currency.toUpperCase()}`;
            }}
          />
          <FunctionField
            label={'Total'}
            render={(record) => {
              return `${toMoney(record.attributes.total, record.attributes.currency.toLowerCase())} ${record.attributes.currency.toUpperCase()}`;
            }}
          />
          <TextField
            source={'attributes.notification_url'}
            label={'URL de notificación'}
          />
          <BooleanField
            source={'attributes.is_ipn_sent'}
            label={'IPN enviado'}
          />
        </Datagrid>
      </List>
    </>
  );
}

const styles = {
  title: {
    color: '#FFFFFF',
    paddingBottom: 10,
  },
};

export default BusinessTransactionList;
