import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from "@material-ui/core";

import styles from "../styles";

const ModalAutomaticWithdrawal = (props) => {
  const {
    isAutomaticWithdrawal,
    setAutomaticWithdrawal,
    automaticWithdrawal,
    payment_processor,
    payment_processor_key,
  } = props;

  const handleClose = () => setAutomaticWithdrawal(false);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isAutomaticWithdrawal}
    >
      <div
        style={styles.centerAlert}
      >
        <div
          style={styles.centerColumn}
        >
          <div style={styles.alertTitle}>
            { (payment_processor ? `¿Realizar pago con ${payment_processor}?` : '¿Está seguro de realizar esta operación?').toUpperCase() }
          </div>
          <div
            style={styles.alertBody}
          >
            La operación será irreversible
          </div>
          <div
            style={styles.alertButtom}
          >
            <Button
              onClick={() => automaticWithdrawal(payment_processor_key)}
              style={styles.buttonBlue}
            >
              PAGAR
            </Button>
            <Button
              onClick={handleClose}
              style={styles.buttonRed}
            >
              CANCELAR
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ModalAutomaticWithdrawal.defaultProps = {
  isAutomaticWithdrawal: false,
  setAutomaticWithdrawal: () => {},
  automaticWithdrawal: () => {},
  payment_processor: '',
  payment_processor_key: '',
};

ModalAutomaticWithdrawal.propTypes = {
  isAutomaticWithdrawal: PropTypes.bool.isRequired,
  setAutomaticWithdrawal: PropTypes.func.isRequired,
  automaticWithdrawal: PropTypes.func.isRequired,
  payment_processor: PropTypes.string,
  payment_processor_key: PropTypes.string,
};

export default ModalAutomaticWithdrawal;