import React from 'react';
import { Filter, TextInput, DateInput } from 'react-admin';

import { Grid, withStyles } from '@material-ui/core';

const styles = {
  helperText: {
    color: ' rgba(255, 255, 255, 0.7)',
    margin: '8px 0',
    fontSize: '0.75rem',
    textAlign: 'left',
    lineHeight: '1em'
  },
  dateInput: {
    margin: '8px 0 0 0',
    '&:nth-child(1)': {
      paddingRight: '5px'
    },
    '&:nth-child(2)': {
      paddingLeft: '5px'
    },
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      backgroundColor: ' rgb(140, 140, 140)',
      borderRadius: '5px',
      display: 'block'
    }
  }
};

const LoginFilter = (props) => {

  const { classes } = props;

  return (
    <Filter {...props}>
      <TextInput
        label={'Buscar'}
        source={'search_key'}
        helperText={"Busca un usuario por id, nombre, apellido, email o DNI"}
        alwaysOn
      />
      <Grid
        label="Fecha de sesión"
        source={'login_dates'}
        alwaysOn
      >
        <Grid
          container
        >
          <DateInput
            label={'Desde'}
            source="login_dates.login_date_start"
            className={classes.dateInput}
          />
          <DateInput
            label={'Hasta'}
            source="login_dates.login_date_end"
            className={classes.dateInput}
          />
        </Grid>
        <p className={classes.helperText}>Fecha de sesíon</p>
      </Grid>
    </Filter>
  );
};

export default withStyles(styles)(LoginFilter);
