import {GET_ONE, DELETE_MANY} from 'react-admin';

const responseHandler = (response, type, params) => {
    switch (type) {
        case GET_ONE: {
            let data = [];
            data = response.data.bank_transfers.map((item) => {
                return {
                    ...item,
                    sender_account: {
                        holder_id: item.holder_id_sender,
                        holder_name: item.holder_name_sender,
                        institution: item.institution_sender,
                        number: item.number_sender,
                    },
                    recipient_account: {
                        holder_id: item.holder_id_recipient,
                        holder_name: item.holder_name_recipient,
                        institution: item.institution_recipient,
                        number: item.number_recipient,
                    },
                };
            });
            return {
                data: data[0],
            }
        }
        case DELETE_MANY: {
            return {
                data: params.ids,
            }
        }
        default: {
            let {filter: {is_bank_transfers_unassigned = false}} = params;
            let data = [];

            data = response.data.bank_transfers.map((item) => {
                if (is_bank_transfers_unassigned) {
                    return {
                        ...item,
                        sender_account: {
                            holder_id: item.holder_id_sender,
                            holder_name: item.holder_name_sender,
                            institution: item.institution_sender,
                            number: item.number_sender,
                        },
                        recipient_account: {
                            holder_id: item.holder_id_recipient,
                            holder_name: item.holder_name_recipient,
                            institution: item.institution_recipient,
                            number: item.number_recipient,
                        },
                    };
                } else {
                    return {
                        ...item,
                    };
                }
            });

            return {
                data,
                total: response.data['total'],
            }
        }
    }
};

export default responseHandler;