import Navigation from '../../../Navigation';

export const USERS_TYPES = [ 'business', 'personal' ];

export const USER_TYPES_SELECT = [
    {  
        id: 'business', 
        name: 'Empresa',
    },
    { 
        id: 'personal', 
        name: 'Natural',
    },
];

export const REQUEST_STATUS = {
    pending: 'Pendiente',
    approved: 'Aprobada',
    rejected: 'Rechazada',
};

export const REQUEST_STATUS_SELECT = [
    {
        id: '',
        name: '',
    },
    {  
        id: 'pending', 
        name: 'Solicitudes pendientes',
    },
    { 
        id: 'approved', 
        name: 'Solicitudes aprobadas',
    },
    {
        id: 'rejected',
        name: 'Solicitudes rechazadas',
    }
];

export const truncateText = (text = '', lenght = 40) => {
    if (text.length > lenght) {
      return text.slice(0, lenght) + "...";
    } else {
      return text;
    }
};

export const toUserLink = (email) => {
    Navigation.nav().history.push(`/usuarios?filter={"search_key"%3A"${encodeURIComponent(email)}"}&order=DESC&page=1&perPage=10&sort=id`);
};
  