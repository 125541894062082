import React from 'react';
import PropTypes from 'prop-types';
import useAlerts from "../../../../../../hooks/useAlerts";
import { Grid } from "@material-ui/core";
import { RemoveRedEye } from '@material-ui/icons';

const risks = {
  '0': 'low',
  '1': 'low',
  '5': 'medium',
  '10': 'high',
  '15': 'high'
};

const generateHTMLString = (data) => {
  let htmlString = `
    <table style="width: 100%; border-collapse: collapse;">
      <thead>
        <tr style="font-size: 15px;">
          <th style="border: 1px solid black; padding: 3px; background-color: #f2f2f2; text-align: left;">Riesgo</th>
          <th style="border: 1px solid black; padding: 3px; background-color: #f2f2f2; text-align: left;">Mensaje</th>
          <th style="border: 1px solid black; padding: 3px; background-color: #f2f2f2; text-align: left;">Categoría</th>
          <th style="border: 1px solid black; padding: 3px; background-color: #f2f2f2; text-align: left;">Tipo</th>
        </tr>
      </thead>
      <tbody>`;

  data.forEach((item, index) => {
    const rowStyle = index % 2 === 0 ? 'background-color: #f9f9f9;' : 'background-color: #ffffff;';
    htmlString += `
      <tr style="font-size: 13px; ${rowStyle}">
        <td style="border: 1px solid black; padding: 3px;">${item.risk}</td>
        <td style="border: 1px solid black; padding: 3px;">${item.message}</td>
        <td style="border: 1px solid black; padding: 3px;">${item.category}</td>
        <td style="border: 1px solid black; padding: 3px;">${item.risk_type}</td>
      </tr>`;
  });

  htmlString += `
      </tbody>
    </table>`;

  return htmlString;
};

const ThirdColumn = (props) => (
  <Grid style={{ padding: '7%', display: 'flex', flexDirection: 'flexRow' }}>
    <div className='score-row'>
      <div className='score-row-buttons'>
        <h4 className={`score-risk-${risks[`${props.risk}`] || "low"}`}>
          Risk {props.risk}
        </h4>
        {
          props.isIndicators && (
            <div
              className={`score-risk-${risks[`${props.risk}`] || "low"}`}
              style={{ width: 30, marginLeft: 10, cursor: 'pointer' }}
              title='Ver más indicadores de riesgo'
              onClick={props.onIndicators}
            >
              <RemoveRedEye style={{ color: '#FFFFFF' }} />
            </div>
          )
        }
      </div>
      <p className='score-title'>{props.message}</p>
      {
        props.risk_type && (
          <div style={{ paddingBottom: 10 }}>
            <div style={{ fontSize: 12, paddingBottom: 3, opacity: 0.5 }}>Tipo de riesgo</div>
            <div>{props.risk_type}</div>
          </div>
        )
      }
      {
        props.isOwner && (
          <div style={{ paddingBottom: 10 }}>
            <div style={{ fontSize: 12, paddingBottom: 3, opacity: 0.5 }}>Propietario</div>
            <div>{props.risk_owner}</div>
          </div>
        )
      }
      {
        props.provider && (
          <>
            <div style={{ fontSize: 12, paddingBottom: 3, opacity: 0.5 }}>Proveedor</div>
            <div>{props.provider.toUpperCase()}</div>
          </>
        )
      }
    </div>
  </Grid>
);

ThirdColumn.propTypes = {
  indicators: PropTypes.arrayOf({
    category: PropTypes.string,
    message: PropTypes.string,
    risk: PropTypes.number,
    risk_type: PropTypes.string,
  }),
  isIndicators: PropTypes.bool,
  isOwner: PropTypes.bool,
  isRisk: PropTypes.bool,
  risk: PropTypes.number,
  owner: PropTypes.string,
  message: PropTypes.string,
  onIndicators: PropTypes.func,
  risk_type: PropTypes.string,
  category: PropTypes.string,
  provider: PropTypes.string,
  risk_owner: PropTypes.string,
};

ThirdColumn.defaultProps = {
  indicators: [],
  isIndicators: false,
  isOwner: false,
  isRisk: false,
  risk: 0,
  owner: 'Desconocido',
  message: '',
  onIndicators: () => { },
  risk_type: '',
  category: '',
  provider: '',
  risk_owner: '',
};

const useThirdColumn = (props = {}) => {
  const {
    score,
    record,
  } = props;
  const { attributes } = record || {};
  const {
    risk,
    risk_type,
    risk_owner,
    risk_message,
    risk_category,
    risk_provider,
    risk_indicators = [],
  } = attributes || {};
  const {
    indicators = [],
    risk_owner: score_risk_owner,
    risk: score_risk,
    risk_type: score_risk_type,
    message, category,
    risk_provider: score_risk_provider,
  } = score || {};

  const { showHTML } = useAlerts();

  const indi = indicators.length > 0 ? indicators : risk_indicators;
  const risk_i = score_risk === 0 ? score_risk : score_risk || risk;

  const onIndicators = () => {
    showHTML({ html: generateHTMLString(indi) })
  };

  return {
    ...score,
    indicators: indi,
    risk: risk_i,
    risk_type: score_risk_type || risk_type,
    risk_owner: score_risk_owner || risk_owner,
    message: message || risk_message,
    category: category || risk_category,
    provider: score_risk_provider || risk_provider,
    isIndicators: indi.length > 1,
    isOwner: score_risk_owner || risk_owner,
    isRisk: risk_i === 0 ? true : !!risk_i,
    onIndicators,
  }
}

export default (props) => {
  const hook = useThirdColumn(props);

  if (!hook.isRisk) {
    return null;
  }

  return <ThirdColumn {...hook} />
};