import React from "react";
import { Container } from "react-grid-system";

import useUserBusinessLink from "../../../../../hooks/useUserBusinessLink";
import ProvidersActives from "./components/ProvidersActives";
import ListLinks from "./components/ListLinks";
import GeneralInfo from "./components/GeneralInfo";

const BusinessUserPanel = (props) => {
  const {
    isAccess,
    configUser,
    configUserUpdate,
    handleCloseModalInfo,
    handleDownloadLogoUser,
    handleOnchangeConfigUser,
    handleShowImage,
    handleUpdateConfigUser,
    hasInfo,
    image_design,
    isOpenModalInfo,
    setImage_design,
    updateConfigUser,
    dataProvider,
    disableButton,
    handleAssignNewProvider,
    handleCloseModal,
    handleDeleteProvider,
    handleOnchangeItemUpdate,
    handleOpenModal,
    handleUpdateProviderActive,
    handleUpdateProviderItemUpdate,
    isOpenModal,
    isUpdate,
    listProvidersActives,
    providerUpdate,
    hadleCopyLink,
    handleChangePageLinks,
    handleChangeRowsPerPageLinks,
    listLinksUser,
    pageList,
  } = useUserBusinessLink(props);

  return (
    <Container>
      {hasInfo && isAccess("show_user_payment_order_providers") && (
        <GeneralInfo
          configUser={configUser}
          configUserUpdate={configUserUpdate}
          handleCloseModalInfo={handleCloseModalInfo}
          handleDownloadLogoUser={handleDownloadLogoUser}
          handleOnchangeConfigUser={handleOnchangeConfigUser}
          handleShowImage={handleShowImage}
          handleUpdateConfigUser={handleUpdateConfigUser}
          image_design={image_design}
          isOpenModalInfo={isOpenModalInfo}
          setImage_design={setImage_design}
          updateConfigUser={updateConfigUser}
        />
      )}

      {isAccess("show_business_payment_order_config") && (
        <ProvidersActives
          dataProvider={dataProvider}
          handleAssignNewProvider={handleAssignNewProvider}
          handleCloseModal={handleCloseModal}
          handleDeleteProvider={handleDeleteProvider}
          handleOnchangeItemUpdate={handleOnchangeItemUpdate}
          handleOpenModal={handleOpenModal}
          handleUpdateProviderActive={handleUpdateProviderActive}
          handleUpdateProviderItemUpdate={handleUpdateProviderItemUpdate}
          isOpenModal={isOpenModal}
          isUpdate={isUpdate}
          listProvidersActives={listProvidersActives}
          providerUpdate={providerUpdate}
          disableButton={disableButton}
        />
      )}

      <ListLinks
        hadleCopyLink={hadleCopyLink}
        listLinksUser={listLinksUser}
        handleChangePage={handleChangePageLinks}
        handleChangeRowsPerPage={handleChangeRowsPerPageLinks}
        pageList={pageList}
      />
    </Container>
  );
};

export default BusinessUserPanel;
