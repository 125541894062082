import React, { useState }                            from 'react';
import {NotificationContainer, NotificationManager}   from 'react-notifications';
import { Card, CardContent, Grid, TextField, Button } from '@material-ui/core';

import Images                 from '../../../Assets/images';
import { DlocalProvider }     from '../../../Providers';
import { TO_NUMBER, toMoney } from '../../../Helpers/MoneyHelper';

import styles from './styles';

const DlocalCreate = () => {
    const [amount, setAmount] = useState('');

    const showError = () => {
        NotificationManager.error('Ha ocurrido un error con DLocal, por favor intente mas tarde', '', 5000, () => {
        });
    };

    const send = async () => {
        try {
            if (amount && amount > 0) {
                const response = await DlocalProvider.chargeToDlocal(amount);
                if (response) {
                    const { url_redirect } = response.data;
                    window.location = url_redirect;
                } else {
                    showError();
                }
            }
        } catch (e) {
            showError();
        }

        setAmount('');
    };

    const changeAmount = (event) => {
        setAmount(parseValue(event.target.value));
    };

    const formatValue = (value) =>
        toMoney(
            value,
            'CLP'
        );

    const parseValue = (value) =>
        TO_NUMBER(
            value,
            'CLP'
        );

    return (
        <Card>
            <NotificationContainer />
            <CardContent>
                <Grid container className={styles.container}>
                    <Grid item xs={12} style={styles.header}>
                        <img src={Images.DLocalLogo} style={styles.dlocalImage}/>
                    </Grid>
                    <Grid item xs={12} style={styles.description}>
                        Ingrese el monto a recargar en Dlocal
                    </Grid>
                    <Grid item xs={12} style={styles.boxCenter}>
                        <TextField
                            label="Monto CLP"
                            value={formatValue(amount)}
                            onChange={changeAmount}
                        />
                    </Grid>
                    <Grid item xs={12} style={styles.header}>
                        <Button variant="contained" color="primary" onClick={send}>
                            <div style={{color: '#000000'}}>
                                RECARGAR
                            </div>
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default DlocalCreate;
