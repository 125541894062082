import React from "react";
import PropTypes from "prop-types";

import styles from "../styles";

const ListActions = (props) => {
  const { editSnackbar, showSnackbar, id } = props;

  return (
    <>
      <button style={styles.styleButtonEdit} onClick={() => showSnackbar(id)}>
        Ver
      </button>
      <button
        style={{ ...styles.styleButtonEdit, marginLeft: 25 }}
        onClick={() => editSnackbar(id)}
      >
        Editar
      </button>
    </>
  );
};

ListActions.propTypes = {
  editSnackbar: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

ListActions.defaultProps = {
  editSnackbar: () => {},
  showSnackbar: () => {},
  id: "1",
};

export default ListActions;
