import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  BooleanField,
} from "react-admin";

const CurrencyList = (props) => {
  return (
    <List
      {...props}
      title={"Lista de monedas"}
      exporter={false}
      bulkActionButtons={false}
      debounce={1000}
    >
      <Datagrid>
        <TextField source="id" label="Id" />
        <TextField
          source="attributes.singular_name"
          label="Nombre singular"
        />
        <TextField
          source="attributes.plural_name"
          label="Nombre plural"
        />
        <TextField
          source="attributes.iso_code"
          label="Código"
        />
        <TextField
          source="attributes.quantity_decimals"
          label="Cantidad de decimales"
        />
        <BooleanField
          label={'Crypto'}
          source={'attributes.is_crypto'}
        />
        <BooleanField
          label={'Habilitada'}
          source={'attributes.is_available'}
        />

        <EditButton />
      </Datagrid>
    </List>
  );
};

export default CurrencyList;
