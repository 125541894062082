import React from "react";
import { List, TextField, Datagrid, FunctionField } from "react-admin";
import { ListActions, StatusSwich } from "../";
import { Filters, ListCountries } from "../../../../../Components/Comunications";
import RenderType from "../RenderType";

const ListBanners = (props) => {
  return (
    <List
      {...props}
      title={"Banners"}
      filters={<Filters />}
      bulkActionButtons={false}
      exporter={false}
      debounce={1000}
    >
      <Datagrid>
        <TextField source={"attributes.identifier"} label={"Nombre banner"} />
        <TextField source={"attributes.text_banner"} label={"Texto banner"} />
        <FunctionField
          label={"Visibilidad"}
          render={(record) =>
            <ListCountries
              data={JSON.parse(record.attributes.list_countries)}
            />
          }
        />
        <FunctionField
          label={"Tipo de usuario"}
          render={(record) =>
            <RenderType type={record.attributes.type_user}/>
          }
        />
        <FunctionField
          label={"Estado"}
          render={(record) => <StatusSwich {...record} />}
        />
        <FunctionField
          label={"Acciones"}
          render={(record) => (
            <ListActions
              {...record}
              editBanner={props.editBanner}
              showBanner={props.showBanner}
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

export default ListBanners;
