import React from "react";
import Button from "@material-ui/core/Button";

const HeaderMenu = (props) => {
  const { history } = props;
  return (
    <div style={{ backgroundColor: "#595c5d" }}>
      <Button
        size="small"
        onClick={() => {
          history.push("/coupons/create");
        }}
      >
        Nuevo Cupon
      </Button>
      <Button
        size="small"
        onClick={() => {
          history.push("/coupons");
        }}
      >
        Cupones
      </Button>
      <Button
        size="small"
        onClick={() => {
          history.push("/segments");
        }}
      >
        Segmentos
      </Button>
    </div>
  );
};

export default HeaderMenu;
