import { apiCall } from "../../Middlewares";
import { ENDPOINTS } from "../../Config/env";

const changeStatus = async (data) =>
  await apiCall({
    authorization: true,
    ...ENDPOINTS.ADMIN.TRANSACTIONS.CHANGE_STATUS,
    data,
  });

export default {
  changeStatus,
}