import {CREATE, DELETE, GET_ONE, UPDATE} from 'react-admin';

const responseHandler = (response, type, params) => {
    switch (type) {
        case CREATE: {
            return {
                data: response.data.data,
            }
        }
        case GET_ONE: {
            return {
                data: response.data.bank_account_accesses.data[0],
            }
        }
        case DELETE: {
            return {
                data: response,
            }
        }
        case UPDATE: {
            return {
                data: response.data.data,
            }
        }
        default: {
            return {
                data: response.data.bank_account_accesses.data,
                total: response.data['total'],
            }
        }
    }
};

export default responseHandler;