import React, { useState, useEffect } from "react";
import { Filter, TextInput, SelectInput } from "react-admin";

import { withStyles } from "@material-ui/core";
import CATEGORIES from "../../../../Providers/Categories";

const styles = {
  helperText: {
    color: " rgba(255, 255, 255, 0.7)",
    margin: "8px 0",
    fontSize: "0.75rem",
    textAlign: "left",
    lineHeight: "1em",
  },
  dateInput: {
    margin: "8px 0 0 0",
    "&:nth-child(1)": {
      paddingRight: "5px",
    },
    "&:nth-child(2)": {
      paddingLeft: "5px",
    },
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      backgroundColor: " rgb(140, 140, 140)",
      borderRadius: "5px",
      display: "block",
    },
  },
};

const ServiceFilter = (props) => {
  const [categories, setCategories] = useState();

  const getCategories = async () => {
    const result = await CATEGORIES.getCategories();
    setCategories(
      result.data.categories.data.map((category) => ({
        id: category.id,
        name: category.attributes.title,
      }))
    );
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Filter {...props}>
      <TextInput
        label={"Nombre convenio"}
        source={"utility_name"}
        helperText={"Filtra por nombre de convenio"}
        alwaysOn
      />
      <SelectInput
        label={"Categoría convenio"}
        source={"service_category_id"}
        choices={categories}
        helperText={"Filtra por categoría del convenio"}
      />
      <TextInput
        label={"Tipo convenio"}
        source={"utility_type"}
        helperText={"Filtra por tipo de convenio"}
      />
      <TextInput
        label={"Número convenio"}
        source={"utility_number"}
        helperText={"Filtra por número de convenio"}
      />
      <SelectInput
        label={"Estado convenio"}
        source={"vita_enable"}
        choices={[
          { id: "false", name: "Desactivados" },
          { id: "true", name: "Activados" },
        ]}
        helperText={"Filtra por el estado del convenio"}
      />
    </Filter>
  );
};

export default withStyles(styles)(ServiceFilter);
