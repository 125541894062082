import {CREATE, DELETE, UPDATE} from 'react-admin';

import { API_URL } from '../../../../Config/env';

const requestHandler = async (type, params) => {
    switch (type) {
        case CREATE: {
            const [key_file, crt_file] = await readFileAsDataURL(params.data.attributes);

            const data = { 
                ...params.data.attributes,
                key_file,
                crt_file,
            };

            return {
                url: `${API_URL}/admin/bank_account_accesses`,
                method: 'post',
                data,
            };
        }
        case DELETE: {
            return {
                url: `${API_URL}/admin/bank_account_accesses/${params.id}`,
                method: 'delete',
            };
        }
        case UPDATE: {
           const data = { ...params.data.attributes };

           if (
                params.data.attributes.crt_file && 
                params.data.attributes.key_file && 
                params.data.attributes.crt_file !== 'Certificado almacenado' && 
                params.data.attributes.key_file !== 'Clave de certificado almacenado'
            ) {
                const [key_file, crt_file] = await readFileAsDataURL(params.data.attributes);
                data["key_file"] = key_file;
                data["crt_file"] = crt_file;
           } else {
                delete data["key_file"];
                delete data["crt_file"];
           }

           return {
                url: `${API_URL}/admin/bank_account_accesses/${params.id}`,
                method: 'put',
                data,
            };
        }
        default: {
            return {

            }
        }
    }
};

const readFileAsDataURL = async ({ key_file = {}, crt_file = {} } = {}) => {
    const readFile = async (file) =>
    !file
    ? null
    : new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", (event) => resolve(event.target.result));
    reader.addEventListener("error", (event) => reject(event.target.error));
    reader.readAsText(file);
    });
    
    const [result1, result2] = await Promise.all([readFile(key_file['rawFile']), readFile(crt_file['rawFile'])]);
    
    return [result1, result2];
};

export default requestHandler;