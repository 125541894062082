import React from 'react';
import { List, Datagrid, TextField, ShowButton, EditButton }  from 'react-admin';

import { Pagination } from '../../../Components';

const RolesList = (props) => {
  const {hasEdit} = props;

  return (
    <List
      {...props}
      title={'Roles de Usuario'}
      exporter={false}
      pagination={<Pagination/>}
      bulkActionButtons={false}
      debounce={1000}
    >
      <Datagrid>
        <TextField
          source={'id'}
          label={'Id'}
        />
        <TextField
          source={'attributes.name'}
          label={'Nombre'}
        />
        <TextField
          source={'attributes.public_name'}
          label={'Nombre Publico'}
        />
        <TextField
          source={'attributes.description'}
          label={'Descripción'}
        />
        <ShowButton label="" />
        {hasEdit ? <EditButton label="" /> : null}
      </Datagrid>
    </List>
  );
};

export default RolesList;