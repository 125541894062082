import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { Tabs, Tab } from '@material-ui/core';
import { BankAccountList, FavoriteAccountList } from './CustomComponents'

const FavoriteAccount = (props) => {
  const [ tabState, setTabState ] = useState(0);

  const changeTab = (event, value) => setTabState(value);

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={tabState}
          onChange={changeTab}
          indicatorColor="primary"
          textColor="primary"
          scrollable
          scrollButtons="auto"
        >
          <Tab key="international" label="Internacional" />
          <Tab key="internal" label="Internas" />
          <Tab key="banks_account" label="Propias" />
        </Tabs>
      </AppBar>
      { tabState === 0 && <FavoriteAccountList {...props} account_type="international" /> }
      { tabState === 1 && <FavoriteAccountList {...props} account_type="internal"/> }
      { tabState === 2 && <BankAccountList {...props} />}
    </div>
  );
}
 
export default FavoriteAccount;