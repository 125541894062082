import { Settings }     from '@material-ui/icons';

import ConfigurationList  from './ConfigurationList';
import ConfigurationEdit  from './ConfigurationEdit';
import useAccessRole from '../../hooks/useAccessRole';

const {isAccess} = useAccessRole();

export default {
    name: 'configuraciones',
    icon: Settings,
    list: ConfigurationList,
    edit: isAccess('edit_config') ? ConfigurationEdit : false
}
