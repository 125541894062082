import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import useCreateActiveUserTrxLimit from '../../../hooks/useCreateActiveUserTrxLimit';

export const CreateActiveUserTrxLimit = (props) => (
    <Button
        style={styles.button}  
        onClick={props.onCreate}
    >
        Crear límite activo
    </Button>
);

CreateActiveUserTrxLimit.propTypes = {
    onCreate: PropTypes.func,
};

CreateActiveUserTrxLimit.defaultProps = {
    onCreate: () => {},
};

const styles = {
    button: {
        backgroundColor: '#009bff',
        width: 180,
        height: 35,
        fontSize: 12,
        marginTop: 20,
    },
};

const CreateActiveUserTrxLimitHoc = (props) => {
    const hook = useCreateActiveUserTrxLimit(props);
    return <CreateActiveUserTrxLimit {...hook} />;
};

export default CreateActiveUserTrxLimitHoc;