import {useEffect, useState} from 'react';

import userService from '../Providers/Users';

const useLimitList = (user_id) => {
    const [limits, setLimits] = useState([]);

    const fetchLimits = async () => {
        try {
            const response = await userService.getUserLimit(user_id);
            setLimits(response.data.data);
        } catch {}
    };

    useEffect(() => {
        let isFetch = true;

        if (isFetch) {
            fetchLimits();
            isFetch = false;
        }

        return () => {
            isFetch = false;
        };
    }, []);

    const deleteLimit = async (id) => {
        try {
            await userService.deleteUserLimit(id);
            fetchLimits();
        } catch {}
    };

    return {
        limits,
        deleteLimit,
    };
};

export default useLimitList;