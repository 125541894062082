import React from 'react';
import {Filter, SelectInput} from 'react-admin';

export const CURRENCIES = [
    {id: '2', name: 'Peso colombiano'},
    {id: '0', name: 'Peso chileno'},
    {id: '1', name: 'Dolar estadounidense'},
    {id: '3', name: 'Peso argentino'},
    {id: '4', name: 'Peso mexicano'},
    {id: '5', name: 'btc'},
    {id: '6', name: 'usdt'},
    {id: '7', name: 'usdc'},
];

const PricesFilter = (props) => (
    <Filter {...props}>
        <SelectInput
            alwaysOn
            label={'Moneda'}
            source={'currency'}
            choices={CURRENCIES}
        />
    </Filter>
);

export default PricesFilter;
