import { ObjectiveProvider } from '../Providers';

const getObjectives = async () => {
  try {
    const response = await ObjectiveProvider.getListObjectives();
    if (response.data) {
      return response.data;
    } else {
      console.error("ERROR inesperado al solicitar objetivos al sistema -> ", response);
    }
  } catch (error) {
  }
};

export default {
  getObjectives
};