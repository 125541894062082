import React, {useState} from 'react';
import * as XLSX from 'xlsx';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {toMoney} from '../../../Helpers/MoneyHelper';
import {Animated} from "react-animated-css";
import Images from "../../../Assets/images";
import {TransactionProvider} from "../../../Providers";
import {downloadCSV} from "react-admin";
import { unparse as convertToCSV }  from 'papaparse/papaparse.min';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
});

const BatchPayoutList = (props) => {
    const { classes } = props;
    const [isLoading, setLoading] = useState(false);
    const [isSuccessful, setSuccessful] = useState(false);
    const [isNext, setNext] = useState(false);
    const [state, setState] = useState({
        sheets: [],
    });

    const isInvalidRow = (row) => {
        return (
            row.country === null || row.country === '' ||
            (row.beneficiary_type.localeCompare("individual") && row.beneficiary_type.localeCompare("corporate")) ||
            (row.beneficiary_type.localeCompare("individual") === 0 && (row.first_name === null || row.first_name === '' || row.last_name === null || row.last_name === '') || (row.beneficiary_type.localeCompare("corporate") === 0 && (row.company_name === null || row.company_name === ''))) ||
            (row.document_type.localeCompare("cc") && row.document_type.localeCompare("ce") && row.document_type.localeCompare("pass")) ||
            isNaN(row.document_number) ||
            row.bank === null || row.bank === '' ||
            (row.account_type.localeCompare("c") && row.account_type.localeCompare("s")) ||
            isNaN(row.account_number) ||
            isNaN(row.amount) ||
            ('COP'.toLowerCase() !== 'cop')
        );
    };

    const showNext = () => {
        setNext(true);
    };

    const cancelBatch = () => {
        setState({sheets: []});
    };

    const hiddenNext = () => {
        setNext(false);
    };

    const exit = () => {
        setSuccessful(false);
        hiddenNext();
        setState({
            sheets: []
        });
    };

    const exportExcel = () => {
        const csv = convertToCSV({
            data: state.sheets,
            fields: ['PAIS', 'TIPO_BENEFICIARIO', 'NOMBRE', 'APELLIDO', 'NOMBRE_EMPRESA', 'TIPO_DOCUMENTO', 'NRO_DOCUMENTO', 'BANCO', 'TIPO_CUENTA', 'NRO_CUENTA', 'MONTO', 'transaccion', 'error']
        });
        downloadCSV(csv, 'Pagos');
    };

    const cancelPayout = () => {
        hiddenNext();
    };

    const renderError = () => {
        let errorCount = 0;
        state.sheets.map((sheet) => {
            if (sheet.error)
                errorCount++;
        });

        if (errorCount) {
            return (
                <div style={{color: '#FF0000', paddingTop: 20}}>
                    Pagos fallidos: {errorCount}
                </div>
            );
        }
    };

    const isValidRows = () => {
        let isValid = true;
        state.sheets.map((row) => {
            if (isInvalidRow(row))
                isValid = false;
        });

        return isValid;
    };

    const onPayout = async () => {
        if (isValidRows()) {
            hiddenNext();
            setLoading(true);
            try {
                const response = await TransactionProvider.batchPayout(state.sheets);
                if (response.data !== undefined && response.data.message !== undefined && response.data.message === 'Batch scheduled') { 
                    setSuccessful(true);
                }
            } catch (e) {

            }
            setLoading(false);
        }
    };

    const inputChangeHandle = (event) => {
        try {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            setState({
                [name]: value,
                sheets: [],
            });
            let sheets = [];
            if (name === 'file') {
                let reader = new FileReader();
                reader.readAsArrayBuffer(target.files[0]);
                reader.onloadend = (e) => {
                    var data = new Uint8Array(e.target.result);
                    var workbook = XLSX.read(data, {type: 'array'});

                    workbook.SheetNames.forEach(function(sheetName) {
                        var XL_row_object = XLSX.utils.sheet_to_json(
                            workbook.Sheets[sheetName]
                        );

                        XL_row_object = XL_row_object.map(object => {
                            return {
                                country: object.PAIS.trim().toLowerCase(),
                                beneficiary_type: object.TIPO_BENEFICIARIO.trim().toLowerCase(),
                                first_name: object.hasOwnProperty("NOMBRE") ? object.NOMBRE.trim().toLowerCase() : null,
                                last_name: object.hasOwnProperty("APELLIDO") ? object.APELLIDO.trim().toLowerCase() : null,
                                company_name: object.hasOwnProperty("NOMBRE_EMPRESA") ? object.NOMBRE_EMPRESA.trim().toLowerCase() : null,
                                document_type: object.TIPO_DOCUMENTO.trim().toLowerCase(),
                                document_number: parseInt(object.NRO_DOCUMENTO),
                                bank: object.BANCO.trim().toLowerCase(),
                                account_type: object.TIPO_CUENTA.trim().toLowerCase(),
                                account_number: parseInt(object.NRO_CUENTA),
                                amount: parseFloat(object.MONTO),
                            }
                        });

                        if (XL_row_object[0] && XL_row_object[0].hasOwnProperty("country")) {
                            sheets.push({
                                data: XL_row_object,
                                sheetName,
                            })
                        }
                    })

                    if (sheets[0] && sheets[0].data) {
                        setState({
                            ...state,
                            selectedFileDocument: target.files[0],
                            sheets: sheets[0].data,
                        });
                    }
                }
            }
        } catch (e) {}
    };

    if (isNext)
        return (
            <div style={{padding: 25, color: '#FFFFFF', backgroundColor: '#232323', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <Button onClick={onPayout} style={{backgroundColor: '#00a6ff', color: '#FFFFFF'}}>
                        Enviar pagos
                    </Button>
                    <div  style={{width: 20}}/>
                    <Button onClick={cancelPayout} style={{backgroundColor: '#ff0000', color: '#FFFFFF'}}>
                        Cancelar
                    </Button>
                </div>
                <div style={{width: '100%', fontSize: 12, maxWidth: 300, padding: 25, textAlign: 'center'}}>
                    Si no esta seguro de estos pagos, por favor cancele y verifique los datos
                </div>
            </div>
        );

    if (state.sheets.length > 0)
        return (
            <div style={{color: '#FFFFFF', backgroundColor: '#232323', height: '100%', padding: 25}}>
                {
                    isLoading && (
                        <div style={{display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <Animated style={{animationIterationCount: 'infinite'}} animationIn="bounceIn" animationOut="fadeOut" isVisible={true}>
                                <img src={Images.vita} width={40} height={40}/>
                            </Animated>
                        </div>
                    )
                }
                <div style={{opacity: isLoading ? .4 : 1}}>
                    {
                        isSuccessful && (
                            <Button onClick={exit} style={{backgroundColor: '#FF0000', color: '#FFFFFF'}}>
                                Salir
                            </Button>
                        )
                    }
                    {
                        isSuccessful && (
                            <Button onClick={exportExcel} style={{marginLeft: 10, backgroundColor: '#00a6ff', color: '#FFFFFF'}}>
                                Exportar Excel
                            </Button>
                        )
                    }
                    {
                        !isLoading && !isSuccessful && isValidRows() && (
                            <Button onClick={showNext} style={{backgroundColor: '#00a6ff', color: '#FFFFFF'}}>
                                Enviar pagos
                            </Button>
                        )
                    }
                    {
                        !isLoading && !isSuccessful && (
                            <Button onClick={cancelBatch} style={{marginLeft: 10, backgroundColor: '#FF0000', color: '#FFFFFF'}}>
                                Cancelar envio
                            </Button>
                        )
                    }
                    {renderError()}
                    <Paper className={classes.root}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>PAIS</TableCell>
                                    <TableCell align="right">TIPO_BENEFICIARIO</TableCell>
                                    <TableCell align="right">NOMBRE</TableCell>
                                    <TableCell align="right">APELLIDO</TableCell>
                                    <TableCell align="right">NOMBRE_EMPRESA</TableCell>
                                    <TableCell align="right">TIPO_DOCUMENTO</TableCell>
                                    <TableCell align="right">NRO_DOCUMENTO</TableCell>
                                    <TableCell align="right">BANCO</TableCell>
                                    <TableCell align="right">TIPO_CUENTA</TableCell>
                                    <TableCell align="right">NRO_CUENTA</TableCell>
                                    <TableCell align="right">MONTO</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.sheets.map(row => (
                                    <TableRow key={row.country} style={{backgroundColor: isInvalidRow(row) ? '#FF0000' : ''}}>
                                        <TableCell style={{fontSize: 15}} component="th" scope="row">
                                            {row.country}
                                        </TableCell>
                                        <TableCell align="right">{row.beneficiary_type}</TableCell>
                                        <TableCell align="right">{row.first_name}</TableCell>
                                        <TableCell align="right">{row.last_name}</TableCell>
                                        <TableCell align="right">{row.company_name}</TableCell>
                                        <TableCell align="right">{row.document_type}</TableCell>
                                        <TableCell align="right">{row.document_number}</TableCell>
                                        <TableCell align="right">{row.bank}</TableCell>
                                        <TableCell align="right">{row.account_type}</TableCell>
                                        <TableCell align="right">{row.account_number}</TableCell>
                                        <TableCell align="right">{toMoney(row.amount, 'COP')}</TableCell>
                                        {
                                            row.transaccion ? (
                                                <TableCell align="right">{row.transaccion}</TableCell>
                                            ) : (
                                                <TableCell align="right"></TableCell>
                                            )
                                        }
                                        {
                                            row.error ? (
                                                <TableCell align="right">{row.error}</TableCell>
                                            ) : (
                                                <TableCell align="right"></TableCell>
                                            )
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </div>
                </div>
        );

    return (
        <div style={{padding: 25, color: '#FFFFFF', backgroundColor: '#232323', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <input
                hidden
                required
                type="file"
                name="file"
                id="file"
                onChange={inputChangeHandle}
            />
            <label htmlFor="file" style={{
                display: 'inline-block',
                backgroundColor: '#00a6ff',
                padding: '15px 40px',
                borderRadius: 5,
                cursor: 'pointer',
                marginTop: '1rem',
            }}>Examinar</label>
            <div style={{width: '100%', fontSize: 12, maxWidth: 300, padding: 25, textAlign: 'center'}}>
                Por favor seleccion el archivo excel con los datos para realizar los pagos por lote
            </div>
        </div>
    );
}

export default withStyles(styles)(BatchPayoutList);
